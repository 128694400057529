import React, { useState } from "react"
import { Tabs, Tab, Divider, Button, Typography, Box } from "@mui/material"
import PropTypes from "prop-types"
import ContactsContent from "./ContactsContent"
import { useSelector } from "react-redux"
import { jwtDecode } from "jwt-decode"
function Contacts({ handleClickAddProspects }) {
    const user = JSON.parse(localStorage.getItem("user")) // eslint-disable-next-line
    const decodedToken = jwtDecode(user?.idToken)
    const [selectedTab, setSelectedTab] = useState(0) // eslint-disable-next-line
    const tabData = [{ label: "Clients" }, { label: "Prospects" }]
    const dashboardContent = useSelector(
        (state) => state.dashboard[btoa(decodedToken?.email)]
    )

    // const clients = useSelector(state => state.general.clients);
    // const prospects = useSelector(state => state.general.prospects);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const TabPanel = ({ children, value, index }) => {
        return (
            <div role="tabpanel" hidden={value !== index}>
                {value === index && <Box>{children}</Box>}
            </div>
        )
    }

    return (
        <>
            <Box sx={{ height: "670px", paddingX: "15px", marginTop: "20px" }}>
                {/** Header */}
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: "Mona Sans",
                            textAlign: "left",
                            fontSize: "18px",
                            fontWeight: 500
                        }}
                    >
                        Contacts
                    </Typography>
                    <Button
                        onClick={handleClickAddProspects}
                        sx={{
                            width: "auto",
                            height: "24px",
                            background: "#6A3BE2 0% 0% no-repeat padding-box",
                            borderRdius: "8px",
                            textTransform: "capitalize",
                            whiteSpace: "nowrap"
                        }}
                        variant="contained"
                    >
                        Add Prospect
                    </Button>
                </div>

                {/** Contacts table */}
                <Tabs
                    key={selectedTab}
                    value={selectedTab}
                    onChange={handleChangeTab}
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#643DD6"
                        },
                        "& .MuiTab-root.Mui-selected": {
                            color: "#643DD6"
                        }
                    }}
                >
                    {tabData.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            sx={{
                                fontFamily: "Mona Sans",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                textAlign: "left",
                                color: "#959595"
                            }}
                        />
                    ))}
                </Tabs>
                <Divider variant="li" />
                {tabData.map((tab, index) => (
                    <TabPanel
                        key={index}
                        value={selectedTab}
                        index={index}
                        sx={{ marginBottom: 1 }}
                    >
                        {selectedTab === index && (
                            <ContactsContent
                                data={
                                    tabData[selectedTab].label === "Clients"
                                        ? dashboardContent?.api5?.clients
                                        : dashboardContent?.api6?.prospects
                                }
                                type={tab.label}
                            />
                        )}
                    </TabPanel>
                ))}
            </Box>
        </>
    )
}

export default Contacts
Contacts.propTypes = {
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.oneOf(["asc", "desc"]),
    orderBy: PropTypes.string,
    rowCount: PropTypes.number
}
