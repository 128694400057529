// save old color palette for future reference
// export const chartColors = [
//     "#8E6BE6", "#7448E0", "#5925DA", "#4B1FB7", "#2E1371", "#2D204B", "#DDD3F7", "#D1C2F5", "#C3B0F2", "#A98EEC",
// ];
import Chart from "chart.js/auto"
import { CategoryScale } from "chart.js"
import { useEffect, useRef, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { useMediaQuery } from "@mui/material"

Chart.register(CategoryScale)

export default function DonutCharts(props) {
    const chartContainerRef = useRef(null)
    const [chartHeight, setChartHeight] = useState(310)
    const isMobileOrTablet = useMediaQuery("(max-width: 1279px)")

    useEffect(() => {
        const updateChartHeight = () => {
            if (chartContainerRef.current) {
                const containerHeight = chartContainerRef.current.clientHeight
                if (isMobileOrTablet) {
                    setChartHeight(310)
                } else {
                    setChartHeight(containerHeight)
                }
            }
        }

        // Call updateChartHeight initially and whenever isMobileOrTablet changes
        updateChartHeight()
        window.addEventListener("resize", updateChartHeight)
        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener("resize", updateChartHeight)
        }
    }, [isMobileOrTablet])

    const dataDonut = {
        labels: props?.data?.label,
        datasets: [
            {
                data: props?.data?.data,
                backgroundColor: [
                    "#643DD6",
                    "#3E90D6",
                    "#7FE1D8",
                    "#FFB94B",
                    "#FF7557"
                ],
                borderWidth: 1
            }
        ]
    }

    const getTooltipLabel = (context) => {
        if (context.datasetIndex === 0) {
            const value = context.dataset.data[context.dataIndex]
            return `${value.toFixed(2) + "%"}`
        }
        return ""
    }

    return (
        <div
            ref={chartContainerRef}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                height: chartHeight
            }}
        >
            <Doughnut
                data={dataDonut}
                options={{
                    cutout: "65%",
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                label: function (context) {
                                    return getTooltipLabel(context)
                                }
                            }
                        },
                        legend: {
                            display: true,
                            position: "bottom"
                        },
                        datalabels: {
                            formatter: function (value, context) {
                                return null
                            },
                            color: "white"
                        }
                    },
                    elements: {
                        arc: {
                            borderWidth: 1
                        }
                    }
                }}
            />
        </div>
    )
}
