import React, { useState, useEffect } from "react"
import { Grid, Typography, TextField } from "@mui/material"
import "./layout.css"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useSelector, useDispatch } from "react-redux"
import {
    setOpenLogout,
    setProfileButton,
    setText,
    setProfileImg,
    setButton,
    setCloseDrawer
} from "../../redux/slices/navSlice"
import { useNavigate } from "react-router-dom"
import Notification from "../common/Notification/Notification"
import {
    getAllNotification,
    addFeedback,
    getAllNotificationBG
} from "../../services/index"
import { toast } from "react-toastify"
import navButton from "./navButtons"
import SideBarMenuLinnear from "../../theme/images/NewSidebarIcons/SideBarMenuLinear.svg"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Rating from "@mui/material/Rating"
import StarRoundedIcon from "@mui/icons-material/StarRounded"
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded"
import { styled } from "@mui/material/styles"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
}

const navButtons = navButton

const profileButtons = [
    {
        id: 0,
        text: "My Profile",
        path: "/profile"
    },
    {
        id: 1,
        text: "Change Password",
        path: "/changePassword"
    },
    {
        id: 3,
        text: "Change Currency",
        path: "/changeCurrency"
    },
    {
        id: 2,
        text: "Logout",
        path: "/logout"
    }
]

const hniProfileButtons = [
    {
        id: 0,
        text: "My Profile",
        path: "/hniprofile"
    },
    {
        id: 4,
        text: "Change Currency",
        path: "/changeCurrency"
    },
    {
        id: 1,
        text: "Change Password",
        path: "/changePassword"
    },
    {
        id: 3,
        text: "Feedback"
    },
    {
        id: 2,
        text: "Logout",
        path: "/logout"
    }
]

const hniMemberButtons = [
    {
        id: 0,
        text: "My Profile",
        path: "/memberProfile"
    },
    {
        id: 1,
        text: "Change Password",
        path: "/changePassword"
    },
    {
        id: 4,
        text: "Change Currency",
        path: "/changeCurrency"
    },
    {
        id: 3,
        text: "Feedback"
    },
    {
        id: 2,
        text: "Logout",
        path: "/logout"
    }
]

const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
        color: "#FFDFOO"
    },
    "& .MuiRating-iconHover": {
        color: "#FFDFOO"
    }
})

function BottomSideBar() {
    const profileImg = useSelector((state) => state.nav.profileImg)

    const text = useSelector((state) => state.nav.text)
    const user = JSON.parse(window.localStorage.getItem("user"))
    const userId = user?.userId
    const userRights = user?.userRights
    const isHni =
        user?.userTypeId?.description === "Client Head" ||
            user?.userTypeId?.description === "Client Family Member"
            ? true
            : false
    let ProfileButtons
    if (user?.userTypeId?.description === "Client Head") {
        ProfileButtons = hniProfileButtons
    } else if (user?.userTypeId?.description === "Client Family Member") {
        ProfileButtons = hniMemberButtons
    } else {
        ProfileButtons = profileButtons
    }
    const isDrawerOpen = useSelector((state) => state.nav.openDrawer)
    const firstName = user.firstName
    const lastName = user.lastName
    const name = lastName === null ? firstName : firstName + " " + lastName

    const dispatch = useDispatch()
    const navigate = useNavigate()
    //eslint-disable-next-line
    const [anchorEl, setAnchorEl] = useState(null)
    const [pushNotificationCount, setpushNotificationCount] = useState(0)
    const [rating, setRating] = useState(0)
    const [feedback, setFeedback] = useState("")
    const [warnings, setWarnings] = useState(false)
    const [anchorMenu, setAnchorMenu] = React.useState(null)
    const [anchorProfile, setAnchorProfile] = React.useState(null)

    const modulesWithPermissions =
        userRights?.filter(
            (permissionObj) =>
                permissionObj.create ||
                permissionObj.readOnly ||
                permissionObj.approve
        ) || []

    let moduleNames = modulesWithPermissions?.map((obj) =>
        obj?.module.replace(/\s/g, "")
    )

    const NewButtons =
        navButtons.filter(
            (buttonObj) =>
                moduleNames.indexOf(buttonObj?.text?.replace(/\s/g, "")) !== -1
        ) || []

    const [openFeedback, setOpenFeedback] = React.useState(user?.feedbackEnable)

    function handleFeedClose() {
        setOpenFeedback(false)
    }
    const handleFeedbackClose = (e) => {
        handleFeedClose()
        e.preventDefault()
        const obj = {
            userId: userId,
            rating: rating,
            feedback: feedback,
            isSubmit: false
        }
        addFeedback(isHni ? "clientfeedback" : "customerfeedback", obj)?.then(
            (res) => {
                handleFeedbackClose()
            }
        )
        setRating(0)
        setFeedback("")
        handleFeedClose()
    }

    // every 5 minutes
    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorMenu)
    const handleClick = (event) => {
        // ** Current figma design instructed to close the sidebar when notifiction btn clicked
        dispatch(setCloseDrawer())
        setAnchorMenu(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorMenu(null)
    }
    const openProfile = Boolean(anchorProfile)
    const handleProfileClick = (event) => {
        setAnchorProfile(event.currentTarget)
    }
    const handleProfileClose = () => {
        setAnchorProfile(null)
    }

    useEffect(() => {
        for (const element of ProfileButtons) {
            if (window.location.pathname.includes(element.path)) {
                dispatch(setButton(element))
                dispatch(setText(element.text))
            }
        }
        for (const element of NewButtons) {
            if (window.location.pathname.includes(element.path)) {
                dispatch(setButton(element))
                dispatch(setText(element.text))
            }
        } // eslint-disable-next-line
    }, [window.location.pathname])

    useEffect(() => {
        const updatedProfilePhotoUrl =
            user?.profilePhotoUrl !== null
                ? `${user?.profilePhotoUrl}?${Date.now()}`
                : null
        dispatch(setProfileImg(updatedProfilePhotoUrl))
        initialNotifications()
        const interval = setInterval(() => {
            getNotificationsBG()
        }, 965000)
        //Clearing the interval
        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [])

    const initialNotifications = async () => {
        const response = await getAllNotification({
            userId: userId,
            pageNo: 0,
            pageSize: 3
        })
        if (response?.data?.totalUnreadCount) {
            await setpushNotificationCount(response?.data?.totalUnreadCount)
        }
    }

    const getNotificationsBG = async () => {
        const res = await getAllNotificationBG({
            userId: userId,
            pageSize: 3,
            pageNo: 0
        })
        if (res) {
            setpushNotificationCount(res?.data?.totalUnreadCount)
        }
    }

    const handleFeedback = (e) => {
        setFeedback(e.target.value)
    }
    const handleRating = (e, newVal) => {
        setRating(newVal)
        if (newVal > 0) {
            setWarnings(false)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const obj = {
            userId: userId,
            rating: rating,
            feedback: feedback,
            submit: true
        }
        if (rating > 0) {
            addFeedback(
                isHni ? "clientfeedback" : "customerfeedback",
                obj
            ).then((res) => {
                handleFeedClose()
                if (res?.data?.status === true) {
                    toast.success(res?.data?.statusMessage)
                } else {
                    toast.error(res?.data?.errorMessage)
                }
            })
        } else {
            setWarnings(true)
        }
    }

    return (
        <>
            <Grid
                sx={{
                    width: "100%",
                    background: { xs: "#171B24", sm: "white" },
                    borderTop: "1px solid #EBEBEB"
                }}
            >
                {isDrawerOpen && (
                    <Grid
                        container
                        sx={{ display: "flex", width: "100%", mt: "7px" }}
                    >
                        <Grid
                            item
                            container
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            sx={{ width: "100%", padding: "0px 0px 5px 21px" }}
                        >
                            {isDrawerOpen && (
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontFamily: "Mona Sans",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "16.8px",
                                            pt: "2px",
                                            textAlign: "left"
                                        }}
                                    >
                                        {"Notification"}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item sx={{ marginRight: "20px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#643DD6",
                                        cursor: "pointer",
                                        width: "24px",
                                        height: "24px",
                                        fontFamily: "Mona Sans",
                                        fontSize: "10px",
                                        fontWeight: 500,
                                        lineHeight: "15px",
                                        textAlign: "middle"
                                    }}
                                    onClick={handleClick}
                                >
                                    {pushNotificationCount <= 99
                                        ? pushNotificationCount
                                        : "99+"}
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {isDrawerOpen && (
                    <Grid
                        container
                        sx={{
                            borderTop: "1px solid #EBEBEB",
                            width: "100%",
                            height: "64px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0px 15px 15px 15px"
                        }}
                    >
                        <Grid item>
                            {profileImg ? (
                                <Avatar
                                    alt="Remy Sharp"
                                    src={profileImg}
                                    sx={{ width: "24px", height: "24px" }}
                                />
                            ) : (
                                <Avatar
                                    sx={{
                                        width: "24px",
                                        height: "24px",
                                        bgcolor: "#F6CD5B",
                                        fontFamily: "Mona Sans",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.8px",
                                        textAlign: "left",
                                        color: "black"
                                    }}
                                >
                                    {name[0]}
                                </Avatar>
                            )}
                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    fontFamily: " Mona Sans",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "16.8px",
                                    textAlign: "left",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis"
                                }}
                                pl={1}
                            >
                                {name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            style={{ cursor: "pointer" }}
                            onClick={handleProfileClick}
                        >
                            <img src={SideBarMenuLinnear} alt="SideMenus"></img>
                        </Grid>
                    </Grid>
                )}
                {!isDrawerOpen && (
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                    >
                        <Grid
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ height: "30px" }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: "#643DD6",
                                    cursor: "pointer",
                                    width: "24px",
                                    height: "24px",
                                    mt: "3px",
                                    fontFamily: "Mona Sans",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                    lineHeight: "15px",
                                    textAlign: "middle"
                                }}
                                onClick={handleClick}
                            >
                                {pushNotificationCount <= 99
                                    ? pushNotificationCount
                                    : "99+"}
                            </Avatar>
                        </Grid>
                    </Grid>
                )}
                {!isDrawerOpen && (
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ width: "100%", borderTop: "1px solid #EBEBEB" }}
                    >
                        <Grid
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ height: "64px", mt: "3px" }}
                        >
                            {profileImg ? (
                                <Avatar
                                    alt="Remy Sharp"
                                    src={profileImg}
                                    onClick={handleProfileClick}
                                    sx={{ width: "24px", height: "24px" }}
                                />
                            ) : (
                                <Avatar
                                    onClick={handleProfileClick}
                                    sx={{
                                        width: "24px",
                                        height: "24px",
                                        bgcolor: "#F6CD5B",
                                        fontFamily: "Mona Sans",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.8px",
                                        textAlign: "left",
                                        color: "black"
                                    }}
                                >
                                    {name[0]}
                                </Avatar>
                            )}
                        </Grid>
                    </Grid>
                )}
                {open && (
                    <Notification
                        onClick={handleClose}
                        onClose={handleMenuClose}
                        open={open}
                        setpushNotificationCount={setpushNotificationCount}
                        anchorEl={anchorMenu}
                    />
                )}

                <Menu
                    anchorEl={anchorProfile}
                    id="account-menu"
                    open={openProfile}
                    onClose={handleProfileClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: 0,
                                mr: 0
                            },
                            "& .MuiButtonBase-root:hover": {
                                backgroundColor: "#693BE1",
                                color: "white"
                            }
                        }
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "bottom" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                >
                    {ProfileButtons.map((data) => (
                        <MenuItem
                            key={data.id}
                            onClick={() => {
                                dispatch(setProfileButton(data))
                                dispatch(setButton(data))
                                data.text === "Feedback" &&
                                    setOpenFeedback(true)
                                data.text !== "Logout" &&
                                    dispatch(setText(data.text))
                                data.text === "Logout" &&
                                    dispatch(setOpenLogout())
                                data.text !== "Logout" && navigate(data.path)
                                handleProfileClose()
                            }}
                        >
                            {data.text}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
            <Grid
                sx={{
                    display: { xs: "block", sm: "none" },
                    padding: "5px 7px ",
                    background: "#F7F7F7",
                    border: "1.1px solid #E0E0E0"
                }}
            >
                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        letterSpacing: "0.16px"
                    }}
                >
                    {text === "Payments" ? "My Transactions" : text}
                </div>
            </Grid>

            {/** Feedback modal */}
            <Modal
                open={openFeedback}
                onClose={handleFeedbackClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit}>
                        <Typography
                            id="modal-modal-title"
                            sx={{
                                fontSize: "16px",
                                fontFamily: "Mona Sans",
                                fontWeight: 600
                            }}
                        >
                            Send us your Feedback!
                        </Typography>
                        <Typography
                            id="modal-modal-title"
                            sx={{
                                fontSize: "13px",
                                fontFamily: "Mona Sans",
                                mt: 1.5
                            }}
                        >
                            Rate your experience with Kairos so far.
                        </Typography>
                        <StyledRating
                            name="customized-color"
                            value={rating}
                            onChange={(e, newVal) => handleRating(e, newVal)}
                            precision={1}
                            icon={<StarRoundedIcon fontSize="large" />}
                            emptyIcon={
                                <StarOutlineRoundedIcon fontSize="large" />
                            }
                        />
                        {warnings && (
                            <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    fontFamily: "Mona Sans",
                                    marginTop: "-3px",
                                    marginLeft: "10px"
                                }}
                            >
                                This field is required
                            </p>
                        )}
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 0.5 }}
                        >
                            Feedback
                        </Typography>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            name="feedback"
                            rows={4}
                            inputProps={{
                                maxLength: 1000
                            }}
                            placeholder="Type your enquiry here"
                            value={feedback}
                            onChange={(e) => handleFeedback(e)}
                            fullWidth
                        />
                        <div style={{ display: "flex", marginTop: "20px" }}>
                            <button
                                type="submit"
                                style={{
                                    marginRight: "20px",
                                    padding: "5px 15px",
                                    background: "#6A3BE2",
                                    border: "1px solid #6A3BE2",
                                    borderRadius: "4px",
                                    color: "white",
                                    cursor: "pointer"
                                }}
                            >
                                Submit
                            </button>
                            <button
                                style={{
                                    padding: "5px 15px",
                                    borderRadius: "4px",
                                    color: "#7A86A1",
                                    background: "white",
                                    border: "1px solid #7A86A1",
                                    cursor: "pointer"
                                }}
                                onClick={handleFeedbackClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default BottomSideBar
