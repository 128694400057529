import { Typography, Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme"

const Header = ({
    title,
    subtitle,
    dark,
    isNonMobile,
    fontHeaderWeight,
    fontFamilies
}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return (
        <Box mb="30px" fullwidth="true">
            <Typography
                variant="h3"
                color={
                    !isNonMobile
                        ? "white"
                        : dark
                          ? colors.grey[100]
                          : colors.grey[700]
                }
                fontWeight="bold"
                sx={{
                    m: "0 0 5px 0",
                    fontSize: "20px",
                    fontFamily: fontFamilies ? fontFamilies : "Mona Sans",
                    fontWeight: fontHeaderWeight ? fontHeaderWeight : 300
                }}
            >
                {title}
            </Typography>
            <Typography
                variant="h6"
                color={
                    !isNonMobile
                        ? "white"
                        : dark
                          ? colors.grey[100]
                          : colors.grey[700]
                }
                sx={{
                    fontFamily: fontFamilies ? fontFamilies : "Mona Sans",
                    maxWidth: "300px",
                    fontSize: "14px"
                }}
            >
                {subtitle}
            </Typography>
        </Box>
    )
}

export default Header
