import { Button } from "../Form"
import { WarningAmber, Clear } from "@mui/icons-material"
import {
    DialogContentText,
    Dialog,
    DialogContent,
    IconButton,
    DialogActions,
    Typography,
    Box,
    Stack
} from "@mui/material"

const ConfirmDeleteDialog = ({
    showModal,
    handleCancelBtn: closeModal,
    handleDeleteBtn,
    isLoading
}) => {
    return (
        <Dialog
            open={showModal}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Stack direction="row" spacing={4}>
                    <Box
                        sx={{
                            bgcolor: "red.100",
                            borderRadius: "999px",
                            alignSelf: "flex-start",
                            padding: "4px 8px"
                        }}
                    >
                        <WarningAmber
                            sx={{ color: "red.500" }}
                            fontSize="large"
                        />
                    </Box>
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Delete confirmation
                        </Typography>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete data? All of data
                            will be permanently removed from our servers
                            forever. This action cannot be undone.
                        </DialogContentText>
                    </Box>
                    <IconButton
                        aria-label="close"
                        sx={{ alignSelf: "flex-start", padding: 0 }}
                        onClick={closeModal}
                    >
                        <Clear fontSize="medium" />
                    </IconButton>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
                <Button variant="outline" onClick={closeModal} sx={{ mr: 1 }}>
                    Cancel
                </Button>
                <Button
                    disabled={isLoading}
                    sx={{
                        backgroundColor: "red.600",
                        color: "#fff",
                        "&:hover": { backgroundColor: "red.700" },
                        "&:disabled": {
                            color: "#fff",
                            backgroundColor: "red.400"
                        }
                    }}
                    onClick={handleDeleteBtn}
                >
                    {isLoading ? "Delete..." : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteDialog
