import { createSlice } from "@reduxjs/toolkit"

const dealRoomSlice = createSlice({
    name: "dealRoom",
    initialState: {
        dealsData: [],
        selectedDeal: {},
        addMetricModalOpen: false,
        addInvestorModalOpen: false,
        addTeamModalOpen: false,
        addDocumentModalOpen: false,
        editDealFormSubmitTriggered: false
    },
    reducers: {
        setDealsData: (state, action) => {
            state.dealsData = action.payload
        },
        setSelectedDeal: (state, action) => {
            state.selectedDeal = action.payload
        },
        setAddMetricModalOpen: (state, action) => {
            state.addMetricModalOpen = action.payload
        },
        setAddInvestorModalOpen: (state, action) => {
            state.addInvestorModalOpen = action.payload
        },
        setAddTeamModalOpen: (state, action) => {
            state.addTeamModalOpen = action.payload
        },
        setAddDocumentModalOpen: (state, action) => {
            state.addDocumentModalOpen = action.payload
        },
        editDealTriggerFormSubmit: (state) => {
            state.editDealFormSubmitTriggered = true
        },
        editDealResetFormSubmit: (state) => {
            state.editDealFormSubmitTriggered = false
        }
    }
})
export const {
    setDealsData,
    setSelectedDeal,
    setAddMetricModalOpen,
    setAddInvestorModalOpen,
    setAddTeamModalOpen,
    setAddDocumentModalOpen,
    editDealTriggerFormSubmit,
    editDealResetFormSubmit
} = dealRoomSlice.actions

export default dealRoomSlice.reducer
