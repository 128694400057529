import { useField } from "formik"
import { TextField } from "@mui/material"

const TextFieldWrapper = ({ name, size = "small", rows = 2, ...props }) => {
    const [field, meta] = useField(name)

    const config = {
        ...field,
        ...props,
        fullWidth: true,
        size,
        rows
    }

    if (meta && meta.error && meta.touched) {
        config.error = true
        config.helperText = meta.error
    }

    return (
        <TextField
            sx={{
                font: "inherit",
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {},
                    "&:hover fieldset": {
                        borderColor: "#7450da"
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#7450da"
                    }
                }
            }}
            {...config}
        />
    )
}
export default TextFieldWrapper
