import React from "react"
import { Grid, Pagination } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import ProductCard from "../ProductCard/ProductCard"
import ProductCardLinear from "../ProductCard/ProductCardLinear"
import Select from "@mui/material/Select"
import moment from "moment"
import Typography from "@mui/material/Typography"
import "./CatalogCards.css"
import { useSelector } from "react-redux"
/******************************************************************************************/
/* Component displaying product catalogue in Indvidual category view,    */
/******************************************************************************************/
// Sorting types
let sortItems = [
    { id: 1, description: "Price: Low to High" },
    { id: 2, description: "Price: High to Low" },
    { id: 3, description: "Newest Arrivals" }
]

// Component displaying product catalogue in a specific asset class view, with horizontal cards displayed
const CardsDisplayList = (props) => {
    const [dataListOnUI, setDataListOnUI] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const [filterParam, setFilterParam] = React.useState(sortItems[0].id)

    const isGridDisplay = useSelector((state) => state.nav.isGridProductDisplay)

    React.useEffect(() => {
        if (props?.datalist?.productServiceCatalogueResponseDto) {
            const sortedData = sortData(
                props.datalist.productServiceCatalogueResponseDto,
                filterParam
            )
            setDataListOnUI(sortedData)
        }
    }, [props?.datalist?.productServiceCatalogueResponseDto, filterParam])
    // Methods to sort depends on selection
    const sortData = (data, sortOrder) => {
        let sortedData = [...data]
        if (sortOrder === 1) {
            sortedData.sort(
                (a, b) =>
                    parseFloat(a.sellingPrice) - parseFloat(b.sellingPrice)
            )
        } else if (sortOrder === 2) {
            sortedData.sort(
                (a, b) =>
                    parseFloat(b.sellingPrice) - parseFloat(a.sellingPrice)
            )
        } else if (sortOrder === 3) {
            sortedData.sort(
                (a, b) =>
                    moment(b.updatedAt).format("X") -
                    moment(a.updatedAt).format("X")
            )
        }
        return sortedData
    }

    const handleChangeSort = (event) => {
        setFilterParam(event.target.value)
    }

    const handleChangePage = (event, value) => {
        setCurrentPage(value)
    }
    // pagination works FE only
    const localPagination = (page_size, page_number, data) => {
        return data.slice(
            (page_number - 1) * page_size,
            page_number * page_size
        )
    }

    if (props.loading) {
        return <h2>Loading...</h2>
    }

    return (
        <Grid
            sx={{
                mb: 4,
                paddingX: "10px",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Grid
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                {/* Display category header */}
                <Typography sx={{ fontSize: "18px", fontWeight: "500", mb: 2 }}>
                    {props?.datalist?.category[0]?.toUpperCase() +
                        props?.datalist?.category?.slice(1).toLowerCase()}
                </Typography>
                {/* Option TO display Sorting Dropdown */}
                <Grid pt={"5px"} item flexDirection={"row"}>
                    <Select
                        labelId="simple-select-autowidth-label-cardList"
                        value={filterParam}
                        id="simple-select-autowidth"
                        sx={{
                            fontFamily: "Mona Sans",
                            height: "24px",
                            fontSize: "14px",
                            "& input::placeholder": {
                                textOverflow: "ellipsis !important",
                                color: "#363636 !important",
                                fontFamily: "Mona Sans",
                                opacity: 1
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "8px",
                                border: "0px solid"
                            },
                            "& .MuiInputBase-root": {
                                fontFamily: "Mona Sans",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box"
                            }
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={handleChangeSort}
                    >
                        <MenuItem selected disabled default value="">
                            <em>Sort By</em>
                        </MenuItem>
                        {sortItems.map((data) => (
                            <MenuItem key={data.id} value={data.id}>
                                {data.description}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            {/** Cards (displayed based on grid display selector) */}
            <Grid sx={{ mb: 2 }}>
                {Array.isArray(dataListOnUI) && (
                    <>
                        {isGridDisplay ? (
                            <Grid
                                display="flex"
                                flexDirection="row"
                                flexWrap="wrap"
                                width="100%"
                            >
                                {localPagination(
                                    20,
                                    currentPage,
                                    dataListOnUI
                                ).map((product, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        display="flex"
                                        flexDirection="row"
                                        gap={2}
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        justifyContent={"space-around"}
                                        sx={{
                                            paddingRight: 2,
                                            paddingBottom: 2,
                                            minWidth: {
                                                xs: "80%",
                                                md: "40%",
                                                lg: "30%"
                                            }
                                        }}
                                    >
                                        <ProductCard product={product} />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Grid
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                sx={
                                    dataListOnUI.length > 0
                                        ? {
                                              backgroundColor: "white",
                                              borderRadius: "12px",
                                              paddingY: 1,
                                              border: "1px solid #ebebeb"
                                          }
                                        : null
                                }
                            >
                                {localPagination(
                                    20,
                                    currentPage,
                                    dataListOnUI
                                ).map((product, index) => (
                                    <Grid
                                        key={index}
                                        display="flex"
                                        flexDirection="column"
                                        gap={1}
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        justifyContent={"flex-start"}
                                        sx={{
                                            ":hover": {
                                                backgroundColor: "#f8f8f8"
                                            }
                                        }}
                                    >
                                        <ProductCardLinear
                                            product={product}
                                            sx={{ width: "100%" }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </>
                )}
                {!dataListOnUI.length && (
                    <Grid container spacing={3}>
                        <p className="nodataMessage">
                            {" "}
                            No {props?.datalist?.category?.toLowerCase() ||
                                ""}{" "}
                            details
                        </p>
                    </Grid>
                )}
            </Grid>
            {/** Pagination */}
            <Pagination
                sx={{
                    "& .MuiPaginationItem-root.Mui-selected": {
                        background:
                            "linear-gradient(61deg, #D2C6FC -0.84%, #9FB3F0 83.4%)",
                        color: "white !important"
                    }
                }}
                count={Math.ceil(
                    (props?.datalist?.productServiceCatalogueResponseDto
                        ?.length || 0) / 20
                )}
                page={currentPage}
                onChange={handleChangePage}
                style={{ float: "right" }}
            />
        </Grid>
    )
}

export default CardsDisplayList
