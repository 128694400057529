import * as yup from "yup"
export const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export const checkoutSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required")
    // contact: yup
    //     .string()
    //     .matches(phoneRegExp, "Phone number is not valid")
    //     .required("required"),
    // otp: yup.string().email("invalid OTP").required("required"),
})
export const initialValues = {
    email: "selvan315731@gmail.com",
    contact: "+91 90034 23232"
}
