import React from "react"
import { Grid, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { setWarning } from "../../../redux/slices/navSlice"

function Buttons({
    index,
    question,
    setIndex,
    totalPages,
    handleSubmit,
    array
}) {
    const dispatch = useDispatch()
    const surveyId = useSelector((state) => state.nav.surveyId)

    function handleNext() {
        if (surveyId === 3) {
            const missingQuestions = question.filter((question) => {
                return !array.some(
                    (arr) => arr.questionId === question.surveyQuestionId
                )
            })
            if (missingQuestions.length === 0) {
                setIndex(index + 1)
                dispatch(setWarning(false))
            } else {
                dispatch(setWarning(true))
            }
        } else {
            setIndex(index + 1)
        }
    }

    function handlePrevious() {
        setIndex(index - 1)
    }

    return (
        <div>
            <Grid container sx={{ display: "flex", marginBottom: "20px" }}>
                {index > 0 && (
                    <Button
                        variant="outlined"
                        sx={{
                            marginTop: { xs: "10px", sm: "" },
                            color: "#767676",
                            width: { xs: "100%", sm: "110px" },
                            marginRight: { sm: "30px", xs: "" },
                            "&:hover": {
                                color: "#767676"
                            }
                        }}
                        onClick={handlePrevious}
                    >
                        Previous
                    </Button>
                )}
                <Button
                    variant="contained"
                    sx={{
                        color: "white",
                        background: "#683AE0",
                        marginRight: { sm: "30px", xs: "" },
                        "&:hover": {
                            backgroundColor: "#683AE0"
                        },
                        width: { xs: "100%", sm: "110px" },
                        marginTop: { xs: "12px", sm: "" }
                    }}
                    onClick={
                        totalPages !== index + 1 ? handleNext : handleSubmit
                    }
                >
                    {totalPages !== index + 1 ? "Next" : "Submit"}
                </Button>
            </Grid>
        </div>
    )
}

export default Buttons
