import { Grid, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

const FinancialFilterTabs = ({ selectedButton, handleButtonClick }) => {
    const { t } = useTranslation()
    const buttons = [
        {
            value: t("Stocks"),
            englishValue: "Stocks"
        },
        {
            value: t("Internal Data"),
            englishValue: "Internal Data"
        },
        {
            value: t("Portfolio"),
            englishValue: "Portfolio"
        },
        {
            value: t("Agent"),
            englishValue: "Agent"
        }
    ]
    return (
        <Grid container spacing={2} px={1} mt={1}>
            {buttons.map((label, index) => (
                <Grid item xs={12} lg={6}>
                    <Button
                        key={index}
                        fullWidth={true}
                        color="brand"
                        onClick={() => handleButtonClick(label)}
                        variant={
                            selectedButton === label.value
                                ? "contained"
                                : "outlined"
                        }
                    >
                        {label.value}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )
}
export default FinancialFilterTabs
