import { Paper, InputBase, IconButton } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

const SearchInput = ({ value, onChange, placeholder }) => {
    return (
        <Paper
            component="div"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "none",
                width: {
                    xl: 1 / 3,
                    lg: 1 / 2,
                    sm: 1
                }
            }}
        >
            <InputBase
                placeholder={placeholder}
                fullWidth
                sx={{
                    border: "1px solid #EBEBEB",
                    borderRadius: "999px",
                    padding: "6px 16px",
                    font: "inherit",
                    color: "#1E1E1E",
                    "&.Mui-focused": {
                        borderColor: "#643DD6"
                    }
                }}
                inputProps={{ "aria-label": placeholder }}
                value={value}
                onChange={onChange}
            />
            <IconButton
                type="button"
                sx={{ p: "10px", ml: "-100px" }}
                aria-label="search"
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    )
}

export default SearchInput
