import { Axios1 } from "./config"

export const getDirectDeals = async (data) => {
    var resultAPI = await Axios1.post(
        "/api/v1/direct-deal/get-direct-deals",
        data
    )
    return resultAPI
}

export const getDirectDeal = async (dealId) => {
    var resultAPI = await Axios1.get(
        "/api/v1/direct-deal/get-direct-deal/" + dealId
    )
    return resultAPI
}

export const addDirectDeal = async (data) => {
    var resultAPI = await Axios1.post(
        "/api/v1/direct-deal/add-direct-deal",
        data
    )
    return resultAPI
}

export const addDirectDealDocument = async (data) => {
    var resultAPI = await Axios1.post(
        "/api/v1/direct-deal/add-direct-deal-document",
        data
    )
    return resultAPI
}

export const addDirectDealMetric = async (data) => {
    var resultAPI = await Axios1.post(
        "/api/v1/direct-deal/add-direct-deal-metric",
        data
    )
    return resultAPI
}

export const addDirectDealInvestor = async (data) => {
    var resultAPI = await Axios1.post(
        "/api/v1/direct-deal/add-direct-deal-investor",
        data
    )
    return resultAPI
}

export const addDirectDealMember = async (data) => {
    var resultAPI = await Axios1.post(
        "/api/v1/direct-deal/add-direct-deal-member",
        data
    )
    return resultAPI
}

export const updateDirectDeal = async (data, dealId) => {
    var resultAPI = await Axios1.put(
        "/api/v1/direct-deal/update-direct-deal/" + dealId,
        data
    )
    return resultAPI
}

export const updateDirectDealMetric = async (data) => {
    var resultAPI = await Axios1.put(
        "/api/v1/direct-deal/update-direct-deal-metric",
        data
    )
    return resultAPI
}

export const updateDirectDealDocument = async (data) => {
    var resultAPI = await Axios1.put(
        "/api/v1/direct-deal/update-direct-deal-document",
        data
    )
    return resultAPI
}

export const updateDirectDealInvestor = async (data) => {
    var resultAPI = await Axios1.put(
        "/api/v1/direct-deal/update-direct-deal-investor",
        data
    )
    return resultAPI
}

export const updateDirectDealMember = async (data) => {
    var resultAPI = await Axios1.put(
        "/api/v1/direct-deal/update-direct-deal-member",
        data
    )
    return resultAPI
}

export const deleteDirectDeal = async (id) => {
    var resultAPI = await Axios1.delete(
        "/api/v1/direct-deal/delete-direct-deal/" + id
    )
    return resultAPI
}

export const deleteDirectDealMetric = async (metricId, dealId) => {
    var resultAPI = await Axios1.delete(
        "/api/v1/direct-deal/delete-direct-deal-metric/" + dealId + "/" + metricId
    )
    return resultAPI
}

export const deleteDirectDealInvestor = async (investorId, dealId) => {
    var resultAPI = await Axios1.delete(
        "/api/v1/direct-deal/delete-direct-deal-investor/" + dealId + "/" + investorId
    )
    return resultAPI
}

export const deleteDirectDealMember = async (memberId, dealId) => {
    var resultAPI = await Axios1.delete(
        "/api/v1/direct-deal/delete-direct-deal-member/" + dealId + "/" + memberId
    )
    return resultAPI
}

export const deleteDirectDealDocument = async (documentId, dealId) => {
    var resultAPI = await Axios1.delete(
        "/api/v1/direct-deal/delete-direct-deal-document/" + dealId + "/" + documentId
    )
    return resultAPI
}

export const submitDealForApproval = async (dealId) => {
    var resultAPI = await Axios1.put(
        "/api/v1/direct-deal/submit-deal-for-approval/" + dealId
    )
    return resultAPI
}

export const approveOrRejectDeal = async (dealId, data) => {
    var resultAPI = await Axios1.put(
        "/api/v1/direct-deal/approve-or-reject-deal/" + dealId,
        data
    )
    return resultAPI
}
