import { Box, Typography } from "@mui/material"
const RelatedPartyWrapper = ({ title, subtitle, children }) => {
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                py: 4,
                px: 6,
                borderRadius: 1,
                mt: 4
            }}
        >
            <Box
                sx={{
                    pb: 3,
                    borderBottom: "1px solid",
                    borderColor: "gray.200",
                    mb: 4
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: 500, mb: 1 }}>
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ color: "gray.500", maxWidth: "90ch" }}
                >
                    {subtitle}
                </Typography>
            </Box>
            {children}
        </Box>
    )
}
export default RelatedPartyWrapper
