/* eslint-disable no-unused-vars */
import React from "react"
import {
    Typography,
    Grid,
    useMediaQuery,
    Box,
    CircularProgress
} from "@mui/material"
import "./hniDashboard.css"
import { Link, useNavigate } from "react-router-dom"
import Slider from "@mui/material/Slider"
import PieChartController from "../DashboardModalController/PieChartController"
import BarChartController from "../DashboardModalController/BarChartController"
import DynamicDashboardCards from "../../../common/Grid/DynamicDashboardCards"
import { useDispatch, useSelector } from "react-redux"
import {
    setText,
    setButton,
    setchatBot,
    setChatWindowShow,
    setTargetValue
} from "../../../../redux/slices/navSlice"
import "../../../../theme/styles/carosuelstyles.css"
import { useTheme } from "@mui/material/styles"
import { jwtDecode } from "jwt-decode"

const marks = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" }
]

function HniDashboardSectionone({ loader, User, isDataFetching }) {
    let cols
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const user = JSON.parse(localStorage.getItem("user"))
    const isHni =
        user?.userTypeId?.description === "Client Head" ||
        user?.userTypeId?.description === "Client Family Member"
            ? true
            : false
    const targetValue = useSelector((state) => state.nav.targetValue)
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"))
    const isMediumScreen = useMediaQuery((theme) =>
        theme.breakpoints.between("md", "lg")
    )
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"))
    const isMobileOrTablet = useMediaQuery("(max-width: 1279px)")
    const isXs = useMediaQuery(theme.breakpoints.down("sm"))
    const [windowSize, setWindowSize] = React.useState([
        window.innerWidth,
        window.innerHeight
    ])
    const decodedToken = jwtDecode(user?.idToken)
    // depending on user mail id -> We use base64 format to encrypt the user mail id., the data fetch from redux presist.
    const dashboardContent = useSelector(
        (state) => state.dashboard[btoa(decodedToken?.email)]
    )

    //Checking Condtion For Chat wiith RM , from User rights
    const userRights = user?.userRights
    const chatWithRM = userRights.find(
        (item) => item.module === "Chat with RM" && item.create === true
    )

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener("resize", handleWindowResize)

        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }) // eslint-disable-next-line

    if (isLargeScreen) {
        cols = 4 // Number of columns for large screens
    } else if (isMediumScreen) {
        cols = 4 // Number of columns for medium screens
    } else if (isSmallScreen) {
        cols = 2 // Number of columns for small screens
    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: "none",
        padding: "20px 16px 30px 16px"
    }
    const firstName = User.firstName
    const lastName = User.lastName
    const name = lastName === null ? firstName : firstName + " " + lastName
    const handleFaqs = (button) => {
        if (button.path === "/chatgpt") {
            dispatch(setchatBot(true))
            dispatch(setTargetValue("ChatGpt"))
            navigate(button.path)
        } else if (
            (button.text === "Chat with RM" || button.text === "Chat") &&
            chatWithRM
        ) {
            dispatch(setButton(button))

            if (!isHni) {
                dispatch(setText(button.text))
            }
            if (isHni) {
                dispatch(setChatWindowShow(true))
            } else {
                navigate(button.path)
            }
        } else if (targetValue === "ChatGpt") {
            if (button.path === "/chatgpt/history") {
                dispatch(setchatBot(true))
                dispatch(setTargetValue("ChatGpt"))
                navigate(button.path)
            }
        } else {
            console.log(button, "buttonnnnnnnn")
            dispatch(setButton(button))
            dispatch(setText(button.text))
            dispatch(setchatBot(false))
            navigate(button.path)
        }
    }

    return (
        <div>
            {/** Gradient top banner background */}
            <Box
                sx={{
                    width: "200%",
                    marginLeft: "-50%",
                    height: { xs: "160px", sm: "250px" },
                    background:
                        "radial-gradient(at bottom center, #ffffff 0%, #643DD6 4%, #41288A 35%, #1E133E 65%);",
                    display: "block",
                    position: "absolute",
                    top: "0px",
                    zIndex: "-10"
                }}
            ></Box>

            {/** Top dashboard cards */}
            <Box
                width="100%"
                sx={{
                    height: "auto",
                    paddingX: "10px",
                    backgroundColor: "rgba(0,0,0,0)",
                    marginTop: isXs ? "0px" : "24px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        marginBottom: isXs ? "10px" : "30px"
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "40px",
                            fontFamily: "The Seasons",
                            fontWeight: 600,
                            color: "white"
                        }}
                        className="welcomeText"
                    >{`Welcome ${user?.firstName}`}</Typography>
                    <Box>
                        {isDataFetching && (
                            <CircularProgress
                                size={40}
                                sx={{ color: "brand.light" }}
                            />
                        )}
                    </Box>
                </Box>
                <DynamicDashboardCards
                    data={dashboardContent?.api1?.cardList}
                    cardsPerRow={3}
                />
            </Box>

            {user?.userTypeId?.description === "Client Head" && (
                <>
                    <Grid
                        container
                        className="secondContainer"
                        sx={{ paddingX: "10px" }}
                    >
                        <Grid item>
                            <Typography>
                                Current Investment Approach:{" "}
                                <span style={{ color: "#6A3BE0" }}>
                                    {
                                        dashboardContent?.api1?.dashboardList
                                            ?.survey?.surveyInference
                                    }
                                </span>
                            </Typography>
                            <Slider
                                value={
                                    dashboardContent?.api1?.dashboardList
                                        ?.survey?.totalPoint || 0
                                }
                                marks={marks}
                                step={null}
                                min={10}
                                max={40}
                                sx={{
                                    left: "5px",
                                    "& .MuiSlider-thumb": {
                                        color: "#FFFFFF",
                                        width: 8,
                                        height: 8,
                                        border: "1px solid #5D5D5D"
                                    },
                                    "& .MuiSlider-track": {
                                        color: "#6A3BE0"
                                    },
                                    "& .MuiSlider-markLabel": {
                                        color: "#737373"
                                    },
                                    "& .MuiSlider-mark": {
                                        color: "transparent"
                                    },
                                    "& .MuiSlider-rail": {
                                        color: "#E2E0E0"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>
                                To update financial overview{" "}
                                {user?.userTypeId?.description ===
                                "Client Head" ? (
                                    <Link
                                        style={{ color: "#7037E7" }}
                                        to={"/hniprofile"}
                                        state={{ finacial: true }}
                                    >
                                        click here
                                    </Link>
                                ) : (
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            textDecoration: "underline",
                                            color: "#7037e7"
                                        }}
                                    >
                                        click here
                                    </span>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}

            {/** Desktop main display */}
            {!isMobileOrTablet ? (
                <Box
                    width="100%"
                    sx={{ height: "auto", paddingX: "10px", paddingTop: "0px" }}
                >
                    <Grid container xs={12}>
                        {/* Main graphs (bars and pies) */}
                        <Grid container item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    maxHeight: "500px",
                                    minHeight: "460px",
                                    marginTop: 0
                                }}
                            >
                                <Grid item xs={12} md={12}>
                                    <BarChartController
                                        title={"TTM Aggregate AUM"}
                                        data={
                                            dashboardContent?.api1
                                                ?.dashboardList
                                                ?.ttmAggregateAumBarChart
                                        }
                                        category={"AUM"}
                                        isloading={loader}
                                        type={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    maxHeight: "420px",
                                    minHeight: "410px",
                                    marginY: 1
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <PieChartController
                                        title={"Aggregate Asset Allocation"}
                                        data={
                                            dashboardContent?.api2
                                                ?.dashboardListsequencetwo
                                                ?.yourCurrentPortfolioAssetAllocation
                                        }
                                        category={"Asset"}
                                        isloading={loader}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PieChartController
                                        title={"Aggregate Sector Allocation"}
                                        data={
                                            dashboardContent?.api2
                                                ?.dashboardListsequencetwo
                                                ?.sectorAssetAllocation
                                        }
                                        category={"Sector"}
                                        isloading={loader}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Grid container columnSpacing={1} sx={{ paddingX: "10px" }}>
                    {/** Mobile and tablet main display */}
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{ marginTop: "5px", marginBottom: "5px" }}
                    >
                        <Grid container columnSpacing={1}>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                sx={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                                <BarChartController
                                    title={"TTM Aggregate AUM"}
                                    data={
                                        dashboardContent?.api1?.dashboardList
                                            ?.ttmAggregateAumBarChart
                                    }
                                    category={"AUM"}
                                    isloading={loader}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                sx={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                                <PieChartController
                                    title={"Aggregate Asset Allocation"}
                                    data={
                                        dashboardContent?.api2
                                            ?.dashboardListsequencetwo
                                            ?.yourCurrentPortfolioAssetAllocation
                                    }
                                    category={"Asset"}
                                    isloading={loader}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                sx={{ marginTop: "5px", marginBottom: "5px" }}
                            >
                                <PieChartController
                                    title={"Aggregate Sector Allocation"}
                                    data={
                                        dashboardContent?.api2
                                            ?.dashboardListsequencetwo
                                            ?.sectorAssetAllocation
                                    }
                                    category={"Sector"}
                                    isloading={loader}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

export default HniDashboardSectionone
