import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
import Box from "@mui/material/Box"
import { motion, AnimatePresence } from "framer-motion"

import StepperWrapper from "./components/StepperWrapper"
import {
    InvestorTypeDetails,
    PersonalDetails,
    IndividualSuitabilityDetails,
    ReviewAndSubmit,
    IndividualKycDetails,
    EntityDetails,
    EntitySuitabilityDetails,
    RelatedPartyDetails,
    EntityKycDetails
} from "./steps"
import { useLinkVerification } from "./hooks/useLinkVerification"

const SelfOnboardPage = () => {
    const { direction, activeStep, investorType, totalStepsCount } =
        useSelector((state) => state.selfOnboard)
    const { isLoading: isVerificationProcessing } = useLinkVerification()

    if (isVerificationProcessing) {
        return (
            <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <StepperWrapper>
            <AnimatePresence initial={false} custom={direction}>
                <Box
                    sx={{
                        overflowX: "hidden",
                        mt: 4,
                        bgcolor: "#fff",
                        px: 6,
                        py: 4
                    }}
                >
                    <MotionWrapper id={activeStep} direction={direction}>
                        {activeStep === 0 && <InvestorTypeDetails />}
                        {activeStep === totalStepsCount - 1 && (
                            <ReviewAndSubmit />
                        )}
                        {investorType === "INDIVIDUAL" ? (
                            <>
                                {activeStep === 1 && <PersonalDetails />}
                                {activeStep === 2 && (
                                    <IndividualSuitabilityDetails />
                                )}
                                {activeStep === 3 && <IndividualKycDetails />}
                            </>
                        ) : (
                            <>
                                {activeStep === 1 && <EntityDetails />}

                                {activeStep === 2 && (
                                    <EntitySuitabilityDetails />
                                )}
                                {activeStep === 3 && <RelatedPartyDetails />}
                                {activeStep === 4 && <EntityKycDetails />}
                            </>
                        )}
                    </MotionWrapper>
                </Box>
            </AnimatePresence>
        </StepperWrapper>
    )
}

const MotionWrapper = ({ children, id, direction }) => {
    return (
        <motion.div
            variants={variants}
            transition={{
                x: {
                    type: "spring",
                    stiffness: 300,
                    damping: 30
                },
                opacity: { duration: 0.2 }
            }}
            initial="enter"
            animate="center"
            exit="exit"
            custom={direction}
            key={id}
        >
            {children}
        </motion.div>
    )
}

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 200 : -200,
            opacity: 0
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 200 : -200,
            opacity: 0
        }
    }
}

export default SelfOnboardPage
