export const checkPdfFormat = (value) => {
    if (value) {
        const supportedFormats = ["pdf", "png", "jpeg", "jpg"]
        return supportedFormats.includes(value.name.split(".").pop())
    }
    return true
}

export const checkPdfSize = (value) => {
    if (value) {
        return value.size <= 9 * 1024 * 1024
    }
    return true
}
