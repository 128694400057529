import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getDropDownProspectPersonal } from "services"

export const fetchGeneralDropdowns = createAsyncThunk(
    "general/fetchGeneralDropdowns",
    async () => {
        const resp = await getDropDownProspectPersonal()
        return resp.data
    }
)

const generalSlice = createSlice({
    name: "general",
    initialState: {
        isLicensed: window.location.hostname === 'app.kairoswealth.com' ? false : true, // change to true to display full ordering system, or false to limit to product directory
        clients: {},
        prospects: {},
        marketNews: {},
        isContactsLoading: false,
        isMarketNewsLoading: false,
        rmRecommendedProducts: [],
        rmRecommendedServices: [],
        countries: [],
        gender: [],
        salutation: [],
        education: []
    },
    reducers: {
        setIsLicensed: (state, action) => {
            state.isLicensed = action.payload;
        },
        setClientsData: (state, action) => {
            state.clients = action.payload
        },
        setProspectsData: (state, action) => {
            state.prospects = action.payload
        },
        setMarketNewsData: (state, action) => {
            state.marketNews = action.payload
        },
        setIsContactsLoading: (state, action) => {
            state.isContactsLoading = action.payload
        },
        setIsMarketNewsLoading: (state, action) => {
            state.isMarketNewsLoading = action.payload
        },
        setRmRecommendedProducts: (state, action) => {
            state.rmRecommendedProducts = action.payload
        },
        setRmRecommendedServices: (state, action) => {
            state.rmRecommendedServices = action.payload
        },
        setCountries: (state, action) => {
            state.countries = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchGeneralDropdowns.fulfilled, (state, action) => {
            const { countries, gender, salutation, education } =
                action.payload.body
            state.education = education
            state.countries = countries
            state.gender = gender
            state.salutation = salutation
        })
    }
})
export const {
    setIsLicensed,
    setClientsData,
    setProspectsData,
    setMarketNewsData,
    setIsContactsLoading,
    setIsMarketNewsLoading,
    setRmRecommendedProducts,
    setRmRecommendedServices,
    setCountries
} = generalSlice.actions

export default generalSlice.reducer
