import { FormModal } from "../FormModal/FormModals"
import { vendorIDVALUE } from "../../../../../services/vendormanagement"
const {
    formField: {
        vendorId,
        vendorName,
        vendorCode,
        vendorTypeId,
        vendorSubtypeId,
        businessIdentityNo,
        taxId,
        takeRate,
        takeRateParameterId,
        keyPocPrefix,
        emailId,
        keyPointOfContact,
        contactDetails,
        countryPrifixId,
        registeredAddress,
        city,
        state,
        country,
        zip
    }
} = FormModal

export const fieldValuesVendor = {
    [vendorId.name]: "",
    [vendorName.name]: "",
    [vendorCode.name]: "",
    [vendorTypeId.name]: "",
    [vendorSubtypeId.name]: "",
    [businessIdentityNo.name]: "",
    [taxId.name]: "",
    [takeRate.name]: "",
    [takeRateParameterId.name]: "",
    [emailId.name]: "",
    [keyPointOfContact.name]: "",
    [keyPocPrefix.name]: "",
    [contactDetails.name]: "",
    [countryPrifixId.name]: "",
    [registeredAddress.name]: "",
    [city.name]: "",
    [state.name]: "",
    [country.name]: "",
    [zip.name]: ""
}

export const fieldValuesVendorEdit = {
    [vendorId.name]: vendorIDVALUE?.vendorIDDetails?.vendorId,
    [vendorName.name]: vendorIDVALUE?.vendorIDDetails?.vendorName,
    [vendorCode.name]: vendorIDVALUE?.vendorIDDetails?.vendorCode,
    [vendorTypeId.name]: vendorIDVALUE?.vendorIDDetails?.vendorTypeId?.id,
    [vendorSubtypeId.name]: vendorIDVALUE?.vendorIDDetails?.vendorSubtypeId?.id,
    [businessIdentityNo.name]:
        vendorIDVALUE?.vendorIDDetails?.businessIdentityNo,
    [taxId.name]: vendorIDVALUE?.vendorIDDetails?.taxId,
    [takeRate.name]: vendorIDVALUE?.vendorIDDetails?.takeRate,
    [takeRateParameterId.name]:
        vendorIDVALUE?.vendorIDDetails?.takeRateParameterId?.id,
    [emailId.name]: vendorIDVALUE?.vendorIDDetails?.emailId,
    [keyPointOfContact.name]: vendorIDVALUE?.vendorIDDetails?.keyPointOfContact,
    [keyPocPrefix.name]: vendorIDVALUE?.vendorIDDetails?.keyPocPrefix?.id,
    [contactDetails.name]: vendorIDVALUE?.vendorIDDetails?.contactDetails,
    [countryPrifixId.name]: vendorIDVALUE?.vendorIDDetails?.countryPrifixId?.id,
    [registeredAddress.name]: vendorIDVALUE?.vendorIDDetails?.registeredAddress,
    [city.name]: vendorIDVALUE?.vendorIDDetails?.city,
    [state.name]: vendorIDVALUE?.vendorIDDetails?.state,
    [country.name]: vendorIDVALUE?.vendorIDDetails?.country,
    [zip.name]: vendorIDVALUE?.vendorIDDetails?.zip
}
