import MenuIcon from "@mui/icons-material/Menu"
import { AppBar, Toolbar, IconButton } from "@mui/material"
import logo from "theme/images/GuestKairos Logo.svg"

const MobileTopBar = ({ handleDrawerToggle, navigate, drawerWidth }) => {
    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
                "&.MuiAppBar-root": {
                    borderBottom: {
                        xs: "1px solid rgba(255, 255, 255, 0.4)",
                        md: "none"
                    }
                }
            }}
        >
            <Toolbar
                sx={{
                    mr: 2,
                    display: { xs: "flex", md: "none" },
                    alignItems: "center"
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                >
                    <MenuIcon color="gray.100" />
                </IconButton>

                <IconButton
                    color="inherit"
                    edge="center"
                    sx={{ mx: "auto" }}
                    onClick={() => navigate("/")}
                >
                    <img src={logo} alt="logoIcon" style={{ height: "16px" }} />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default MobileTopBar
