export const resources = {
    en: {
        translation: {
            SUBQUESTION: "SUBQUESTION",
            Answer: "Answer",
            "Tool Used": "Tool Used",
            Show: "Show",
            Hide: "Hide",
            "detailed analysis": "detailed analysis",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "Upload your private data, organize it into folder and ask any questions about them.",
            "Collection of tools helping you better manage and understand your portfolio.":
                "Collection of tools helping you better manage and understand your portfolio.",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)",
            "Gaia Terminal": "Gaia Terminal",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "Ask any question on 80.000+ financial instruments and let the agent do the work.",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "Find out the key areas of revenue & Profit generation for Alphabet",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "Explore how the treasury investments of Alphabet is distributed among various asset classes",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends",
            "Find out the total assets and total liabilities of Apple":
                "Find out the total assets and total liabilities of Apple",
            "Check out the different tax obligation/provision on Apple":
                "Check out the different tax obligation/provision on Apple",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation",
            "Figure out the revenue by geography for Meta":
                "Figure out the revenue by geography for Meta",
            "Understand the major areas of capital expenditure for Microsoft":
                "Understand the major areas of capital expenditure for Microsoft",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook",
            "Invest in a timeless designer handbag":
                "Invest in a timeless designer handbag",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses",
            "What should i consider when selecting a luxury service vendor?":
                "What should i consider when selecting a luxury service vendor?",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "Can luxury service vendors customize their offerings to meet specific needs or preferences?",
            "What type of certifications or credentials should luxury service vendors have?":
                "What type of certifications or credentials should luxury service vendors have?",
            "Evolution and Trends in Luxury Services":
                "Evolution and Trends in Luxury Services",
            "Luxury Travel Experiences and Destination Management":
                "Luxury Travel Experiences and Destination Management",
            "The Influence of social media on luxury services":
                "The Influence of social media on luxury services",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?",
            "Research Assistant": "Research Assistant",
            "Fundamentals Analysis": "Fundamentals Analysis",
            "Market Research": "Market Research",
            "Financial Analysis": "Financial Analysis",
            "Portfolio Risk Management": "Portfolio Risk Management",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "Upload market researches, large reports, and get answer to your questions without having to read these documents",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "Upload a large document, select it in the folder display, and the summarize button becomes active.",
            "Deep search through large documents":
                "Deep search through large documents",
            "Analyze complex financial documents":
                "Analyze complex financial documents",
            "Summarize large documents": "Summarize large documents",
            Suggestions: "Suggestions",
            "Internal Data": "Internal Data",
            "Upload your own files, order them across folders, and ask any question.":
                "Upload your own files, order them across folders, and ask any question.",
            "Here are a few ideas of what you can do with Gaia:":
                "Here are a few ideas of what you can do with Gaia:",
            Ideas: "Ideas",
            Topics: "Topics",
            FAQ: "FAQ",
            Intraday: "Intraday",
            Daily: "Daily",
            Weekly: "Weekly",
            Monthly: "Monthly",
            "LATEST QUOTE": "LATEST QUOTE",
            "Open:": "Open:",
            "High:": "High:",
            "Low:": "Low:",
            "Volume:": "Volume:",
            "Latest trading day:": "Latest trading day:",
            "Previous close:": "Previous close:",
            "MARKET NEWS": "MARKET NEWS",
            Date: "Date",
            Title: "Title",
            Sentiment: "Sentiment",
            Source: "Source",
            "Share Information": "Share Information",
            Action: "Action",
            Admins: "Admins",
            Share: "Share",
            "Client Portfolio": "Client Portfolio",
            "Client's": "Client's",
            Clients: "Clients",
            "View Portfolio": "View Portfolio",
            "Gaia Agent History": "Gaia Agent History",
            "Click refresh button to update status of agents in progress":
                "Click refresh button to update status of agents in progress",
            "Documents in folder": "Documents in folder",
            File: "File",
            Folder: "Folder",
            "Pick Internal Data folder": "Pick Internal Data folder",
            "Summarize document": "Summarize document",
            "Folder created successfully": "Folder created successfully",
            "Folder ": "Folder ",
            " successfully deleted": " successfully deleted",
            "File uploaded successfully": "File uploaded successfully",
            "Document deleted successfully": "Document deleted successfully",
            "Create Internal Data Folder": "Create Internal Data Folder",
            Save: "Save",
            Cancel: "Cancel",
            "Are you sure you want to delete the ":
                "Are you sure you want to delete the ",
            " folder?": " folder?",
            Delete: "Delete",
            "Upload New Document in Folder": "Upload New Document in Folder",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf",
            "Notes regarding pdfs:": "Notes regarding pdfs:",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "therefore we encourage you to submit such documents in alternative formats for better accuracy.",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "Large pdf can take up to 5 mins to process, it is advised not to close this window.",
            "Are you sure you want to delete this document?":
                "Are you sure you want to delete this document?",
            "Select a Client's portfolio": "Select a Client's portfolio",
            "Please select a client": "Please select a client",
            "Get Portfolio Recommendations": "Get Portfolio Recommendations",
            "Robo-Advisor (Asset Classes)": "Robo-Advisor (Asset Classes)",
            "Pick a stock:": "Pick a stock:",
            Ticker: "Ticker",
            Compare: "Compare",
            Client: "Client",
            DCA: "DCA",
            Financial: "Financial",
            Lifestyle: "Lifestyle",
            Stocks: "Stocks",
            Portfolio: "Portfolio",
            Agent: "Agent",
            Bookmarks: "Bookmarks",
            History: "History",
            Notifications: "Notifications",
            "Please select a Stock first": "Please select a Stock first",
            "Please select a folder first": "Please select a folder first",
            "Please select a private equity first":
                "Please select a private equity first",
            "Please enter a question": "Please enter a question",
            "Share to Admin": "Share to Admin",
            "Share to client": "Share to client",
            "Useful response": "Useful response",
            "Bad response": "Bad response",
            "Get Stock Information": "Get Stock Information",
            "Generate Graph": "Generate Graph",
            "Financial Data based research": "Financial Data based research",
            "Web based research": "Web based research",
            "Send a message.": "Send a message.",
            "Name/Emp ID": "Name/Emp ID",
            Idea: "Idea",
            "Folder Name": "Folder Name",
            Dashboard: "Dashboard",
            "Ask Gaia": "Ask Gaia",
            Product: "Product",
            Service: "Service",
            "My Prospects": "My Prospects",
            "My Clients": "My Clients",
            Subscriptions: "Subscriptions",
            Payments: "Payments",
            FAQs: "FAQs",
            "User Management": "User Management",
            "Vendor Management": "Vendor Management",
            "Financial Products": "Financial Products",
            "Lifestyle Products": "Lifestyle Products",
            "Financial Services": "Financial Services",
            "Lifestyle Services": "Lifestyle Services",
            Campaign: "Campaign",
            Reports: "Reports",
            "RM Performance": "RM Performance",
            "Customer Feedback": "Customer Feedback",
            "Order Management": "Order Management",
            "Ref.Data Management": "Ref.Data Management",
            "Chat with RM": "Chat with RM",
            Chat: "Chat",
            Bordeaux: "Bordeaux",
            "Bill of Materials": "Bill of Materials",
            "Configure Freight Charges": "Configure Freight Charges",
            "No. of Clients": "No. of Clients",
            "No. of RM's": "No. of RM's",
            "Total Partners/Vendors": "Total Partners/Vendors",
            "Total AUM": "Total AUM",
            "Invested Wealth": "Invested Wealth",
            "Aggregate Asset Allocation (%)": "Aggregate Asset Allocation (%)",
            "Aggregate Geographical Allocation (%)":
                "Aggregate Geographical Allocation (%)",
            "Aggregate Sector Allocation (%)":
                "Aggregate Sector Allocation (%)",
            AUM: "AUM",
            Revenue: "Revenue",
            Task: "Task",
            "Market News": "Market News",
            "Gaia Help": "Gaia Help",
            All: "All",
            Equities: "Equities",
            "Fixed Income": "Fixed Income",
            "Cash Or Equivalent": "Cash Or Equivalent",
            "Structured Products": "Structured Products",
            "Funds and Etfs": "Funds and Etfs",
            Commodities: "Commodities",
            "Real Estate": "Real Estate",
            "Esg/Impact Investing": "Esg/Impact Investing",
            Insurance: "Insurance",
            Crypto: "Crypto",
            "Private Equity": "Private Equity",
            "Growth Equity": "Growth Equity",
            "Venture Capital Investment": "Venture Capital Investment",
            "Private Credit": "Private Credit",
            "Pre-Ipo Products": "Pre-Ipo Products",
            "Direct Deal Products": "Direct Deal Products",
            "Other Alternatives": "Other Alternatives",
            Search: "Search",
            Filters: "Filters",
            Travel: "Travel",
            Wellness: "Wellness",
            Food: "Food",
            Health: "Health",
            Sports: "Sports",
            Art: "Art",
            Wine: "Wine",
            "Live Trade": "Live Trade",
            "Sign In": "Sign In",
            "Sign in": "Sign in",
            "Email address": "Email address",
            Password: "Password",
            "Forgot password?": "Forgot password?",
            "All Rights Reserved": "All Rights Reserved",
            Currency: "Currency",
            "My Profile": "My Profile",
            "Change Password": "Change Password",
            Logout: "Logout",
            Status: "Status",
            Active: "Active",
            Actions: "Actions",
            Profile: "Profile",
            Transactions: "Transactions",
            "Client Name": "Client Name",
            "Invested Wealth ($)": "Invested Wealth ($)",
            "Investible Wealth ($)": "Investible Wealth ($)",
            "Revenue ($)": "Revenue ($)",
            Personal: "Personal",
            Professional: "Professional",
            Family: "Family",
            Interest: "Interest",
            KYC: "KYC",
            Salutation: "Salutation",
            "First Name": "First Name",
            "Last Name": "Last Name",
            "D.O.B": "D.O.B",
            Gender: "Gender",
            "Highest Education": "Highest Education",
            "Primary Email ID": "Primary Email ID",
            "Secondary Email ID": "Secondary Email ID",
            "Mobile Number": "Mobile Number",
            "Not Editable": "Not Editable",
            "Alternative Mobile Number": "Alternative Mobile Number",
            "Base Location": "Base Location",
            Origin: "Origin",
            "Address 1": "Address 1",
            "Address 2": "Address 2",
            "Address 3": "Address 3",
            Country: "Country",
            State: "State",
            City: "City",
            "Postal Code": "Postal Code",
            "Net Worth $": "Net Worth $",
            "Source of Wealth": "Source of Wealth",
            "Investable wealth $": "Investable wealth $",
            "Risk appetite": "Risk appetite",
            "Industry preference": "Industry preference",
            "Financial goals": "Financial goals",
            "Joining Fee $": "Joining Fee $",
            "Annual Subscription Fee $": "Annual Subscription Fee $",
            "Current Investment Approach": "Current Investment Approach",
            "Take Survey": "Take Survey",
            "Present Asset Allocations:": "Present Asset Allocations:",
            ALL: "ALL",
            EQUITIES: "EQUITIES",
            "FIXED INCOME": "FIXED INCOME",
            "CASH OR EQUIVALENT": "CASH OR EQUIVALENT",
            "STRUCTURED PRODUCTS": "STRUCTURED PRODUCTS",
            "FUNDS AND ETFS": "FUNDS AND ETFS",
            COMMODITIES: "COMMODITIES",
            "REAL ESTATE": "REAL ESTATE",
            "ESG/IMPACT INVESTING": "ESG/IMPACT INVESTING",
            INSURANCE: "INSURANCE",
            CRYPTO: "CRYPTO",
            "PRIVATE EQUITY": "PRIVATE EQUITY",
            "GROWTH EQUITY": "GROWTH EQUITY",
            "VENTURE CAPITAL INVESTMENT": "VENTURE CAPITAL INVESTMENT",
            "PRIVATE CREDIT": "PRIVATE CREDIT",
            "PRE-IPO PRODUCTS": "PRE-IPO PRODUCTS",
            "DIRECT DEAL PRODUCTS": "DIRECT DEAL PRODUCTS",
            "OTHER ALTERNATIVES": "OTHER ALTERNATIVES",
            "Is my RM authorised to do transactions on my behalf?":
                "Is my RM authorised to do transactions on my behalf?",
            YES: "YES",
            NO: "NO",
            Yes: "Yes",
            No: "No",
            "Profession Type": "Profession Type",
            Industry: "Industry",
            "Domain / Sector": "Domain / Sector",
            Turnover: "Turnover",
            "Is it a hereditary business?": "Is it a hereditary business?",
            "Marital status": "Marital status",
            "Number of family members": "Number of family members",
            "Family Members": "Family Members",
            Personality: "Personality",
            "Current Memberships": "Current Memberships",
            "Previous Vacations": "Previous Vacations",
            "Proof of Identity": "Proof of Identity",
            "Proof of Address": "Proof of Address",
            "Proof of Tax Residency": "Proof of Tax Residency",
            "Proof of Wealth": "Proof of Wealth",
            "Other Documents": "Other Documents",
            "Document Type": "Document Type",
            "ID Number": "ID Number",
            "Expiry date": "Expiry date",
            "Upload documents": "Upload documents",
            "Zip code": "Zip code",
            Edit: "Edit",
            Type: "Type",
            "Product / Service Category": "Product / Service Category",
            "Date of Purchase": "Date of Purchase",
            Time: "Time",
            "Price($)": "Price($)",
            Quantity: "Quantity",
            "Amount($)": "Amount($)",
            "Payment Ref": "Payment Ref",
            Terminate: "Terminate",
            "Go To Order": "Go To Order",
            Open: "Open",
            Completed: "Completed",
            "Purchase Initiated": "Purchase Initiated",
            "Aging Days": "Aging Days",
            Vendor: "Vendor",
            "RM/Employee Id": "RM/Employee Id",
            "Product Name": "Product Name",
            "Selling Price ($)": "Selling Price ($)",
            "Investment Term (in Months)": "Investment Term (in Months)",
            "End Date": "End Date",
            "Product Category": "Product Category",
            Region: "Region",
            Liquidity: "Liquidity",
            "Returns Level": "Returns Level",
            "Risk Level": "Risk Level",
            "Price ($)": "Price ($)",
            "Take Rate": "Take Rate",
            "Client's Invested": "Client's Invested",
            Tags: "Tags",
            "Product Description": "Product Description",
            "Purchased Items": "Purchased Items",
            "Current Quantity": "Current Quantity",
            "Current price ($)": "Current price ($)",
            "Active Buy/Sell": "Active Buy/Sell",
            "No Active buy/sell records found":
                "No Active buy/sell records found",
            "Product / Transaction Status": "Product / Transaction Status",
            ACTIVE: "ACTIVE",
            INACTIVE: "INACTIVE",
            WFA: "WFA",
            DELETED: "DELETED",
            NEW: "NEW",
            REPLIED: "REPLIED",
            REJECTED: "REJECTED",
            SYSTEM: "SYSTEM",
            CLIENT: "CLIENT",
            "FLAT RATE": "FLAT RATE",
            "MR.": "MR.",
            "MRS.": "MRS.",
            "MS.": "MS.",
            "FINANCIAL PRODUCTS": "FINANCIAL PRODUCTS",
            "LIFESTYLE PRODUCTS": "LIFESTYLE PRODUCTS",
            "FINANCIAL SERVICES": "FINANCIAL SERVICES",
            "LIFESTYLE SERVICES": "LIFESTYLE SERVICES",
            STEADY: "STEADY",
            AGGRESSIVE: "AGGRESSIVE",
            MODERATE: "MODERATE",
            CREDIT: "CREDIT",
            DEBIT: "DEBIT",
            "LOW RISK": "LOW RISK",
            "MEDIUM RISK": "MEDIUM RISK",
            "HIGH RISK": "HIGH RISK",
            "N/A": "N/A",
            "HIGH SCHOOL": "HIGH SCHOOL",
            "UNDER GRADUATE": "UNDER GRADUATE",
            "POST GRADUATE": "POST GRADUATE",
            "AEROSPACE AND DEFENSE": "AEROSPACE AND DEFENSE",
            "AGRICULTURE AND AGRIBUSINESS": "AGRICULTURE AND AGRIBUSINESS",
            AUTOMOTIVE: "AUTOMOTIVE",
            "BANKING AND FINANCE": "BANKING AND FINANCE",
            CHEMICALS: "CHEMICALS",
            "CONSTRUCTION AND INFRASTRUCTURE":
                "CONSTRUCTION AND INFRASTRUCTURE",
            "CONSUMER GOODS AND SERVICES": "CONSUMER GOODS AND SERVICES",
            "EDUCATION AND TRAINING": "EDUCATION AND TRAINING",
            "ENERGY AND UTILITIES": "ENERGY AND UTILITIES",
            "ENTERTAINMENT AND MEDIA": "ENTERTAINMENT AND MEDIA",
            "HEALTHCARE AND PHARMACEUTICALS": "HEALTHCARE AND PHARMACEUTICALS",
            "HOSPITALITY AND TOURISM": "HOSPITALITY AND TOURISM",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS":
                "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS",
            MANUFACTURING: "MANUFACTURING",
            "MINING AND METALS": "MINING AND METALS",
            "REAL ESTATE AND PROPERTY": "REAL ESTATE AND PROPERTY",
            RETAIL: "RETAIL",
            "TRANSPORTATION AND LOGISTICS": "TRANSPORTATION AND LOGISTICS",
            "BIOTECHNOLOGY AND LIFE SCIENCES":
                "BIOTECHNOLOGY AND LIFE SCIENCES",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY":
                "CLEAN TECHNOLOGY AND RENEWABLE ENERGY",
            "CONSUMER ELECTRONICS": "CONSUMER ELECTRONICS",
            "ENVIRONMENTAL AND WASTE MANAGEMENT":
                "ENVIRONMENTAL AND WASTE MANAGEMENT",
            "FOOD AND BEVERAGE": "FOOD AND BEVERAGE",
            "GOVERNMENT AND PUBLIC SERVICES": "GOVERNMENT AND PUBLIC SERVICES",
            "LEGAL SERVICES": "LEGAL SERVICES",
            "NON-PROFIT AND SOCIAL ENTERPRISE":
                "NON-PROFIT AND SOCIAL ENTERPRISE",
            "PROFESSIONAL SERVICES": "PROFESSIONAL SERVICES",
            "SECURITY AND CYBERSECURITY": "SECURITY AND CYBERSECURITY",
            "TEXTILES AND APPAREL": "TEXTILES AND APPAREL",
            "RISK AVERSE": "RISK AVERSE",
            CONSERVATIVE: "CONSERVATIVE",
            "WEALTH PRESERVATION": "WEALTH PRESERVATION",
            "CAPITAL APPRECIATION": "CAPITAL APPRECIATION",
            "ESTATE PLANNING": "ESTATE PLANNING",
            "RISK MANAGEMENT": "RISK MANAGEMENT",
            "SUCCESSION PLANNING": "SUCCESSION PLANNING",
            "TAX PLANNING": "TAX PLANNING",
            ENTREPRENEURS: "ENTREPRENEURS",
            INVESTORS: "INVESTORS",
            "CORPORATE EXECUTIVES": "CORPORATE EXECUTIVES",
            "FINANCIAL PROFESSIONALS": "FINANCIAL PROFESSIONALS",
            "ENTERTAINMENT PROFESSIONALS": "ENTERTAINMENT PROFESSIONALS",
            "PROFESSIONAL ATHLETES": "PROFESSIONAL ATHLETES",
            "REAL ESTATE PROFESSIONALS": "REAL ESTATE PROFESSIONALS",
            "TECH INDUSTRY PROFESSIONALS": "TECH INDUSTRY PROFESSIONALS",
            "LUXURY GOODS AND SERVICES": "LUXURY GOODS AND SERVICES",
            "ART AND COLLECTIBLES": "ART AND COLLECTIBLES",
            PHILANTHROPY: "PHILANTHROPY",
            EDUCATION: "EDUCATION",
            "HEALTH AND WELLNESS": "HEALTH AND WELLNESS",
            TECHNOLOGY: "TECHNOLOGY",
            "SUSTAINABLE INVESTMENTS": "SUSTAINABLE INVESTMENTS",
            SPORTS: "SPORTS",
            "TRAVEL AND HOSPITALITY": "TRAVEL AND HOSPITALITY",
            MARRIED: "MARRIED",
            UNMARRIED: "UNMARRIED",
            DIVORCED: "DIVORCED",
            "WEALTH MANAGEMENT": "WEALTH MANAGEMENT",
            OTHERS: "OTHERS",
            MALE: "MALE",
            FEMALE: "FEMALE",
            "Payment Initiated": "Payment Initiated",
            "Payment Reversed": "Payment Reversed",
            "Payment Received": "Payment Received",
            "Product Allocated": "Product Allocated",
            "Sell Initiated": "Sell Initiated",
            "Product Sold": "Product Sold",
            "Service Provisioned": "Service Provisioned",
            "Open - Purchase Initiated": "Open - Purchase Initiated",
            "Open - Payment Initiated": "Open - Payment Initiated",
            "Open - Payment Reversed": "Open - Payment Reversed",
            "Open - Payment Received": "Open - Payment Received",
            "Completed - Product Allocated": "Completed - Product Allocated",
            "Open - Sell Initiated": "Open - Sell Initiated",
            "Completed - Product Sold": "Completed - Product Sold",
            "Completed - Service Provisioned":
                "Completed - Service Provisioned",
            Bank: "Bank",
            Asset: "Asset",
            Liability: "Liability",
            SPOUSE: "SPOUSE",
            CHILD: "CHILD",
            PARENT: "PARENT",
            SIBLING: "SIBLING",
            OTHER: "OTHER",
            "BUYING A HOUSE": "BUYING A HOUSE",
            TRAVEL: "TRAVEL",
            RETIREMENT: "RETIREMENT",
            "LEGACY PLANNING": "LEGACY PLANNING",
            NOVICE: "NOVICE",
            INTERMEDIATE: "INTERMEDIATE",
            EXPERT: "EXPERT",
            "SHORT TERM": "SHORT TERM",
            "MEDIUM TERM": "MEDIUM TERM",
            "LONG TERM": "LONG TERM",
            "INVESTMENT STRATEGIES": "INVESTMENT STRATEGIES",
            "TRUST SERVICES": "TRUST SERVICES",
            "SPORTS AND FITNESS ACTIVITIES": "SPORTS AND FITNESS ACTIVITIES",
            "ARTS AND CULTURE": "ARTS AND CULTURE",
            "TRAVEL AND ADVENTURE": "TRAVEL AND ADVENTURE",
            "MUSIC AND ENTERTAINMENT": "MUSIC AND ENTERTAINMENT",
            "FOOD AND DINING": "FOOD AND DINING",
            "FASHION AND STYLE:": "FASHION AND STYLE:",
            "TECHNOLOGY AND GADGETS": "TECHNOLOGY AND GADGETS",
            SALARY: "SALARY",
            INVESTMENTS: "INVESTMENTS",
            "BUSINESS OWNERSHIP": "BUSINESS OWNERSHIP",
            CASH: "CASH",
            SAVINGS: "SAVINGS",
            "MONEY MARKET ACCOUNTS": "MONEY MARKET ACCOUNTS",
            STOCKS: "STOCKS",
            BONDS: "BONDS",
            "MUTUAL FUNDS": "MUTUAL FUNDS",
            "PENSION PLANS": "PENSION PLANS",
            ANNUITIES: "ANNUITIES",
            ART: "ART",
            COLLECTIBLES: "COLLECTIBLES",
            PROPERTIES: "PROPERTIES",
            "PERSONAL LOANS": "PERSONAL LOANS",
            "STUDENT LOANS": "STUDENT LOANS",
            HOUSING: "HOUSING",
            UTILITIES: "UTILITIES",
            TRANSPORTATION: "TRANSPORTATION",
            "PROPERTY INSURANCE": "PROPERTY INSURANCE",
            "LIABILITY INSURANCE": "LIABILITY INSURANCE",
            "LEGAL ADVISORY": "LEGAL ADVISORY",
            "COMPLIANCE SERVICES": "COMPLIANCE SERVICES",
            "ACCOUNTING SERVICES": "ACCOUNTING SERVICES",
            "LEVERAGE MANAGEMENT": "LEVERAGE MANAGEMENT",
            "REQUEST EXPERT ADVISORY MEETING":
                "REQUEST EXPERT ADVISORY MEETING",
            PASSPORT: "PASSPORT",
            "IDENTITY CARD": "IDENTITY CARD",
            "DRIVING LICENSE": "DRIVING LICENSE",
            "BANK STATEMENT": "BANK STATEMENT",
            "UTILITY BILL": "UTILITY BILL",
            "PHONE BILL": "PHONE BILL",
            "TAX BILL": "TAX BILL",
            "RENTAL AGREEMENT": "RENTAL AGREEMENT",
            "NORMAL DELIVERY": "NORMAL DELIVERY",
            INBOND: "INBOND",
            "SPECIAL DELIVERY": "SPECIAL DELIVERY",
            Latest: "Latest",
            Ascending: "Ascending",
            Descending: "Descending",
            "Enable Access": "Enable Access",
            "Disable Access": "Disable Access",
            MFO: "MFO",
            SFO: "SFO"
        }
    },
    hi: {
        translation: {
            SUBQUESTION: "उप-प्रश्न",
            Answer: "उत्तर",
            "Tool Used": "उपयोग किया गया उपकरण",
            Show: "दिखाएं",
            Hide: "छुपाएं",
            "detailed analysis": "विस्तृत विश्लेषण",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "5500+ स्टॉक्स नवीनतम 10-K और 10-Q क्वेरी करें, कुछ सेकंड में उत्तर पाएं",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "अपना निजी डेटा अपलोड करें, इसे फोल्डर में संगठित करें और इसके बारे में कोई सवाल पूछें।",
            "Collection of tools helping you better manage and understand your portfolio.":
                "उपकरणों का संग्रह जो आपको अपने पोर्टफोलियो का बेहतर प्रबंधन और समझने में मदद करता है।",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "Gaia एजेंट आपको 80.000 से अधिक सार्वजनिक प्रदेशों (इक्विटी, बॉन्ड, कमोडिटीज, क्रिप्टो...) पर गहरी बाजार अनुसंधान करने की अनुमति देता है",
            "Gaia Terminal": "Gaia टर्मिनल",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "80.000+ वित्तीय उपकरणों पर किसी भी प्रश्न का उत्तर दें और एजेंट को काम करने दें।",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "सुनिश्चित करें कि आप विशिष्ट टिकर दर्ज करें, विशेषकर अमेरिकी विनिमयों के लिए (उदा। RELIANCE.NS)",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "एल्फाबेट के लिए राजस्व और लाभ उत्पादन के प्रमुख क्षेत्रों का पता लगाएं",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "एल्फाबेट के खजाने के निवेश को विभिन्न संपत्ति वर्गों में वितरित किया गया है, इसे अन्वेषित करें",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "अमेज़न के हाल के आय रिपोर्ट का विश्लेषण देखें, जिसमें कोई विशेष आश्चर्य या प्रवृत्तियां शामिल हैं",
            "Find out the total assets and total liabilities of Apple":
                "एप्पल के कुल संपत्ति और कुल दायित्व का पता लगाएं",
            "Check out the different tax obligation/provision on Apple":
                "एप्पल पर विभिन्न कर अवसर/प्रावधान की जांच करें",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "बॉट का उपयोग करके मेटा के लिए विज्ञान और विकास खर्चों का विश्लेषण करें और साथ ही मेटा के नवाचार के प्रतिबद्धता का मूल्यांकन करें",
            "Figure out the revenue by geography for Meta":
                "मेटा के लिए भूगोल के अनुसार राजस्व का पता लगाएं",
            "Understand the major areas of capital expenditure for Microsoft":
                "माइक्रोसॉफ्ट के लिए पूंजी व्यय के प्रमुख क्षेत्रों को समझें",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "हाल के नियामकीय परिवर्तनों के व्यावसायिक परिचालन पर प्रभाव का पता लगाएं और उसके वित्तीय दृष्टिकोण को देखें",
            "Invest in a timeless designer handbag":
                "एक समयहीन डिज़ाइनर हैंडबैग में निवेश करें",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "रोलेक्स, फिलिप या ऑडमर्स पिग्यूए की तरह के प्रतिष्ठित ब्रांड से एक लक्जरी घड़ी में निवेश करें",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "प्रसिद्ध फैशन हाउस से एक लक्जरी कोट या जैकेट के साथ स्टाइलिश और गर्म रहें",
            "What should i consider when selecting a luxury service vendor?":
                "एक लक्जरी सेवा विक्रेता का चयन करते समय मुझे क्या ध्यान में रखना चाहिए?",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "क्या लक्जरी सेवा विक्रेता विशेष आवश्यकताओं या पसंदों को पूरा करने के लिए अपनी पेशकशों को कस्टमाइज़ कर सकते हैं?",
            "What type of certifications or credentials should luxury service vendors have?":
                "लक्जरी सेवा विक्रेताओं के पास कौन से प्रमाणपत्र या प्रमाणपत्र होने चाहिए?",
            "Evolution and Trends in Luxury Services":
                "लक्जरी सेवाओं में विकास और प्रवृत्तियाँ",
            "Luxury Travel Experiences and Destination Management":
                "लक्जरी यात्रा अनुभव और गंतव्य प्रबंधन",
            "The Influence of social media on luxury services":
                "लक्जरी सेवाओं पर सोशल मीडिया का प्रभाव",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "देखें कि TSLA पीयर्स क्या हैं। उनमें से, जिसका सबसे अधिक बाजारी शीर्षक है, उसे चेक करें। फिर, जिस टिकर पर सबसे अधिक बाजारी शीर्षक है, उससे एक विशेषज्ञ की ओर से सबसे हाल की मूल्य लक्ष्य अनुमान प्राप्त करें, और मुझे बताएं कि यह किस तारीख को था और अनुमान किस तारीख को बनाया गया था। मुझे बताएं कि विशेषज्ञ इस स्टॉक पर बुलिश या बियरिश है।",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "सबसे हाल ही में उपलब्ध डेटा का उपयोग करके AMZN का मौलिक वित्तीय विश्लेषण करें। आपको क्या मिलता है जो दिलचस्प है?",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "सोने के मूल्य में हाल के वृद्धि के पीछे के मुख्य ड्राइवर क्या हैं, और इस प्रवृत्ति को मध्यम अवधि में कितनी दीर्घकारी है?",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "हमारे निर्धारित आय पोर्टफोलियो पर संघीय रिजर्व की ब्याज दर में 25 बेसिस प्वाइंट वृद्धि का संभावित प्रभाव क्या है?",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "हमारे वर्तमान पोर्टफोलियो संरचना दी गई है, इसमें बड़ी मात्रा में ब्याज दरों में वृद्धि का संभावित प्रभाव क्या है?",
            "Research Assistant": "अनुसंधान सहायक",
            "Fundamentals Analysis": "मौलिक विश्लेषण",
            "Market Research": "बाजार अनुसंधान",
            "Financial Analysis": "वित्तीय विश्लेषण",
            "Portfolio Risk Management": "पोर्टफोलियो जोखिम प्रबंधन",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "बाजार अनुसंधान, बड़ी रिपोर्ट अपलोड करें, और इन दस्तावेजों को पढ़ने की आवश्यकता नहीं है, आपके प्रश्नों का उत्तर पाएं",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "जटिल वित्तीय उत्पादों के टर्म शीट और प्रोस्पेक्टस अपलोड करें, गया आपको उनमें से जो कुछ भी आवश्यक है वह पाएगा जैसे बंदोबस्त अनुसूची, स्ट्राइक प्राइस, नॉक-आउट, ...",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "एक बड़े दस्तावेज़ को अपलोड करें, इसे फोल्डर प्रदर्शन में चुनें, और सारांश बटन सक्रिय हो जाता है।",
            "Deep search through large documents":
                "बड़े दस्तावेज़ के माध्यम से गहरी खोज",
            "Analyze complex financial documents":
                "जटिल वित्तीय दस्तावेज़ों का विश्लेषण करें",
            "Summarize large documents": "बड़े दस्तावेज़ का सारांश दें",
            Suggestions: "सुझाव",
            "Internal Data": "आंतरिक डेटा",
            "Upload your own files, order them across folders, and ask any question.":
                "अपनी फ़ाइलें अपलोड करें, उन्हें फोल्डर के अवरोहण करें, और कोई भी सवाल पूछें।",
            "Here are a few ideas of what you can do with Gaia:":
                "यहाँ कुछ विचार हैं कि आप गया के साथ क्या कर सकते हैं:",
            Ideas: "विचार",
            Topics: "विषय",
            FAQ: "FAQ",
            Intraday: "इंट्राडे",
            Daily: "दैनिक",
            Weekly: "साप्ताहिक",
            Monthly: "मासिक",
            "LATEST QUOTE": "नवीनतम कोट",
            "Open:": "खुला:",
            "High:": "उच्च:",
            "Low:": "कम:",
            "Volume:": "मात्रा:",
            "Latest trading day:": "नवीनतम व्यापारिक दिन:",
            "Previous close:": "पिछला बंद:",
            "MARKET NEWS": "बाजार समाचार",
            Date: "तारीख",
            Title: "शीर्षक",
            Sentiment: "भावना",
            Source: "स्रोत",
            "Share Information": "साझा करें जानकारी",
            Action: "कार्रवाई",
            Admins: "व्यवस्थापक",
            Share: "शेयर",
            "Client Portfolio": "ग्राहक पोर्टफोलियो",
            "Client's": "ग्राहक का",
            Clients: "ग्राहक",
            "View Portfolio": "पोर्टफोलियो देखें",
            "Gaia Agent History": "गया एजेंट इतिहास",
            "Click refresh button to update status of agents in progress":
                "एजेंट्स की प्रगति की स्थिति को अपडेट करने के लिए रिफ्रेश बटन पर क्लिक करें",
            "Documents in folder": "फ़ोल्डर में दस्तावेज़",
            File: "फ़ाइल",
            Folder: "फ़ोल्डर",
            "Pick Internal Data folder": "आंतरिक डेटा फ़ोल्डर चुनें",
            "Summarize document": "सारांश दस्तावेज़",
            "Folder created successfully": "फ़ोल्डर सफलतापूर्वक बनाया गया",
            "Folder ": "फ़ोल्डर ",
            " successfully deleted": " सफलतापूर्वक हटाया गया",
            "File uploaded successfully": "फ़ाइल सफलतापूर्वक अपलोड की गई",
            "Document deleted successfully": "दस्तावेज़ सफलतापूर्वक हटाया गया",
            "Create Internal Data Folder": "आंतरिक डेटा फ़ोल्डर बनाएं",
            Save: "सहेजें",
            Cancel: "रद्द करें",
            "Are you sure you want to delete the ": "क्या आप वाकई ",
            " folder?": " फ़ोल्डर हटाना चाहते हैं?",
            Delete: "हटाएं",
            "Upload New Document in Folder":
                "फ़ोल्डर में नया दस्तावेज़ अपलोड करें",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "समर्थित फ़ाइलें: .doc, .docx, .txt, .rtf, .htm, .html, .pdf",
            "Notes regarding pdfs:": "PDF के संबंधित नोट:",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "छवि पीडीएफ या कस्टम फ़ॉन्ट का उपयोग करने वाले पीडीएफ को वर्ण पहचान तकनीक का उपयोग करके लोड किया जाएगा। यह हमारी क्षमता को सीमित करता है कि ऐसे दस्तावेज़ में तालिकाएँ विश्लेषित करने की।",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "इसलिए हम आपको बेहतर सटीकता के लिए ऐसे दस्तावेज़ को वैकल्पिक प्रारूपों में प्रस्तुत करने की सलाह देते हैं।",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "बड़े पीडीएफ को प्रोसेस करने में 5 मिनट लग सकते हैं, इसे बंद न करने की सलाह दी जाती है।",
            "Are you sure you want to delete this document?":
                "क्या आप वाकई इस दस्तावेज़ को हटाना चाहते हैं?",
            "Select a Client's portfolio": "एक ग्राहक का पोर्टफोलियो चुनें",
            "Please select a client": "कृपया एक ग्राहक का चयन करें",
            "Get Portfolio Recommendations":
                "पोर्टफोलियो की सिफारिशें प्राप्त करें",
            "Robo-Advisor (Asset Classes)": "रोबो-सलाहकार (एसेट क्लासेस)",
            "Pick a stock:": "एक स्टॉक चुनें:",
            Ticker: "टिकर",
            Compare: "तुलना करें",
            Client: "ग्राहक",
            DCA: "डीसीए",
            Financial: "वित्तीय",
            Lifestyle: "जीवनशैली",
            Stocks: "स्टॉक्स",
            Portfolio: "पोर्टफोलियो",
            Agent: "एजेंट",
            Bookmarks: "बुकमार्क्स",
            History: "इतिहास",
            Notifications: "सूचनाएं",
            "Please select a Stock first": "कृपया पहले एक स्टॉक चुनें",
            "Please select a folder first": "कृपया पहले एक फ़ोल्डर चुनें",
            "Please select a private equity first":
                "कृपया पहले एक निजी इक्विटी चुनें",
            "Please enter a question": "कृपया एक प्रश्न दर्ज करें",
            "Share to Admin": "व्यवस्थापक के साथ साझा करें",
            "Share to client": "ग्राहक के साथ साझा करें",
            "Useful response": "उपयोगी प्रतिक्रिया",
            "Bad response": "बुरी प्रतिक्रिया",
            "Get Stock Information": "स्टॉक जानकारी प्राप्त करें",
            "Generate Graph": "ग्राफ़ उत्पन्न करें",
            "Financial Data based research": "वित्तीय डेटा पर आधारित अनुसंधान",
            "Web based research": "वेब आधारित अनुसंधान",
            "Send a message.": "एक संदेश भेजें।",
            "Name/Emp ID": "नाम/कर्मचारी आईडी",
            Idea: "विचार",
            "Folder Name": "फ़ोल्डर का नाम",
            Dashboard: "डैशबोर्ड",
            "Ask Gaia": "गया से पूछें",
            Product: "उत्पाद",
            Service: "सेवा",
            "My Prospects": "मेरे संभावनाएँ",
            "My Clients": "मेरे ग्राहक",
            Subscriptions: "सदस्यता",
            Payments: "भुगतान",
            FAQs: "सामान्य प्रश्न",
            "User Management": "उपयोगकर्ता प्रबंधन",
            "Vendor Management": "विक्रेता प्रबंधन",
            "Financial Products": "वित्तीय उत्पाद",
            "Lifestyle Products": "जीवनशैली उत्पाद",
            "Financial Services": "वित्तीय सेवाएं",
            "Lifestyle Services": "जीवनशैली सेवाएं",
            Campaign: "अभियान",
            Reports: "रिपोर्टें",
            "RM Performance": "आरएम प्रदर्शन",
            "Customer Feedback": "ग्राहक प्रतिक्रिया",
            "Order Management": "आदेश प्रबंधन",
            "Ref.Data Management": "संदर्भ डेटा प्रबंधन",
            "Chat with RM": "आरएम के साथ चैट करें",
            Chat: "चैट",
            Bordeaux: "Bordeaux",
            "Bill of Materials": "सामग्री का बिल",
            "Configure Freight Charges": "भाड़ा शुल्क कॉन्फ़िगर करें",
            "No. of Clients": "ग्राहकों की संख्या",
            "No. of RM's": "आरएम की संख्या",
            "Total Partners/Vendors": "कुल साझेदार/विक्रेता",
            "Total AUM": "कुल AUM",
            "Invested Wealth": "निवेशित संपत्ति",
            "Aggregate Asset Allocation (%)": "कुल संपत्ति आवंटन (%)",
            "Aggregate Geographical Allocation (%)": "कुल भौगोलिक आवंटन (%)",
            "Aggregate Sector Allocation (%)": "कुल क्षेत्र आवंटन (%)",
            AUM: "AUM",
            Revenue: "राजस्व",
            Task: "कार्य",
            "Market News": "बाजार समाचार",
            "Gaia Help": "गया सहायता",
            All: "सभी",
            Equities: "इक्विटीज़",
            "Fixed Income": "निर्धारित आय",
            "Cash Or Equivalent": "नकद या समकक्ष",
            "Structured Products": "संरचित उत्पाद",
            "Funds and Etfs": "फंड और ईटीएफ",
            Commodities: "कमोडिटीज़",
            "Real Estate": "रियल एस्टेट",
            "Esg/Impact Investing": "ईएसजी/प्रभाव निवेश",
            Insurance: "बीमा",
            Crypto: "क्रिप्टो",
            "Private Equity": "निजी इक्विटी",
            "Growth Equity": "वृद्धि इक्विटी",
            "Venture Capital Investment": "वेंचर कैपिटल निवेश",
            "Private Credit": "निजी क्रेडिट",
            "Pre-Ipo Products": "प्री-आईपीओ उत्पाद",
            "Direct Deal Products": "सीधे सौदे के उत्पाद",
            "Other Alternatives": "अन्य विकल्प",
            Search: "खोज",
            Filters: "फ़िल्टर",
            Travel: "यात्रा",
            Wellness: "स्वास्थ्य",
            Food: "खाना",
            Health: "स्वास्थ्य",
            Sports: "खेल",
            Art: "कला",
            Wine: "शराब",
            "Live Trade": "लाइव ट्रेड",
            "Sign In": "साइन इन करें",
            "Sign in": "साइन इन करें",
            "Email address": "ईमेल पता",
            Password: "पासवर्ड",
            "Forgot password?": "पासवर्ड भूल गए?",
            "All Rights Reserved": "सभी अधिकार सुरक्षित हैं",
            Currency: "मुद्रा",
            "My Profile": "मेरी प्रोफ़ाइल",
            "Change Password": "पासवर्ड बदलें",
            Logout: "लॉग आउट",
            Status: "स्थिति",
            Active: "सक्रिय",
            Actions: "कार्रवाई",
            Profile: "प्रोफ़ाइल",
            Transactions: "लेन-देन",
            "Client Name": "ग्राहक का नाम",
            "Invested Wealth ($)": "निवेशित संपत्ति ($)",
            "Investible Wealth ($)": "निवेशनीय संपत्ति ($)",
            "Revenue ($)": "राजस्व ($)",
            Personal: "व्यक्तिगत",
            Professional: "पेशेवर",
            Family: "परिवार",
            Interest: "ब्याज",
            KYC: "KYC",
            Salutation: "सलाम",
            "First Name": "पहला नाम",
            "Last Name": "अंतिम नाम",
            "D.O.B": "जन्म तिथि",
            Gender: "लिंग",
            "Highest Education": "सबसे उच्च शिक्षा",
            "Primary Email ID": "प्राथमिक ईमेल आईडी",
            "Secondary Email ID": "माध्यमिक ईमेल आईडी",
            "Mobile Number": "मोबाइल नंबर",
            "Not Editable": "संपादन योग्य नहीं",
            "Alternative Mobile Number": "वैकल्पिक मोबाइल नंबर",
            "Base Location": "आधार स्थान",
            Origin: "मूल",
            "Address 1": "पता 1",
            "Address 2": "पता 2",
            "Address 3": "पता 3",
            Country: "देश",
            State: "राज्य",
            City: "शहर",
            "Postal Code": "पोस्टल कोड",
            "Net Worth $": "नेट वर्थ $",
            "Source of Wealth": "संपत्ति का स्रोत",
            "Investable wealth $": "निवेशनीय संपत्ति $",
            "Risk appetite": "जोखिम भोजन",
            "Industry preference": "उद्योग पसंद",
            "Financial goals": "वित्तीय लक्ष्य",
            "Joining Fee $": "शामिल होने की शुल्क $",
            "Annual Subscription Fee $": "वार्षिक सदस्यता शुल्क $",
            "Current Investment Approach": "वर्तमान निवेश दृष्टिकोण",
            "Take Survey": "सर्वेक्षण लें",
            "Present Asset Allocations:": "वर्तमान संपत्ति आवंटन:",
            ALL: "सभी",
            EQUITIES: "इक्विटीज़",
            "FIXED INCOME": "निर्धारित आय",
            "CASH OR EQUIVALENT": "नकद या समकक्ष",
            "STRUCTURED PRODUCTS": "संरचित उत्पाद",
            "FUNDS AND ETFS": "फंड और ईटीएफ",
            COMMODITIES: "कमोडिटीज़",
            "REAL ESTATE": "रियल एस्टेट",
            "ESG/IMPACT INVESTING": "ईएसजी/प्रभाव निवेश",
            INSURANCE: "बीमा",
            CRYPTO: "क्रिप्टो",
            "PRIVATE EQUITY": "निजी इक्विटी",
            "GROWTH EQUITY": "वृद्धि इक्विटी",
            "VENTURE CAPITAL INVESTMENT": "वेंचर कैपिटल निवेश",
            "PRIVATE CREDIT": "निजी क्रेडिट",
            "PRE-IPO PRODUCTS": "प्री-आईपीओ उत्पाद",
            "DIRECT DEAL PRODUCTS": "सीधे सौदे के उत्पाद",
            "OTHER ALTERNATIVES": "अन्य विकल्प",
            "Is my RM authorised to do transactions on my behalf?":
                "क्या मेरे आरएम को मेरी ओर से लेन-देन करने की अधिकारिता है?",
            YES: "हाँ",
            NO: "नहीं",
            Yes: "हाँ",
            No: "नहीं",
            "Profession Type": "पेशे का प्रकार",
            Industry: "उद्योग",
            "Domain / Sector": "डोमेन / क्षेत्र",
            Turnover: "टर्नओवर",
            "Is it a hereditary business?": "क्या यह एक वंशज व्यवसाय है?",
            "Marital status": "वैवाहिक स्थिति",
            "Number of family members": "परिवार के सदस्यों की संख्या",
            "Family Members": "परिवार के सदस्य",
            Personality: "व्यक्तित्व",
            "Current Memberships": "वर्तमान सदस्यता",
            "Previous Vacations": "पिछली छुट्टियाँ",
            "Proof of Identity": "पहचान का सबूत",
            "Proof of Address": "पते का सबूत",
            "Proof of Tax Residency": "कर निवास का सबूत",
            "Proof of Wealth": "संपत्ति का सबूत",
            "Other Documents": "अन्य दस्तावेज़",
            "Document Type": "दस्तावेज़ का प्रकार",
            "ID Number": "आईडी नंबर",
            "Expiry date": "समाप्ति तिथि",
            "Upload documents": "दस्तावेज़ अपलोड करें",
            "Zip code": "ज़िप कोड",
            Edit: "संपादित करें",
            Type: "प्रकार",
            "Product / Service Category": "उत्पाद / सेवा श्रेणी",
            "Date of Purchase": "खरीद की तारीख",
            Time: "समय",
            "Price($)": "मूल्य($)",
            Quantity: "मात्रा",
            "Amount($)": "राशि($)",
            "Payment Ref": "भुगतान रेफ",
            Terminate: "समाप्त करें",
            "Go To Order": "आदेश पर जाएं",
            Open: "खुला",
            Completed: "पूरा हुआ",
            "Purchase Initiated": "खरीद आरंभ की गई",
            "Aging Days": "उम्र दिन",
            Vendor: "विक्रेता",
            "RM/Employee Id": "आरएम/कर्मचारी आईडी",
            "Product Name": "उत्पाद का नाम",
            "Selling Price ($)": "बिक्री मूल्य($)",
            "Investment Term (in Months)": "निवेश अवधि (महीनों में)",
            "End Date": "समाप्ति तिथि",
            "Product Category": "उत्पाद श्रेणी",
            Region: "क्षेत्र",
            Liquidity: "लिक्विडिटी",
            "Returns Level": "लाभ स्तर",
            "Risk Level": "जोखिम स्तर",
            "Price ($)": "मूल्य ($)",
            "Take Rate": "दर लेना",
            "Client's Invested": "ग्राहक का निवेश",
            Tags: "टैग",
            "Product Description": "उत्पाद विवरण",
            "Purchased Items": "खरीदे गए आइटम",
            "Current Quantity": "वर्तमान मात्रा",
            "Current price ($)": "वर्तमान मूल्य ($)",
            "Active Buy/Sell": "सक्रिय खरीद/बेच",
            "No Active buy/sell records found":
                "कोई सक्रिय खरीद/बेच रिकॉर्ड नहीं मिला",
            "Product / Transaction Status": "उत्पाद / लेन-देन स्थिति",
            ACTIVE: "सक्रिय",
            INACTIVE: "निष्क्रिय",
            WFA: "WFA",
            DELETED: "हटाया गया",
            NEW: "नया",
            REPLIED: "जवाब दिया",
            REJECTED: "अस्वीकृत",
            SYSTEM: "सिस्टम",
            CLIENT: "ग्राहक",
            "FLAT RATE": "स्थिर दर",
            "MR.": "श्री",
            "MRS.": "श्रीमती",
            "MS.": "कुमारी",
            "FINANCIAL PRODUCTS": "वित्तीय उत्पाद",
            "LIFESTYLE PRODUCTS": "जीवनशैली उत्पाद",
            "FINANCIAL SERVICES": "वित्तीय सेवाएं",
            "LIFESTYLE SERVICES": "जीवनशैली सेवाएं",
            STEADY: "स्थिर",
            AGGRESSIVE: "उत्कट",
            MODERATE: "मध्यम",
            CREDIT: "क्रेडिट",
            DEBIT: "डेबिट",
            "LOW RISK": "कम जोखिम",
            "MEDIUM RISK": "मध्यम जोखिम",
            "HIGH RISK": "उच्च जोखिम",
            "N/A": "उपलब्ध नहीं",
            "HIGH SCHOOL": "हाई स्कूल",
            "UNDER GRADUATE": "अंडर ग्रेजुएट",
            "POST GRADUATE": "पोस्ट ग्रेजुएट",
            "AEROSPACE AND DEFENSE": "एयरोस्पेस और रक्षा",
            "AGRICULTURE AND AGRIBUSINESS": "कृषि और कृषि व्यापार",
            AUTOMOTIVE: "ऑटोमोटिव",
            "BANKING AND FINANCE": "बैंकिंग और वित्त",
            CHEMICALS: "रासायनिक",
            "CONSTRUCTION AND INFRASTRUCTURE": "निर्माण और बुनियादी संरचना",
            "CONSUMER GOODS AND SERVICES": "उपभोक्ता वस्त्र और सेवाएं",
            "EDUCATION AND TRAINING": "शिक्षा और प्रशिक्षण",
            "ENERGY AND UTILITIES": "ऊर्जा और यूटिलिटीज़",
            "ENTERTAINMENT AND MEDIA": "मनोरंजन और मीडिया",
            "HEALTHCARE AND PHARMACEUTICALS":
                "स्वास्थ्य देखभाल और फार्मास्यूटिकल्स",
            "HOSPITALITY AND TOURISM": "आतिथ्य और पर्यटन",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS":
                "सूचना प्रौद्योगिकी और दूरसंचार",
            MANUFACTURING: "निर्माण",
            "MINING AND METALS": "खनन और धातु",
            "REAL ESTATE AND PROPERTY": "रियल एस्टेट और संपत्ति",
            RETAIL: "खुदरा",
            "TRANSPORTATION AND LOGISTICS": "परिवहन और लॉजिस्टिक्स",
            "BIOTECHNOLOGY AND LIFE SCIENCES":
                "जैव प्रौद्योगिकी और जीवन विज्ञान",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY":
                "स्वच्छ प्रौद्योगिकी और नवीन ऊर्जा",
            "CONSUMER ELECTRONICS": "उपभोक्ता इलेक्ट्रॉनिक्स",
            "ENVIRONMENTAL AND WASTE MANAGEMENT": "पर्यावरण और कचरा प्रबंधन",
            "FOOD AND BEVERAGE": "खाद्य और पेय",
            "GOVERNMENT AND PUBLIC SERVICES": "सरकार और सार्वजनिक सेवाएं",
            "LEGAL SERVICES": "कानूनी सेवाएं",
            "NON-PROFIT AND SOCIAL ENTERPRISE": "नॉन-प्रॉफिट और सामाजिक उद्यम",
            "PROFESSIONAL SERVICES": "पेशेवर सेवाएं",
            "SECURITY AND CYBERSECURITY": "सुरक्षा और साइबर सुरक्षा",
            "TEXTILES AND APPAREL": "वस्त्र और परिधान",
            "RISK AVERSE": "जोखिम से बचने वाला",
            CONSERVATIVE: "रूढ़िवादी",
            "WEALTH PRESERVATION": "संपत्ति संरक्षण",
            "CAPITAL APPRECIATION": "पूंजी मूल्य वृद्धि",
            "ESTATE PLANNING": "इस्टेट प्लानिंग",
            "RISK MANAGEMENT": "जोखिम प्रबंधन",
            "SUCCESSION PLANNING": "उत्तराधिकारी योजना",
            "TAX PLANNING": "कर नियोजन",
            ENTREPRENEURS: "उद्यमियों",
            INVESTORS: "निवेशकों",
            "CORPORATE EXECUTIVES": "कॉर्पोरेट कार्यकारी",
            "FINANCIAL PROFESSIONALS": "वित्तीय पेशेवर",
            "ENTERTAINMENT PROFESSIONALS": "मनोरंजन पेशेवर",
            "PROFESSIONAL ATHLETES": "पेशेवर खिलाड़ी",
            "REAL ESTATE PROFESSIONALS": "रियल एस्टेट पेशेवर",
            "TECH INDUSTRY PROFESSIONALS": "टेक उद्योग पेशेवर",
            "LUXURY GOODS AND SERVICES": "शौकीन वस्त्र और सेवाएं",
            "ART AND COLLECTIBLES": "कला और संग्रह",
            PHILANTHROPY: "दान",
            EDUCATION: "शिक्षा",
            "HEALTH AND WELLNESS": "स्वास्थ्य और स्वास्थ्य",
            TECHNOLOGY: "प्रौद्योगिकी",
            "SUSTAINABLE INVESTMENTS": "सतत निवेश",
            SPORTS: "खेल",
            "TRAVEL AND HOSPITALITY": "यात्रा और आतिथ्य",
            MARRIED: "विवाहित",
            UNMARRIED: "अविवाहित",
            DIVORCED: "तलाकशुदा",
            "WEALTH MANAGEMENT": "संपत्ति प्रबंधन",
            OTHERS: "अन्य",
            MALE: "पुरुष",
            FEMALE: "महिला",
            "Payment Initiated": "भुगतान प्रारंभ किया गया",
            "Payment Reversed": "भुगतान उलटा",
            "Payment Received": "भुगतान प्राप्त हुआ",
            "Product Allocated": "उत्पाद आवंटित",
            "Sell Initiated": "बेचने की प्रारंभिकता",
            "Product Sold": "उत्पाद बेचा गया",
            "Service Provisioned": "सेवा प्रदान की गई",
            "Open - Purchase Initiated": "खुला - खरीद आरंभ की गई",
            "Open - Payment Initiated": "खुला - भुगतान प्रारंभ किया गया",
            "Open - Payment Reversed": "खुला - भुगतान उलटा",
            "Open - Payment Received": "खुला - भुगतान प्राप्त हुआ",
            "Completed - Product Allocated": "पूरा हुआ - उत्पाद आवंटित",
            "Open - Sell Initiated": "खुला - बेचने की प्रारंभिकता",
            "Completed - Product Sold": "पूरा हुआ - उत्पाद बेचा गया",
            "Completed - Service Provisioned": "पूरा हुआ - सेवा प्रदान की गई",
            Bank: "बैंक",
            Asset: "संपत्ति",
            Liability: "दायित्व",
            SPOUSE: "पति/पत्नी",
            CHILD: "बच्चा",
            PARENT: "माता-पिता",
            SIBLING: "भाई-बहन",
            OTHER: "अन्य",
            "BUYING A HOUSE": "घर खरीदना",
            TRAVEL: "यात्रा",
            RETIREMENT: "निवृत्ति",
            "LEGACY PLANNING": "विरासत योजना",
            NOVICE: "नौसिखिया",
            INTERMEDIATE: "मध्यस्थ",
            EXPERT: "विशेषज्ञ",
            "SHORT TERM": "लघुकालिक",
            "MEDIUM TERM": "मध्यमकालिक",
            "LONG TERM": "दीर्घकालिक",
            "INVESTMENT STRATEGIES": "निवेश रणनीतियाँ",
            "TRUST SERVICES": "विश्वास सेवाएं",
            "SPORTS AND FITNESS ACTIVITIES": "खेल और फिटनेस गतिविधियाँ",
            "ARTS AND CULTURE": "कला और संस्कृति",
            "TRAVEL AND ADVENTURE": "यात्रा और एडवेंचर",
            "MUSIC AND ENTERTAINMENT": "संगीत और मनोरंजन",
            "FOOD AND DINING": "खाना और भोजन",
            "FASHION AND STYLE:": "फैशन और शैली:",
            "TECHNOLOGY AND GADGETS": "प्रौद्योगिकी और गैजेट्स",
            SALARY: "वेतन",
            INVESTMENTS: "निवेश",
            "BUSINESS OWNERSHIP": "व्यवसाय स्वामित्व",
            CASH: "नकद",
            SAVINGS: "बचत",
            "MONEY MARKET ACCOUNTS": "मनी मार्केट खाते",
            STOCKS: "स्टॉक्स",
            BONDS: "बॉन्ड्स",
            "MUTUAL FUNDS": "म्यूचुअल फंड्स",
            "PENSION PLANS": "पेंशन योजनाएं",
            ANNUITIES: "एन्यूइटीज़",
            ART: "कला",
            COLLECTIBLES: "संग्रह",
            PROPERTIES: "संपत्तियाँ",
            "PERSONAL LOANS": "व्यक्तिगत ऋण",
            "STUDENT LOANS": "छात्र ऋण",
            HOUSING: "आवास",
            UTILITIES: "उपयोगिताएँ",
            TRANSPORTATION: "परिवहन",
            "PROPERTY INSURANCE": "संपत्ति बीमा",
            "LIABILITY INSURANCE": "दायित्व बीमा",
            "LEGAL ADVISORY": "कानूनी सलाह",
            "COMPLIANCE SERVICES": "अनुपालन सेवाएं",
            "ACCOUNTING SERVICES": "लेखा सेवाएं",
            "LEVERAGE MANAGEMENT": "लीवरेज प्रबंधन",
            "REQUEST EXPERT ADVISORY MEETING":
                "विशेषज्ञ सलाह बैठक का अनुरोध करें",
            PASSPORT: "पासपोर्ट",
            "IDENTITY CARD": "पहचान पत्र",
            "DRIVING LICENSE": "ड्राइविंग लाइसेंस",
            "BANK STATEMENT": "बैंक स्टेटमेंट",
            "UTILITY BILL": "यूटिलिटी बिल",
            "PHONE BILL": "फोन बिल",
            "TAX BILL": "कर बिल",
            "RENTAL AGREEMENT": "किराया अनुबंध",
            "NORMAL DELIVERY": "सामान्य वितरण",
            INBOND: "इनबॉन्ड",
            "SPECIAL DELIVERY": "विशेष वितरण",
            Latest: "नवीनतम",
            Ascending: "आरोही",
            Descending: "अवरोही",
            "Enable Access": "पहुंच सक्षम करें",
            "Disable Access": "पहुंच अक्षम करें",
            MFO: "MFO",
            SFO: "SFO"
        }
    },
    fr: {
        translation: {
            SUBQUESTION: "Sous-question",
            Answer: "Réponse",
            "Tool Used": "Outil utilisé",
            Show: "Afficher",
            Hide: "Masquer",
            "detailed analysis": "analyse détaillée",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "Interrogez les 5500+ actions les plus récentes 10-K et 10-Q, obtenez une réponse en quelques secondes",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "Téléchargez vos données privées, organisez-les dans un dossier et posez-leur des questions.",
            "Collection of tools helping you better manage and understand your portfolio.":
                "Collection d'outils vous aidant à mieux gérer et comprendre votre portefeuille.",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "Gaia Agent vous permet de réaliser des recherches approfondies sur plus de 80 000 titres publics (actions, obligations, matières premières, crypto...)",
            "Gaia Terminal": "Terminal Gaia",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "Posez n'importe quelle question sur 80 000+ instruments financiers et laissez l'agent faire le travail.",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "Assurez-vous de saisir le ticker exact, en particulier pour les bourses non américaines (ex : RELIANCE.NS)",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "Découvrez les principaux domaines de génération de revenus et de profits pour Alphabet",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "Explorez comment les investissements du trésor d'Alphabet sont répartis entre différentes classes d'actifs",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "Consultez l'analyse des récents rapports de résultats d'Amazon, y compris les surprises ou tendances notables",
            "Find out the total assets and total liabilities of Apple":
                "Découvrez les actifs totaux et les passifs totaux d'Apple",
            "Check out the different tax obligation/provision on Apple":
                "Consultez les différentes obligations fiscales/provisions sur Apple",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "Utilisez le bot pour analyser les dépenses de recherche et développement en pourcentage du chiffre d'affaires pour Meta et évaluez également l'engagement de Meta en matière d'innovation",
            "Figure out the revenue by geography for Meta":
                "Découvrez les revenus par géographie pour Meta",
            "Understand the major areas of capital expenditure for Microsoft":
                "Comprendre les principaux domaines de dépenses en capital pour Microsoft",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "Découvrez l'impact des récents changements réglementaires sur les opérations commerciales de Amazon Communications et ses perspectives financières",
            "Invest in a timeless designer handbag":
                "Investissez dans un sac à main de designer intemporel",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "Investissez dans une montre de luxe de marques prestigieuses comme rolex, philippe ou audemars piguet",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "Restez élégant et au chaud avec un manteau ou une veste de luxe d'une maison de mode renommée",
            "What should i consider when selecting a luxury service vendor?":
                "Que dois-je prendre en compte lors de la sélection d'un fournisseur de services de luxe ?",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "Les fournisseurs de services de luxe peuvent-ils personnaliser leurs offres pour répondre à des besoins ou préférences spécifiques ?",
            "What type of certifications or credentials should luxury service vendors have?":
                "Quel type de certifications ou de qualifications doivent avoir les fournisseurs de services de luxe ?",
            "Evolution and Trends in Luxury Services":
                "Évolution et tendances des services de luxe",
            "Luxury Travel Experiences and Destination Management":
                "Expériences de voyage de luxe et gestion des destinations",
            "The Influence of social media on luxury services":
                "L'influence des médias sociaux sur les services de luxe",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "Vérifiez qui sont les pairs de TSLA. Parmi eux, vérifiez lequel a la capitalisation boursière la plus élevée. Ensuite, sur le ticker qui a la capitalisation boursière la plus élevée, obtenez l'estimation de prix cible la plus récente d'un analyste, et dites-moi qui c'était et à quelle date l'estimation a été faite. Dites-moi si l'analyste est haussier ou baissier sur cette action.",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "Effectuez une analyse financière fondamentale d'AMZN en utilisant les données les plus récentes disponibles. Qu'avez-vous trouvé d'intéressant ?",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "Quels sont les principaux moteurs de la récente hausse du prix de l'or, et cette tendance est-elle durable à moyen terme ?",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "Quel est l'impact potentiel d'une augmentation de 25 points de base des taux d'intérêt de la Réserve fédérale sur notre portefeuille de revenu fixe ?",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "Compte tenu de la composition actuelle de notre portefeuille (<la composition du portefeuille doit être fournie ici>), quel est l'impact potentiel d'une augmentation significative des taux d'intérêt sur nos avoirs en revenu fixe ?",
            "Research Assistant": "Assistant de recherche",
            "Fundamentals Analysis": "Analyse fondamentale",
            "Market Research": "Recherche de marché",
            "Financial Analysis": "Analyse financière",
            "Portfolio Risk Management": "Gestion des risques de portefeuille",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "Téléchargez des études de marché, de grands rapports et obtenez des réponses à vos questions sans avoir à lire ces documents",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "Téléchargez les fiches techniques et les prospectus des produits financiers complexes, Gaia trouvera tout ce dont vous avez besoin, comme le calendrier de règlement, le prix d'exercice, le knock-out, ...",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "Téléchargez un grand document, sélectionnez-le dans l'affichage du dossier, et le bouton de résumé devient actif.",
            "Deep search through large documents":
                "Recherche approfondie dans de grands documents",
            "Analyze complex financial documents":
                "Analyser des documents financiers complexes",
            "Summarize large documents": "Résumer de grands documents",
            Suggestions: "Suggestions",
            "Internal Data": "Données internes",
            "Upload your own files, order them across folders, and ask any question.":
                "Téléchargez vos propres fichiers, classez-les dans des dossiers et posez des questions.",
            "Here are a few ideas of what you can do with Gaia:":
                "Voici quelques idées de ce que vous pouvez faire avec Gaia:",
            Ideas: "Idées",
            Topics: "Sujets",
            FAQ: "FAQ",
            Intraday: "Intraday",
            Daily: "Quotidien",
            Weekly: "Hebdomadaire",
            Monthly: "Mensuel",
            "LATEST QUOTE": "DERNIERE COTATION",
            "Open:": "Ouverture:",
            "High:": "Haut:",
            "Low:": "Bas:",
            "Volume:": "Volume:",
            "Latest trading day:": "Dernier jour d'echange:",
            "Previous close:": "Clôture précédente:",
            "MARKET NEWS": "ACTUALITES DU MARCHE",
            Date: "Date",
            Title: "Titre",
            Sentiment: "Sentiment",
            Source: "Source",
            "Share Information": "Partager des informations",
            Action: "Action",
            Admins: "Administrateurs",
            Share: "Partager",
            "Client Portfolio": "Portefeuille client",
            "Client's": "Du client",
            Clients: "Clients",
            "View Portfolio": "Voir le portefeuille",
            "Gaia Agent History": "Historique de l'agent Gaia",
            "Click refresh button to update status of agents in progress":
                "Cliquez sur le bouton de rafraîchissement pour mettre à jour l'état des agents en cours",
            "Documents in folder": "Documents dans le dossier",
            File: "Fichier",
            Folder: "Dossier",
            "Pick Internal Data folder":
                "Choisissez le dossier de données internes",
            "Summarize document": "Résumer le document",
            "Folder created successfully": "Dossier créé avec succès",
            "Folder ": "Dossier ",
            " successfully deleted": " supprimé avec succès",
            "File uploaded successfully": "Fichier téléchargé avec succès",
            "Document deleted successfully": "Document supprimé avec succès",
            "Create Internal Data Folder":
                "Créer un dossier de données internes",
            Save: "Enregistrer",
            Cancel: "Annuler",
            "Are you sure you want to delete the ":
                "Êtes-vous sûr de vouloir supprimer le ",
            " folder?": " dossier?",
            Delete: "Supprimer",
            "Upload New Document in Folder":
                "Télécharger un nouveau document dans le dossier",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "Fichiers pris en charge : .doc, .docx, .txt, .rtf, .htm, .html, .pdf",
            "Notes regarding pdfs:": "Notes concernant les pdf :",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "Les PDF qui sont des PDF d'image ou qui utilisent des polices personnalisées seront chargés à l'aide de techniques de reconnaissance de caractères. Cela limite notre capacité à analyser les tableaux dans de tels documents,",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "nous vous encourageons donc à soumettre ces documents dans des formats alternatifs pour une meilleure précision.",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "Un grand PDF peut prendre jusqu'à 5 minutes pour être traité, il est conseillé de ne pas fermer cette fenêtre.",
            "Are you sure you want to delete this document?":
                "Êtes-vous sûr de vouloir supprimer ce document ?",
            "Select a Client's portfolio":
                "Sélectionnez le portefeuille d'un client",
            "Please select a client": "Veuillez sélectionner un client",
            "Get Portfolio Recommendations":
                "Obtenir des recommandations de portefeuille",
            "Robo-Advisor (Asset Classes)":
                "Robo-Conseiller (Classes d'actifs)",
            "Pick a stock:": "Choisissez une action:",
            Ticker: "Ticker",
            Compare: "Comparer",
            Client: "Client",
            DCA: "DCA",
            Financial: "Financier",
            Lifestyle: "Style de vie",
            Stocks: "Actions",
            Portfolio: "Portefeuille",
            Agent: "Agent",
            Bookmarks: "Signets",
            History: "Historique",
            Notifications: "Notifications",
            "Please select a Stock first":
                "Veuillez d'abord sélectionner une action",
            "Please select a folder first":
                "Veuillez d'abord sélectionner un dossier",
            "Please select a private equity first":
                "Veuillez d'abord sélectionner un capital-investissement",
            "Please enter a question": "Veuillez poser une question",
            "Share to Admin": "Partager avec les administrateurs",
            "Share to client": "Partager avec un client",
            "Useful response": "Réponse utile",
            "Bad response": "Mauvaise réponse",
            "Get Stock Information": "Obtenir des informations sur l'action",
            "Generate Graph": "Générer un graphique",
            "Financial Data based research":
                "Recherche basée sur les données financières",
            "Web based research": "Recherche sur le web",
            "Send a message.": "Envoyer un message.",
            "Name/Emp ID": "Nom/ID Emp",
            Idea: "Idée",
            "Folder Name": "Nom du dossier",
            Dashboard: "Tableau de bord",
            "Ask Gaia": "Demander à Gaia",
            Product: "Produit",
            Service: "Service",
            "My Prospects": "Mes prospects",
            "My Clients": "Mes clients",
            Subscriptions: "Abonnements",
            Payments: "Paiements",
            FAQs: "FAQ",
            "User Management": "Gestion des utilisateurs",
            "Vendor Management": "Gestion des fournisseurs",
            "Financial Products": "Produits financiers",
            "Lifestyle Products": "Produits de style de vie",
            "Financial Services": "Services financiers",
            "Lifestyle Services": "Services de style de vie",
            Campaign: "Campagne",
            Reports: "Rapports",
            "RM Performance": "Performance de l'AR",
            "Customer Feedback": "Retour client",
            "Order Management": "Gestion des commandes",
            "Ref.Data Management": "Gestion des données de référence",
            "Chat with RM": "Discuter avec l'AR",
            Chat: "Discussion",
            Bordeaux: "Bordeaux",
            "Bill of Materials": "Nomenclature",
            "Configure Freight Charges": "Configurer les frais de transport",
            "No. of Clients": "Nombre de clients",
            "No. of RM's": "Nombre d'AR",
            "Total Partners/Vendors": "Total des partenaires/fournisseurs",
            "Total AUM": "Total AUM",
            "Invested Wealth": "Richesse investie",
            "Aggregate Asset Allocation (%)": "Allocation d'actifs agrégée (%)",
            "Aggregate Geographical Allocation (%)":
                "Allocation géographique agrégée (%)",
            "Aggregate Sector Allocation (%)":
                "Allocation sectorielle agrégée (%)",
            AUM: "AUM",
            Revenue: "Revenu",
            Task: "Tâche",
            "Market News": "Actualités du marché",
            "Gaia Help": "Aide de Gaia",
            All: "Tout",
            Equities: "Actions",
            "Fixed Income": "Revenu fixe",
            "Cash Or Equivalent": "Espèces ou équivalent",
            "Structured Products": "Produits structurés",
            "Funds and Etfs": "Fonds et ETF",
            Commodities: "Matières premières",
            "Real Estate": "Immobilier",
            "Esg/Impact Investing": "Investissement ESG/Impact",
            Insurance: "Assurance",
            Crypto: "Crypto",
            "Private Equity": "Capital-investissement",
            "Growth Equity": "Capital-investissement de croissance",
            "Venture Capital Investment": "Investissement en capital-risque",
            "Private Credit": "Crédit privé",
            "Pre-Ipo Products": "Produits pré-IPO",
            "Direct Deal Products": "Produits de transaction directe",
            "Other Alternatives": "Autres alternatives",
            Search: "Rechercher",
            Filters: "Filtres",
            Travel: "Voyage",
            Wellness: "Bien-être",
            Food: "Alimentation",
            Health: "Santé",
            Sports: "Sports",
            Art: "Art",
            Wine: "Vin",
            "Live Trade": "Commerce en direct",
            "Sign In": "Se connecter",
            "Sign in": "Se connecter",
            "Email address": "Adresse e-mail",
            Password: "Mot de passe",
            "Forgot password?": "Mot de passe oublié ?",
            "All Rights Reserved": "Tous droits réservés",
            Currency: "Devise",
            "My Profile": "Mon profil",
            "Change Password": "Changer le mot de passe",
            Logout: "Déconnexion",
            Status: "Statut",
            Active: "Actif",
            Actions: "Actions",
            Profile: "Profil",
            Transactions: "Transactions",
            "Client Name": "Nom du client",
            "Invested Wealth ($)": "Richesse investie ($)",
            "Investible Wealth ($)": "Richesse investissable ($)",
            "Revenue ($)": "Revenu ($)",
            Personal: "Personnel",
            Professional: "Professionnel",
            Family: "Famille",
            Interest: "Intérêt",
            KYC: "KYC",
            Salutation: "Salutation",
            "First Name": "Prénom",
            "Last Name": "Nom de famille",
            "D.O.B": "Date de naissance",
            Gender: "Genre",
            "Highest Education": "Plus haute éducation",
            "Primary Email ID": "Identifiant de messagerie principal",
            "Secondary Email ID": "Identifiant de messagerie secondaire",
            "Mobile Number": "Numéro de portable",
            "Not Editable": "Non modifiable",
            "Alternative Mobile Number": "Numéro de portable alternatif",
            "Base Location": "Lieu de base",
            Origin: "Origine",
            "Address 1": "Adresse 1",
            "Address 2": "Adresse 2",
            "Address 3": "Adresse 3",
            Country: "Pays",
            State: "État",
            City: "Ville",
            "Postal Code": "Code postal",
            "Net Worth $": "Valeur nette $",
            "Source of Wealth": "Source de richesse",
            "Investable wealth $": "Richesse investissable $",
            "Risk appetite": "Appétit pour le risque",
            "Industry preference": "Préférence de l'industrie",
            "Financial goals": "Objectifs financiers",
            "Joining Fee $": "Frais d'adhésion $",
            "Annual Subscription Fee $": "Frais d'abonnement annuel $",
            "Current Investment Approach":
                "Approche actuelle de l'investissement",
            "Take Survey": "Faire le sondage",
            "Present Asset Allocations:": "Allocations d'actifs actuelles:",
            ALL: "TOUT",
            EQUITIES: "ACTIONS",
            "FIXED INCOME": "REVENUS FIXES",
            "CASH OR EQUIVALENT": "ESPÈCES OU ÉQUIVALENT",
            "STRUCTURED PRODUCTS": "PRODUITS STRUCTURÉS",
            "FUNDS AND ETFS": "FONDS ET ETF",
            COMMODITIES: "MATIÈRES PREMIÈRES",
            "REAL ESTATE": "IMMOBILIER",
            "ESG/IMPACT INVESTING": "INVESTISSEMENT ESG/IMPACT",
            INSURANCE: "ASSURANCE",
            CRYPTO: "CRYPTO",
            "PRIVATE EQUITY": "CAPITAL-INVESTISSEMENT",
            "GROWTH EQUITY": "CAPITAL-INVESTISSEMENT DE CROISSANCE",
            "VENTURE CAPITAL INVESTMENT": "INVESTISSEMENT EN CAPITAL-RISQUE",
            "PRIVATE CREDIT": "CRÉDIT PRIVÉ",
            "PRE-IPO PRODUCTS": "PRODUITS PRÉ-IPO",
            "DIRECT DEAL PRODUCTS": "PRODUITS DE TRANSACTION DIRECTE",
            "OTHER ALTERNATIVES": "AUTRES ALTERNATIVES",
            "Is my RM authorised to do transactions on my behalf?":
                "Mon AR est-il autorisé à effectuer des transactions en mon nom ?",
            YES: "OUI",
            NO: "NON",
            Yes: "Oui",
            No: "Non",
            "Profession Type": "Type de profession",
            Industry: "Industrie",
            "Domain / Sector": "Domaine / Secteur",
            Turnover: "Chiffre d'affaires",
            "Is it a hereditary business?":
                "Est-ce une entreprise héréditaire ?",
            "Marital status": "État civil",
            "Number of family members": "Nombre de membres de la famille",
            "Family Members": "Membres de la famille",
            Personality: "Personnalité",
            "Current Memberships": "Adhésions actuelles",
            "Previous Vacations": "Vacances précédentes",
            "Proof of Identity": "Preuve d'identité",
            "Proof of Address": "Preuve d'adresse",
            "Proof of Tax Residency": "Preuve de résidence fiscale",
            "Proof of Wealth": "Preuve de richesse",
            "Other Documents": "Autres documents",
            "Document Type": "Type de document",
            "ID Number": "Numéro d'identification",
            "Expiry date": "Date d'expiration",
            "Upload documents": "Télécharger des documents",
            "Zip code": "Code postal",
            Edit: "Modifier",
            Type: "Type",
            "Product / Service Category": "Catégorie de produit / service",
            "Date of Purchase": "Date d'achat",
            Time: "Heure",
            "Price($)": "Prix($)",
            Quantity: "Quantité",
            "Amount($)": "Montant($)",
            "Payment Ref": "Référence de paiement",
            Terminate: "Résilier",
            "Go To Order": "Aller à la commande",
            Open: "Ouvrir",
            Completed: "Terminé",
            "Purchase Initiated": "Achat initié",
            "Aging Days": "Jours de vieillissement",
            Vendor: "Fournisseur",
            "RM/Employee Id": "ID AR/Employé",
            "Product Name": "Nom du produit",
            "Selling Price ($)": "Prix de vente ($)",
            "Investment Term (in Months)":
                "Durée de l'investissement (en mois)",
            "End Date": "Date de fin",
            "Product Category": "Catégorie de produit",
            Region: "Région",
            Liquidity: "Liquidité",
            "Returns Level": "Niveau de rendement",
            "Risk Level": "Niveau de risque",
            "Price ($)": "Prix ($)",
            "Take Rate": "Commission prise",
            "Client's Invested": "Investi du client",
            Tags: "Étiquettes",
            "Product Description": "Description du produit",
            "Purchased Items": "Articles achetés",
            "Current Quantity": "Quantité actuelle",
            "Current price ($)": "Prix actuel ($)",
            "Active Buy/Sell": "Achat/Vente actif",
            "No Active buy/sell records found":
                "Aucun enregistrement d'achat/vente actif trouvé",
            "Product / Transaction Status":
                "Statut du produit / de la transaction",
            ACTIVE: "ACTIF",
            INACTIVE: "INACTIF",
            WFA: "En attente d'approbation",
            DELETED: "SUPPRIMÉ",
            NEW: "NOUVEAU",
            REPLIED: "RÉPONDU",
            REJECTED: "REJETÉ",
            SYSTEM: "SYSTÈME",
            CLIENT: "CLIENT",
            "FLAT RATE": "TAUX FIXE",
            "MR.": "M.",
            "MRS.": "MME.",
            "MS.": "MME.",
            "FINANCIAL PRODUCTS": "PRODUITS FINANCIERS",
            "LIFESTYLE PRODUCTS": "PRODUITS DE STYLE DE VIE",
            "FINANCIAL SERVICES": "SERVICES FINANCIERS",
            "LIFESTYLE SERVICES": "SERVICES DE STYLE DE VIE",
            STEADY: "CONSTANT",
            AGGRESSIVE: "AGRESSIF",
            MODERATE: "MODÉRÉ",
            CREDIT: "CRÉDIT",
            DEBIT: "DÉBIT",
            "LOW RISK": "FAIBLE RISQUE",
            "MEDIUM RISK": "RISQUE MOYEN",
            "HIGH RISK": "RISQUE ÉLEVÉ",
            "N/A": "N/A",
            "HIGH SCHOOL": "LYCÉE",
            "UNDER GRADUATE": "LICENCE",
            "POST GRADUATE": "POST-GRADUATION",
            "AEROSPACE AND DEFENSE": "AÉROSPATIALE ET DÉFENSE",
            "AGRICULTURE AND AGRIBUSINESS": "AGRICULTURE ET AGROBUSINESS",
            AUTOMOTIVE: "AUTOMOBILE",
            "BANKING AND FINANCE": "BANQUE ET FINANCE",
            CHEMICALS: "PRODUITS CHIMIQUES",
            "CONSTRUCTION AND INFRASTRUCTURE": "CONSTRUCTION ET INFRASTRUCTURE",
            "CONSUMER GOODS AND SERVICES": "BIENS DE CONSOMMATION ET SERVICES",
            "EDUCATION AND TRAINING": "ÉDUCATION ET FORMATION",
            "ENERGY AND UTILITIES": "ÉNERGIE ET SERVICES PUBLICS",
            "ENTERTAINMENT AND MEDIA": "DIVERTISSEMENT ET MÉDIAS",
            "HEALTHCARE AND PHARMACEUTICALS": "SANTÉ ET PHARMACEUTIQUES",
            "HOSPITALITY AND TOURISM": "HÔTELLERIE ET TOURISME",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS":
                "TECHNOLOGIES DE L'INFORMATION ET TÉLÉCOMMUNICATIONS",
            MANUFACTURING: "FABRICATION",
            "MINING AND METALS": "MINES ET MÉTAUX",
            "REAL ESTATE AND PROPERTY": "IMMOBILIER ET PROPRIÉTÉ",
            RETAIL: "DÉTAIL",
            "TRANSPORTATION AND LOGISTICS": "TRANSPORT ET LOGISTIQUE",
            "BIOTECHNOLOGY AND LIFE SCIENCES":
                "BIOTECHNOLOGIE ET SCIENCES DE LA VIE",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY":
                "TECHNOLOGIES PROPRES ET ÉNERGIES RENOUVELABLES",
            "CONSUMER ELECTRONICS": "ÉLECTRONIQUE GRAND PUBLIC",
            "ENVIRONMENTAL AND WASTE MANAGEMENT":
                "ENVIRONNEMENT ET GESTION DES DÉCHETS",
            "FOOD AND BEVERAGE": "ALIMENTATION ET BOISSONS",
            "GOVERNMENT AND PUBLIC SERVICES":
                "GOUVERNEMENT ET SERVICES PUBLICS",
            "LEGAL SERVICES": "SERVICES JURIDIQUES",
            "NON-PROFIT AND SOCIAL ENTERPRISE":
                "ORGANISATION À BUT NON LUCRATIF ET ENTREPRISE SOCIALE",
            "PROFESSIONAL SERVICES": "SERVICES PROFESSIONNELS",
            "SECURITY AND CYBERSECURITY": "SÉCURITÉ ET CYBERSÉCURITÉ",
            "TEXTILES AND APPAREL": "TEXTILES ET VÊTEMENTS",
            "RISK AVERSE": "AVERTI AU RISQUE",
            CONSERVATIVE: "CONSERVATEUR",
            "WEALTH PRESERVATION": "PRÉSERVATION DE LA RICHESSE",
            "CAPITAL APPRECIATION": "APPRECIATION DU CAPITAL",
            "ESTATE PLANNING": "PLANIFICATION SUCCESSORALE",
            "RISK MANAGEMENT": "GESTION DES RISQUES",
            "SUCCESSION PLANNING": "PLANIFICATION DE LA SUCCESSION",
            "TAX PLANNING": "PLANIFICATION FISCALE",
            ENTREPRENEURS: "ENTREPRENEURS",
            INVESTORS: "INVESTISSEURS",
            "CORPORATE EXECUTIVES": "CADRES D'ENTREPRISE",
            "FINANCIAL PROFESSIONALS": "PROFESSIONNELS DE LA FINANCE",
            "ENTERTAINMENT PROFESSIONALS": "PROFESSIONNELS DU DIVERTISSEMENT",
            "PROFESSIONAL ATHLETES": "ATHLÈTES PROFESSIONNELS",
            "REAL ESTATE PROFESSIONALS": "PROFESSIONNELS DE L'IMMOBILIER",
            "TECH INDUSTRY PROFESSIONALS":
                "PROFESSIONNELS DE L'INDUSTRIE TECHNOLOGIQUE",
            "LUXURY GOODS AND SERVICES": "BIENS ET SERVICES DE LUXE",
            "ART AND COLLECTIBLES": "ART ET OBJETS DE COLLECTION",
            PHILANTHROPY: "PHILANTHROPIE",
            EDUCATION: "ÉDUCATION",
            "HEALTH AND WELLNESS": "SANTÉ ET BIEN-ÊTRE",
            TECHNOLOGY: "TECHNOLOGIE",
            "SUSTAINABLE INVESTMENTS": "INVESTISSEMENTS DURABLES",
            SPORTS: "SPORTS",
            "TRAVEL AND HOSPITALITY": "VOYAGE ET HÔTELLERIE",
            MARRIED: "MARIÉ",
            UNMARRIED: "NON MARIÉ",
            DIVORCED: "DIVORCÉ",
            "WEALTH MANAGEMENT": "GESTION DE PATRIMOINE",
            OTHERS: "AUTRES",
            MALE: "HOMME",
            FEMALE: "FEMME",
            "Payment Initiated": "Paiement initié",
            "Payment Reversed": "Paiement inversé",
            "Payment Received": "Paiement reçu",
            "Product Allocated": "Produit alloué",
            "Sell Initiated": "Vente initiée",
            "Product Sold": "Produit vendu",
            "Service Provisioned": "Service fourni",
            "Open - Purchase Initiated": "Ouvert - Achat initié",
            "Open - Payment Initiated": "Ouvert - Paiement initié",
            "Open - Payment Reversed": "Ouvert - Paiement inversé",
            "Open - Payment Received": "Ouvert - Paiement reçu",
            "Completed - Product Allocated": "Terminé - Produit alloué",
            "Open - Sell Initiated": "Ouvert - Vente initiée",
            "Completed - Product Sold": "Terminé - Produit vendu",
            "Completed - Service Provisioned": "Terminé - Service fourni",
            Bank: "Banque",
            Asset: "Actif",
            Liability: "Passif",
            SPOUSE: "CONJOINT",
            CHILD: "ENFANT",
            PARENT: "PARENT",
            SIBLING: "FRÈRE/SŒUR",
            OTHER: "AUTRE",
            "BUYING A HOUSE": "ACHETER UNE MAISON",
            TRAVEL: "VOYAGE",
            RETIREMENT: "RETRAITE",
            "LEGACY PLANNING": "PLANIFICATION DE LA SUCCESSION",
            NOVICE: "NOVICE",
            INTERMEDIATE: "INTERMÉDIAIRE",
            EXPERT: "EXPERT",
            "SHORT TERM": "À COURT TERME",
            "MEDIUM TERM": "À MOYEN TERME",
            "LONG TERM": "À LONG TERME",
            "INVESTMENT STRATEGIES": "STRATÉGIES D'INVESTISSEMENT",
            "TRUST SERVICES": "SERVICES DE CONFIANCE",
            "SPORTS AND FITNESS ACTIVITIES":
                "ACTIVITÉS SPORTIVES ET DE FITNESS",
            "ARTS AND CULTURE": "ARTS ET CULTURE",
            "TRAVEL AND ADVENTURE": "VOYAGE ET AVENTURE",
            "MUSIC AND ENTERTAINMENT": "MUSIQUE ET DIVERTISSEMENT",
            "FOOD AND DINING": "NOURRITURE ET RESTAURATION",
            "FASHION AND STYLE:": "MODE ET STYLE:",
            "TECHNOLOGY AND GADGETS": "TECHNOLOGIE ET GADGETS",
            SALARY: "SALAIRE",
            INVESTMENTS: "INVESTISSEMENTS",
            "BUSINESS OWNERSHIP": "PROPRIÉTÉ D'ENTREPRISE",
            CASH: "ESPÈCES",
            SAVINGS: "ÉPARGNE",
            "MONEY MARKET ACCOUNTS": "COMPTES DU MARCHÉ MONÉTAIRE",
            STOCKS: "ACTIONS",
            BONDS: "OBLIGATIONS",
            "MUTUAL FUNDS": "FONDS COMMUNS DE PLACEMENT",
            "PENSION PLANS": "PLANS DE PENSION",
            ANNUITIES: "RENTES",
            ART: "ART",
            COLLECTIBLES: "OBJETS DE COLLECTION",
            PROPERTIES: "BIENS",
            "PERSONAL LOANS": "PRÊTS PERSONNELS",
            "STUDENT LOANS": "PRÊTS ÉTUDIANTS",
            HOUSING: "LOGEMENT",
            UTILITIES: "SERVICES PUBLICS",
            TRANSPORTATION: "TRANSPORT",
            "PROPERTY INSURANCE": "ASSURANCE DE BIENS",
            "LIABILITY INSURANCE": "ASSURANCE DE RESPONSABILITÉ",
            "LEGAL ADVISORY": "CONSEIL JURIDIQUE",
            "COMPLIANCE SERVICES": "SERVICES DE CONFORMITÉ",
            "ACCOUNTING SERVICES": "SERVICES DE COMPTABILITÉ",
            "LEVERAGE MANAGEMENT": "GESTION DE L'EFFET DE LEVIER",
            "REQUEST EXPERT ADVISORY MEETING":
                "DEMANDER UNE RÉUNION DE CONSEIL D'EXPERT",
            PASSPORT: "PASSEPORT",
            "IDENTITY CARD": "CARTE D'IDENTITÉ",
            "DRIVING LICENSE": "PERMIS DE CONDUIRE",
            "BANK STATEMENT": "RELEVÉ BANCAIRE",
            "UTILITY BILL": "FACTURE D'ÉLECTRICITÉ",
            "PHONE BILL": "FACTURE DE TÉLÉPHONE",
            "TAX BILL": "FACTURE D'IMPÔT",
            "RENTAL AGREEMENT": "CONTRAT DE LOCATION",
            "NORMAL DELIVERY": "LIVRAISON NORMALE",
            INBOND: "INBOND",
            "SPECIAL DELIVERY": "LIVRAISON SPÉCIALE",
            Latest: "Dernier",
            Ascending: "Croissant",
            Descending: "Décroissant",
            "Enable Access": "Activer l'accès",
            "Disable Access": "Désactiver l'accès",
            MFO: "MFO",
            SFO: "SFO"
        }
    },
    jp: {
        translation: {
            SUBQUESTION: "サブ質問",
            Answer: "回答",
            "Tool Used": "使用されたツール",
            Show: "表示",
            Hide: "非表示",
            "detailed analysis": "詳細な分析",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "5500以上の株式の最新の10-Kおよび10-Qをクエリし、数秒で回答を取得します",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "プライベートデータをアップロードし、フォルダに整理してそれについて質問してください。",
            "Collection of tools helping you better manage and understand your portfolio.":
                "ポートフォリオをよりよく管理し理解するのに役立つツールのコレクション。",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "Gaiaエージェントを使用すると、80,000以上の公開証券（株式、債券、商品、暗号通貨など）にわたる市場調査を実行できます。",
            "Gaia Terminal": "ガイアターミナル",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "80,000以上の金融商品について質問し、エージェントに作業をさせます。",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "米国以外の取引所（例：RELIANCE.NS）の場合は、正確なティッカーを入力してください。",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "アルファベットの収益と利益の主要な分野を見つける",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "アルファベットの財務投資がさまざまな資産クラスに分散されているかを探る",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "Amazonの最近の収益レポートの分析をチェックし、注目すべき驚きやトレンドを含む",
            "Find out the total assets and total liabilities of Apple":
                "Appleの総資産と総負債を調べる",
            "Check out the different tax obligation/provision on Apple":
                "Appleの異なる税務義務/規定をチェック",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "ボットを使用してMetaの研究開発費を収益の割合として分析し、Metaの革新への取り組みを評価",
            "Figure out the revenue by geography for Meta":
                "Metaの地理別収益を把握",
            "Understand the major areas of capital expenditure for Microsoft":
                "Microsoftの主要な資本支出領域を理解",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "最近の規制変更がAmazon Communicationsの事業運営および財務見通しに与える影響を把握",
            "Invest in a timeless designer handbag":
                "時代を超えるデザイナーハンドバッグに投資",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "ロレックス、フィリップなどの名だたるブランドの高級時計に投資",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "有名なファッションハウスの高級コートやジャケットでスタイリッシュで暖かく",
            "What should i consider when selecting a luxury service vendor?":
                "高級サービスベンダーを選択する際に考慮すべき点は？",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "高級サービスベンダーは、特定のニーズや希望に対応するために提供をカスタマイズできますか？",
            "What type of certifications or credentials should luxury service vendors have?":
                "高級サービスベンダーにはどのような認定資格が必要ですか？",
            "Evolution and Trends in Luxury Services":
                "高級サービスの進化とトレンド",
            "Luxury Travel Experiences and Destination Management":
                "高級旅行体験と目的地管理",
            "The Influence of social media on luxury services":
                "ソーシャルメディアが高級サービスに与える影響",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "TSLAのライバルをチェックします。その中で、最も高い時価総額を持つものをチェックします。次に、最も高い時価総額を持つ銘柄で、アナリストから最新の価格目標予測を取得し、誰がいつ予測を行ったかを教えてください。アナリストがこの株に対して楽観的か悲観的かを教えてください。",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "最新のデータを使用してAMZNの基本的な財務分析を行います。興味深い点は何ですか？",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "最近の金価格の急上昇の主な要因は何であり、このトレンドは中期的に持続可能ですか？",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "連邦準備制度の利上げ25ベーシスポイントの潜在的な影響は何ですか？",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "現在のポートフォリオ構成（<ポートフォリオ構成はここに記載する必要があります>）を考慮すると、利上げが固定収入保有資産に与える影響は何ですか？",
            "Research Assistant": "研究アシスタント",
            "Fundamentals Analysis": "基本分析",
            "Market Research": "市場調査",
            "Financial Analysis": "財務分析",
            "Portfolio Risk Management": "ポートフォリオリスク管理",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "市場調査、大規模なレポートをアップロードし、これらの文書を読まなくても質問に回答を得る",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "複雑な金融商品の契約書や開示書をアップロードし、Gaiaがそれらから必要な情報を見つけます（決済スケジュール、行使価格、ノックアウトなど）",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "大きな文書をアップロードし、フォルダ表示で選択し、要約ボタンをアクティブにします。",
            "Deep search through large documents":
                "大規模な文書を通じた深い検索",
            "Analyze complex financial documents": "複雑な金融文書を分析",
            "Summarize large documents": "大規模な文書を要約",
            Suggestions: "提案",
            "Internal Data": "内部データ",
            "Upload your own files, order them across folders, and ask any question.":
                "独自のファイルをアップロードし、フォルダ間で整理し、質問してください。",
            "Here are a few ideas of what you can do with Gaia:":
                "Gaiaでできることのアイデア：",
            Ideas: "アイデア",
            Topics: "トピック",
            FAQ: "よくある質問",
            Intraday: "日中",
            Daily: "毎日",
            Weekly: "毎週",
            Monthly: "毎月",
            "LATEST QUOTE": "最新の見積もり",
            "Open:": "オープン：",
            "High:": "高：",
            "Low:": "低：",
            "Volume:": "ボリューム：",
            "Latest trading day:": "最新の取引日：",
            "Previous close:": "前日終値：",
            "MARKET NEWS": "市場ニュース",
            Date: "日付",
            Title: "タイトル",
            Sentiment: "センチメント",
            Source: "ソース",
            "Share Information": "情報を共有",
            Action: "アクション",
            Admins: "管理者",
            Share: "共有",
            "Client Portfolio": "クライアントポートフォリオ",
            "Client's": "クライアントの",
            Clients: "クライアント",
            "View Portfolio": "ポートフォリオを表示",
            "Gaia Agent History": "Gaiaエージェント履歴",
            "Click refresh button to update status of agents in progress":
                "進行中のエージェントのステータスを更新するには、更新ボタンをクリックしてください",
            "Documents in folder": "フォルダ内の文書",
            File: "ファイル",
            Folder: "フォルダ",
            "Pick Internal Data folder": "内部データフォルダを選択",
            "Summarize document": "文書を要約",
            "Folder created successfully": "フォルダが正常に作成されました",
            "Folder ": "フォルダ ",
            " successfully deleted": " 正常に削除されました",
            "File uploaded successfully":
                "ファイルが正常にアップロードされました",
            "Document deleted successfully": "文書が正常に削除されました",
            "Create Internal Data Folder": "内部データフォルダを作成",
            Save: "保存",
            Cancel: "キャンセル",
            "Are you sure you want to delete the ": "本当に削除しますか ",
            " folder?": " フォルダ？",
            Delete: "削除",
            "Upload New Document in Folder":
                "フォルダに新しいドキュメントをアップロード",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "サポートされているファイル：.doc、.docx、.txt、.rtf、.htm、.html、.pdf",
            "Notes regarding pdfs:": "PDFに関する注意事項：",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "画像PDFまたはカスタムフォントを使用しているPDFは、文字認識技術を使用してロードされます。これにより、そのような文書のテーブルを解析する能力が制限されます。",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "したがって、より正確な情報を得るために、そのような文書を代替形式で提出することをお勧めします。",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "大きなPDFは最大5分かかる場合があります。このウィンドウを閉じないようにしてください。",
            "Are you sure you want to delete this document?":
                "本当にこの文書を削除しますか？",
            "Select a Client's portfolio": "クライアントのポートフォリオを選択",
            "Please select a client": "クライアントを選択してください",
            "Get Portfolio Recommendations": "ポートフォリオの推奨事項を取得",
            "Robo-Advisor (Asset Classes)": "ロボアドバイザー（資産クラス）",
            "Pick a stock:": "株を選択：",
            Ticker: "ティッカー",
            Compare: "比較",
            Client: "クライアント",
            DCA: "DCA",
            Financial: "財務",
            Lifestyle: "ライフスタイル",
            Stocks: "株",
            Portfolio: "ポートフォリオ",
            Agent: "エージェント",
            Bookmarks: "ブックマーク",
            History: "履歴",
            Notifications: "通知",
            "Please select a Stock first": "最初に株を選択してください",
            "Please select a folder first": "最初にフォルダを選択してください",
            "Please select a private equity first":
                "最初にプライベートエクイティを選択してください",
            "Please enter a question": "質問を入力してください",
            "Share to Admin": "管理者に共有",
            "Share to client": "クライアントに共有",
            "Useful response": "有用な回答",
            "Bad response": "悪い回答",
            "Get Stock Information": "株情報を取得",
            "Generate Graph": "グラフを生成",
            "Financial Data based research": "財務データに基づく研究",
            "Web based research": "Webベースの研究",
            "Send a message.": "メッセージを送信。",
            "Name/Emp ID": "名前/従業員ID",
            Idea: "アイデア",
            "Folder Name": "フォルダ名",
            Dashboard: "ダッシュボード",
            "Ask Gaia": "ガイアに尋ねる",
            Product: "製品",
            Service: "サービス",
            "My Prospects": "私の見込み客",
            "My Clients": "私のクライアント",
            Subscriptions: "サブスクリプション",
            Payments: "支払い",
            FAQs: "FAQ",
            "User Management": "ユーザー管理",
            "Vendor Management": "ベンダー管理",
            "Financial Products": "金融商品",
            "Lifestyle Products": "ライフスタイル製品",
            "Financial Services": "金融サービス",
            "Lifestyle Services": "ライフスタイルサービス",
            Campaign: "キャンペーン",
            Reports: "レポート",
            "RM Performance": "RMのパフォーマンス",
            "Customer Feedback": "顧客フィードバック",
            "Order Management": "注文管理",
            "Ref.Data Management": "参照データ管理",
            "Chat with RM": "RMとチャット",
            Chat: "チャット",
            Bordeaux: "ボルドー",
            "Bill of Materials": "材料明細書",
            "Configure Freight Charges": "運賃の設定",
            "No. of Clients": "クライアント数",
            "No. of RM's": "RM数",
            "Total Partners/Vendors": "合計パートナー/ベンダー",
            "Total AUM": "合計AUM",
            "Invested Wealth": "投資された富",
            "Aggregate Asset Allocation (%)": "総資産配分（％）",
            "Aggregate Geographical Allocation (%)": "総地理的配分（％）",
            "Aggregate Sector Allocation (%)": "総セクター配分（％）",
            AUM: "AUM",
            Revenue: "収益",
            Task: "タスク",
            "Market News": "市場ニュース",
            "Gaia Help": "ガイアヘルプ",
            All: "すべて",
            Equities: "株式",
            "Fixed Income": "固定収入",
            "Cash Or Equivalent": "現金または同等",
            "Structured Products": "構造化商品",
            "Funds and Etfs": "ファンドとETF",
            Commodities: "商品",
            "Real Estate": "不動産",
            "Esg/Impact Investing": "ESG/インパクト投資",
            Insurance: "保険",
            Crypto: "暗号",
            "Private Equity": "プライベートエクイティ",
            "Growth Equity": "成長エクイティ",
            "Venture Capital Investment": "ベンチャーキャピタル投資",
            "Private Credit": "プライベートクレジット",
            "Pre-Ipo Products": "IPO前製品",
            "Direct Deal Products": "直接取引製品",
            "Other Alternatives": "その他の代替",
            Search: "検索",
            Filters: "フィルター",
            Travel: "旅行",
            Wellness: "ウェルネス",
            Food: "食べ物",
            Health: "健康",
            Sports: "スポーツ",
            Art: "アート",
            Wine: "ワイン",
            "Live Trade": "ライブトレード",
            "Sign In": "サインイン",
            "Sign in": "サインイン",
            "Email address": "メールアドレス",
            Password: "パスワード",
            "Forgot password?": "パスワードをお忘れですか？",
            "All Rights Reserved": "全著作権所有",
            Currency: "通貨",
            "My Profile": "私のプロフィール",
            "Change Password": "パスワードを変更",
            Logout: "ログアウト",
            Status: "ステータス",
            Active: "アクティブ",
            Actions: "アクション",
            Profile: "プロフィール",
            Transactions: "取引",
            "Client Name": "クライアント名",
            "Invested Wealth ($)": "投資された富（$）",
            "Investible Wealth ($)": "投資可能な富（$）",
            "Revenue ($)": "収益（$）",
            Personal: "個人",
            Professional: "プロフェッショナル",
            Family: "家族",
            Interest: "興味",
            KYC: "KYC",
            Salutation: "敬称",
            "First Name": "名",
            "Last Name": "姓",
            "D.O.B": "生年月日",
            Gender: "性別",
            "Highest Education": "最高学歴",
            "Primary Email ID": "プライマリメールID",
            "Secondary Email ID": "セカンダリメールID",
            "Mobile Number": "携帯電話番号",
            "Not Editable": "編集不可",
            "Alternative Mobile Number": "代替携帯電話番号",
            "Base Location": "ベースロケーション",
            Origin: "オリジン",
            "Address 1": "住所1",
            "Address 2": "住所2",
            "Address 3": "住所3",
            Country: "国",
            State: "州",
            City: "市",
            "Postal Code": "郵便番号",
            "Net Worth $": "純資産$",
            "Source of Wealth": "富の源泉",
            "Investable wealth $": "投資可能な富$",
            "Risk appetite": "リスク許容度",
            "Industry preference": "業界の嗜好",
            "Financial goals": "財務目標",
            "Joining Fee $": "参加費$",
            "Annual Subscription Fee $": "年間サブスクリプション料$",
            "Current Investment Approach": "現在の投資アプローチ",
            "Take Survey": "調査を受ける",
            "Present Asset Allocations:": "現在の資産配分：",
            ALL: "すべて",
            EQUITIES: "株式",
            "FIXED INCOME": "固定収入",
            "CASH OR EQUIVALENT": "現金または同等",
            "STRUCTURED PRODUCTS": "構造化商品",
            "FUNDS AND ETFS": "ファンドとETF",
            COMMODITIES: "商品",
            "REAL ESTATE": "不動産",
            "ESG/IMPACT INVESTING": "ESG/インパクト投資",
            INSURANCE: "保険",
            CRYPTO: "暗号",
            "PRIVATE EQUITY": "プライベートエクイティ",
            "GROWTH EQUITY": "成長エクイティ",
            "VENTURE CAPITAL INVESTMENT": "ベンチャーキャピタル投資",
            "PRIVATE CREDIT": "プライベートクレジット",
            "PRE-IPO PRODUCTS": "IPO前製品",
            "DIRECT DEAL PRODUCTS": "直接取引製品",
            "OTHER ALTERNATIVES": "その他の代替",
            "Is my RM authorised to do transactions on my behalf?":
                "私のRMは私の代わりに取引を行う権限がありますか？",
            YES: "はい",
            NO: "いいえ",
            Yes: "はい",
            No: "いいえ",
            "Profession Type": "職種",
            Industry: "業界",
            "Domain / Sector": "ドメイン/セクター",
            Turnover: "売上高",
            "Is it a hereditary business?": "それは世襲のビジネスですか？",
            "Marital status": "配偶者の有無",
            "Number of family members": "家族の人数",
            "Family Members": "家族の人数",
            Personality: "性格",
            "Current Memberships": "現在のメンバーシップ",
            "Previous Vacations": "以前の休暇",
            "Proof of Identity": "身元証明",
            "Proof of Address": "住所の証明",
            "Proof of Tax Residency": "税務居住証明",
            "Proof of Wealth": "富の証明",
            "Other Documents": "その他の文書",
            "Document Type": "文書タイプ",
            "ID Number": "ID番号",
            "Expiry date": "有効期限",
            "Upload documents": "文書をアップロード",
            "Zip code": "郵便番号",
            Edit: "編集",
            Type: "タイプ",
            "Product / Service Category": "製品/サービスカテゴリ",
            "Date of Purchase": "購入日",
            Time: "時間",
            "Price($)": "価格（$）",
            Quantity: "数量",
            "Amount($)": "金額（$）",
            "Payment Ref": "支払い参照",
            Terminate: "終了",
            "Go To Order": "注文に移動",
            Open: "開く",
            Completed: "完了",
            "Purchase Initiated": "購入が開始されました",
            "Aging Days": "経過日数",
            Vendor: "ベンダー",
            "RM/Employee Id": "RM/従業員ID",
            "Product Name": "製品名",
            "Selling Price ($)": "販売価格（$）",
            "Investment Term (in Months)": "投資期間（月単位）",
            "End Date": "終了日",
            "Product Category": "製品カテゴリ",
            Region: "地域",
            Liquidity: "流動性",
            "Returns Level": "リターンレベル",
            "Risk Level": "リスクレベル",
            "Price ($)": "価格（$）",
            "Take Rate": "テイクレート",
            "Client's Invested": "クライアントの投資",
            Tags: "タグ",
            "Product Description": "製品説明",
            "Purchased Items": "購入したアイテム",
            "Current Quantity": "現在の数量",
            "Current price ($)": "現在の価格（$）",
            "Active Buy/Sell": "アクティブな購入/売却",
            "No Active buy/sell records found":
                "アクティブな購入/売却の記録が見つかりません",
            "Product / Transaction Status": "製品/取引ステータス",
            ACTIVE: "アクティブ",
            INACTIVE: "非アクティブ",
            WFA: "承認待ち",
            DELETED: "削除済み",
            NEW: "新規",
            REPLIED: "返信済み",
            REJECTED: "拒否済み",
            SYSTEM: "システム",
            CLIENT: "クライアント",
            "FLAT RATE": "一律料金",
            "MR.": "氏",
            "MRS.": "夫人",
            "MS.": "さん",
            "FINANCIAL PRODUCTS": "金融商品",
            "LIFESTYLE PRODUCTS": "ライフスタイル製品",
            "FINANCIAL SERVICES": "金融サービス",
            "LIFESTYLE SERVICES": "ライフスタイルサービス",
            STEADY: "安定",
            AGGRESSIVE: "攻撃的",
            MODERATE: "適度",
            CREDIT: "クレジット",
            DEBIT: "デビット",
            "LOW RISK": "低リスク",
            "MEDIUM RISK": "中リスク",
            "HIGH RISK": "高リスク",
            "N/A": "N/A",
            "HIGH SCHOOL": "高校",
            "UNDER GRADUATE": "学部",
            "POST GRADUATE": "大学院",
            "AEROSPACE AND DEFENSE": "航空宇宙と防衛",
            "AGRICULTURE AND AGRIBUSINESS": "農業と農業ビジネス",
            AUTOMOTIVE: "自動車",
            "BANKING AND FINANCE": "銀行と金融",
            CHEMICALS: "化学",
            "CONSTRUCTION AND INFRASTRUCTURE": "建設とインフラ",
            "CONSUMER GOODS AND SERVICES": "消費財とサービス",
            "EDUCATION AND TRAINING": "教育とトレーニング",
            "ENERGY AND UTILITIES": "エネルギーとユーティリティ",
            "ENTERTAINMENT AND MEDIA": "エンターテイメントとメディア",
            "HEALTHCARE AND PHARMACEUTICALS": "ヘルスケアと製薬",
            "HOSPITALITY AND TOURISM": "ホスピタリティと観光",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS": "情報技術と通信",
            MANUFACTURING: "製造",
            "MINING AND METALS": "鉱業と金属",
            "REAL ESTATE AND PROPERTY": "不動産とプロパティ",
            RETAIL: "小売",
            "TRANSPORTATION AND LOGISTICS": "輸送と物流",
            "BIOTECHNOLOGY AND LIFE SCIENCES":
                "バイオテクノロジーとライフサイエンス",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY":
                "クリーンテクノロジーと再生可能エネルギー",
            "CONSUMER ELECTRONICS": "家電",
            "ENVIRONMENTAL AND WASTE MANAGEMENT": "環境と廃棄物管理",
            "FOOD AND BEVERAGE": "食品と飲料",
            "GOVERNMENT AND PUBLIC SERVICES": "政府と公共サービス",
            "LEGAL SERVICES": "法律サービス",
            "NON-PROFIT AND SOCIAL ENTERPRISE": "非営利団体と社会企業",
            "PROFESSIONAL SERVICES": "専門サービス",
            "SECURITY AND CYBERSECURITY": "セキュリティとサイバーセキュリティ",
            "TEXTILES AND APPAREL": "繊維とアパレル",
            "RISK AVERSE": "リスク回避",
            CONSERVATIVE: "保守的",
            "WEALTH PRESERVATION": "富の保全",
            "CAPITAL APPRECIATION": "資本増加",
            "ESTATE PLANNING": "資産配分",
            "RISK MANAGEMENT": "リスク管理",
            "SUCCESSION PLANNING": "相続計画",
            "TAX PLANNING": "税金の計画",
            ENTREPRENEURS: "起業家",
            INVESTORS: "投資家",
            "CORPORATE EXECUTIVES": "企業幹部",
            "FINANCIAL PROFESSIONALS": "金融プロフェッショナル",
            "ENTERTAINMENT PROFESSIONALS":
                "エンターテイメントプロフェッショナル",
            "PROFESSIONAL ATHLETES": "プロアスリート",
            "REAL ESTATE PROFESSIONALS": "不動産プロフェッショナル",
            "TECH INDUSTRY PROFESSIONALS":
                "テクノロジー業界のプロフェッショナル",
            "LUXURY GOODS AND SERVICES": "高級品とサービス",
            "ART AND COLLECTIBLES": "アートとコレクション",
            PHILANTHROPY: "慈善",
            EDUCATION: "教育",
            "HEALTH AND WELLNESS": "健康とウェルネス",
            TECHNOLOGY: "テクノロジー",
            "SUSTAINABLE INVESTMENTS": "持続可能な投資",
            SPORTS: "スポーツ",
            "TRAVEL AND HOSPITALITY": "旅行とホスピタリティ",
            MARRIED: "既婚",
            UNMARRIED: "未婚",
            DIVORCED: "離婚",
            "WEALTH MANAGEMENT": "資産管理",
            OTHERS: "その他",
            MALE: "男性",
            FEMALE: "女性",
            "Payment Initiated": "支払いが開始されました",
            "Payment Reversed": "支払いが取り消されました",
            "Payment Received": "支払いが受け取られました",
            "Product Allocated": "製品が割り当てられました",
            "Sell Initiated": "売却が開始されました",
            "Product Sold": "製品が売却されました",
            "Service Provisioned": "サービスが提供されました",
            "Open - Purchase Initiated": "オープン - 購入が開始されました",
            "Open - Payment Initiated": "オープン - 支払いが開始されました",
            "Open - Payment Reversed": "オープン - 支払いが取り消されました",
            "Open - Payment Received": "オープン - 支払いが受け取られました",
            "Completed - Product Allocated": "完了 - 製品が割り当てられました",
            "Open - Sell Initiated": "オープン - 売却が開始されました",
            "Completed - Product Sold": "完了 - 製品が売却されました",
            "Completed - Service Provisioned":
                "完了 - サービスが提供されました",
            Bank: "銀行",
            Asset: "資産",
            Liability: "負債",
            SPOUSE: "配偶者",
            CHILD: "子供",
            PARENT: "親",
            SIBLING: "兄弟",
            OTHER: "その他",
            "BUYING A HOUSE": "家を買う",
            TRAVEL: "旅行",
            RETIREMENT: "退職",
            "LEGACY PLANNING": "遺産計画",
            NOVICE: "初心者",
            INTERMEDIATE: "中級",
            EXPERT: "専門家",
            "SHORT TERM": "短期",
            "MEDIUM TERM": "中期",
            "LONG TERM": "長期",
            "INVESTMENT STRATEGIES": "投資戦略",
            "TRUST SERVICES": "信託サービス",
            "SPORTS AND FITNESS ACTIVITIES": "スポーツとフィットネス活動",
            "ARTS AND CULTURE": "芸術と文化",
            "TRAVEL AND ADVENTURE": "旅行と冒険",
            "MUSIC AND ENTERTAINMENT": "音楽とエンターテイメント",
            "FOOD AND DINING": "食べ物とダイニング",
            "FASHION AND STYLE:": "ファッションとスタイル：",
            "TECHNOLOGY AND GADGETS": "テクノロジーとガジェット",
            SALARY: "給与",
            INVESTMENTS: "投資",
            "BUSINESS OWNERSHIP": "ビジネス所有",
            CASH: "現金",
            SAVINGS: "貯金",
            "MONEY MARKET ACCOUNTS": "マネーマーケット口座",
            STOCKS: "株",
            BONDS: "債券",
            "MUTUAL FUNDS": "投資信託",
            "PENSION PLANS": "年金計画",
            ANNUITIES: "年金",
            ART: "アート",
            COLLECTIBLES: "コレクタブル",
            PROPERTIES: "プロパティ",
            "PERSONAL LOANS": "個人ローン",
            "STUDENT LOANS": "学生ローン",
            HOUSING: "住宅",
            UTILITIES: "ユーティリティ",
            TRANSPORTATION: "輸送",
            "PROPERTY INSURANCE": "不動産保険",
            "LIABILITY INSURANCE": "責任保険",
            "LEGAL ADVISORY": "法的アドバイザリー",
            "COMPLIANCE SERVICES": "コンプライアンスサービス",
            "ACCOUNTING SERVICES": "会計サービス",
            "LEVERAGE MANAGEMENT": "レバレッジ管理",
            "REQUEST EXPERT ADVISORY MEETING":
                "専門アドバイザリーミーティングをリクエスト",
            PASSPORT: "パスポート",
            "IDENTITY CARD": "身分証明書",
            "DRIVING LICENSE": "運転免許証",
            "BANK STATEMENT": "銀行明細",
            "UTILITY BILL": "公共料金請求書",
            "PHONE BILL": "電話料金",
            "TAX BILL": "税金",
            "RENTAL AGREEMENT": "賃貸契約",
            "NORMAL DELIVERY": "通常配送",
            INBOND: "INBOND",
            "SPECIAL DELIVERY": "特別配送",
            Latest: "最新",
            Ascending: "昇順",
            Descending: "降順",
            "Enable Access": "アクセスを有効にする",
            "Disable Access": "アクセスを無効にする",
            MFO: "MFO",
            SFO: "SFO"
        }
    },
    ar: {
        translation: {
            SUBQUESTION: "السؤال الفرعي",
            Answer: "الجواب",
            "Tool Used": "الأداة المستخدمة",
            Show: "عرض",
            Hide: "إخفاء",
            "detailed analysis": "تحليل مفصل",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "استعلام حول 5500+ سهم أحدث 10-K و 10-Q ، احصل على الإجابة في ثوانٍ",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "قم بتحميل بياناتك الخاصة ، ونظمها في مجلد واطرح أي أسئلة حولها.",
            "Collection of tools helping you better manage and understand your portfolio.":
                "مجموعة من الأدوات التي تساعدك على إدارة وفهم محفظتك بشكل أفضل.",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "يتيح لك وكيل Gaia إجراء أبحاث سوقية عميقة على أكثر من 80.000 أمن عام (أسهم ، سندات ، سلع ، عملات مشفرة ...)",
            "Gaia Terminal": "محطة Gaia",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "اسأل أي سؤال حول 80.000+ أداة مالية واسمح للوكيل بالقيام بالعمل.",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "تأكد من إدخال الرمز الدقيق ، خاصة بالنسبة للبورصات غير الأمريكية (على سبيل المثال: RELIANCE.NS)",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "اكتشف المجالات الرئيسية لتوليد الإيرادات والأرباح لشركة Alphabet",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "استكشف كيف يتم توزيع استثمارات الخزينة لشركة Alphabet بين فئات الأصول المختلفة",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "تحقق من تحليل تقارير الأرباح الأخيرة لشركة Amazon ، بما في ذلك أي مفاجآت أو اتجاهات بارزة",
            "Find out the total assets and total liabilities of Apple":
                "اكتشف الأصول الإجمالية والمسؤوليات الإجمالية لشركة Apple",
            "Check out the different tax obligation/provision on Apple":
                "تحقق من الالتزام الضريبي / الأحكام المختلفة على Apple",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "استخدم الروبوت لتحليل نفقات البحث والتطوير كنسبة من الإيرادات لشركة Meta وقيم أيضًا التزام Meta بالابتكار",
            "Figure out the revenue by geography for Meta":
                "اكتشف الإيرادات حسب الجغرافيا لشركة Meta",
            "Understand the major areas of capital expenditure for Microsoft":
                "فهم المجالات الرئيسية للنفقات الرأسمالية لشركة Microsoft",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "اكتشف تأثير التغييرات التنظيمية الأخيرة على عمليات الأعمال لشركة Amazon Communications وآفاقها المالية",
            "Invest in a timeless designer handbag":
                "استثمر في حقيبة يد مصممة خالدة",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "استثمر في ساعة فاخرة من العلامات التجارية الرصينة مثل رولكس ، فيليب أو أوديمار بيجيه",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "ابق أنيقًا ودافئًا مع معطف أو جاكيت فاخر من دور الأزياء الشهيرة",
            "What should i consider when selecting a luxury service vendor?":
                "ما الذي يجب علي مراعاته عند اختيار مورد خدمة فاخر؟",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "هل يمكن لموردي الخدمات الفاخرة تخصيص عروضهم لتلبية الاحتياجات أو التفضيلات الخاصة؟",
            "What type of certifications or credentials should luxury service vendors have?":
                "ما نوع الشهادات أو الأوراق الثبوتية التي يجب أن يمتلكها موردي الخدمات الفاخرة؟",
            "Evolution and Trends in Luxury Services":
                "تطور واتجاهات الخدمات الفاخرة",
            "Luxury Travel Experiences and Destination Management":
                "تجارب السفر الفاخرة وإدارة الوجهات",
            "The Influence of social media on luxury services":
                "تأثير وسائل التواصل الاجتماعي على الخدمات الفاخرة",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "تحقق من نظرائها TSLA. من بينها ، تحقق أيها لديها أعلى سقف سوقي. ثم ، على الرمز الذي لديه أعلى سقف سوقي ، احصل على تقدير السعر المستهدف الأحدث من محلل ، وأخبرني من كان وفي أي تاريخ تم إجراء التقدير. أخبرني ما إذا كان المحلل متفائلًا أو متشائمًا بشأن هذا السهم.",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "قم بإجراء تحليل مالي أساسي لـ AMZN باستخدام البيانات المتاحة حديثًا. ما الذي تجده مثيرًا للاهتمام؟",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "ما هي العوامل الرئيسية وراء الارتفاع الأخير في سعر الذهب ، ومدى استدامة هذه الاتجاهات على المدى الطويل؟",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "ما هو الأثر المحتمل لزيادة نقطة أساس 25 في أسعار الفائدة للمحفظة الثابتة لدينا؟",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "بناءً على تكوين محفظتنا الحالي (<يجب توفير تكوين المحفظة هنا>), ما هو الأثر المحتمل لزيادة كبيرة في أسعار الفائدة على أصولنا الثابتة؟",
            "Research Assistant": "مساعد البحث",
            "Fundamentals Analysis": "تحليل الأساسيات",
            "Market Research": "بحوث السوق",
            "Financial Analysis": "تحليل مالي",
            "Portfolio Risk Management": "إدارة مخاطر المحفظة",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "قم بتحميل أبحاث السوق والتقارير الكبيرة ، واحصل على إجابة على أسئلتك دون الحاجة إلى قراءة هذه الوثائق",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "قم بتحميل أوراق الشروط والبروشورات للمنتجات المالية المعقدة ، ستجد Gaia كل ما تحتاجه منها مثل جدول التسوية ، سعر الإضراب ، الضرب ...",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "قم بتحميل مستند كبير ، حدده في عرض المجلد ، ويصبح زر الملخص نشطًا.",
            "Deep search through large documents":
                "بحث عميق من خلال مستندات كبيرة",
            "Analyze complex financial documents":
                "تحليل الوثائق المالية المعقدة",
            "Summarize large documents": "تلخيص مستندات كبيرة",
            Suggestions: "اقتراحات",
            "Internal Data": "البيانات الداخلية",
            "Upload your own files, order them across folders, and ask any question.":
                "قم بتحميل ملفاتك الخاصة ، ورتبها عبر المجلدات ، واسأل أي سؤال.",
            "Here are a few ideas of what you can do with Gaia:":
                "إليك بعض الأفكار حول ما يمكنك القيام به مع Gaia:",
            Ideas: "أفكار",
            Topics: "مواضيع",
            FAQ: "الأسئلة الشائعة",
            Intraday: "داخل اليوم",
            Daily: "يوميا",
            Weekly: "أسبوعيا",
            Monthly: "شهريا",
            "LATEST QUOTE": "أحدث اقتباس",
            "Open:": "فتح:",
            "High:": "عالي:",
            "Low:": "منخفض:",
            "Volume:": "حجم:",
            "Latest trading day:": "آخر يوم تداول:",
            "Previous close:": "الإغلاق السابق:",
            "MARKET NEWS": "أخبار السوق",
            Date: "تاريخ",
            Title: "عنوان",
            Sentiment: "المشاعر",
            Source: "مصدر",
            "Share Information": "مشاركة المعلومات",
            Action: "عمل",
            Admins: "المشرفون",
            Share: "شارك",
            "Client Portfolio": "محفظة العميل",
            "Client's": "العميل",
            Clients: "العملاء",
            "View Portfolio": "عرض المحفظة",
            "Gaia Agent History": "تاريخ وكيل Gaia",
            "Click refresh button to update status of agents in progress":
                "انقر فوق زر التحديث لتحديث حالة الوكلاء في التقدم",
            "Documents in folder": "المستندات في المجلد",
            File: "ملف",
            Folder: "مجلد",
            "Pick Internal Data folder": "اختر مجلد البيانات الداخلية",
            "Summarize document": "ملخص المستند",
            "Folder created successfully": "تم إنشاء المجلد بنجاح",
            "Folder ": "المجلد ",
            " successfully deleted": " تم حذف بنجاح",
            "File uploaded successfully": "تم تحميل الملف بنجاح",
            "Document deleted successfully": "تم حذف المستند بنجاح",
            "Create Internal Data Folder": "إنشاء مجلد بيانات داخلي",
            Save: "حفظ",
            Cancel: "إلغاء",
            "Are you sure you want to delete the ":
                "هل أنت متأكد أنك تريد حذف ",
            " folder?": " المجلد؟",
            Delete: "حذف",
            "Upload New Document in Folder": "تحميل مستند جديد في المجلد",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "الملفات المدعومة: .doc ، .docx ، .txt ، .rtf ، .htm ، .html ، .pdf",
            "Notes regarding pdfs:": "ملاحظات حول ملفات PDF:",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "سيتم تحميل PDF التي هي PDF الصور أو تستخدم الخطوط المخصصة باستخدام تقنيات التعرف على الحروف. يحد من قدرتنا على تحليل الجداول في مثل هذه الوثائق.",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "لذلك نشجعك على تقديم هذه الوثائق في تنسيقات بديلة للحصول على دقة أفضل.",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "يمكن أن يستغرق PDF الكبير حتى 5 دقائق للمعالجة ، ويُنصح عدم إغلاق هذه النافذة.",
            "Are you sure you want to delete this document?":
                "هل أنت متأكد أنك تريد حذف هذا المستند؟",
            "Select a Client's portfolio": "حدد محفظة العميل",
            "Please select a client": "يرجى تحديد عميل",
            "Get Portfolio Recommendations": "الحصول على توصيات المحفظة",
            "Robo-Advisor (Asset Classes)": "مستشار روبو (فئات الأصول)",
            "Pick a stock:": "اختر سهم:",
            Ticker: "الرمز",
            Compare: "مقارنة",
            Client: "العميل",
            DCA: "DCA",
            Financial: "مالية",
            Lifestyle: "نمط حياة",
            Stocks: "أسهم",
            Portfolio: "محفظة",
            Agent: "وكيل",
            Bookmarks: "إشارات مرجعية",
            History: "تاريخ",
            Notifications: "الإشعارات",
            "Please select a Stock first": "يرجى تحديد سهم أولا",
            "Please select a folder first": "يرجى تحديد مجلد أولا",
            "Please select a private equity first":
                "يرجى تحديد الأسهم الخاصة أولا",
            "Please enter a question": "الرجاء إدخال سؤال",
            "Share to Admin": "مشاركة مع المسؤول",
            "Share to client": "مشاركة مع العميل",
            "Useful response": "استجابة مفيدة",
            "Bad response": "استجابة سيئة",
            "Get Stock Information": "الحصول على معلومات الأسهم",
            "Generate Graph": "إنشاء رسم بياني",
            "Financial Data based research": "بحث يعتمد على البيانات المالية",
            "Web based research": "بحث على الويب",
            "Send a message.": "إرسال رسالة.",
            "Name/Emp ID": "الاسم / رقم الموظف",
            Idea: "فكرة",
            "Folder Name": "اسم المجلد",
            Dashboard: "لوحة القيادة",
            "Ask Gaia": "اسأل Gaia",
            Product: "المنتج",
            Service: "الخدمة",
            "My Prospects": "آفاقي",
            "My Clients": "عملائي",
            Subscriptions: "الاشتراكات",
            Payments: "المدفوعات",
            FAQs: "الأسئلة الشائعة",
            "User Management": "إدارة المستخدم",
            "Vendor Management": "إدارة البائع",
            "Financial Products": "المنتجات المالية",
            "Lifestyle Products": "منتجات النمط الحياتي",
            "Financial Services": "الخدمات المالية",
            "Lifestyle Services": "خدمات النمط الحياتي",
            Campaign: "حملة",
            Reports: "تقارير",
            "RM Performance": "أداء RM",
            "Customer Feedback": "تعليقات العملاء",
            "Order Management": "إدارة الطلبات",
            "Ref.Data Management": "إدارة بيانات المرجع",
            "Chat with RM": "الدردشة مع RM",
            Chat: "دردشة",
            Bordeaux: "بوردو",
            "Bill of Materials": "فاتورة المواد",
            "Configure Freight Charges": "تكوين رسوم الشحن",
            "No. of Clients": "عدد العملاء",
            "No. of RM's": "عدد RM",
            "Total Partners/Vendors": "إجمالي الشركاء / البائعين",
            "Total AUM": "إجمالي AUM",
            "Invested Wealth": "ثروة مستثمرة",
            "Aggregate Asset Allocation (%)": "تخصيص الأصول الإجمالي (%)",
            "Aggregate Geographical Allocation (%)": "تخصيص جغرافي إجمالي (%)",
            "Aggregate Sector Allocation (%)": "تخصيص القطاع الإجمالي (%)",
            AUM: "AUM",
            Revenue: "إيرادات",
            Task: "مهمة",
            "Market News": "أخبار السوق",
            "Gaia Help": "مساعدة Gaia",
            All: "الكل",
            Equities: "الأسهم",
            "Fixed Income": "الدخل الثابت",
            "Cash Or Equivalent": "نقد أو ما يعادله",
            "Structured Products": "المنتجات المهيكلة",
            "Funds and Etfs": "الصناديق والصناديق المتداولة",
            Commodities: "السلع",
            "Real Estate": "العقارات",
            "Esg/Impact Investing": "الاستثمار في ESG / التأثير",
            Insurance: "التأمين",
            Crypto: "عملة معماة",
            "Private Equity": "الأسهم الخاصة",
            "Growth Equity": "الأسهم النمو",
            "Venture Capital Investment": "استثمار رأس المال الاستثماري",
            "Private Credit": "الائتمان الخاص",
            "Pre-Ipo Products": "منتجات قبل الإدراج",
            "Direct Deal Products": "منتجات الصفقات المباشرة",
            "Other Alternatives": "بدائل أخرى",
            Search: "بحث",
            Filters: "مرشحات",
            Travel: "السفر",
            Wellness: "العافية",
            Food: "طعام",
            Health: "صحة",
            Sports: "رياضة",
            Art: "فن",
            Wine: "نبيذ",
            "Live Trade": "تجارة حية",
            "Sign In": "تسجيل الدخول",
            "Sign in": "تسجيل الدخول",
            "Email address": "عنوان البريد الإلكتروني",
            Password: "كلمه السر",
            "Forgot password?": "هل نسيت كلمة المرور؟",
            "All Rights Reserved": "كل الحقوق محفوظة",
            Currency: "عملة",
            "My Profile": "ملفي",
            "Change Password": "تغيير كلمة المرور",
            Logout: "تسجيل الخروج",
            Status: "الحالة",
            Active: "نشيط",
            Actions: "أفعال",
            Profile: "الملف الشخصي",
            Transactions: "المعاملات",
            "Client Name": "اسم العميل",
            "Invested Wealth ($)": "الثروة المستثمرة ($)",
            "Investible Wealth ($)": "الثروة المستثمرة ($)",
            "Revenue ($)": "الإيرادات ($)",
            Personal: "شخصي",
            Professional: "محترف",
            Family: "عائلة",
            Interest: "اهتمام",
            KYC: "KYC",
            Salutation: "تحية",
            "First Name": "الاسم الأول",
            "Last Name": "الكنية",
            "D.O.B": "تاريخ الميلاد",
            Gender: "جنس",
            "Highest Education": "أعلى تعليم",
            "Primary Email ID": "البريد الإلكتروني الأساسي",
            "Secondary Email ID": "البريد الإلكتروني الثانوي",
            "Mobile Number": "رقم الهاتف المحمول",
            "Not Editable": "غير قابل للتحرير",
            "Alternative Mobile Number": "رقم الهاتف المحمول البديل",
            "Base Location": "الموقع الأساسي",
            Origin: "أصل",
            "Address 1": "العنوان 1",
            "Address 2": "العنوان 2",
            "Address 3": "العنوان 3",
            Country: "بلد",
            State: "حالة",
            City: "مدينة",
            "Postal Code": "الرمز البريدي",
            "Net Worth $": "صافي القيمة $",
            "Source of Wealth": "مصدر الثروة",
            "Investable wealth $": "الثروة المستثمرة $",
            "Risk appetite": "رغبة المخاطرة",
            "Industry preference": "تفضيل الصناعة",
            "Financial goals": "الأهداف المالية",
            "Joining Fee $": "رسوم الانضمام $",
            "Annual Subscription Fee $": "رسوم الاشتراك السنوي $",
            "Current Investment Approach": "النهج الاستثماري الحالي",
            "Take Survey": "خذ استطلاع",
            "Present Asset Allocations:": "تخصيصات الأصول الحالية:",
            ALL: "الكل",
            EQUITIES: "الأسهم",
            "FIXED INCOME": "الدخل الثابت",
            "CASH OR EQUIVALENT": "نقد أو ما يعادله",
            "STRUCTURED PRODUCTS": "المنتجات المهيكلة",
            "FUNDS AND ETFS": "الصناديق والصناديق المتداولة",
            COMMODITIES: "السلع",
            "REAL ESTATE": "العقارات",
            "ESG/IMPACT INVESTING": "ESG / الاستثمار في التأثير",
            INSURANCE: "التأمين",
            CRYPTO: "عملة معماة",
            "PRIVATE EQUITY": "الأسهم الخاصة",
            "GROWTH EQUITY": "الأسهم النمو",
            "VENTURE CAPITAL INVESTMENT": "استثمار رأس المال الاستثماري",
            "PRIVATE CREDIT": "الائتمان الخاص",
            "PRE-IPO PRODUCTS": "منتجات قبل الإدراج",
            "DIRECT DEAL PRODUCTS": "منتجات الصفقات المباشرة",
            "OTHER ALTERNATIVES": "بدائل أخرى",
            "Is my RM authorised to do transactions on my behalf?":
                "هل يحق لـ RM الخاص بي إجراء معاملات نيابة عني؟",
            YES: "نعم",
            NO: "لا",
            Yes: "نعم",
            No: "لا",
            "Profession Type": "نوع المهنة",
            Industry: "صناعة",
            "Domain / Sector": "نطاق / قطاع",
            Turnover: "دوران",
            "Is it a hereditary business?": "هل هو عمل موروث؟",
            "Marital status": "الحالة الزوجية",
            "Number of family members": "عدد أفراد الأسرة",
            "Family Members": "أفراد الأسرة",
            Personality: "شخصية",
            "Current Memberships": "العضويات الحالية",
            "Previous Vacations": "العطلات السابقة",
            "Proof of Identity": "إثبات الهوية",
            "Proof of Address": "إثبات العنوان",
            "Proof of Tax Residency": "إثبات الإقامة الضريبية",
            "Proof of Wealth": "إثبات الثروة",
            "Other Documents": "مستندات أخرى",
            "Document Type": "نوع المستند",
            "ID Number": "رقم الهوية",
            "Expiry date": "تاريخ الانتهاء",
            "Upload documents": "تحميل المستندات",
            "Zip code": "الرمز البريدي",
            Edit: "تصحيح",
            Type: "نوع",
            "Product / Service Category": "فئة المنتج / الخدمة",
            "Date of Purchase": "تاريخ الشراء",
            Time: "زمن",
            "Price($)": "السعر($)",
            Quantity: "كمية",
            "Amount($)": "المبلغ($)",
            "Payment Ref": "مرجع الدفع",
            Terminate: "إنهاء",
            "Go To Order": "الذهاب إلى الطلب",
            Open: "فتح",
            Completed: "منجز",
            "Purchase Initiated": "تم بدء الشراء",
            "Aging Days": "أيام الشيخوخة",
            Vendor: "البائع",
            "RM/Employee Id": "RM / معرف الموظف",
            "Product Name": "اسم المنتج",
            "Selling Price ($)": "سعر البيع ($)",
            "Investment Term (in Months)": "مدة الاستثمار (بالشهور)",
            "End Date": "تاريخ الانتهاء",
            "Product Category": "فئة المنتج",
            Region: "المنطقة",
            Liquidity: "السيولة",
            "Returns Level": "مستوى العائدات",
            "Risk Level": "مستوى المخاطر",
            "Price ($)": "السعر ($)",
            "Take Rate": "معدل الاستيلاء",
            "Client's Invested": "استثمر العميل",
            Tags: "العلامات",
            "Product Description": "وصف المنتج",
            "Purchased Items": "العناصر المشتراة",
            "Current Quantity": "الكمية الحالية",
            "Current price ($)": "السعر الحالي ($)",
            "Active Buy/Sell": "شراء / بيع نشط",
            "No Active buy/sell records found":
                "لم يتم العثور على سجلات شراء / بيع نشطة",
            "Product / Transaction Status": "حالة المنتج / المعاملة",
            ACTIVE: "نشط",
            INACTIVE: "غير نشط",
            WFA: "WFA",
            DELETED: "تم الحذف",
            NEW: "جديد",
            REPLIED: "رد",
            REJECTED: "مرفوض",
            SYSTEM: "النظام",
            CLIENT: "العميل",
            "FLAT RATE": "سعر ثابت",
            "MR.": "السيد",
            "MRS.": "السيدة",
            "MS.": "الآنسة",
            "FINANCIAL PRODUCTS": "المنتجات المالية",
            "LIFESTYLE PRODUCTS": "منتجات النمط الحياتي",
            "FINANCIAL SERVICES": "الخدمات المالية",
            "LIFESTYLE SERVICES": "خدمات النمط الحياتي",
            STEADY: "ثابت",
            AGGRESSIVE: "عدواني",
            MODERATE: "معتدل",
            CREDIT: "ائتمان",
            DEBIT: "مدين",
            "LOW RISK": "مخاطرة منخفضة",
            "MEDIUM RISK": "مخاطرة متوسطة",
            "HIGH RISK": "مخاطرة عالية",
            "N/A": "غير متاح",
            "HIGH SCHOOL": "المدرسة الثانوية",
            "UNDER GRADUATE": "الدراسات الجامعية",
            "POST GRADUATE": "الدراسات العليا",
            "AEROSPACE AND DEFENSE": "الطيران والدفاع",
            "AGRICULTURE AND AGRIBUSINESS": "الزراعة والأعمال الزراعية",
            AUTOMOTIVE: "السيارات",
            "BANKING AND FINANCE": "البنوك والتمويل",
            CHEMICALS: "المواد الكيميائية",
            "CONSTRUCTION AND INFRASTRUCTURE": "البناء والبنية التحتية",
            "CONSUMER GOODS AND SERVICES": "السلع والخدمات الاستهلاكية",
            "EDUCATION AND TRAINING": "التعليم والتدريب",
            "ENERGY AND UTILITIES": "الطاقة والمرافق",
            "ENTERTAINMENT AND MEDIA": "الترفيه والإعلام",
            "HEALTHCARE AND PHARMACEUTICALS": "الرعاية الصحية والصيدلة",
            "HOSPITALITY AND TOURISM": "الضيافة والسياحة",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS":
                "تكنولوجيا المعلومات والاتصالات",
            MANUFACTURING: "تصنيع",
            "MINING AND METALS": "التعدين والمعادن",
            "REAL ESTATE AND PROPERTY": "العقارات والممتلكات",
            RETAIL: "التجزئة",
            "TRANSPORTATION AND LOGISTICS": "النقل والخدمات اللوجستية",
            "BIOTECHNOLOGY AND LIFE SCIENCES":
                "التكنولوجيا الحيوية والعلوم الحيوية",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY":
                "تكنولوجيا النظافة والطاقة المتجددة",
            "CONSUMER ELECTRONICS": "الإلكترونيات الاستهلاكية",
            "ENVIRONMENTAL AND WASTE MANAGEMENT": "البيئة وإدارة النفايات",
            "FOOD AND BEVERAGE": "الطعام والمشروبات",
            "GOVERNMENT AND PUBLIC SERVICES": "الحكومة والخدمات العامة",
            "LEGAL SERVICES": "الخدمات القانونية",
            "NON-PROFIT AND SOCIAL ENTERPRISE":
                "غير الربحية والمؤسسة الاجتماعية",
            "PROFESSIONAL SERVICES": "الخدمات المهنية",
            "SECURITY AND CYBERSECURITY": "الأمن والأمان السيبراني",
            "TEXTILES AND APPAREL": "المنسوجات والملابس",
            "RISK AVERSE": "خائف من المخاطر",
            CONSERVATIVE: "محافظ",
            "WEALTH PRESERVATION": "حفظ الثروة",
            "CAPITAL APPRECIATION": "تقدير الرأسمال",
            "ESTATE PLANNING": "تخطيط العقارات",
            "RISK MANAGEMENT": "إدارة المخاطر",
            "SUCCESSION PLANNING": "تخطيط الخلافة",
            "TAX PLANNING": "تخطيط الضرائب",
            ENTREPRENEURS: "رجال الأعمال",
            INVESTORS: "المستثمرون",
            "CORPORATE EXECUTIVES": "التنفيذيون الشركيون",
            "FINANCIAL PROFESSIONALS": "المحترفون الماليون",
            "ENTERTAINMENT PROFESSIONALS": "المحترفون في مجال الترفيه",
            "PROFESSIONAL ATHLETES": "الرياضيون المحترفون",
            "REAL ESTATE PROFESSIONALS": "المحترفون في مجال العقارات",
            "TECH INDUSTRY PROFESSIONALS": "المحترفون في صناعة التكنولوجيا",
            "LUXURY GOODS AND SERVICES": "السلع الفاخرة والخدمات",
            "ART AND COLLECTIBLES": "الفن والتحف",
            PHILANTHROPY: "الأعمال الخيرية",
            EDUCATION: "التعليم",
            "HEALTH AND WELLNESS": "الصحة والعافية",
            TECHNOLOGY: "التكنولوجيا",
            "SUSTAINABLE INVESTMENTS": "الاستثمارات المستدامة",
            SPORTS: "الرياضة",
            "TRAVEL AND HOSPITALITY": "السفر والضيافة",
            MARRIED: "متزوج",
            UNMARRIED: "غير متزوج",
            DIVORCED: "مطلق",
            "WEALTH MANAGEMENT": "إدارة الثروة",
            OTHERS: "آخرون",
            MALE: "ذكر",
            FEMALE: "أنثى",
            "Payment Initiated": "تم بدء الدفع",
            "Payment Reversed": "تم عكس الدفع",
            "Payment Received": "تم استلام الدفع",
            "Product Allocated": "تم تخصيص المنتج",
            "Sell Initiated": "تم بدء البيع",
            "Product Sold": "تم بيع المنتج",
            "Service Provisioned": "تم توفير الخدمة",
            "Open - Purchase Initiated": "مفتوح - تم بدء الشراء",
            "Open - Payment Initiated": "مفتوح - تم بدء الدفع",
            "Open - Payment Reversed": "مفتوح - تم عكس الدفع",
            "Open - Payment Received": "مفتوح - تم استلام الدفع",
            "Completed - Product Allocated": "منجز - تم تخصيص المنتج",
            "Open - Sell Initiated": "مفتوح - تم بدء البيع",
            "Completed - Product Sold": "منجز - تم بيع المنتج",
            "Completed - Service Provisioned": "منجز - تم توفير الخدمة",
            Bank: "البنك",
            Asset: "الأصول",
            Liability: "المسؤولية",
            SPOUSE: "الزوجة",
            CHILD: "الطفل",
            PARENT: "الوالد",
            SIBLING: "الأخ",
            OTHER: "آخر",
            "BUYING A HOUSE": "شراء منزل",
            TRAVEL: "السفر",
            RETIREMENT: "التقاعد",
            "LEGACY PLANNING": "تخطيط الإرث",
            NOVICE: "مبتدئ",
            INTERMEDIATE: "متوسط",
            EXPERT: "خبير",
            "SHORT TERM": "المدى القصير",
            "MEDIUM TERM": "المدى المتوسط",
            "LONG TERM": "المدى الطويل",
            "INVESTMENT STRATEGIES": "استراتيجيات الاستثمار",
            "TRUST SERVICES": "خدمات الثقة",
            "SPORTS AND FITNESS ACTIVITIES": "أنشطة الرياضة واللياقة البدنية",
            "ARTS AND CULTURE": "الفنون والثقافة",
            "TRAVEL AND ADVENTURE": "السفر والمغامرة",
            "MUSIC AND ENTERTAINMENT": "الموسيقى والترفيه",
            "FOOD AND DINING": "الطعام والطعام",
            "FASHION AND STYLE:": "الأزياء والأناقة:",
            "TECHNOLOGY AND GADGETS": "التكنولوجيا والأجهزة",
            SALARY: "الراتب",
            INVESTMENTS: "الاستثمارات",
            "BUSINESS OWNERSHIP": "ملكية الأعمال",
            CASH: "نقد",
            SAVINGS: "المدخرات",
            "MONEY MARKET ACCOUNTS": "حسابات السوق النقدي",
            STOCKS: "الأسهم",
            BONDS: "السندات",
            "MUTUAL FUNDS": "صناديق الاستثمار المشترك",
            "PENSION PLANS": "خطط التقاعد",
            ANNUITIES: "التقاعد",
            ART: "الفن",
            COLLECTIBLES: "التحف",
            PROPERTIES: "الخصائص",
            "PERSONAL LOANS": "القروض الشخصية",
            "STUDENT LOANS": "القروض الطلابية",
            HOUSING: "الإسكان",
            UTILITIES: "المرافق",
            TRANSPORTATION: "النقل",
            "PROPERTY INSURANCE": "تأمين الممتلكات",
            "LIABILITY INSURANCE": "تأمين المسؤولية",
            "LEGAL ADVISORY": "استشارات قانونية",
            "COMPLIANCE SERVICES": "خدمات الامتثال",
            "ACCOUNTING SERVICES": "خدمات المحاسبة",
            "LEVERAGE MANAGEMENT": "إدارة الرافعة المالية",
            "REQUEST EXPERT ADVISORY MEETING": "طلب اجتماع استشاري متخصص",
            PASSPORT: "جواز سفر",
            "IDENTITY CARD": "بطاقة الهوية",
            "DRIVING LICENSE": "رخصة القيادة",
            "BANK STATEMENT": "كشف حساب بنكي",
            "UTILITY BILL": "فاتورة المرافق",
            "PHONE BILL": "فاتورة الهاتف",
            "TAX BILL": "فاتورة الضرائب",
            "RENTAL AGREEMENT": "عقد الإيجار",
            "NORMAL DELIVERY": "التسليم العادي",
            INBOND: "INBOND",
            "SPECIAL DELIVERY": "التسليم الخاص",
            Latest: "الأحدث",
            Ascending: "تصاعدي",
            Descending: "تنازلي",
            "Enable Access": "تمكين الوصول",
            "Disable Access": "تعطيل الوصول",
            MFO: "MFO",
            SFO: "SFO"
        }
    },
    id: {
        translation: {
            SUBQUESTION: "Subpertanyaan",
            Answer: "Jawaban",
            "Tool Used": "Alat yang",
            Show: "Tampilkan",
            Hide: "Sembunyikan",
            "detailed analysis": "analisis rinci",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "Query 5500+ saham terbaru 10-K dan 10-Q, dapatkan jawaban dalam hitungan detik",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "Unggah data pribadi Anda, atur ke dalam folder dan ajukan pertanyaan tentang mereka.",
            "Collection of tools helping you better manage and understand your portfolio.":
                "Kumpulan alat yang membantu Anda mengelola dan memahami portofolio Anda dengan lebih baik.",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "Agen Gaia memungkinkan Anda melakukan riset pasar mendalam lebih dari 80.000 sekuritas publik (Ekuitas, Obligasi, Komoditas, Crypto...)",
            "Gaia Terminal": "Terminal Gaia",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "Ajukan pertanyaan tentang 80.000+ instrumen keuangan dan biarkan agen yang melakukan pekerjaan.",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "Pastikan untuk memasukkan ticker yang tepat, terutama untuk bursa non-AS (misalnya: RELIANCE.NS)",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "Temukan area kunci dari pendapatan & generasi laba untuk Alphabet",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "Jelajahi bagaimana investasi kas Alphabet didistribusikan di antara berbagai kelas aset",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "Periksa analisis laporan pendapatan terbaru untuk Amazon, termasuk kejutan atau tren yang mencolok",
            "Find out the total assets and total liabilities of Apple":
                "Temukan total aset dan total kewajiban Apple",
            "Check out the different tax obligation/provision on Apple":
                "Periksa kewajiban/persyaratan pajak yang berbeda pada Apple",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "Gunakan bot untuk menganalisis biaya riset dan pengembangan sebagai persentase dari pendapatan untuk Meta dan juga menilai komitmen Meta terhadap inovasi",
            "Figure out the revenue by geography for Meta":
                "Temukan pendapatan berdasarkan geografi untuk Meta",
            "Understand the major areas of capital expenditure for Microsoft":
                "Pahami area utama pengeluaran modal untuk Microsoft",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "Temukan dampak perubahan regulasi terbaru pada operasi bisnis Amazon Communications dan prospek keuangannya",
            "Invest in a timeless designer handbag":
                "Investasikan dalam tas tangan desainer yang abadi",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "Investasikan dalam jam tangan mewah dari merek-merek bergengsi seperti rolex, philippe atau audemars piguet",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "Tetap bergaya dan hangat dengan mantel atau jaket mewah dari rumah mode terkenal",
            "What should i consider when selecting a luxury service vendor?":
                "Apa yang harus saya pertimbangkan saat memilih vendor layanan mewah?",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "Apakah vendor layanan mewah dapat menyesuaikan penawaran mereka untuk memenuhi kebutuhan atau preferensi tertentu?",
            "What type of certifications or credentials should luxury service vendors have?":
                "Jenis sertifikasi atau kredensial apa yang harus dimiliki vendor layanan mewah?",
            "Evolution and Trends in Luxury Services":
                "Evolusi dan Tren dalam Layanan Mewah",
            "Luxury Travel Experiences and Destination Management":
                "Pengalaman Perjalanan Mewah dan Manajemen Tujuan",
            "The Influence of social media on luxury services":
                "Pengaruh media sosial pada layanan mewah",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "Periksa apa saja rekan-rekan TSLA. Dari itu, periksa yang mana yang memiliki kapitalisasi pasar tertinggi. Kemudian, pada ticker yang memiliki kapitalisasi pasar tertinggi, dapatkan perkiraan target harga terbaru dari seorang analis, dan beri tahu saya siapa dan pada tanggal berapa perkiraan itu dibuat. Beri tahu saya apakah analis tersebut bullish atau bearish pada saham ini.",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "Lakukan analisis keuangan dasar AMZN menggunakan data yang paling baru yang tersedia. Apa yang menurut Anda menarik?",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "Apa yang menjadi pendorong utama di balik lonjakan harga emas baru-baru ini, dan seberapa berkelanjutan tren ini dalam jangka menengah?",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "Apa dampak potensial dari kenaikan 25 basis poin dalam suku bunga Federal Reserve terhadap portofolio pendapatan tetap kami?",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "Dengan komposisi portofolio kami saat ini (<komposisi portofolio perlu disediakan di sini>), apa dampak potensial dari peningkatan signifikan dalam suku bunga pada aset tetap kami?",
            "Research Assistant": "Asisten Penelitian",
            "Fundamentals Analysis": "Analisis Fundamental",
            "Market Research": "Riset Pasar",
            "Financial Analysis": "Analisis Keuangan",
            "Portfolio Risk Management": "Manajemen Risiko Portofolio",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "Unggah riset pasar, laporan besar, dan dapatkan jawaban atas pertanyaan Anda tanpa harus membaca dokumen-dokumen ini",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "Unggah lembaran terminologi dan prospektus produk keuangan kompleks, Gaia akan menemukan apa pun yang Anda butuhkan dari mereka seperti jadwal penyelesaian, harga pelaksanaan, knock-out, ...",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "Unggah dokumen besar, pilih di tampilan folder, dan tombol ringkasan menjadi aktif.",
            "Deep search through large documents":
                "Pencarian mendalam melalui dokumen besar",
            "Analyze complex financial documents":
                "Analisis dokumen keuangan yang kompleks",
            "Summarize large documents": "Ringkas dokumen besar",
            Suggestions: "Saran",
            "Internal Data": "Data Internal",
            "Upload your own files, order them across folders, and ask any question.":
                "Unggah file Anda sendiri, urutkan di seluruh folder, dan ajukan pertanyaan apa pun.",
            "Here are a few ideas of what you can do with Gaia:":
                "Berikut adalah beberapa ide tentang apa yang bisa Anda lakukan dengan Gaia:",
            Ideas: "Ide",
            Topics: "Topik",
            FAQ: "FAQ",
            Intraday: "Intraday",
            Daily: "Harian",
            Weekly: "Mingguan",
            Monthly: "Bulanan",
            "LATEST QUOTE": "Kutipan Terbaru",
            "Open:": "Buka:",
            "High:": "Tinggi:",
            "Low:": "Rendah:",
            "Volume:": "Volume:",
            "Latest trading day:": "Hari perdagangan terakhir:",
            "Previous close:": "Tutup sebelumnya:",
            "MARKET NEWS": "Berita Pasar",
            Date: "Tanggal",
            Title: "Judul",
            Sentiment: "Sentimen",
            Source: "Sumber",
            "Share Information": "Bagikan Informasi",
            Action: "Aksi",
            Admins: "Admin",
            Share: "Bagikan",
            "Client Portfolio": "Portofolio Klien",
            "Client's": "Klien",
            Clients: "Klien",
            "View Portfolio": "Lihat Portofolio",
            "Gaia Agent History": "Sejarah Agen Gaia",
            "Click refresh button to update status of agents in progress":
                "Klik tombol segarkan untuk memperbarui status agen yang sedang berlangsung",
            "Documents in folder": "Dokumen dalam folder",
            File: "Berkas",
            Folder: "Folder",
            "Pick Internal Data folder": "Pilih folder Data Internal",
            "Summarize document": "Ringkas dokumen",
            "Folder created successfully": "Folder berhasil dibuat",
            "Folder ": "Folder ",
            " successfully deleted": " berhasil dihapus",
            "File uploaded successfully": "File berhasil diunggah",
            "Document deleted successfully": "Dokumen berhasil dihapus",
            "Create Internal Data Folder": "Buat Folder Data Internal",
            Save: "Simpan",
            Cancel: "Batal",
            "Are you sure you want to delete the ":
                "Apakah Anda yakin ingin menghapus ",
            " folder?": " folder?",
            Delete: "Hapus",
            "Upload New Document in Folder": "Unggah Dokumen Baru di Folder",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "File yang didukung: .doc, .docx, .txt, .rtf, .htm, .html, .pdf",
            "Notes regarding pdfs:": "Catatan mengenai pdf:",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "PDF yang merupakan pdf gambar atau menggunakan font kustom akan dimuat menggunakan teknik pengenalan karakter. Ini membatasi kemampuan kami untuk mengurai tabel dalam dokumen semacam itu.",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "oleh karena itu kami mendorong Anda untuk mengirimkan dokumen semacam itu dalam format alternatif untuk akurasi yang lebih baik.",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "PDF besar dapat memakan waktu hingga 5 menit untuk diproses, disarankan untuk tidak menutup jendela ini.",
            "Are you sure you want to delete this document?":
                "Apakah Anda yakin ingin menghapus dokumen ini?",
            "Select a Client's portfolio": "Pilih portofolio klien",
            "Please select a client": "Silakan pilih klien",
            "Get Portfolio Recommendations": "Dapatkan Rekomendasi Portofolio",
            "Robo-Advisor (Asset Classes)": "Robo-Advisor (Kelas Aset)",
            "Pick a stock:": "Pilih saham:",
            Ticker: "Ticker",
            Compare: "Bandingkan",
            Client: "Klien",
            DCA: "DCA",
            Financial: "Keuangan",
            Lifestyle: "Gaya hidup",
            Stocks: "Saham",
            Portfolio: "Portofolio",
            Agent: "Agen",
            Bookmarks: "Bookmark",
            History: "Sejarah",
            Notifications: "Notifikasi",
            "Please select a Stock first": "Harap pilih Saham terlebih dahulu",
            "Please select a folder first":
                "Harap pilih folder terlebih dahulu",
            "Please select a private equity first":
                "Harap pilih ekuitas swasta terlebih dahulu",
            "Please enter a question": "Silakan masukkan pertanyaan",
            "Share to Admin": "Bagikan ke Admin",
            "Share to client": "Bagikan ke klien",
            "Useful response": "Respon yang berguna",
            "Bad response": "Respon buruk",
            "Get Stock Information": "Dapatkan Informasi Saham",
            "Generate Graph": "Buat Grafik",
            "Financial Data based research": "Riset berbasis data keuangan",
            "Web based research": "Riset berbasis web",
            "Send a message.": "Kirim pesan.",
            "Name/Emp ID": "Nama/ID Karyawan",
            Idea: "Idea",
            "Folder Name": "Nama Folder",
            Dashboard: "Dasbor",
            "Ask Gaia": "Tanyakan Gaia",
            Product: "Produk",
            Service: "Layanan",
            "My Prospects": "Prospek Saya",
            "My Clients": "Klien Saya",
            Subscriptions: "Langganan",
            Payments: "Pembayaran",
            FAQs: "FAQ",
            "User Management": "Manajemen Pengguna",
            "Vendor Management": "Manajemen Vendor",
            "Financial Products": "Produk Keuangan",
            "Lifestyle Products": "Produk Gaya Hidup",
            "Financial Services": "Layanan Keuangan",
            "Lifestyle Services": "Layanan Gaya Hidup",
            Campaign: "Kampanye",
            Reports: "Laporan",
            "RM Performance": "Kinerja RM",
            "Customer Feedback": "Umpan Balik Pelanggan",
            "Order Management": "Manajemen Pesanan",
            "Ref.Data Management": "Manajemen Data Referensi",
            "Chat with RM": "Obrolan dengan RM",
            Chat: "Obrolan",
            Bordeaux: "Bordeaux",
            "Bill of Materials": "Bill of Materials",
            "Configure Freight Charges": "Konfigurasikan Biaya Pengiriman",
            "No. of Clients": "Jumlah Klien",
            "No. of RM's": "Jumlah RM",
            "Total Partners/Vendors": "Total Mitra/Penjual",
            "Total AUM": "Total AUM",
            "Invested Wealth": "Kekayaan yang Diinvestasikan",
            "Aggregate Asset Allocation (%)": "Pengalokasian Aset Agregat (%)",
            "Aggregate Geographical Allocation (%)":
                "Pengalokasian Geografis Agregat (%)",
            "Aggregate Sector Allocation (%)":
                "Pengalokasian Sektor Agregat (%)",
            AUM: "AUM",
            Revenue: "Pendapatan",
            Task: "Tugas",
            "Market News": "Berita Pasar",
            "Gaia Help": "Bantuan Gaia",
            All: "Semua",
            Equities: "Ekuitas",
            "Fixed Income": "Pendapatan Tetap",
            "Cash Or Equivalent": "Uang Tunai Atau Setara",
            "Structured Products": "Produk Terstruktur",
            "Funds and Etfs": "Dana dan ETF",
            Commodities: "Komoditas",
            "Real Estate": "Properti",
            "Esg/Impact Investing": "Investasi ESG/Dampak",
            Insurance: "Asuransi",
            Crypto: "Crypto",
            "Private Equity": "Ekuitas Swasta",
            "Growth Equity": "Ekuitas Pertumbuhan",
            "Venture Capital Investment": "Investasi Modal Ventura",
            "Private Credit": "Kredit Swasta",
            "Pre-Ipo Products": "Produk Pra-IPO",
            "Direct Deal Products": "Produk Kesepakatan Langsung",
            "Other Alternatives": "Alternatif Lainnya",
            Search: "Cari",
            Filters: "Filter",
            Travel: "Perjalanan",
            Wellness: "Kesehatan",
            Food: "Makanan",
            Health: "Kesehatan",
            Sports: "Olahraga",
            Art: "Seni",
            Wine: "Anggur",
            "Live Trade": "Perdagangan Langsung",
            "Sign In": "Masuk",
            "Sign in": "Masuk",
            "Email address": "Alamat email",
            Password: "Kata sandi",
            "Forgot password?": "Lupa kata sandi?",
            "All Rights Reserved": "Seluruh Hak Dilindungi",
            Currency: "Mata Uang",
            "My Profile": "Profil Saya",
            "Change Password": "Ubah Kata Sandi",
            Logout: "Keluar",
            Status: "Status",
            Active: "Aktif",
            Actions: "Tindakan",
            Profile: "Profil",
            Transactions: "Transaksi",
            "Client Name": "Nama Klien",
            "Invested Wealth ($)": "Kekayaan yang Diinvestasikan ($)",
            "Investible Wealth ($)": "Kekayaan yang Dapat Diinvestasikan ($)",
            "Revenue ($)": "Pendapatan ($)",
            Personal: "Pribadi",
            Professional: "Profesional",
            Family: "Keluarga",
            Interest: "Minat",
            KYC: "KYC",
            Salutation: "Salam",
            "First Name": "Nama Depan",
            "Last Name": "Nama Belakang",
            "D.O.B": "Tgl Lahir",
            Gender: "Jenis Kelamin",
            "Highest Education": "Pendidikan Tertinggi",
            "Primary Email ID": "Email ID Utama",
            "Secondary Email ID": "Email ID Sekunder",
            "Mobile Number": "Nomor Ponsel",
            "Not Editable": "Tidak Dapat Diedit",
            "Alternative Mobile Number": "Nomor Ponsel Alternatif",
            "Base Location": "Lokasi Dasar",
            Origin: "Asal",
            "Address 1": "Alamat 1",
            "Address 2": "Alamat 2",
            "Address 3": "Alamat 3",
            Country: "Negara",
            State: "Negara Bagian",
            City: "Kota",
            "Postal Code": "Kode Pos",
            "Net Worth $": "Kekayaan Bersih $",
            "Source of Wealth": "Sumber Kekayaan",
            "Investable wealth $": "Kekayaan yang dapat diinvestasikan $",
            "Risk appetite": "Kecenderungan Risiko",
            "Industry preference": "Preferensi Industri",
            "Financial goals": "Tujuan Keuangan",
            "Joining Fee $": "Biaya Bergabung $",
            "Annual Subscription Fee $": "Biaya Langganan Tahunan $",
            "Current Investment Approach": "Pendekatan Investasi Saat Ini",
            "Take Survey": "Ikuti Survei",
            "Present Asset Allocations:": "Alokasi Aset Saat Ini:",
            ALL: "SEMUA",
            EQUITIES: "EKUITAS",
            "FIXED INCOME": "PENDAPATAN TETAP",
            "CASH OR EQUIVALENT": "UANG TUNAI ATAU SETARA",
            "STRUCTURED PRODUCTS": "PRODUK TERSTRUKTUR",
            "FUNDS AND ETFS": "DANA DAN ETF",
            COMMODITIES: "KOMODITAS",
            "REAL ESTATE": "PROPERTI",
            "ESG/IMPACT INVESTING": "INVESTASI ESG/DAMPAK",
            INSURANCE: "ASURANSI",
            CRYPTO: "CRYPTO",
            "PRIVATE EQUITY": "EKUITAS SWASTA",
            "GROWTH EQUITY": "EKUITAS PERTUMBUHAN",
            "VENTURE CAPITAL INVESTMENT": "INVESTASI MODAL VENTURA",
            "PRIVATE CREDIT": "KREDIT SWASTA",
            "PRE-IPO PRODUCTS": "PRODUK PRA-IPO",
            "DIRECT DEAL PRODUCTS": "PRODUK KESEPAKATAN LANGSUNG",
            "OTHER ALTERNATIVES": "ALTERNATIF LAINNYA",
            "Is my RM authorised to do transactions on my behalf?":
                "Apakah RM saya diizinkan untuk melakukan transaksi atas nama saya?",
            YES: "YA",
            NO: "TIDAK",
            Yes: "Ya",
            No: "Tidak",
            "Profession Type": "Jenis Profesi",
            Industry: "Industri",
            "Domain / Sector": "Domain / Sektor",
            Turnover: "Omset",
            "Is it a hereditary business?": "Apakah ini bisnis turun-temurun?",
            "Marital status": "Status pernikahan",
            "Number of family members": "Jumlah anggota keluarga",
            "Family Members": "Anggota Keluarga",
            Personality: "Kepribadian",
            "Current Memberships": "Keanggotaan Saat Ini",
            "Previous Vacations": "Liburan Sebelumnya",
            "Proof of Identity": "Bukti Identitas",
            "Proof of Address": "Bukti Alamat",
            "Proof of Tax Residency": "Bukti Residensi Pajak",
            "Proof of Wealth": "Bukti Kekayaan",
            "Other Documents": "Dokumen Lainnya",
            "Document Type": "Jenis Dokumen",
            "ID Number": "Nomor ID",
            "Expiry date": "Tanggal kadaluarsa",
            "Upload documents": "Unggah dokumen",
            "Zip code": "Kode pos",
            Edit: "Edit",
            Type: "Tipe",
            "Product / Service Category": "Kategori Produk / Layanan",
            "Date of Purchase": "Tanggal Pembelian",
            Time: "Waktu",
            "Price($)": "Harga($)",
            Quantity: "Kuantitas",
            "Amount($)": "Jumlah($)",
            "Payment Ref": "Ref Pembayaran",
            Terminate: "Akhiri",
            "Go To Order": "Pergi ke Pesanan",
            Open: "Buka",
            Completed: "Selesai",
            "Purchase Initiated": "Pembelian Dimulai",
            "Aging Days": "Usia Hari",
            Vendor: "Penjual",
            "RM/Employee Id": "RM/ID Karyawan",
            "Product Name": "Nama Produk",
            "Selling Price ($)": "Harga Jual ($)",
            "Investment Term (in Months)":
                "Jangka Waktu Investasi (dalam Bulan)",
            "End Date": "Tanggal Berakhir",
            "Product Category": "Kategori Produk",
            Region: "Wilayah",
            Liquidity: "Likuiditas",
            "Returns Level": "Tingkat Pengembalian",
            "Risk Level": "Tingkat Risiko",
            "Price ($)": "Harga ($)",
            "Take Rate": "Tingkat Pengambilan",
            "Client's Invested": "Klien yang Diinvestasikan",
            Tags: "Tag",
            "Product Description": "Deskripsi Produk",
            "Purchased Items": "Item yang Dibeli",
            "Current Quantity": "Kuantitas Saat Ini",
            "Current price ($)": "Harga saat ini ($)",
            "Active Buy/Sell": "Beli/Jual Aktif",
            "No Active buy/sell records found":
                "Tidak ada catatan beli/jual aktif ditemukan",
            "Product / Transaction Status": "Status Produk / Transaksi",
            ACTIVE: "AKTIF",
            INACTIVE: "TIDAK AKTIF",
            WFA: "WFA",
            DELETED: "DIHAPUS",
            NEW: "BARU",
            REPLIED: "MENJAWAB",
            REJECTED: "DITOLAK",
            SYSTEM: "SISTEM",
            CLIENT: "KLIEN",
            "FLAT RATE": "TARIF DATAR",
            "MR.": "TUAN",
            "MRS.": "NYONYA",
            "MS.": "NYONYA",
            "FINANCIAL PRODUCTS": "PRODUK KEUANGAN",
            "LIFESTYLE PRODUCTS": "PRODUK GAYA HIDUP",
            "FINANCIAL SERVICES": "LAYANAN KEUANGAN",
            "LIFESTYLE SERVICES": "LAYANAN GAYA HIDUP",
            STEADY: "STABIL",
            AGGRESSIVE: "AGRESIF",
            MODERATE: "MODERAT",
            CREDIT: "KREDIT",
            DEBIT: "DEBIT",
            "LOW RISK": "RISIKO RENDAH",
            "MEDIUM RISK": "RISIKO SEDANG",
            "HIGH RISK": "RISIKO TINGGI",
            "N/A": "TIDAK TERSEDIA",
            "HIGH SCHOOL": "SEKOLAH MENENGAH ATAS",
            "UNDER GRADUATE": "SARJANA",
            "POST GRADUATE": "PASCA SARJANA",
            "AEROSPACE AND DEFENSE": "AEROSPACE DAN PERTAHANAN",
            "AGRICULTURE AND AGRIBUSINESS": "PERTANIAN DAN AGRIBISNIS",
            AUTOMOTIVE: "OTOMOTIF",
            "BANKING AND FINANCE": "PERBANKAN DAN KEUANGAN",
            CHEMICALS: "KIMIA",
            "CONSTRUCTION AND INFRASTRUCTURE": "KONSTRUKSI DAN INFRASTRUKTUR",
            "CONSUMER GOODS AND SERVICES": "BARANG KONSUMEN DAN LAYANAN",
            "EDUCATION AND TRAINING": "PENDIDIKAN DAN PELATIHAN",
            "ENERGY AND UTILITIES": "ENERGI DAN UTILITAS",
            "ENTERTAINMENT AND MEDIA": "HIBURAN DAN MEDIA",
            "HEALTHCARE AND PHARMACEUTICALS": "KESEHATAN DAN FARMASI",
            "HOSPITALITY AND TOURISM": "HOSPITALITAS DAN PARIWISATA",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS":
                "TEKNOLOGI INFORMASI DAN TELEKOMUNIKASI",
            MANUFACTURING: "MANUFAKTUR",
            "MINING AND METALS": "PENAMBANGAN DAN LOGAM",
            "REAL ESTATE AND PROPERTY": "REAL ESTATE DAN PROPERTY",
            RETAIL: "RITEL",
            "TRANSPORTATION AND LOGISTICS": "TRANSPORTASI DAN LOGISTIK",
            "BIOTECHNOLOGY AND LIFE SCIENCES":
                "BIOTEKNOLOGI DAN ILMU KEHIDUPAN",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY":
                "TEKNOLOGI BERSIH DAN ENERGI TERBARUKAN",
            "CONSUMER ELECTRONICS": "ELEKTRONIK KONSUMEN",
            "ENVIRONMENTAL AND WASTE MANAGEMENT":
                "LINGKUNGAN DAN MANAJEMEN LIMBAH",
            "FOOD AND BEVERAGE": "MAKANAN DAN MINUMAN",
            "GOVERNMENT AND PUBLIC SERVICES": "PEMERINTAH DAN LAYANAN PUBLIK",
            "LEGAL SERVICES": "LAYANAN HUKUM",
            "NON-PROFIT AND SOCIAL ENTERPRISE": "NIRLABA DAN USAHA SOSIAL",
            "PROFESSIONAL SERVICES": "LAYANAN PROFESIONAL",
            "SECURITY AND CYBERSECURITY": "KEAMANAN DAN KEAMANAN CYBER",
            "TEXTILES AND APPAREL": "TEKSTIL DAN PAKAIAN",
            "RISK AVERSE": "TAKUT RISIKO",
            CONSERVATIVE: "KONSERVATIF",
            "WEALTH PRESERVATION": "PEMELIHARA KEKAYAAN",
            "CAPITAL APPRECIATION": "PENINGKATAN MODAL",
            "ESTATE PLANNING": "PERENCANAAN WARISAN",
            "RISK MANAGEMENT": "MANAJEMEN RISIKO",
            "SUCCESSION PLANNING": "PERENCANAAN SUCCESSION",
            "TAX PLANNING": "PERENCANAAN PAJAK",
            ENTREPRENEURS: "PEBISNIS",
            INVESTORS: "INVESTOR",
            "CORPORATE EXECUTIVES": "EKSEKUTIF KORPORAT",
            "FINANCIAL PROFESSIONALS": "PROFESIONAL KEUANGAN",
            "ENTERTAINMENT PROFESSIONALS": "PROFESIONAL HIBURAN",
            "PROFESSIONAL ATHLETES": "ATLET PROFESIONAL",
            "REAL ESTATE PROFESSIONALS": "PROFESIONAL REAL ESTATE",
            "TECH INDUSTRY PROFESSIONALS": "PROFESIONAL INDUSTRI TEKNOLOGI",
            "LUXURY GOODS AND SERVICES": "BARANG DAN LAYANAN MEWAH",
            "ART AND COLLECTIBLES": "SENIMAN DAN KOLEKSI",
            PHILANTHROPY: "FILANTROPI",
            EDUCATION: "PENDIDIKAN",
            "HEALTH AND WELLNESS": "KESEHATAN DAN KESEJAHTERAAN",
            TECHNOLOGY: "TEKNOLOGI",
            "SUSTAINABLE INVESTMENTS": "INVESTASI BERKELANJUTAN",
            SPORTS: "OLAHRAGA",
            "TRAVEL AND HOSPITALITY": "PERJALANAN DAN HOSPITALITAS",
            MARRIED: "MENIKAH",
            UNMARRIED: "BELUM MENIKAH",
            DIVORCED: "BERCERAI",
            "WEALTH MANAGEMENT": "MANAJEMEN KEKAYAAN",
            OTHERS: "LAINNYA",
            MALE: "PRIA",
            FEMALE: "WANITA",
            "Payment Initiated": "Pembayaran Dimulai",
            "Payment Reversed": "Pembayaran Dibalikkan",
            "Payment Received": "Pembayaran Diterima",
            "Product Allocated": "Produk Dialokasikan",
            "Sell Initiated": "Jual Dimulai",
            "Product Sold": "Produk Terjual",
            "Service Provisioned": "Layanan Disediakan",
            "Open - Purchase Initiated": "Buka - Pembelian Dimulai",
            "Open - Payment Initiated": "Buka - Pembayaran Dimulai",
            "Open - Payment Reversed": "Buka - Pembayaran Dibalikkan",
            "Open - Payment Received": "Buka - Pembayaran Diterima",
            "Completed - Product Allocated": "Selesai - Produk Dialokasikan",
            "Open - Sell Initiated": "Buka - Jual Dimulai",
            "Completed - Product Sold": "Selesai - Produk Terjual",
            "Completed - Service Provisioned": "Selesai - Layanan Disediakan",
            Bank: "Bank",
            Asset: "Aset",
            Liability: "Kewajiban",
            SPOUSE: "SUAMI/ISTRI",
            CHILD: "ANAK",
            PARENT: "ORANG TUA",
            SIBLING: "SAUDARA",
            OTHER: "LAINNYA",
            "BUYING A HOUSE": "MEMBELI RUMAH",
            TRAVEL: "PERJALANAN",
            RETIREMENT: "PENSIUN",
            "LEGACY PLANNING": "PERENCANAAN WARISAN",
            NOVICE: "PEMULA",
            INTERMEDIATE: "MENENGAH",
            EXPERT: "AHLI",
            "SHORT TERM": "JANGKA PENDEK",
            "MEDIUM TERM": "JANGKA MENENGAH",
            "LONG TERM": "JANGKA PANJANG",
            "INVESTMENT STRATEGIES": "STRATEGI INVESTASI",
            "TRUST SERVICES": "LAYANAN TRUST",
            "SPORTS AND FITNESS ACTIVITIES": "AKTIVITAS OLAHRAGA DAN KEBUGARAN",
            "ARTS AND CULTURE": "SENIRUPA DAN BUDAYA",
            "TRAVEL AND ADVENTURE": "PERJALANAN DAN PETUALANGAN",
            "MUSIC AND ENTERTAINMENT": "MUSIK DAN HIBURAN",
            "FOOD AND DINING": "MAKANAN DAN MAKANAN",
            "FASHION AND STYLE:": "FASHION DAN GAYA:",
            "TECHNOLOGY AND GADGETS": "TEKNOLOGI DAN GADGET",
            SALARY: "GAJI",
            INVESTMENTS: "INVESTASI",
            "BUSINESS OWNERSHIP": "KEPEMILIKAN USAHA",
            CASH: "UANG TUNAI",
            SAVINGS: "TABUNGAN",
            "MONEY MARKET ACCOUNTS": "REKENING PASAR UANG",
            STOCKS: "SAHAM",
            BONDS: "OBLIGASI",
            "MUTUAL FUNDS": "DANA INVESTASI",
            "PENSION PLANS": "RENCANA PENSION",
            ANNUITIES: "ANUITAS",
            ART: "SENIRUPA",
            COLLECTIBLES: "KOLEKSI",
            PROPERTIES: "PROPERTI",
            "PERSONAL LOANS": "PINJAMAN PRIBADI",
            "STUDENT LOANS": "PINJAMAN PELAJAR",
            HOUSING: "RUMAH",
            UTILITIES: "UTILITAS",
            TRANSPORTATION: "TRANSPORTASI",
            "PROPERTY INSURANCE": "ASURANSI PROPERTI",
            "LIABILITY INSURANCE": "ASURANSI KEWAJIBAN",
            "LEGAL ADVISORY": "KONSULTASI HUKUM",
            "COMPLIANCE SERVICES": "LAYANAN KEPATUHAN",
            "ACCOUNTING SERVICES": "LAYANAN AKUNTANSI",
            "LEVERAGE MANAGEMENT": "MANAJEMEN LEVERAGE",
            "REQUEST EXPERT ADVISORY MEETING":
                "MINTA PERTEMUAN KONSULTASI AHLI",
            PASSPORT: "PASPOR",
            "IDENTITY CARD": "KARTU IDENTITAS",
            "DRIVING LICENSE": "SIM",
            "BANK STATEMENT": "REKENING BANK",
            "UTILITY BILL": "TAGIHAN UTILITAS",
            "PHONE BILL": "TAGIHAN TELEPON",
            "TAX BILL": "TAGIHAN PAJAK",
            "RENTAL AGREEMENT": "PERJANJIAN SEWA",
            "NORMAL DELIVERY": "PENGIRIMAN NORMAL",
            INBOND: "INBOND",
            "SPECIAL DELIVERY": "PENGIRIMAN KHUSUS",
            Latest: "Terbaru",
            Ascending: "Menaik",
            Descending: "Menurun",
            "Enable Access": "Aktifkan Akses",
            "Disable Access": "Nonaktifkan Akses",
            MFO: "MFO",
            SFO: "SFO"
        }
    },
    th: {
        translation: {
            SUBQUESTION: "คำถามย่อย",
            Answer: "คำตอบ",
            "Tool Used": "เครื่องมือที่ใช้",
            Show: "แสดง",
            Hide: "ซ่อน",
            "detailed analysis": "การวิเคราะห์อย่างละเอียด",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "ค้นหา 5500+ หุ้น 10-K และ 10-Q ล่าสุด รับคำตอบในไม่กี่วินาที",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "อัปโหลดข้อมูลส่วนตัวของคุณ จัดระเบียบในโฟลเดอร์และถามคำถามเกี่ยวกับมัน",
            "Collection of tools helping you better manage and understand your portfolio.":
                "ชุดเครื่องมือช่วยให้คุณจัดการและเข้าใจพอร์ตโฟลิโอของคุณได้ดีขึ้น",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "Gaia Agent ช่วยให้คุณดำเนินการวิจัยตลาดลึกกว่า 80,000 หลักทรัพย์สาธารณะ (หุ้น พันธบัตร สินค้า คริปโต ...)",
            "Gaia Terminal": "Gaia Terminal",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "ถามคำถามใด ๆ เกี่ยวกับเครื่องมือการเงิน 80,000+ และให้ตัวแทนทำงาน โปรดตรวจสอบรหัสหุ้นที่แน่ใจ",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "แน่ใจว่าคุณใส่รหัสหุ้นที่แน่ใจโดยเฉพาะสำหรับตลาดที่ไม่ใช่สหรัฐ (เช่น: RELIANCE.NS)",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "ค้นหาพื้นที่สำคัญของรายได้และการสร้างกำไรสำหรับ Alphabet",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "สำรวจว่าการลงทุนของทุนของ Alphabet ถูกแจกแจงไปยังคลาสสินทรัพย์ต่าง ๆ",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "ตรวจสอบการวิเคราะห์ของรายงานกำไรล่าสุดสำหรับ Amazon รวมถึงความประทับใจหรือแนวโน้มที่น่าสังเกต",
            "Find out the total assets and total liabilities of Apple":
                "ค้นหาสินทรัพย์รวมและหนี้สินรวมของ Apple",
            "Check out the different tax obligation/provision on Apple":
                "ตรวจสอบหนี้สินภาษี / ข้อบังคับที่แตกต่างกันใน Apple",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "ใช้บอทเพื่อวิเคราะห์ค่าใช้จ่ายในการวิจัยและพัฒนาเป็นเปอร์เซ็นต์ของรายได้สำหรับ Meta และประเมินการสร้างสรรค์ของ Meta",
            "Figure out the revenue by geography for Meta":
                "คำนวณรายได้ตามภูมิภาคสำหรับ Meta",
            "Understand the major areas of capital expenditure for Microsoft":
                "เข้าใจพื้นที่หลักของการใช้จ่ายส่วนทุนสำหรับ Microsoft",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "คำนวณผลของการเปลี่ยนแปลงกฎหมายล่าสุดต่อการดำเนินงานของ Amazon Communications และทฤษฎีการเงินของมัน",
            "Invest in a timeless designer handbag":
                "ลงทุนในกระเป๋ามือที่ไม่เปลี่ยนสไตล์",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "ลงทุนในนาฬิกาหรูหราจากแบรนด์ชั้นนำเช่น rolex, philippe หรือ audemars piguet",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "อยู่ในสไตล์และอบอุ่นด้วยเสื้อคลุมหรูหราหรือเสื้อแจ็คเก็ตจากบ้านแฟชั่นชื่อดัง",
            "What should i consider when selecting a luxury service vendor?":
                "ฉันควรพิจารณาอะไรเมื่อเลือกผู้ขายบริการหรูหรา?",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "ผู้ขายบริการหรูหราสามารถปรับแต่งการเสนอของพวกเขาเพื่อตอบสนองความต้องการหรือความชอบเฉพาะ?",
            "What type of certifications or credentials should luxury service vendors have?":
                "ประเภทของการรับรองหรือเอกสารสิทธิที่ผู้ขายบริการหรูหราควรมี?",
            "Evolution and Trends in Luxury Services":
                "วิวัฒนาการและแนวโน้มในบริการหรูหรา",
            "Luxury Travel Experiences and Destination Management":
                "ประสบการณ์การเดินทางหรูหราและการจัดการปลายทาง",
            "The Influence of social media on luxury services":
                "อิทธิพลของโซเชียลมีเดียต่อบริการหรูหรา",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "ตรวจสอบเพื่อดู TSLA พวกเขา จากนั้น ตรวจสอบว่าใครมีทุนตลาดสูงสุด จากนั้น ในตัวหมายที่มีทุนตลาดสูงสุด รับประมาณการเป้าหมายราคาล่าสุดจากนักวิเคราะห์ และบอกฉันว่าใครและในวันที่ประมาณการถูกทำ บอกฉันว่านักวิเคราะห์เชื่อมั่นหรือไม่เชื่อมั่นในหุ้นนี้",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "ดำเนินการวิเคราะห์การเงินพื้นฐานของ AMZN โดยใช้ข้อมูลล่าสุดที่มีอยู่ คุณพบอะไรที่น่าสนใจ?",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "ปัจจัยหลักที่อยู่เบื้องหลังการเพิ่มขึ้นล่าสุดในราคาทองคำ และแนวโน้มนี้คงอยู่ได้ในระยะกลางมั้ย?",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "ผลกระทบที่เป็นไปได้ของการเพิ่มอัตราดอกเบี้ย 25 พ้อยท์ในอัตราดอกเบี้ยของสำรองเงินรายได้ของเราคืออะไร?",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "โดยพิจารณาความสมบูรณ์ของพอร์ตโฟลิโอของเราในปัจจุบัน (<ความสมบูรณ์ของพอร์ตโฟลิโอต้องมีที่นี่>) ผลกระทบที่เป็นไปได้ของการเพิ่มอัตราดอกเบี้ยที่สำคัญต่อการถือหุ้นรายได้ของเราคืออะไร?",
            "Research Assistant": "ผู้ช่วยวิจัย",
            "Fundamentals Analysis": "การวิเคราะห์พื้นฐาน",
            "Market Research": "การวิจัยตลาด",
            "Financial Analysis": "การวิเคราะห์ทางการเงิน",
            "Portfolio Risk Management": "การจัดการความเสี่ยงพอร์ตโฟลิโอ",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "อัปโหลดการวิจัยตลาด รายงานขนาดใหญ่ และได้รับคำตอบต่อคำถามของคุณโดยไม่ต้องอ่านเอกสารเหล่านี้",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "อัปโหลดแผ่นเอกสารและข้อเสนอของผลิตภัณฑ์การเงินที่ซับซ้อน Gaia จะค้นหาสิ่งใดก็ที่คุณต้องการจากพวกเขา เช่น ตารางการชำระเงิน ราคาเสนอ การตีความ ...",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "อัปโหลดเอกสารขนาดใหญ่ เลือกในการแสดงโฟลเดอร์ และปุ่มสรุปกลายเป็นใช้งานได้",
            "Deep search through large documents": "ค้นหาลึกลงในเอกสารขนาดใหญ่",
            "Analyze complex financial documents":
                "วิเคราะห์เอกสารการเงินที่ซับซ้อน",
            "Summarize large documents": "สรุปเอกสารขนาดใหญ่",
            Suggestions: "คำแนะนำ",
            "Internal Data": "ข้อมูลภายใน",
            "Upload your own files, order them across folders, and ask any question.":
                "อัปโหลดไฟล์ของคุณเอง จัดเรียงในโฟลเดอร์และถามคำถามใด ๆ",
            "Here are a few ideas of what you can do with Gaia:":
                "นี่คือความคิดเห็นเกี่ยวกับสิ่งที่คุณสามารถทำกับ Gaia:",
            Ideas: "ความคิด",
            Topics: "หัวข้อ",
            FAQ: "คำถามที่พบบ่อย",
            Intraday: "ในวัน",
            Daily: "รายวัน",
            Weekly: "รายสัปดาห์",
            Monthly: "รายเดือน",
            "LATEST QUOTE": "คำพูดล่าสุด",
            "Open:": "เปิด:",
            "High:": "สูง:",
            "Low:": "ต่ำ:",
            "Volume:": "ปริมาณ:",
            "Latest trading day:": "วันซื้อขายล่าสุด:",
            "Previous close:": "ปิดก่อนหน้า:",
            "MARKET NEWS": "ข่าวตลาด",
            Date: "วันที่",
            Title: "ชื่อเรื่อง",
            Sentiment: "ความรู้สึก",
            Source: "แหล่งที่มา",
            "Share Information": "แบ่งปันข้อมูล",
            Action: "การกระทำ",
            Admins: "ผู้ดูแล",
            Share: "แชร์",
            "Client Portfolio": "พอร์ตโฟลิโอของลูกค้า",
            "Client's": "ลูกค้า",
            Clients: "ลูกค้า",
            "View Portfolio": "ดูพอร์ตโฟลิโอ",
            "Gaia Agent History": "ประวัติเอเจนต์ Gaia",
            "Click refresh button to update status of agents in progress":
                "คลิกปุ่มรีเฟรชเพื่ออัปเดตสถานะของเอเจนต์ที่กำลังดำเนินการ",
            "Documents in folder": "เอกสารในโฟลเดอร์",
            File: "ไฟล์",
            Folder: "โฟลเดอร์",
            "Pick Internal Data folder": "เลือกโฟลเดอร์ข้อมูลภายใน",
            "Summarize document": "สรุปเอกสาร",
            "Folder created successfully": "สร้างโฟลเดอร์สำเร็จ",
            "Folder ": "โฟลเดอร์ ",
            " successfully deleted": " ถูกลบเรียบร้อย",
            "File uploaded successfully": "อัปโหลดไฟล์สำเร็จ",
            "Document deleted successfully": "ลบเอกสารสำเร็จ",
            "Create Internal Data Folder": "สร้างโฟลเดอร์ข้อมูลภายใน",
            Save: "บันทึก",
            Cancel: "ยกเลิก",
            "Are you sure you want to delete the ": "คุณแน่ใจว่าต้องการลบ ",
            " folder?": " โฟลเดอร์?",
            Delete: "ลบ",
            "Upload New Document in Folder": "อัปโหลดเอกสารใหม่ในโฟลเดอร์",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "ไฟล์ที่รองรับ: .doc, .docx, .txt, .rtf, .htm, .html, .pdf",
            "Notes regarding pdfs:": "บันทึกเกี่ยวกับ pdf:",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "PDF ที่เป็น pdf รูปภาพหรือใช้ฟอนต์ที่กำหนดเองจะถูกโหลดโดยใช้เทคนิคการระบุตัวอักษร สิ่งนี้จำกัดความสามารถของเราในการแยกตารางในเอกสารเช่นนี้",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "ดังนั้นเราขอแนะนำให้คุณส่งเอกสารเช่นนี้ในรูปแบบทดแทนเพื่อความแม่นยำที่ดีขึ้น",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "PDF ขนาดใหญ่อาจใช้เวลาในการประมวลผลสูงสุด 5 นาที แนะนำให้ไม่ปิดหน้าต่างนี้",
            "Are you sure you want to delete this document?":
                "คุณแน่ใจว่าต้องการลบเอกสารนี้?",
            "Select a Client's portfolio": "เลือกพอร์ตโฟลิโอของลูกค้า",
            "Please select a client": "โปรดเลือกลูกค้า",
            "Get Portfolio Recommendations": "รับคำแนะนำพอร์ตโฟลิโอ",
            "Robo-Advisor (Asset Classes)": "โรโบ-ที่ปรึกษา (ชนิดสินทรัพย์)",
            "Pick a stock:": "เลือกหุ้น:",
            Ticker: "ตัวชี้",
            Compare: "เปรียบเทียบ",
            Client: "ลูกค้า",
            DCA: "DCA",
            Financial: "การเงิน",
            Lifestyle: "ไลฟ์สไตล์",
            Stocks: "หุ้น",
            Portfolio: "พอร์ตโฟลิโอ",
            Agent: "ตัวแทน",
            Bookmarks: "บุ๊คมาร์ก",
            History: "ประวัติ",
            Notifications: "การแจ้งเตือน",
            "Please select a Stock first": "โปรดเลือกหุ้นก่อน",
            "Please select a folder first": "โปรดเลือกโฟลเดอร์ก่อน",
            "Please select a private equity first":
                "โปรดเลือกเอกวิชชั่นส่วนตัวก่อน",
            "Please enter a question": "โปรดป้อนคำถาม",
            "Share to Admin": "แชร์ไปยังผู้ดูแล",
            "Share to client": "แชร์ไปยังลูกค้า",
            "Useful response": "การตอบกลับที่เป็นประโยชน์",
            "Bad response": "การตอบกลับที่ไม่ดี",
            "Get Stock Information": "รับข้อมูลหุ้น",
            "Generate Graph": "สร้างกราฟ",
            "Financial Data based research": "การวิจัยที่ใช้ข้อมูลการเงิน",
            "Web based research": "การวิจัยที่ใช้เว็บ",
            "Send a message.": "ส่งข้อความ.",
            "Name/Emp ID": "ชื่อ/รหัสพนักงาน",
            Idea: "ความคิด",
            "Folder Name": "ชื่อโฟลเดอร์",
            Dashboard: "แดชบอร์ด",
            "Ask Gaia": "ถาม Gaia",
            Product: "สินค้า",
            Service: "บริการ",
            "My Prospects": "โอกาสของฉัน",
            "My Clients": "ลูกค้าของฉัน",
            Subscriptions: "การสมัครสมาชิก",
            Payments: "การชำระเงิน",
            FAQs: "คำถามที่พบบ่อย",
            "User Management": "การจัดการผู้ใช้",
            "Vendor Management": "การจัดการผู้ขาย",
            "Financial Products": "ผลิตภัณฑ์การเงิน",
            "Lifestyle Products": "ผลิตภัณฑ์ไลฟ์สไตล์",
            "Financial Services": "บริการทางการเงิน",
            "Lifestyle Services": "บริการไลฟ์สไตล์",
            Campaign: "แคมเปญ",
            Reports: "รายงาน",
            "RM Performance": "ประสิทธิภาพ RM",
            "Customer Feedback": "ข้อเสนอแนะจากลูกค้า",
            "Order Management": "การจัดการคำสั่งซื้อ",
            "Ref.Data Management": "การจัดการข้อมูลอ้างอิง",
            "Chat with RM": "แชทกับ RM",
            Chat: "แชท",
            Bordeaux: "Bordeaux",
            "Bill of Materials": "บิลวัสดุ",
            "Configure Freight Charges": "กำหนดค่าขนส่ง",
            "No. of Clients": "จำนวนลูกค้า",
            "No. of RM's": "จำนวน RM",
            "Total Partners/Vendors": "พาร์ทเนอร์/ผู้ขายทั้งหมด",
            "Total AUM": "AUM ทั้งหมด",
            "Invested Wealth": "ความร่ำรวยที่ลงทุน",
            "Aggregate Asset Allocation (%)": "การจัดสรรสินทรัพย์รวม (%)",
            "Aggregate Geographical Allocation (%)": "การจัดสรรภูมิภาครวม (%)",
            "Aggregate Sector Allocation (%)": "การจัดสรรภูมิภาครวม (%)",
            AUM: "AUM",
            Revenue: "รายได้",
            Task: "งาน",
            "Market News": "ข่าวตลาด",
            "Gaia Help": "ความช่วยเหลือของ Gaia",
            All: "ทั้งหมด",
            Equities: "หุ้น",
            "Fixed Income": "หนี้สิน",
            "Cash Or Equivalent": "เงินสดหรือเทียบเท่า",
            "Structured Products": "ผลิตภัณฑ์โครงสร้าง",
            "Funds and Etfs": "กองทุนและ ETF",
            Commodities: "สินค้าโภคภัณฑ์",
            "Real Estate": "อสังหาริมทรัพย์",
            "Esg/Impact Investing": "Esg/การลงทุนที่มีผล",
            Insurance: "ประกัน",
            Crypto: "คริปโต",
            "Private Equity": "เอกวิชชันส่วนตัว",
            "Growth Equity": "เอกวิชชันการเติบโต",
            "Venture Capital Investment": "การลงทุนในเวนเจอร์แคปิตอล",
            "Private Credit": "เครดิตส่วนตัว",
            "Pre-Ipo Products": "ผลิตภัณฑ์ก่อน IPO",
            "Direct Deal Products": "ผลิตภัณฑ์ดีเร็คท์ดีล",
            "Other Alternatives": "ทางเลือกอื่น",
            Search: "ค้นหา",
            Filters: "ตัวกรอง",
            Travel: "การเดินทาง",
            Wellness: "สุขภาพดี",
            Food: "อาหาร",
            Health: "สุขภาพ",
            Sports: "กีฬา",
            Art: "ศิลปะ",
            Wine: "ไวน์",
            "Live Trade": "การซื้อขายสด",
            "Sign In": "เข้าสู่ระบบ",
            "Sign in": "เข้าสู่ระบบ",
            "Email address": "ที่อยู่อีเมล",
            Password: "รหัสผ่าน",
            "Forgot password?": "ลืมรหัสผ่าน?",
            "All Rights Reserved": "สงวนลิขสิทธิ์ทั้งหมด",
            Currency: "สกุลเงิน",
            "My Profile": "โปรไฟล์ของฉัน",
            "Change Password": "เปลี่ยนรหัสผ่าน",
            Logout: "ออกจากระบบ",
            Status: "สถานะ",
            Active: "ใช้งาน",
            Actions: "การกระทำ",
            Profile: "โปรไฟล์",
            Transactions: "ธุรกรรม",
            "Client Name": "ชื่อลูกค้า",
            "Invested Wealth ($)": "ความร่ำรวยที่ลงทุน ($)",
            "Investible Wealth ($)": "ความร่ำรวยที่ลงทุน ($)",
            "Revenue ($)": "รายได้ ($)",
            Personal: "ส่วนตัว",
            Professional: "วิชาชีพ",
            Family: "ครอบครัว",
            Interest: "ดอกเบี้ย",
            KYC: "KYC",
            Salutation: "คำนำหน้า",
            "First Name": "ชื่อจริง",
            "Last Name": "นามสกุล",
            "D.O.B": "วันเกิด",
            Gender: "เพศ",
            "Highest Education": "การศึกษาสูงสุด",
            "Primary Email ID": "อีเมลหลัก",
            "Secondary Email ID": "อีเมลรอง",
            "Mobile Number": "หมายเลขโทรศัพท์มือถือ",
            "Not Editable": "ไม่สามารถแก้ไข",
            "Alternative Mobile Number": "หมายเลขโทรศัพท์มือถือทางเลือก",
            "Base Location": "สถานที่ตั้งฐาน",
            Origin: "กำเนิด",
            "Address 1": "ที่อยู่ 1",
            "Address 2": "ที่อยู่ 2",
            "Address 3": "ที่อยู่ 3",
            Country: "ประเทศ",
            State: "รัฐ",
            City: "เมือง",
            "Postal Code": "รหัสไปรษณีย์",
            "Net Worth $": "มูลค่าสุทธิ $",
            "Source of Wealth": "แหล่งที่มาของความร่ำรวย",
            "Investable wealth $": "ความร่ำรวยที่ลงทุน $",
            "Risk appetite": "ความเสี่ยง",
            "Industry preference": "ความชอบในอุตสาหกรรม",
            "Financial goals": "เป้าหมายการเงิน",
            "Joining Fee $": "ค่าธรรมเนียมเข้าร่วม $",
            "Annual Subscription Fee $": "ค่าบริการรายปี $",
            "Current Investment Approach": "วิธีการลงทุนปัจจุบัน",
            "Take Survey": "ทำแบบสำรวจ",
            "Present Asset Allocations:": "การจัดสรรสินทรัพย์ปัจจุบัน:",
            ALL: "ทั้งหมด",
            EQUITIES: "หุ้น",
            "FIXED INCOME": "หนี้สิน",
            "CASH OR EQUIVALENT": "เงินสดหรือเทียบเท่า",
            "STRUCTURED PRODUCTS": "ผลิตภัณฑ์โครงสร้าง",
            "FUNDS AND ETFS": "กองทุนและ ETF",
            COMMODITIES: "สินค้าโภคภัณฑ์",
            "REAL ESTATE": "อสังหาริมทรัพย์",
            "ESG/IMPACT INVESTING": "ESG/การลงทุนที่มีผล",
            INSURANCE: "ประกัน",
            CRYPTO: "คริปโต",
            "PRIVATE EQUITY": "เอกวิชชันส่วนตัว",
            "GROWTH EQUITY": "เอกวิชชันการเติบโต",
            "VENTURE CAPITAL INVESTMENT": "การลงทุนในเวนเจอร์แคปิตอล",
            "PRIVATE CREDIT": "เครดิตส่วนตัว",
            "PRE-IPO PRODUCTS": "ผลิตภัณฑ์ก่อน IPO",
            "DIRECT DEAL PRODUCTS": "ผลิตภัณฑ์ดีเร็คท์ดีล",
            "OTHER ALTERNATIVES": "ทางเลือกอื่น",
            "Is my RM authorised to do transactions on my behalf?":
                "RM ของฉันได้รับอนุญาตให้ดำเนินการธุรกรรมแทนฉันหรือไม่?",
            YES: "ใช่",
            NO: "ไม่",
            Yes: "ใช่",
            No: "ไม่",
            "Profession Type": "ประเภทอาชีพ",
            Industry: "อุตสาหกรรม",
            "Domain / Sector": "โดเมน / ภาค",
            Turnover: "มูลค่าการขาย",
            "Is it a hereditary business?":
                "เป็นธุรกิจที่ถ่ายทอดมาจากพ่อแม่หรือไม่?",
            "Marital status": "สถานะภาพการสมรส",
            "Number of family members": "จำนวนสมาชิกในครอบครัว",
            "Family Members": "สมาชิกในครอบครัว",
            Personality: "บุคลิกภาพ",
            "Current Memberships": "สมาชิกปัจจุบัน",
            "Previous Vacations": "วันหยุดก่อนหน้า",
            "Proof of Identity": "หลักฐานแสดงตัวตน",
            "Proof of Address": "หลักฐานที่อยู่",
            "Proof of Tax Residency": "หลักฐานการอาศัยในประเทศ",
            "Proof of Wealth": "หลักฐานความร่ำรวย",
            "Other Documents": "เอกสารอื่น ๆ",
            "Document Type": "ประเภทเอกสาร",
            "ID Number": "หมายเลขบัตรประชาชน",
            "Expiry date": "วันหมดอายุ",
            "Upload documents": "อัปโหลดเอกสาร",
            "Zip code": "รหัสไปรษณีย์",
            Edit: "แก้ไข",
            Type: "ประเภท",
            "Product / Service Category": "หมวดหมู่ผลิตภัณฑ์ / บริการ",
            "Date of Purchase": "วันที่ซื้อ",
            Time: "เวลา",
            "Price($)": "ราคา($)",
            Quantity: "ปริมาณ",
            "Amount($)": "จำนวน($)",
            "Payment Ref": "อ้างอิงการชำระเงิน",
            Terminate: "สิ้นสุด",
            "Go To Order": "ไปที่คำสั่ง",
            Open: "เปิด",
            Completed: "เสร็จสิ้น",
            "Purchase Initiated": "เริ่มการซื้อ",
            "Aging Days": "วันที่เก่า",
            Vendor: "ผู้ขาย",
            "RM/Employee Id": "RM/รหัสพนักงาน",
            "Product Name": "ชื่อผลิตภัณฑ์",
            "Selling Price ($)": "ราคาขาย($)",
            "Investment Term (in Months)": "ระยะเวลาการลงทุน(เดือน)",
            "End Date": "วันที่สิ้นสุด",
            "Product Category": "หมวดหมู่ผลิตภัณฑ์",
            Region: "ภูมิภาค",
            Liquidity: "เงินสด",
            "Returns Level": "ระดับผลตอบแทน",
            "Risk Level": "ระดับความเสี่ยง",
            "Price ($)": "ราคา($)",
            "Take Rate": "อัตราการเก็บเงิน",
            "Client's Invested": "ลูกค้าลงทุน",
            Tags: "แท็ก",
            "Product Description": "คำอธิบายผลิตภัณฑ์",
            "Purchased Items": "รายการที่ซื้อ",
            "Current Quantity": "ปริมาณปัจจุบัน",
            "Current price ($)": "ราคาปัจจุบัน($)",
            "Active Buy/Sell": "ซื้อ/ขายที่ใช้งาน",
            "No Active buy/sell records found":
                "ไม่พบบันทึกการซื้อ/ขายที่ใช้งาน",
            "Product / Transaction Status": "สถานะผลิตภัณฑ์ / ธุรกรรม",
            ACTIVE: "ใช้งาน",
            INACTIVE: "ไม่ใช้งาน",
            WFA: "WFA",
            DELETED: "ลบ",
            NEW: "ใหม่",
            REPLIED: "ตอบกลับ",
            REJECTED: "ปฏิเสธ",
            SYSTEM: "ระบบ",
            CLIENT: "ลูกค้า",
            "FLAT RATE": "อัตราค่าบริการ",
            "MR.": "นาย",
            "MRS.": "นาง",
            "MS.": "นางสาว",
            "FINANCIAL PRODUCTS": "ผลิตภัณฑ์การเงิน",
            "LIFESTYLE PRODUCTS": "ผลิตภัณฑ์ไลฟ์สไตล์",
            "FINANCIAL SERVICES": "บริการทางการเงิน",
            "LIFESTYLE SERVICES": "บริการไลฟ์สไตล์",
            STEADY: "เรียบร้อย",
            AGGRESSIVE: "รุนแรง",
            MODERATE: "ปานกลาง",
            CREDIT: "เครดิต",
            DEBIT: "เดบิต",
            "LOW RISK": "ความเสี่ยงต่ำ",
            "MEDIUM RISK": "ความเสี่ยงปานกลาง",
            "HIGH RISK": "ความเสี่ยงสูง",
            "N/A": "ไม่ระบุ",
            "HIGH SCHOOL": "มัธยม",
            "UNDER GRADUATE": "ปริญญาตรี",
            "POST GRADUATE": "ปริญญาโท",
            "AEROSPACE AND DEFENSE": "อวกาศและการป้องกัน",
            "AGRICULTURE AND AGRIBUSINESS": "เกษตรและธุรกิจเกษตร",
            AUTOMOTIVE: "ยานยนต์",
            "BANKING AND FINANCE": "การเงินและการเงิน",
            CHEMICALS: "เคมี",
            "CONSTRUCTION AND INFRASTRUCTURE": "ก่อสร้างและสถาปัตยกรรม",
            "CONSUMER GOODS AND SERVICES": "สินค้าบริการบริษัท",
            "EDUCATION AND TRAINING": "การศึกษาและการฝึกอบรม",
            "ENERGY AND UTILITIES": "พลังงานและสาธารณูปโภค",
            "ENTERTAINMENT AND MEDIA": "บันเทิงและสื่อ",
            "HEALTHCARE AND PHARMACEUTICALS": "ดูแลสุขภาพและยา",
            "HOSPITALITY AND TOURISM": "การบริการและการท่องเที่ยว",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS":
                "เทคโนโลยีสารสนเทศและโทรคมนาคม",
            MANUFACTURING: "การผลิต",
            "MINING AND METALS": "การทำเหมืองและโลหะ",
            "REAL ESTATE AND PROPERTY": "อสังหาริมทรัพย์และทรัพย์สิน",
            RETAIL: "ค้าปลีก",
            "TRANSPORTATION AND LOGISTICS": "การขนส่งและโลจิสติกส์",
            "BIOTECHNOLOGY AND LIFE SCIENCES": "ชีววิทยาและวิทยาศาสตร์ชีวภาพ",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY":
                "เทคโนโลยีสะอาดและพลังงานทดแทน",
            "CONSUMER ELECTRONICS": "อิเล็กทรอนิกส์บริษัท",
            "ENVIRONMENTAL AND WASTE MANAGEMENT": "สิ่งแวดล้อมและการจัดการขยะ",
            "FOOD AND BEVERAGE": "อาหารและเครื่องดื่ม",
            "GOVERNMENT AND PUBLIC SERVICES": "รัฐบาลและบริการสาธารณะ",
            "LEGAL SERVICES": "บริการทางกฎหมาย",
            "NON-PROFIT AND SOCIAL ENTERPRISE":
                "องค์กรไม่แสวงหาผลกำไรและองค์กรสังคม",
            "PROFESSIONAL SERVICES": "บริการวิชาชีพ",
            "SECURITY AND CYBERSECURITY": "ความปลอดภัยและความปลอดภัยไซเบอร์",
            "TEXTILES AND APPAREL": "สิ่งทอและเสื้อผ้า",
            "RISK AVERSE": "เกรงกลัวความเสี่ยง",
            CONSERVATIVE: "อนุรักษ์",
            "WEALTH PRESERVATION": "การอนุรักษ์ความร่ำรวย",
            "CAPITAL APPRECIATION": "การประเมินมูลค่าทุน",
            "ESTATE PLANNING": "การวางแผนที่ดิน",
            "RISK MANAGEMENT": "การจัดการความเสี่ยง",
            "SUCCESSION PLANNING": "การวางแผนสืบทอด",
            "TAX PLANNING": "การวางแผนภาษี",
            ENTREPRENEURS: "ผู้ประกอบการ",
            INVESTORS: "นักลงทุน",
            "CORPORATE EXECUTIVES": "ผู้บริหารบริษัท",
            "FINANCIAL PROFESSIONALS": "ผู้เชี่ยวชาญด้านการเงิน",
            "ENTERTAINMENT PROFESSIONALS": "ผู้เชี่ยวชาญด้านบันเทิง",
            "PROFESSIONAL ATHLETES": "นักกีฬามืออาชีพ",
            "REAL ESTATE PROFESSIONALS": "ผู้เชี่ยวชาญด้านอสังหาริมทรัพย์",
            "TECH INDUSTRY PROFESSIONALS":
                "ผู้เชี่ยวชาญด้านอุตสาหกรรมเทคโนโลยี",
            "LUXURY GOODS AND SERVICES": "สินค้าและบริการหรูหรา",
            "ART AND COLLECTIBLES": "ศิลปะและสิ่งสะสม",
            PHILANTHROPY: "กุศล",
            EDUCATION: "การศึกษา",
            "HEALTH AND WELLNESS": "สุขภาพและความเป็นอยู่ที่ดี",
            TECHNOLOGY: "เทคโนโลยี",
            "SUSTAINABLE INVESTMENTS": "การลงทุนที่ยั่งยืน",
            SPORTS: "กีฬา",
            "TRAVEL AND HOSPITALITY": "การเดินทางและการต้อนรับ",
            MARRIED: "แต่งงาน",
            UNMARRIED: "โสด",
            DIVORCED: "หย่า",
            "WEALTH MANAGEMENT": "การจัดการความร่ำรวย",
            OTHERS: "อื่น ๆ",
            MALE: "ชาย",
            FEMALE: "หญิง",
            "Payment Initiated": "เริ่มการชำระเงิน",
            "Payment Reversed": "การชำระเงินถอนกลับ",
            "Payment Received": "การชำระเงินได้รับ",
            "Product Allocated": "ผลิตภัณฑ์ที่จัดสรร",
            "Sell Initiated": "เริ่มการขาย",
            "Product Sold": "ขายผลิตภัณฑ์",
            "Service Provisioned": "บริการที่จัดสรร",
            "Open - Purchase Initiated": "เปิด - เริ่มการซื้อ",
            "Open - Payment Initiated": "เปิด - เริ่มการชำระเงิน",
            "Open - Payment Reversed": "เปิด - การชำระเงินถอนกลับ",
            "Open - Payment Received": "เปิด - การชำระเงินได้รับ",
            "Completed - Product Allocated": "เสร็จสิ้น - ผลิตภัณฑ์ที่จัดสรร",
            "Open - Sell Initiated": "เปิด - เริ่มการขาย",
            "Completed - Product Sold": "เสร็จสิ้น - ขายผลิตภัณฑ์",
            "Completed - Service Provisioned": "เสร็จสิ้น - บริการที่จัดสรร",
            Bank: "ธนาคาร",
            Asset: "สินทรัพย์",
            Liability: "หนี้สิน",
            SPOUSE: "คู่สมรส",
            CHILD: "เด็ก",
            PARENT: "พ่อแม่",
            SIBLING: "พี่น้อง",
            OTHER: "อื่น ๆ",
            "BUYING A HOUSE": "ซื้อบ้าน",
            TRAVEL: "การเดินทาง",
            RETIREMENT: "การเกษียณ",
            "LEGACY PLANNING": "การวางแผนมรดก",
            NOVICE: "มือใหม่",
            INTERMEDIATE: "ระดับกลาง",
            EXPERT: "ผู้เชี่ยวชาญ",
            "SHORT TERM": "ระยะสั้น",
            "MEDIUM TERM": "ระยะกลาง",
            "LONG TERM": "ระยะยาว",
            "INVESTMENT STRATEGIES": "กลยุทธ์การลงทุน",
            "TRUST SERVICES": "บริการทรัสต์",
            "SPORTS AND FITNESS ACTIVITIES": "กิจกรรมกีฬาและการออกกำลังกาย",
            "ARTS AND CULTURE": "ศิลปะและวัฒนธรรม",
            "TRAVEL AND ADVENTURE": "การเดินทางและการผจญภัย",
            "MUSIC AND ENTERTAINMENT": "ดนตรีและบันเทิง",
            "FOOD AND DINING": "อาหารและอาหาร",
            "FASHION AND STYLE:": "แฟชั่นและสไตล์:",
            "TECHNOLOGY AND GADGETS": "เทคโนโลยีและเครื่องมือ",
            SALARY: "เงินเดือน",
            INVESTMENTS: "การลงทุน",
            "BUSINESS OWNERSHIP": "การเป็นเจ้าของธุรกิจ",
            CASH: "เงินสด",
            SAVINGS: "เงินออม",
            "MONEY MARKET ACCOUNTS": "บัญชีตลาดเงิน",
            STOCKS: "หุ้น",
            BONDS: "หนี้สิน",
            "MUTUAL FUNDS": "กองทุนรวม",
            "PENSION PLANS": "แผนบำนาญ",
            ANNUITIES: "เงินประกัน",
            ART: "ศิลปะ",
            COLLECTIBLES: "สิ่งสะสม",
            PROPERTIES: "คุณสมบัติ",
            "PERSONAL LOANS": "สินเชื่อส่วนบุคคล",
            "STUDENT LOANS": "สินเชื่อนักศึกษา",
            HOUSING: "ที่พัก",
            UTILITIES: "สาธารณูปโภค",
            TRANSPORTATION: "การขนส่ง",
            "PROPERTY INSURANCE": "ประกันทรัพย์สิน",
            "LIABILITY INSURANCE": "ประกันความรับผิด",
            "LEGAL ADVISORY": "ที่ปรึกษาทางกฎหมาย",
            "COMPLIANCE SERVICES": "บริการปฏิบัติตามกฎหมาย",
            "ACCOUNTING SERVICES": "บริการบัญชี",
            "LEVERAGE MANAGEMENT": "การจัดการเงินกู้",
            "REQUEST EXPERT ADVISORY MEETING": "ขอนัดหมายที่ปรึกษาผู้เชี่ยวชาญ",
            PASSPORT: "หนังสือเดินทาง",
            "IDENTITY CARD": "บัตรประจำตัว",
            "DRIVING LICENSE": "ใบขับขี่",
            "BANK STATEMENT": "รายการเงินธนาคาร",
            "UTILITY BILL": "ใบเสร็จค่าบริการสาธารณูปโภค",
            "PHONE BILL": "ใบเสร็จค่าโทรศัพท์",
            "TAX BILL": "ใบเสร็จภาษี",
            "RENTAL AGREEMENT": "สัญญาเช่า",
            "NORMAL DELIVERY": "การจัดส่งปกติ",
            INBOND: "อินบอนด์",
            "SPECIAL DELIVERY": "การจัดส่งพิเศษ",
            Latest: "ล่าสุด",
            Ascending: "เรียงลำดับ",
            Descending: "เรียงลำดับ",
            "Enable Access": "เปิดใช้งาน",
            "Disable Access": "ปิดการเข้าถึง",
            MFO: "MFO",
            SFO: "SFO"
        }
    },
    zh: {
        translation: {
            SUBQUESTION: "子问题",
            Answer: "答案",
            "Tool Used": "使用的工具",
            Show: "显示",
            Hide: "隐藏",
            "detailed analysis": "详细分析",
            "Query 5500+ stocks latest 10-K and 10-Q, get answer in seconds":
                "查询5500多只股票的最新10-K和10-Q，几秒钟内获得答案",
            "Upload your private data, organize it into folder and ask any questions about them.":
                "上传您的私人数据，将其整理到文件夹中，并询问有关它们的任何问题。",
            "Collection of tools helping you better manage and understand your portfolio.":
                "一组工具，帮助您更好地管理和了解您的投资组合。",
            "Gaia Agent allows you to run deep market research over more than 80.000 public securities (Equities, Bonds, Commodities, Crypto...)":
                "Gaia代理允许您对80,000多种公共证券（股票、债券、商品、加密货币等）进行深入的市场研究。",
            "Gaia Terminal": "Gaia终端",
            "Ask any question on 80.000+ financial instruments and let the agent do the work.":
                "询问80,000多种金融工具的任何问题，并让代理人处理",
            "Make sure to enter the exact ticker, especially for non US exchanges (ex: RELIANCE.NS)":
                "确保输入确切的股票代码，尤其是对于非美国交易所（例如：RELIANCE.NS）。",
            "Find out the key areas of revenue & Profit generation for Alphabet":
                "找出Alphabet的收入和利润产生的关键领域",
            "Explore how the treasury investments of Alphabet is distributed among various asset classes":
                "探索Alphabet的资金投资如何在各种资产类别中分布",
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends":
                "查看亚马逊最近财报的分析，包括任何值得注意的惊喜或趋势",
            "Find out the total assets and total liabilities of Apple":
                "找出苹果的总资产和总负债",
            "Check out the different tax obligation/provision on Apple":
                "查看苹果的不同税务义务/规定",
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation":
                "使用机器人分析Meta的研发费用占收入的百分比，并评估Meta对创新的承诺",
            "Figure out the revenue by geography for Meta":
                "找出Meta的地理收入",
            "Understand the major areas of capital expenditure for Microsoft":
                "了解Microsoft的主要资本支出领域",
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook":
                "找出最近监管变化对亚马逊通信业务运营和财务前景的影响",
            "Invest in a timeless designer handbag": "投资于永恒的设计师手提包",
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet":
                "投资于劳力士、菲利普等著名品牌的豪华手表",
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses":
                "穿着知名时装品牌的豪华外套或夹克，保持时尚和温暖",
            "What should i consider when selecting a luxury service vendor?":
                "选择豪华服务供应商时我应该考虑什么？",
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?":
                "豪华服务供应商是否可以定制其服务以满足特定需求或偏好？",
            "What type of certifications or credentials should luxury service vendors have?":
                "豪华服务供应商应具备哪些认证或证书？",
            "Evolution and Trends in Luxury Services": "豪华服务的发展和趋势",
            "Luxury Travel Experiences and Destination Management":
                "豪华旅行体验和目的地管理",
            "The Influence of social media on luxury services":
                "社交媒体对豪华服务的影响",
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.":
                "查看TSLA的同行。从中查看哪个市值最高。然后，在市值最高的股票代码上，从分析师那里获得最新的价格目标估计，并告诉我是谁以及估计是在什么日期做出的。告诉我分析师对这支股票是看涨还是看跌。",
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?":
                "使用最新可用数据对AMZN进行基本财务分析。您发现了什么有趣的地方？",
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?":
                "最近金价上涨的主要驱动因素是什么，这种趋势在中期内是可持续的吗？",
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?":
                "联邦储备利率上涨25个基点对我们的固定收益投资组合可能会产生什么影响？",
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?":
                "鉴于我们当前的投资组合构成（<需要提供投资组合构成>），利率大幅上升对我们的固定收益持有的潜在影响是什么？",
            "Research Assistant": "研究助理",
            "Fundamentals Analysis": "基本分析",
            "Market Research": "市场研究",
            "Financial Analysis": "财务分析",
            "Portfolio Risk Management": "投资组合风险管理",
            "Upload market researches, large reports, and get answer to your questions without having to read these documents":
                "上传市场研究、大型报告，并在无需阅读这些文档的情况下获得答案",
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...":
                "上传复杂金融产品的条款和招股说明书，Gaia将从中找到您需要的任何信息，如结算时间表、行使价格、敲出价格...",
            "Upload a large document, select it in the folder display, and the summarize button becomes active.":
                "上传大型文档，在文件夹显示中选择它，然后摘要按钮变为活动状态。",
            "Deep search through large documents": "通过大型文档进行深入搜索",
            "Analyze complex financial documents": "分析复杂的金融文件",
            "Summarize large documents": "总结大型文档",
            Suggestions: "建议",
            "Internal Data": "内部数据",
            "Upload your own files, order them across folders, and ask any question.":
                "上传您自己的文件，将它们按文件夹排序，并提出任何问题。",
            "Here are a few ideas of what you can do with Gaia:":
                "以下是您可以使用Gaia做的一些事情的一些建议：",
            Ideas: "想法",
            Topics: "主题",
            FAQ: "常见问题",
            Intraday: "日内",
            Daily: "每日",
            Weekly: "每周",
            Monthly: "每月",
            "LATEST QUOTE": "最新报价",
            "Open:": "开盘价：",
            "High:": "最高价：",
            "Low:": "最低价：",
            "Volume:": "成交量：",
            "Latest trading day:": "最新交易日：",
            "Previous close:": "前收盘价：",
            "MARKET NEWS": "市场新闻",
            Date: "日期",
            Title: "标题",
            Sentiment: "情绪",
            Source: "来源",
            "Share Information": "分享信息",
            Action: "操作",
            Admins: "管理员",
            Share: "分享",
            "Client Portfolio": "客户投资组合",
            "Client's": "客户的",
            Clients: "客户",
            "View Portfolio": "查看投资组合",
            "Gaia Agent History": "Gaia代理历史",
            "Click refresh button to update status of agents in progress":
                "单击刷新按钮以更新进行中的代理状态",
            "Documents in folder": "文件夹中的文档",
            File: "文件",
            Folder: "文件夹",
            "Pick Internal Data folder": "选择内部数据文件夹",
            "Summarize document": "总结文档",
            "Folder created successfully": "文件夹创建成功",
            "Folder ": "文件夹 ",
            " successfully deleted": " 成功删除",
            "File uploaded successfully": "文件上传成功",
            "Document deleted successfully": "文档删除成功",
            "Create Internal Data Folder": "创建内部数据文件夹",
            Save: "保存",
            Cancel: "取消",
            "Are you sure you want to delete the ": "您确定要删除 ",
            " folder?": " 文件夹？",
            Delete: "删除",
            "Upload New Document in Folder": "在文件夹中上传新文档",
            "Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf":
                "支持的文件：.doc、.docx、.txt、.rtf、.htm、.html、.pdf",
            "Notes regarding pdfs:": "有关pdf的说明：",
            "PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,":
                "图像pdf或使用自定义字体的PDF将使用字符识别技术加载。这限制了我们在此类文档中解析表格的能力。",
            "therefore we encourage you to submit such documents in alternative formats for better accuracy.":
                "因此，我们鼓励您以其他格式提交此类文档，以获得更好的准确性。",
            "Large pdf can take up to 5 mins to process, it is advised not to close this window.":
                "大型pdf可能需要长达5分钟的处理时间，建议不要关闭此窗口。",
            "Are you sure you want to delete this document?":
                "您确定要删除此文档吗？",
            "Select a Client's portfolio": "选择客户的投资组合",
            "Please select a client": "请选择一个客户",
            "Get Portfolio Recommendations": "获取投资组合建议",
            "Robo-Advisor (Asset Classes)": "Robo-顾问（资产类别）",
            "Pick a stock:": "选择一支股票：",
            Ticker: "股票代码",
            Compare: "比较",
            Client: "客户",
            DCA: "DCA",
            Financial: "财务",
            Lifestyle: "生活方式",
            Stocks: "股票",
            Portfolio: "投资组合",
            Agent: "代理",
            Bookmarks: "书签",
            History: "历史",
            Notifications: "通知",
            "Please select a Stock first": "请先选择一支股票",
            "Please select a folder first": "请先选择一个文件夹",
            "Please select a private equity first": "请先选择私募股权",
            "Please enter a question": "请输入问题",
            "Share to Admin": "分享给管理员",
            "Share to client": "分享给客户",
            "Useful response": "有用的回答",
            "Bad response": "不好的回答",
            "Get Stock Information": "获取股票信息",
            "Generate Graph": "生成图表",
            "Financial Data based research": "基于财务数据的研究",
            "Web based research": "基于网络的研究",
            "Send a message.": "发送消息。",
            "Name/Emp ID": "姓名/员工编号",
            Idea: "想法",
            "Folder Name": "文件夹名称",
            Dashboard: "仪表板",
            "Ask Gaia": "问Gaia",
            Product: "产品",
            Service: "服务",
            "My Prospects": "我的前景",
            "My Clients": "我的客户",
            Subscriptions: "订阅",
            Payments: "付款",
            FAQs: "常见问题",
            "User Management": "用户管理",
            "Vendor Management": "供应商管理",
            "Financial Products": "金融产品",
            "Lifestyle Products": "生活方式产品",
            "Financial Services": "金融服务",
            "Lifestyle Services": "生活方式服务",
            Campaign: "活动",
            Reports: "报告",
            "RM Performance": "RM绩效",
            "Customer Feedback": "客户反馈",
            "Order Management": "订单管理",
            "Ref.Data Management": "参考数据管理",
            "Chat with RM": "与RM聊天",
            Chat: "聊天",
            Bordeaux: "波尔多",
            "Bill of Materials": "物料清单",
            "Configure Freight Charges": "配置运费",
            "No. of Clients": "客户数量",
            "No. of RM's": "RM数量",
            "Total Partners/Vendors": "合作伙伴/供应商总数",
            "Total AUM": "总资产管理规模",
            "Invested Wealth": "投资财富",
            "Aggregate Asset Allocation (%)": "资产配置总计 (%)",
            "Aggregate Geographical Allocation (%)": "地理配置总计 (%)",
            "Aggregate Sector Allocation (%)": "行业配置总计 (%)",
            AUM: "资产管理规模",
            Revenue: "收入",
            Task: "任务",
            "Market News": "市场新闻",
            "Gaia Help": "Gaia帮助",
            All: "全部",
            Equities: "股票",
            "Fixed Income": "固定收益",
            "Cash Or Equivalent": "现金或等值物",
            "Structured Products": "结构化产品",
            "Funds and Etfs": "基金和ETF",
            Commodities: "商品",
            "Real Estate": "房地产",
            "Esg/Impact Investing": "ESG/影响投资",
            Insurance: "保险",
            Crypto: "加密货币",
            "Private Equity": "私募股权",
            "Growth Equity": "增长股权",
            "Venture Capital Investment": "风险投资",
            "Private Credit": "私人信贷",
            "Pre-Ipo Products": "IPO前产品",
            "Direct Deal Products": "直接交易产品",
            "Other Alternatives": "其他替代品",
            Search: "搜索",
            Filters: "筛选",
            Travel: "旅行",
            Wellness: "健康",
            Food: "食品",
            Health: "健康",
            Sports: "体育",
            Art: "艺术",
            Wine: "葡萄酒",
            "Live Trade": "实时交易",
            "Sign In": "登录",
            "Sign in": "登录",
            "Email address": "电子邮件地址",
            Password: "密码",
            "Forgot password?": "忘记密码？",
            "All Rights Reserved": "保留所有权利",
            Currency: "货币",
            "My Profile": "我的资料",
            "Change Password": "更改密码",
            Logout: "登出",
            Status: "状态",
            Active: "活跃",
            Actions: "操作",
            Profile: "资料",
            Transactions: "交易",
            "Client Name": "客户姓名",
            "Invested Wealth ($)": "投资财富（$）",
            "Investible Wealth ($)": "可投资财富（$）",
            "Revenue ($)": "收入（$）",
            Personal: "个人",
            Professional: "专业",
            Family: "家庭",
            Interest: "兴趣",
            KYC: "KYC",
            Salutation: "称谓",
            "First Name": "名字",
            "Last Name": "姓",
            "D.O.B": "出生日期",
            Gender: "性别",
            "Highest Education": "最高学历",
            "Primary Email ID": "主要电子邮件地址",
            "Secondary Email ID": "次要电子邮件地址",
            "Mobile Number": "手机号码",
            "Not Editable": "不可编辑",
            "Alternative Mobile Number": "备用手机号码",
            "Base Location": "基地位置",
            Origin: "起源",
            "Address 1": "地址1",
            "Address 2": "地址2",
            "Address 3": "地址3",
            Country: "国家",
            State: "州",
            City: "城市",
            "Postal Code": "邮政编码",
            "Net Worth $": "净值 $",
            "Source of Wealth": "财富来源",
            "Investable wealth $": "可投资财富 $",
            "Risk appetite": "风险偏好",
            "Industry preference": "行业偏好",
            "Financial goals": "财务目标",
            "Joining Fee $": "加入费 $",
            "Annual Subscription Fee $": "年度订阅费 $",
            "Current Investment Approach": "当前投资方法",
            "Take Survey": "参加调查",
            "Present Asset Allocations:": "目前资产配置：",
            ALL: "全部",
            EQUITIES: "股票",
            "FIXED INCOME": "固定收益",
            "CASH OR EQUIVALENT": "现金或等值物",
            "STRUCTURED PRODUCTS": "结构化产品",
            "FUNDS AND ETFS": "基金和ETF",
            COMMODITIES: "商品",
            "REAL ESTATE": "房地产",
            "ESG/IMPACT INVESTING": "ESG/影响投资",
            INSURANCE: "保险",
            CRYPTO: "加密货币",
            "PRIVATE EQUITY": "私募股权",
            "GROWTH EQUITY": "增长股权",
            "VENTURE CAPITAL INVESTMENT": "风险投资",
            "PRIVATE CREDIT": "私人信贷",
            "PRE-IPO PRODUCTS": "IPO前产品",
            "DIRECT DEAL PRODUCTS": "直接交易产品",
            "OTHER ALTERNATIVES": "其他替代品",
            "Is my RM authorised to do transactions on my behalf?":
                "我的RM是否被授权代表我进行交易？",
            YES: "是",
            NO: "否",
            Yes: "是",
            No: "否",
            "Profession Type": "职业类型",
            Industry: "行业",
            "Domain / Sector": "领域/部门",
            Turnover: "营业额",
            "Is it a hereditary business?": "这是一家世袭企业吗？",
            "Marital status": "婚姻状况",
            "Number of family members": "家庭成员数量",
            "Family Members": "家庭成员",
            Personality: "个性",
            "Current Memberships": "当前会员资格",
            "Previous Vacations": "以前的假期",
            "Proof of Identity": "身份证明",
            "Proof of Address": "地址证明",
            "Proof of Tax Residency": "税收居民证明",
            "Proof of Wealth": "财富证明",
            "Other Documents": "其他文件",
            "Document Type": "文件类型",
            "ID Number": "ID号码",
            "Expiry date": "到期日期",
            "Upload documents": "上传文件",
            "Zip code": "邮政编码",
            Edit: "编辑",
            Type: "类型",
            "Product / Service Category": "产品/服务类别",
            "Date of Purchase": "购买日期",
            Time: "时间",
            "Price($)": "价格($)",
            Quantity: "数量",
            "Amount($)": "金额($)",
            "Payment Ref": "付款参考",
            Terminate: "终止",
            "Go To Order": "转到订单",
            Open: "打开",
            Completed: "已完成",
            "Purchase Initiated": "购买已启动",
            "Aging Days": "老化天数",
            Vendor: "供应商",
            "RM/Employee Id": "RM/员工编号",
            "Product Name": "产品名称",
            "Selling Price ($)": "销售价格($)",
            "Investment Term (in Months)": "投资期限（月）",
            "End Date": "结束日期",
            "Product Category": "产品类别",
            Region: "地区",
            Liquidity: "流动性",
            "Returns Level": "回报水平",
            "Risk Level": "风险水平",
            "Price ($)": "价格($)",
            "Take Rate": "接受率",
            "Client's Invested": "客户的投资",
            Tags: "标签",
            "Product Description": "产品描述",
            "Purchased Items": "已购买的物品",
            "Current Quantity": "当前数量",
            "Current price ($)": "当前价格($)",
            "Active Buy/Sell": "活跃买/卖",
            "No Active buy/sell records found": "未找到活跃的买/卖记录",
            "Product / Transaction Status": "产品/交易状态",
            ACTIVE: "活跃",
            INACTIVE: "不活跃",
            WFA: "等待批准",
            DELETED: "已删除",
            NEW: "新",
            REPLIED: "已回复",
            REJECTED: "已拒绝",
            SYSTEM: "系统",
            CLIENT: "客户",
            "FLAT RATE": "固定费率",
            "MR.": "先生",
            "MRS.": "夫人",
            "MS.": "小姐",
            "FINANCIAL PRODUCTS": "金融产品",
            "LIFESTYLE PRODUCTS": "生活方式产品",
            "FINANCIAL SERVICES": "金融服务",
            "LIFESTYLE SERVICES": "生活方式服务",
            STEADY: "稳定",
            AGGRESSIVE: "激进",
            MODERATE: "适度",
            CREDIT: "信用",
            DEBIT: "借记",
            "LOW RISK": "低风险",
            "MEDIUM RISK": "中风险",
            "HIGH RISK": "高风险",
            "N/A": "不适用",
            "HIGH SCHOOL": "高中",
            "UNDER GRADUATE": "本科",
            "POST GRADUATE": "研究生",
            "AEROSPACE AND DEFENSE": "航空航天和国防",
            "AGRICULTURE AND AGRIBUSINESS": "农业和农业企业",
            AUTOMOTIVE: "汽车",
            "BANKING AND FINANCE": "银行和金融",
            CHEMICALS: "化学品",
            "CONSTRUCTION AND INFRASTRUCTURE": "建筑和基础设施",
            "CONSUMER GOODS AND SERVICES": "消费品和服务",
            "EDUCATION AND TRAINING": "教育和培训",
            "ENERGY AND UTILITIES": "能源和公用事业",
            "ENTERTAINMENT AND MEDIA": "娱乐和媒体",
            "HEALTHCARE AND PHARMACEUTICALS": "医疗保健和制药",
            "HOSPITALITY AND TOURISM": "酒店业和旅游业",
            "INFORMATION TECHNOLOGY AND TELECOMMUNICATIONS": "信息技术和电信",
            MANUFACTURING: "制造业",
            "MINING AND METALS": "采矿和金属",
            "REAL ESTATE AND PROPERTY": "房地产和物业",
            RETAIL: "零售",
            "TRANSPORTATION AND LOGISTICS": "运输和物流",
            "BIOTECHNOLOGY AND LIFE SCIENCES": "生物技术和生命科学",
            "CLEAN TECHNOLOGY AND RENEWABLE ENERGY": "清洁技术和可再生能源",
            "CONSUMER ELECTRONICS": "消费电子",
            "ENVIRONMENTAL AND WASTE MANAGEMENT": "环境和废物管理",
            "FOOD AND BEVERAGE": "食品和饮料",
            "GOVERNMENT AND PUBLIC SERVICES": "政府和公共服务",
            "LEGAL SERVICES": "法律服务",
            "NON-PROFIT AND SOCIAL ENTERPRISE": "非营利组织和社会企业",
            "PROFESSIONAL SERVICES": "专业服务",
            "SECURITY AND CYBERSECURITY": "安全和网络安全",
            "TEXTILES AND APPAREL": "纺织品和服装",
            "RISK AVERSE": "风险规避",
            CONSERVATIVE: "保守",
            "WEALTH PRESERVATION": "财富保值",
            "CAPITAL APPRECIATION": "资本增值",
            "ESTATE PLANNING": "遗产规划",
            "RISK MANAGEMENT": "风险管理",
            "SUCCESSION PLANNING": "继任计划",
            "TAX PLANNING": "税务规划",
            ENTREPRENEURS: "企业家",
            INVESTORS: "投资者",
            "CORPORATE EXECUTIVES": "公司高管",
            "FINANCIAL PROFESSIONALS": "金融专业人士",
            "ENTERTAINMENT PROFESSIONALS": "娱乐专业人士",
            "PROFESSIONAL ATHLETES": "职业运动员",
            "REAL ESTATE PROFESSIONALS": "房地产专业人士",
            "TECH INDUSTRY PROFESSIONALS": "科技行业专业人士",
            "LUXURY GOODS AND SERVICES": "奢侈品和服务",
            "ART AND COLLECTIBLES": "艺术和收藏品",
            PHILANTHROPY: "慈善事业",
            EDUCATION: "教育",
            "HEALTH AND WELLNESS": "健康和健康",
            TECHNOLOGY: "技术",
            "SUSTAINABLE INVESTMENTS": "可持续投资",
            SPORTS: "体育",
            "TRAVEL AND HOSPITALITY": "旅行和款待",
            MARRIED: "已婚",
            UNMARRIED: "未婚",
            DIVORCED: "离婚",
            "WEALTH MANAGEMENT": "财富管理",
            OTHERS: "其他",
            MALE: "男性",
            FEMALE: "女性",
            "Payment Initiated": "付款已启动",
            "Payment Reversed": "付款已撤销",
            "Payment Received": "已收到付款",
            "Product Allocated": "产品已分配",
            "Sell Initiated": "卖出已启动",
            "Product Sold": "产品已售出",
            "Service Provisioned": "服务已提供",
            "Open - Purchase Initiated": "打开 - 购买已启动",
            "Open - Payment Initiated": "打开 - 付款已启动",
            "Open - Payment Reversed": "打开 - 付款已撤销",
            "Open - Payment Received": "打开 - 已收到付款",
            "Completed - Product Allocated": "已完成 - 产品已分配",
            "Open - Sell Initiated": "打开 - 卖出已启动",
            "Completed - Product Sold": "已完成 - 产品已售出",
            "Completed - Service Provisioned": "已完成 - 服务已提供",
            Bank: "银行",
            Asset: "资产",
            Liability: "负债",
            SPOUSE: "配偶",
            CHILD: "孩子",
            PARENT: "父母",
            SIBLING: "兄弟姐妹",
            OTHER: "其他",
            "BUYING A HOUSE": "购房",
            TRAVEL: "旅行",
            RETIREMENT: "退休",
            "LEGACY PLANNING": "遗产规划",
            NOVICE: "新手",
            INTERMEDIATE: "中级",
            EXPERT: "专家",
            "SHORT TERM": "短期",
            "MEDIUM TERM": "中期",
            "LONG TERM": "长期",
            "INVESTMENT STRATEGIES": "投资策略",
            "TRUST SERVICES": "信托服务",
            "SPORTS AND FITNESS ACTIVITIES": "体育和健身活动",
            "ARTS AND CULTURE": "艺术和文化",
            "TRAVEL AND ADVENTURE": "旅行和冒险",
            "MUSIC AND ENTERTAINMENT": "音乐和娱乐",
            "FOOD AND DINING": "食品和餐饮",
            "FASHION AND STYLE:": "时尚和风格：",
            "TECHNOLOGY AND GADGETS": "技术和小工具",
            SALARY: "工资",
            INVESTMENTS: "投资",
            "BUSINESS OWNERSHIP": "企业所有权",
            CASH: "现金",
            SAVINGS: "储蓄",
            "MONEY MARKET ACCOUNTS": "货币市场账户",
            STOCKS: "股票",
            BONDS: "债券",
            "MUTUAL FUNDS": "共同基金",
            "PENSION PLANS": "养老金计划",
            ANNUITIES: "年金",
            ART: "艺术",
            COLLECTIBLES: "收藏品",
            PROPERTIES: "属性",
            "PERSONAL LOANS": "个人贷款",
            "STUDENT LOANS": "学生贷款",
            HOUSING: "住房",
            UTILITIES: "公用事业",
            TRANSPORTATION: "交通",
            "PROPERTY INSURANCE": "财产保险",
            "LIABILITY INSURANCE": "责任保险",
            "LEGAL ADVISORY": "法律咨询",
            "COMPLIANCE SERVICES": "合规服务",
            "ACCOUNTING SERVICES": "会计服务",
            "LEVERAGE MANAGEMENT": "杠杆管理",
            "REQUEST EXPERT ADVISORY MEETING": "请求专家咨询会议",
            PASSPORT: "护照",
            "IDENTITY CARD": "身份证",
            "DRIVING LICENSE": "驾驶执照",
            "BANK STATEMENT": "银行对账单",
            "UTILITY BILL": "水电费账单",
            "PHONE BILL": "电话费账单",
            "TAX BILL": "税单",
            "RENTAL AGREEMENT": "租赁协议",
            "NORMAL DELIVERY": "正常交付",
            INBOND: "入境",
            "SPECIAL DELIVERY": "特殊交付",
            Latest: "最新",
            Ascending: "升序",
            Descending: "降序",
            "Enable Access": "启用访问",
            "Disable Access": "禁用访问",
            MFO: "MFO",
            SFO: "SFO"
        }
    }
}
