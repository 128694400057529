import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import InvestorTypeRadioGroup from "./InvestorTypeRadioGroup"
import FormWrapper from "../../components/FormWrapper"
import FormAction from "../../components/FormAction"
import { goToNextInvestorType } from "redux/slices/selfOnboardSlice"

const title = "Investor type"
const subtitle =
    "Please let us know what type of investor you are. This helps us tailor the onboarding experience to gather the right information based on your investor profile."
const validationSchema = Yup.object({
    investorType: Yup.string()
})

export default function InvestorType() {
    const dispatch = useDispatch()
    const { investorType } = useSelector((state) => state.selfOnboard)

    const handleNext = (data) => {
        dispatch(goToNextInvestorType(data))
    }

    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={{ investorType }}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    <Field
                        name="investorType"
                        component={InvestorTypeRadioGroup}
                    />
                    <FormAction />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
