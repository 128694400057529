import {  useEffect } from "react"
import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Typography
} from "@mui/material"
import { useField } from "formik"
import { useDispatch } from "react-redux"
import { setInvestorType } from "redux/slices/selfOnboardSlice"

const options = [
    {
        value: "INDIVIDUAL",
        label: "Individual",
        subtext: "For direct individual accounts and personal trusts"
    },
    {
        value: "ENTITY",
        label: "Entity/Trust/Foundation",
        subtext:
            "For businesses like family offices, holding companies, and corporations"
    }
]

export default function InvestorTypeRadioGroup({
    field,
    name,
    children,
    ...props
}) {
    const dispatch = useDispatch()

    const fieldName = name || field.name

    useEffect(() => {
        dispatch(setInvestorType(field.value))
    }, [field.value, dispatch])

    return (
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            {...field}
            {...props}
            name={fieldName}
        >
            {renderOptions()}
        </RadioGroup>
    )
}

const renderOptions = () => {
    return options.map((option) => (
        <InvestorType
            key={option.value}
            value={option.value}
            label={option.label}
            subtext={option.subtext}
        />
    ))
}

const InvestorType = ({ value, label, subtext }) => {
    const [field] = useField("investorType")
    return (
        <Box
            sx={{
                border: "2px solid",
                borderRadius: 1,
                py: 2,
                px: 3,
                mb: 2,
                borderColor: field.value === value ? "brand.main" : "gray.200"
            }}
        >
            <FormControlLabel
                sx={{ display: "block" }}
                value={value}
                control={<Radio color="brand" />}
                label={label}
            />
            <Typography variant="body2" sx={{ color: "gray.500" }}>
                {subtext}
            </Typography>
        </Box>
    )
}
