import { Box, Typography } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const OnboardSuccess = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 4
            }}
        >
            <Box
                sx={{
                    maxWidth: "70ch",
                    bgcolor: "gray.50",
                    p: 5,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                <CheckCircleIcon
                    sx={{ fontSize: 70, mb: 2, color: "green.600" }}
                />
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 600, color: "gray.700", mb: 1 }}
                >
                    Thank you for registering with KairosWealth!
                </Typography>
                <Typography variant="section2" sx={{ maxWidth: "70ch" }}>
                    Your application has been submitted for review. You will
                    receive a confirmation email shortly. If you don’t see it in
                    your inbox within 5 minutes, please check your spam or junk
                    folder.
                </Typography>
            </Box>
        </Box>
    )
}
export default OnboardSuccess
