import { FormModalBordeauxFilter } from "../FormModalBordeaux/FormModalBordeaux" // eslint-disable-next-line

const {
    formFieldbordeauxFilters: {
        regionFilter,
        vintageFilter,
        growerFilter,
        colorFilter
    }
} = FormModalBordeauxFilter

export const fieldValuesBordeauxFilters = {
    [regionFilter.name]: "",
    [vintageFilter.name]: "",
    [growerFilter.name]: "",
    [colorFilter.name]: ""
}
