import * as React from "react"
import { Grid, Button, Card, CardContent, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import "../../../theme/styles/ProductsCard.css"
import { UseCurrencyHook } from "../../common/GlobalCurrency/useCurrencyHook"

export default function BordeauxProductsCard(props) {
    const navigate = useNavigate()
    const navigateToproducts = async (value) => {
        navigate("/bordeaux/productdetails", { state: { value } })
    }

    return (
        <Card
            variant="outlined"
            sx={{
                background:
                    "linear-gradient(61deg, #D2C6FC -0.84%, #9FB3F0 83.4%)",
                "& .MuiCardContent-root": {
                    paddingLeft: "15px !important",
                    paddingBottom: "0px !important",
                    paddingRight: "12px !important"
                }
            }}
            className="cardStyles"
        >
            {" "}
            <CardContent
                sx={{
                    "& .MuiCardContent-root": {
                        padding: "15px !important",
                        paddingBottom: "0px !important"
                    }
                }}
            >
                <Grid
                    container
                    flexDirection="row"
                    justifyContent={"space-between"}
                >
                    <Grid item xs={11} lg={11} md={9}>
                        <Typography
                            title={"Vintage " + props?.data?.vintage}
                            gutterBottom
                            style={{
                                color: "#1e1e1e",
                                overflow: "hidden",
                                fontSize: "10px",
                                textOverflow: "ellipsis"
                            }}
                        >
                            Vintage {props?.data?.vintage}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    style={{ marginTop: "15px" }}
                    flexDirection="column"
                >
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography
                            noWrap
                            title={props?.data.name}
                            id="Bordeaux-productName"
                            className="typoGraphyStylesProducts"
                            sx={{ color: "#1e1e1e !important" }}
                        >
                            {props?.data?.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container flexDirection="column">
                    <Grid item xs={11} lg={11} md={11}>
                        <Typography
                            noWrap
                            title={`Pack: ${props?.data?.pack} - Size: ${props?.data?.size}cl`}
                        >
                            <span
                                style={{
                                    color: "#1e1e1e",
                                    fontFamily: "Mona Sans",
                                    fontSize: "12px",
                                    letterSpacing: " 0.41px"
                                }}
                            >
                                Pack: {props?.data?.pack} - Size:{" "}
                                {props?.data?.size}cl
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    style={{ marginTop: "17px" }}
                    flexDirection="row"
                    justifyContent={"space-between"}
                    p={"5px"}
                >
                    <Grid item>
                        <Grid
                            container
                            flexDirection={"column"}
                            sx={{ marginTop: "2px" }}
                        >
                            <Grid item>
                                <Typography
                                    title={"Offer Value"}
                                    style={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        fontSize: "10px",
                                        color: "#1e1e1e"
                                    }}
                                >
                                    Offer Value
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    title={props?.data?.offer_price}
                                    style={{
                                        color: "#1e1e1e",
                                        fontSize: "10px",
                                        textOverflow: "ellipsis",
                                        fontWeight: "700",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden"
                                    }}
                                >
                                    <UseCurrencyHook
                                        val={props?.data?.offer_price}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className="gridStyle">
                        <Button
                            sx={{
                                float: "right",
                                fontSize: "12px",
                                fontWeight: 800,
                                height: "60%",
                                backgroundColor: "#491cbe",
                                color: "#ffffff",
                                justifyContent: "center",
                                borderRadius: "40px",
                                marginTop: "8px"
                            }}
                            onClick={() => navigateToproducts(props?.data)}
                        >
                            View
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
