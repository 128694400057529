import React from "react"
import "./Logout.css"
import { clearLocalStorageExceptPersistRoot } from "../../../constant/ClearLocalStorage"
import Dialog from "@mui/material/Dialog"
import { useSelector, useDispatch } from "react-redux"
import { setOpenLogout, setCloseLogout } from "../../../redux/slices/navSlice"
import { useNavigate } from "react-router-dom"
import { logOut } from "../../../services/index"
import "../../../theme/styles/globalStyles.css"

function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const openLog = useSelector((state) => state.nav.openLogout)
    console.log(openLog, "open")
    const handleClose = () => {
        dispatch(setOpenLogout())
    }
    const user = JSON.parse(window.localStorage.getItem("user"))
    const accessToken = user.accessToken

    const handleLogout = () => {
        logOut({ accessToken: accessToken, userId: user?.userId })
            .then((res) => {
                localStorage.removeItem("user")
                localStorage.removeItem("refreshToken")
                // localStorage.clear();
                clearLocalStorageExceptPersistRoot()
                navigate(`/login`)
                window.location.reload()
                // }
            })
            .catch((error) => {
                localStorage.removeItem("user")
                localStorage.removeItem("refreshToken")
                //   localStorage.clear(); clear local storage except Presist data
                clearLocalStorageExceptPersistRoot()
                navigate(`/login`)
                window.location.reload()
            })
    }
    return (
        <>
            <Dialog
                onClose={handleClose}
                open={openLog}
                hideBackdrop
                sx={{ borderRadius: "12px" }}
            >
                <div style={{ padding: "30px 50px", textAlign: "center" }}>
                    <h3
                        style={{
                            fontSize: "20px",
                            color: "#1e1e1e",
                            fontWeight: "500"
                        }}
                    >
                        LOG OUT
                    </h3>
                    <p
                        style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#1e1e1e"
                        }}
                    >
                        Are you sure you want to log out?
                    </p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "45px"
                        }}
                    >
                        <button
                            className="yes-b"
                            onClick={handleLogout}
                            style={{ cursor: "pointer" }}
                        >
                            Yes
                        </button>
                        <button
                            className="no-b"
                            onClick={() => dispatch(setCloseLogout())}
                            style={{ cursor: "pointer" }}
                        >
                            No
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Logout
