import React from "react"
import { at } from "lodash"
import { useField } from "formik"
import { Box, Typography, Select } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import Chip from "@mui/material/Chip"
export default function SelectInputMultiple(props) {
    const { label, data, ...rest } = props
    const [field, meta] = useField(props)
    const { value: selectedValue } = field
    const [touched, error] = at(meta, "touched", "error")
    const isError = touched && error && true
    function _renderHelperText() {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>
        }
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
                variant="h6"
                style={{
                    marginTop: "5px",
                    fontSize: "14px",
                    fontFamily: props?.fontCustom
                        ? props?.fontCustom
                        : "Mona Sans",
                    marginBottom: "15px"
                }}
            >
                {props.header}
            </Typography>
            <FormControl {...rest} error={isError}>
                <Select
                    multiple
                    sx={{
                        "& fieldset": {
                            border: props.readOnly ? "none" : "block"
                        },
                        height: props?.fixedHeight
                            ? props?.fixedHeight + "px"
                            : "40px",
                        fontWeight: "bold",

                        width: props?.fixedwidth
                            ? props?.fixedwidth + "px"
                            : "250px",

                        "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                                fontFamily: "Mona Sans !important",
                                fontWeight: "600px"
                            },
                        "& .MuiSelect-select .notranslate::after": {
                            fontFamily: "Mona Sans !important",
                            textOverflow: "ellipsis !important",
                            opacity: 1,
                            content: props?.placeholder
                                ? `${JSON.stringify(props?.placeholder)}`
                                : '"Select"',
                            color: "#BBC5D5 !important"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "8px",
                            fontFamily: "Mona Sans !important"
                        },
                        "& input::placeholder": {
                            fontFamily: "Mona Sans",
                            textOverflow: "ellipsis !important",
                            color: "#BBC5D5 !important",
                            opacity: 1
                        },
                        backgroundColor: "#ffffff",
                        fontFamily: "Mona Sans !important",
                        iconFilled: "filled"
                    }}
                    {...field}
                    value={selectedValue ? selectedValue : []}
                    inputProps={{ readOnly: props?.readOnly }}
                    input={<OutlinedInput id="select-multiple-chip" />}
                    renderValue={(selected) =>
                        selected.map((value) => (
                            <Chip
                                key={value}
                                label={
                                    data?.find((e) => e.id === value)
                                        .description
                                }
                            />
                        ))
                    }
                >
                    {/* <MenuItem selected disabled default value="">
                        <em style={{
                            fontFamily: "Mona Sans",
                            textOverflow: 'ellipsis !important',
                            color: '#BBC5D5 !important',
                            opacity: 1,
                        }}>{props?.placeholder ? props?.placeholder : "Select"}</em>
                    </MenuItem> */}

                    {data &&
                        data?.map((item, index) => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox
                                    checked={
                                        selectedValue?.indexOf(item.id) > -1
                                    }
                                    color="customColor"
                                />
                                {item.description}
                            </MenuItem>
                        ))}
                </Select>

                {_renderHelperText()}
            </FormControl>
        </Box>
    )
}
