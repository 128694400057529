import React from "react"
import { Box, Typography, MenuItem, Avatar } from "@mui/material"
import { formatRelativeTime } from "../../common/Utils/DateFormat"
import NotificationIcon from "@mui/icons-material/CampaignOutlined"

const NotificationItem = ({ notification, handleNotificationClick }) => {
    return (
        <MenuItem
            onClick={() => handleNotificationClick(notification)}
            sx={{
                borderBottom: "1px solid #E7E7E7",
                py: "16px",
                display: "flex",
                columnGap: "32px",
                ":hover": {
                    backgroundColor: "#F8F8F8"
                }
            }}
        >
            <Avatar sx={{ backgroundColor: "#F0EBFF", color: "#643DD6" }}>
                <NotificationIcon />
            </Avatar>
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        textTransform: "capitalize",
                        mb: "8px",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            whiteSpace: "pre-line",
                            overflowWrap: "break-word",
                            font: "inherit",
                            color: "#1e1e1e",
                            fontWeight: 500,
                            fontSize: "1rem",
                            textTransform: "capitalize"
                        }}
                    >
                        {notification.senderName}
                    </Typography>
                    <Typography
                        sx={{
                            font: "inherit",
                            fontSize: "10px",
                            color: "#777777"
                        }}
                    >
                        {formatRelativeTime(notification?.createdAt)}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        whiteSpace: "pre-line",
                        overflowWrap: "break-word",
                        fontSize: "14px",
                        color: "#1e1e1e"
                    }}
                >
                    {notification.message}
                </Typography>
                {!notification.isRead && (
                    <Typography
                        sx={{
                            fontSize: "12px",
                            mt: "8px",
                            fontWeight: 400,
                            color: "yellow.600",
                            backgroundColor: "yellow.50",
                            display: "inline-block",
                            padding: "2px 8px",
                            borderRadius: "999px"
                        }}
                    >
                        Unread
                    </Typography>
                )}
            </Box>
        </MenuItem>
    )
}
export default NotificationItem
