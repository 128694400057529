import { Button as BtnLib } from "@mui/material"

const ButtonWrapper = ({
    startIcon: Icon,
    endIcon: EndIcon,
    sx,
    children,
    ...props
}) => {
    return (
        <BtnLib
            color="brand"
            startIcon={Icon && <Icon />}
            endIcon={EndIcon && <EndIcon/>}
            sx={{
                borderRadius: "999px",
                padding: "10px 30px",
                "&.MuiButton-outlined": {
                    border: "none",
                    backgroundColor: "gray.100"
                },
                ...sx
            }}
            {...props}
        >
            {children}
        </BtnLib>
    )
}
export default ButtonWrapper
