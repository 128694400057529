export const TABLE_ROWS_PER_PAGE = 7 // ** 8 because it is the max record that can fit screen without scroll

export const RESPONSIVE_MODES = {
    XL: "XL",
    LG: "LG",
    MD: "MD",
    SM: "SM"
}

export const REGEX = {
    mobileNumber: /^\d+$/,
    postalCode: /^\d{1,8}$/,
    id: /^[\d\w-]+$/
}
