import { useState } from "react"
import { useNavigate } from "react-router-dom"
import GiaSidebar from "./SidebarLayout"
import { Grid, Box } from "@mui/material"
import MobileTopBar from "./MobileTopBar"

const drawerWidth = 340

const GaiaPageLayout = ({ children }) => {
    const navigate = useNavigate()
    const [mobileOpen, setMobileOpen] = useState(false)
    const [isClosing, setIsClosing] = useState(false)

    const handleDrawerClose = () => {
        setIsClosing(true)
        setMobileOpen(false)
    }

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false)
    }

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen)
        }
    }
    return (
        <Box sx={{ display: "flex", color: "gray.600" }}>
            <GiaSidebar
                handleDrawerClose={handleDrawerClose}
                mobileOpen={mobileOpen}
                handleDrawerTransitionEnd={handleDrawerTransitionEnd}
                drawerWidth={drawerWidth}
            />
            <MobileTopBar
                drawerWidth={drawerWidth}
                handleDrawerToggle={handleDrawerToggle}
                navigate={navigate}
            />

            <Box sx={{ flexGrow: 1 }}>
                <Grid>
                    <Grid
                        display="flex"
                        style={{
                            overflow: "auto",
                            height: "100vh",
                            width: "100%"
                        }}
                    >
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default GaiaPageLayout
