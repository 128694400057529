import { fetchOnboardDropdowns } from "services/prospectsServices"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { storeDropdowns } from "redux/slices/selfOnboardSlice"
import { sortInAscendingOrder } from "constant/helpersUtility"
import { toast } from "react-toastify"

export const useSelfOnboardDropdown = () => {
    const { get } = useSelfOnboardApi()
    const dispatch = useDispatch()
    const {
        token,
        dropDowns: { riskAppetites }
    } = useSelector((state) => state.selfOnboard)

    useEffect(() => {
        if (riskAppetites.length > 0) return

        const _fetchData = async () => {
            const resp = await get({
                apiCaller: fetchOnboardDropdowns,
                params: { token }
            })

            if (resp?.code === "ERR_NETWORK") {
                toast.error("Network error, please try again later !", {
                    toastId: resp?.code
                })
                return
            }

            const {
                countries,
                risk_appetite_values,
                financial_goals_values,
                industry_preference_values
            } = resp?.at(0)

            const countryList = countries.map((country) => ({
                label: country.at(1),
                id: country.at(0),
                code: country.at(2)
            }))

            const financialGoals = financial_goals_values.map((finGoal) => ({
                label: finGoal.at(1),
                value: finGoal.at(0)
            }))
            const industryPreferences = industry_preference_values.map(
                (indPref) => ({ label: indPref.at(1), value: indPref.at(0) })
            )
            const riskAppetites = risk_appetite_values.map((riskAppetite) => ({
                label: riskAppetite.at(1),
                value: riskAppetite.at(0)
            }))

            const payload = {
                countries: sortInAscendingOrder(countryList),
                financialGoals: sortInAscendingOrder(financialGoals),
                industryPreferences: sortInAscendingOrder(industryPreferences),
                riskAppetites: sortInAscendingOrder(riskAppetites)
            }
            dispatch(storeDropdowns(payload))
        }
        _fetchData()
    }, [riskAppetites.length, dispatch, get, token])
}
