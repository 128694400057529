
import { Box, Drawer } from "@mui/material"
import SidebarContent from "../ChatGptSidebar"

const GiaSidebar = ({
    window,
    handleDrawerClose,
    handleDrawerTransitionEnd,
    mobileOpen,
    drawerWidth
}) => {
    const container =
        window !== undefined ? () => window().document.body : undefined
    return (
        <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth
                    }
                }}
            >
                <SidebarContent />
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", md: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth
                    }
                }}
                open
            >
                <SidebarContent />
            </Drawer>
        </Box>
    )
}

export default GiaSidebar
