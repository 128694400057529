import { createSlice } from "@reduxjs/toolkit"

const loadingSlice = createSlice({
    name: "loader",
    initialState: {
        loading: false
    },
    reducers: {
        updateLoading: (state, action) => {
            return (state.loading = action.payload)
        }
    }
})
export const { updateLoading } = loadingSlice.actions

export default loadingSlice.reducer
