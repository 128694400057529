/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import BarChartController from "../../Dashboard/DashboardModalController/BarChartController" // eslint-disable-next-line
import PieChartController from "../../Dashboard/DashboardModalController/PieChartController"
import {
    Grid,
    Button,
    Typography,
    Box,
    Modal,
    ListItem,
    List,
    ListItemAvatar,
    Avatar,
    useMediaQuery,
    ListItemText,
    CircularProgress
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Loader from "../../../common/Loader/Loader"
import DynamicDashboardCards from "../../../common/Grid/DynamicDashboardCards"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "react-redux"
import { jwtDecode } from "jwt-decode"

const RMDashboardSectionOne = ({isDataFetching}) => {
    const theme = useTheme()
    const user = JSON.parse(localStorage.getItem("user")) // eslint-disable-next-line
    const [isLoading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [portfolioIndex, setPortfolioIndex] = useState(0)
    const isMobileOrTablet = useMediaQuery("(max-width: 1279px)")
    const [portfolioType, setPortfolioType] = useState("")
    const navigate = useNavigate()
    const isXs = useMediaQuery(theme.breakpoints.down("sm"))
    const decodedToken = jwtDecode(user?.idToken)
    // depending on user mail id -> We use base64 format to encrypt the user mail id., the data fetch from redux presist.
    const dashboardContent = useSelector(
        (state) => state.dashboard[btoa(decodedToken?.email)]
    )

    const handleCloseLifStylePortfolio = () => {
        setModal(false)
    }

    const handleClickViewPortfolio = (index, clientId) => {
        if (portfolioType === "financial") {
            navigate("/myPortfolio", {
                state: {
                    setTab: index,
                    financialPortfolio: "financialPortfolio",
                    hniId: clientId
                }
            })
        } else {
            navigate("/myPortfolio", {
                state: {
                    setTab: index,
                    lifestylePortfolio: "lifestylePortfolio",
                    hniId: clientId
                }
            })
        }
    }

    return (
        <>
            <Modal open={modal} onClose={handleCloseLifStylePortfolio}>
                <Box
                    sx={{
                        width: 350,
                        backgroundColor: "white",
                        borderRadius: "5px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        border: "1px solid #ffffff",
                        boxShadow: 24,
                        padding: 2
                    }}
                >
                    <List
                        sx={{
                            width: "100%",
                            maxHeight: 300, // Adjust the max height as needed
                            overflowY: "auto" // Make the list scrollable
                        }}
                    >
                        <CloseIcon
                            sx={{
                                float: "right",
                                fontSize: "20px",
                                cursor: "pointer"
                            }}
                            onClick={handleCloseLifStylePortfolio}
                        ></CloseIcon>
                        <Typography
                            sx={{ fontSize: "14px", marginLeft: "20px" }}
                        >
                            Client's List
                        </Typography>
                        {dashboardContent?.api3?.hniList?.map((user, index) => {
                            return (
                                <ListItem key={index} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="Client"
                                            src={user?.profilePhotoUrl}
                                        ></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user?.firstName}
                                        sx={{
                                            marginTop: "15px",
                                            fontSize: "10px"
                                        }}
                                    ></ListItemText>
                                    <Button
                                        sx={{
                                            float: "right",
                                            marginTop: "10px",
                                            background: "#6442C4",
                                            border: "8px",
                                            color: "white",
                                            "&:hover": {
                                                background: "#6442C4",
                                                color: "white"
                                            }
                                        }}
                                        onClick={() =>
                                            handleClickViewPortfolio(
                                                portfolioIndex,
                                                user?.clientId
                                            )
                                        }
                                    >
                                        View Portfolio
                                    </Button>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </Modal>

            <Box>
                {isLoading && <Loader isLoading={isLoading} />}

                {/** Gradient top banner background */}
                <Box
                    sx={{
                        width: "200%",
                        marginLeft: "-50%",
                        height: "250px",
                        background:
                            "radial-gradient(at bottom center, #ffffff 0%, #643DD6 4%, #41288A 35%, #1E133E 65%);",
                        display: { xs: "none", sm: "block" },
                        position: "absolute",
                        top: "0px",
                        zIndex: "-10"
                    }}
                ></Box>

                {/** Top dashboard cards */}
                <Box
                    width="100%"
                    sx={{
                        height: "auto",
                        paddingX: "10px",
                        backgroundColor: "rgba(0,0,0,0)",
                        marginTop: isXs ? "0px" : "24px"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                                marginBottom: isXs ? "10px" : "30px"
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "40px",
                                fontFamily: "The Seasons",
                                fontWeight: 600,
                                color: isXs ? "#1b1b1b" : "white",
                            }}
                            className="welcomeText"
                        >{`Welcome ${user?.firstName}`}</Typography>
                        <Box>
                            {isDataFetching && <CircularProgress size={40} sx={{ color: "brand.light" }} />}
                        </Box>
                    </Box>
                    <DynamicDashboardCards
                        data={dashboardContent?.api1?.cardList}
                        cardsPerRow={3}
                    />
                </Box>

                {/** Desktop main display */}
                {!isMobileOrTablet ? (
                    <Box
                        width="100%"
                        sx={{
                            height: "auto",
                            paddingX: "10px",
                            paddingTop: "0px"
                        }}
                    >
                        <Grid container width="100%">
                            {/* Main graphs (bars and pies) */}
                            <Grid container item xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        maxHeight: "420px",
                                        minHeight: "410px",
                                        marginTop: 1
                                    }}
                                >
                                    <Grid item xs={12} md={8}>
                                        <BarChartController
                                            title={"TTM Aggregate AUM"}
                                            data={
                                                dashboardContent?.api2
                                                    ?.ttmAggAum
                                            }
                                            category={"AUM"}
                                            type={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <PieChartController
                                            title={
                                                "Aggregate Sector Allocation"
                                            }
                                            data={
                                                dashboardContent?.api1
                                                    ?.aggSectorAllocation
                                            }
                                            category={"Sector"}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        maxHeight: "420px",
                                        minHeight: "410px",
                                        marginY: 1
                                    }}
                                >
                                    <Grid item xs={12} md={6}>
                                        <PieChartController
                                            title={"Aggregate Asset Allocation"}
                                            data={
                                                dashboardContent?.api1
                                                    ?.aggAssetAllocation
                                            }
                                            category={"Asset"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PieChartController
                                            title={
                                                "Aggregate Geographical Allocation"
                                            }
                                            data={
                                                dashboardContent?.api1
                                                    ?.aggGeoAllocation
                                            }
                                            category={"Geographical"}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Grid container columnSpacing={1}>
                        {/** Mobile and tablet main display */}
                        <Grid
                            item
                            xs={12}
                            lg={12}
                            md={12}
                            sx={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                            <Grid container columnSpacing={1}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={8}
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    <BarChartController
                                        title={"TTM Aggregate AUM"}
                                        data={dashboardContent?.api2?.ttmAggAum}
                                        category={"AUM"}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    <PieChartController
                                        title={"Aggregate Sector Allocation"}
                                        data={
                                            dashboardContent?.api1
                                                ?.aggSectorAllocation
                                        }
                                        category={"Sector"}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    <PieChartController
                                        title={"Aggregate Asset Allocation"}
                                        data={
                                            dashboardContent?.api1
                                                ?.aggAssetAllocation
                                        }
                                        category={"Asset"}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    <PieChartController
                                        title={
                                            "Aggregate Geographical Allocation"
                                        }
                                        data={
                                            dashboardContent?.api1
                                                ?.aggGeoAllocation
                                        }
                                        category={"Geographical"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>

            {/** Save old leaderboards component for later use */}
            {/* Right Side (lg={3}) */}
            {/* <Grid item xs={12} lg={3} xl={3}>
                    <Box sx={{ height: '670px', }}>
                        <div style={{ height: '100%', background: '#FFFFFF', boxShadow: 'none', border: '1px solid #EBEBEB', borderRadius: '12px', padding: '20px 15px' }}> */}
            {/* <LeaderBoard aumrows={props?.aumleaderboard} revenuerows={props?.revenueleaderboard} revenueTotalPages={props?.totalResponse?.revenueTotalPages} aumTotalPage={props?.totalResponse?.aumTotalPages} aumPaginations={props?.handleAumPagination} aumPage={props?.aumpage} revenuepage={props?.revenuepage} revenuePaginations={props?.handleRevenuePagination} revenueLeaderboardLocalState={props?.revenueleaderboard} /> */}
            {/* <Typography variant='h6' sx={{
                                fontFamily: "Mona Sans",
                                textAlign: "left",
                                fontSize: "18px",
                                fontWeight: 500
                            }}>Leaderboard</Typography>
                            <LazyLoadTabs tabData={[{
                                label: 'AUM',
                                data: {
                                    // aumrows: aumrows,
                                    // aumTotalPage: aumTotalPage,
                                    // aumPaginations: aumPaginations,
                                    // aumPage: aumPage
                                }
                            },
                            {
                                label: 'REVENUE',
                                data: {
                                    // revenuerows: revenuerows,
                                    // revenueTotalPages: revenueTotalPages,
                                    // revenuepage: revenuepage,
                                    // revenuePaginations: revenuePaginations,
                                    // revenueLeaderboardLocalState: revenueLeaderboardLocalState
                                }
                            },]} /> */}
            {/* </div>
                    </Box>
                </Grid> */}
        </>
    )
}

export default RMDashboardSectionOne
