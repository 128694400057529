import React, { useEffect, useState } from "react"
import {
    Button,
    Box,
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    IconButton
} from "@mui/material"
import {
    getAlldropdownChatgpt,
    getAllhistoryChatgpt
} from "../../../services/index"
import { useFormik } from "formik"
import moment from "moment"
import { ArrowBackIos } from "@mui/icons-material"
import {
    setHistoryMessages,
    setpaginationObj,
    setTotalCount,
    setPage
} from "../../../redux/slices/ChatGptSlice"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import FormHelperText from "@mui/material/FormHelperText"
import "./chat.css"
import "../Style/fontStyle.css"
import { useNavigate } from "react-router-dom"

const validSchema = Yup.object().shape({
    fromDate: Yup.date().required("From Date is required"),
    hniValue: Yup.string()
        .test("conditional-validation", "Client is Required", function (value) {
            const hniValues = this.resolve(Yup.ref("user"))
            if (hniValues === "Client") {
                return !!value
            }
            return true
        })
        .nullable(),
    toDate: Yup.date().min(
        Yup.ref("fromDate"),
        "To Date must be after From Date"
    )
})

const HistoryList = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    const isHni =
        user?.userTypeId?.description === "Client Head" ||
        user?.userTypeId?.description === "Client Family Member"
            ? true
            : false
    const page = useSelector((state) => state.chatGpt.page)
    const targetValue = useSelector((state) => state.nav.targetValue)

    const [hnidropdown, setHniDropdowm] = useState([])
    const UserList = [{ name: "My History" }, { name: "Client" }]
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            fromDate: moment(new Date())
                .subtract(1, "months")
                .format("YYYY-MM-DD"),
            toDate: moment(new Date()).format("YYYY-MM-DD"),
            user: "My History",
            hniValue: ""
        },
        validationSchema: validSchema, //(!isHni && (targetValue === 'ChatGpt' || targetValue !== 'llm')) ? validSchema : '',
        onSubmit: async (values, e) => {
            console.log("Form submitted!", values)
            dispatch(setPage(0))
            let userValue = ""
            if (values.user === "My History") {
                userValue = user?.userId
            } else {
                userValue = values?.hniValue
            }
            const obj = {
                userId: userValue,
                target: targetValue,
                fromDate: values.fromDate,
                toDate: values.toDate,
                pageNo: 0,
                pageSize: 3
            }
            const history = await getAllhistoryChatgpt(obj)
            if (history?.data?.status) {
                console.log(history?.data?.body, "ress")
                dispatch(
                    setHistoryMessages(
                        history?.data?.body ? history?.data?.body : []
                    )
                )
                dispatch(setTotalCount(history?.data?.totalPages))
            } else {
                dispatch(setHistoryMessages([]))
                dispatch(setTotalCount(""))
            }
            dispatch(
                setpaginationObj({
                    userId: userValue,
                    fromDate: values.fromDate,
                    toDate: values.toDate
                })
            )
        }
    })
    useEffect(() => {
        console.log("dropdown")
        getAlldropdownChatgpt(user?.userId).then((res) => {
            if (res?.data?.status) {
                console.log(res?.data?.body, "ress")
                setHniDropdowm(res?.data?.body)
            }
        })
        const obj = {
            userId: user?.userId,
            fromDate: formik.values.fromDate,
            toDate: formik.values.toDate,
            pageNo: page,
            pageSize: 3,
            target: targetValue
        }

        dispatch(
            setpaginationObj({
                userId: user?.userId,
                fromDate: formik.values.fromDate,
                toDate: formik.values.toDate
            })
        )
        fetchData(obj)
        // eslint-disable-next-line
    }, [])
    const fetchData = async (obj) => {
        console.log("fetch", obj)
        const history = await getAllhistoryChatgpt(obj)
        if (history?.data?.status) {
            console.log(history?.data?.body, "ress")
            dispatch(
                setHistoryMessages(
                    history?.data?.body ? history?.data?.body : []
                )
            )
            dispatch(setTotalCount(history?.data?.totalPages))
        }
    }
    const handleHni = (event) => {
        formik.setFieldValue("user", event.target.value)
        if (formik.values.user === "My History") {
            formik.setFieldValue("hniValue", null)
        }
    }
    return (
        <>
            <HistorySidebarTop logo={user?.entityLogo} />
            <Box className="navList" sx={{ px: 3, mt: 4 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid item xs={12} mb={2}>
                            <Typography
                                variant="h6"
                                style={{
                                    marginBottom: "8px",
                                    marginTop: "12px"
                                }}
                            >
                                {"From Date"}
                            </Typography>
                            <TextField
                                size="small"
                                id="fromDate"
                                fullWidth
                                name="fromDate"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.fromDate}
                                InputProps={{ endAdornment: null }}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <Typography
                                variant="h6"
                                style={{
                                    marginBottom: "8px",
                                    marginTop: "12px"
                                }}
                            >
                                {"To Date"}
                            </Typography>
                            <TextField
                                fullWidth
                                size="small"
                                id="toDate"
                                name="toDate"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.toDate}
                            />
                            <FormHelperText
                                error={formik.errors.toDate ? true : false}
                                id="hniValue-helper"
                            >
                                {formik.touched.toDate && formik.errors.toDate
                                    ? formik.errors.toDate
                                    : null}
                            </FormHelperText>
                        </Grid>
                        {!isHni &&
                            (targetValue === "ChatGpt" ||
                                targetValue !== "llm") && (
                                <>
                                    <Grid item xs={12} mb={2}>
                                        <p
                                            className="title"
                                            variant="h6"
                                            style={{
                                                marginBottom: "8px",
                                                marginTop: "12px",
                                                fontSize: "14px"
                                            }}
                                        >
                                            {"User"}
                                        </p>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            name="user"
                                            fullWidth
                                            // error={formik.touched.currencyCode && Boolean(formik.errors.currencyCode)}
                                            onChange={handleHni}
                                            value={formik.values.user}
                                            size="small"
                                            className="subTitle"
                                        >
                                            <MenuItem
                                                selected
                                                disabled
                                                value=""
                                            >
                                                <em>Select</em>
                                            </MenuItem>
                                            {UserList &&
                                                UserList?.map((item) => (
                                                    <MenuItem
                                                        key={item?.name}
                                                        value={item?.name}
                                                    >
                                                        {item?.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p
                                            className="title"
                                            variant="h6"
                                            style={{
                                                marginBottom: "8px",
                                                marginTop: "12px",
                                                fontSize: "14px"
                                            }}
                                        >
                                            {"Client"}
                                        </p>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            name="hniValue"
                                            size="small"
                                            className="subTitle"
                                            fullWidth
                                            value={
                                                formik.values.user !==
                                                "My History"
                                                    ? formik.values.hniValue
                                                    : ""
                                            }
                                            onChange={formik.handleChange}
                                            disabled={
                                                formik.values.user ===
                                                "My History"
                                                    ? true
                                                    : false
                                            }
                                            error={
                                                formik.touched.hniValue &&
                                                Boolean(formik.errors.hniValue)
                                            }
                                        >
                                            <MenuItem
                                                selected
                                                disabled
                                                value=""
                                            >
                                                <em>Select</em>
                                            </MenuItem>
                                            {hnidropdown?.map((item) => (
                                                <MenuItem
                                                    key={item?.hniId}
                                                    value={item?.hniId}
                                                >
                                                    {console.log(item?.hniId)}
                                                    {item?.hniName}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {formik.values.user !==
                                            "My History" && (
                                            <FormHelperText
                                                error={
                                                    formik.errors.hniValue
                                                        ? true
                                                        : false
                                                }
                                                id="hniValue-helper"
                                            >
                                                {formik.touched.hniValue &&
                                                formik.errors.hniValue
                                                    ? formik.errors.hniValue
                                                    : null}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </>
                            )}
                    </Grid>
                    <Grid item xs={12} mt={4}>
                        <Button
                            color="brand"
                            size="large"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            Apply
                        </Button>
                    </Grid>
                </form>
            </Box>
        </>
    )
}

const HistorySidebarTop = ({ logo }) => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                px: 3,
                pb: 1,
                borderBottom: "1px solid",
                borderColor: "gray.500"
            }}
        >
            <IconButton sx={{}} onClick={() => navigate(-1)}>
                <ArrowBackIos />
            </IconButton>
            <IconButton onClick={() => navigate("/dashboard")}>
                <img
                    src={logo}
                    alt="logoIcon"
                    style={{
                        width: "32px",
                        height: "32px",
                        objectFit: "cover"
                    }}
                />
            </IconButton>
        </Box>
    )
}

export default HistoryList
