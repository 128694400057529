//Catalog API's
import { Axios1 } from "./config" // eslint-disable-next-line

export const getAlldropDownForCatalog = async (passingObject, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/getall-dropdown-productservice-catalogue/` +
            moduleId,
        passingObject
    )
    return resultService
}
export const getAllBordeauxLiveData = async () => {
    var resultService = await Axios1.get(
        `/api/v1/biservice/get-bordeaux-live-trade`
    )
    return resultService
}

export const getAllDetailsListCatalog = async (passingObject, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/getall-products-services-catalogue/` + moduleId,
        passingObject
    )
    return resultService
}

export const getAllRecommentationList = async (passingObject, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/getall-portfolio-subscriptions/` + moduleId,
        passingObject
    )
    return resultService
}

export const getIndividualData = async (passingObjests, moduleId) => {
    var resultService = await Axios1.post(
        `/api/v1/productservice/get-products-services-catalogue-view/` +
            moduleId,
        passingObjests
    )
    return resultService
}

export const confirmPaymentAPI = async (objectPassing) => {
    var resultService = await Axios1.post(
        `/api/v1/order-management/confirm-transaction-details`,
        objectPassing
    )
    return resultService
}

export const getByBannerIDData = async (value) => {
    try {
        const resultofBanner = await Axios1.post(
            `/api/v1/productservice/get-product-service-cms-config`,
            value
        )
        if (resultofBanner) return resultofBanner
    } catch (error) {
        console.log("error", error)
        return error
    }
}

export const statusChangeAPI = async (valuePassed) => {
    var resultsofApi = await Axios1.post(
        "/api/v1/order-management/update-child-status",
        valuePassed
    )
    return resultsofApi
}
