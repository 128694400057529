import * as React from "react"
import { StepLabel, Stepper, Box, Step, StepConnector } from "@mui/material"
import { motion } from "framer-motion"
import { useSelector } from "react-redux"

export default function StepperWrapper({ children }) {
    const { activeStep, steps } = useSelector((state) => state.selfOnboard)

    return (
        <Box
            sx={{
                width: "100%",
                pb: 4
            }}
        >
            <Stepper
                connector={<StepConnector />}
                activeStep={activeStep}
                alternativeLabel
                sx={{
                    "& .MuiStepConnector-line": {},
                    "& .Mui-active, & .Mui-completed": {
                        "&.MuiStepIcon-root, &.MuiStepLabel-alternativeLabel": {
                            color: "brand.main"
                        },
                        "& .MuiStepConnector-line": {
                            border: "2px solid",
                            borderColor: "brand.main"
                        }
                    }
                }}
            >
                {steps.map((label) => {
                    return (
                        <Step key={label}>
                            <motion.div layout={true}>
                                <StepLabel>{label}</StepLabel>
                            </motion.div>
                        </Step>
                    )
                })}
            </Stepper>

            <Box>{children}</Box>
        </Box>
    )
}
