import CloseIcon from "@mui/icons-material/Close"
import {
    DialogContent,
    Dialog,
    DialogTitle,
    IconButton,
    Typography,
    List,
    ListItem,
    Divider,
    Grid
} from "@mui/material"
import { Link as ScrollLink } from 'react-scroll';

const TermsOfServiceModal = ({ showModal, closeModal }) => {

    const sections = [
        'Introduction',
        'Definitions',
        'Scope Of Services',
        'User Registration, Eligibility And Obligations',
        'Investment Information And Risk Acknowledgment',
        'Fees And Payments',
        'Intellectual Property Rights',
        'Prohibited Conduct',
        'Confidentiality And Non-Disclosure',
        'Data Privacy And Protection',
        'Disclaimers Of Liability',
        'Warranties And Representations',
        'Indemnification',
        'Force Majeure',
        'Termination Of Services',
        'Governing Law And Jurisdiction',
        'Dispute Resolution',
        'Miscellaneous Provisions',
        'Notices',
        'Amendments To Terms',
        'Severability',
        'Governing Language',
        'Relationship Between The Parties',
        'Acknowledgment',
        'Contact Information',
        'Final Provisions'
    ];

    return (
        <Dialog
            maxWidth="md"
            scroll="paper"
            fullWidth={true}
            open={showModal}
            onClose={closeModal}
            sx={{
                "& .MuiDialogTitle-root": {
                    px: 4,
                    py: 3
                },
                "& .MuiDialogContent-root": {
                    px: 4,
                    pb: 4
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 500, fontSize: "24px" }}>
                <Typography
                    variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}
                    color="brand.main"
                    fontWeight={500}
                    mb={1}
                >
                    Terms Of Service
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "#777" }}>
                    Please read the following Terms and Conditions carefully
                    before proceeding.
                </Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={(theme) => ({
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500]
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography variant="h3" sx={{ width: "100%", textAlign: "center", fontWeight: 500, marginBottom: 1 }}>General Terms and Conditions</Typography>
                <Grid container>
                    {sections.map((section, index) => (
                        <Grid item xs={12} key={index}>
                            <ScrollLink
                                to={section.toLowerCase().replace(/\s+/g, '-')}
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-50}
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        },
                                        color: 'brand.main'
                                    }}
                                >
                                    {section}
                                </Typography>
                            </ScrollLink>
                        </Grid>
                    ))}
                </Grid>
                <Divider sx={{ my: 2 }} />

                <Typography id="introduction" variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>1. Introduction</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>1.1. Agreement and Acceptance</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms and Conditions (“Terms,” and “Agreement”) form a binding contract between Kairoswealth Pte. Ltd. (“Kairos,” “we,” “us,” or “our”) and you: (a) as an individual, and (b) any entity you represent while accessing and using the Platform and its related services. Whether you are acting as an employee, contractor, agent, or other representative of an entity ((a) and (b) collectively, “You,” “User,” “Investor”), these Terms regulate your use of the Kairos Platform (the “Platform”) and the services and applications Kairos provides (collectively, the “Services”). By registering for or accessing the Services through the Platform, you fully agree to abide by these Terms. Should you disagree with any part, you must stop using the Platform immediately.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    KAIROS RESERVES THE RIGHT TO ALTER THE PLATFORM, SERVICES, AND/OR THESE TERMS AT ANY TIME WITHOUT PRIOR NOTIFICATION, WHETHER DUE TO CHANGES IN LAWS, REGULATIONS, OR OTHER REASONS. YOU ARE ENCOURAGED TO REVIEW THE CURRENT TERMS WHENEVER ACCESSING OR USING THE PLATFORM OR SERVICES. BY CONTINUING TO ACCESS OR USE THE PLATFORM AND SERVICES AFTER ANY MODIFICATIONS, YOU INDICATE ACCEPTANCE OF THE REVISED TERMS. HOWEVER, IF ANY CHANGES MATERIALLY AND ADVERSELY AFFECT THE SERVICES, YOU MAY BE NOTIFIED AT LEAST 30 DAYS IN ADVANCE.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>1.2. Purpose</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos provides an online platform that facilitates access to factual, non-personalized information about capital markets products offered by third-party distributors (“Distributors”). Kairos does not provide personalized investment advice, and you acknowledge that all decisions regarding investments are made at your own discretion and risk.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>1.3. Governing Law</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms are governed by the laws of Singapore, specifically the Securities and Futures Act (SFA), Personal Data Protection Act (PDPA), and all other relevant regulations. By using the Platform, you agree to the exclusive jurisdiction of the courts of Singapore for any disputes that arise.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>1.4. Changes to Terms</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right to amend or modify these Terms at any time without prior notice, except where the law requires otherwise. Any changes will be posted on the Platform, and your continued use of the Platform after such changes constitutes acceptance of the revised Terms.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>2. Definitions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    For clarity, the following definitions apply in these Terms:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• “Accredited Investor”: An individual or entity who meets the qualification requirements under Singapore's Securities and Futures Act (SFA) or equivalent foreign laws.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• “Institutional Investor”: An entity or organization that qualifies as an institutional investor under applicable Singaporean law, or equivalent foreign laws, including but not limited to banks, insurance companies, and government agencies.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• “Distributor”: A third-party entity licensed by the Monetary Authority of Singapore (MAS) or other relevant authorities to distribute financial and capital markets products through the Platform, as required on a case-by-case basis.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• “Capital Markets Products”: Financial products such as securities, bonds, funds, derivatives, and other investment instruments offered on the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• “Personal Data”: Information that can be used to identify an individual, as defined in Singapore's Personal Data Protection Act (PDPA).</ListItem>
                    <ListItem sx={{ color: "#777" }}>• “Platform”: The online website, mobile applications, and any related services provided by Kairos.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• “Services”: The provision of factual information regarding Capital Markets Products to Investors through the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• “Deal Syndication Module” (also referred to as the “Confidential Deals Section”): A restricted-access section within the Platform allowing authorized Investors to view specific investment deals, provided under strict confidentiality and non-circumvent terms.</ListItem>
                </List>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>3. Scope of Services</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.1. Platform Purpose and Nature of Services</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The Platform acts as an online interface that provides factual, non-personalized information about Capital Markets Products distributed by third-party Distributors. The Platform's primary purpose is to allow Accredited Investors and Institutional Investors to access information about potential investment opportunities. The Platform does not facilitate the transaction or negotiation of these investments, nor does it offer personalized financial advice.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.2. Non-Advisory Role</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos is not a financial advisor. The Platform does not offer any personalized investment recommendations or advice tailored to your personal circumstances. All information provided through the Platform is intended for informational purposes only, and Users must seek independent professional advice before making any investment decisions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.3. User Responsibility for Investment Decisions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You agree to carefully review and assess all relevant information related to any financial product or investment opportunity, including but not limited to materials provided through the Platform as well as any other sources or information available to you before making an investment decision. You acknowledge that you will rely on your own independent judgment when making investment decisions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.4. Non-Facilitation of Transactions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The Platform provides no transactional services. All negotiations, transactions, and agreements occur off-Platform, directly between the User and the Distributor. Kairos is not a party to any agreements or transactions between Users and Distributors and does not accept any liability for the performance of those transactions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.5. Access to Distributor Information</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Through the Platform, Investors can access detailed factual information about Capital Markets Products. Investors may choose to contact the Distributors through the contact information available on the Platform, but Kairos does not participate in any form of negotiation or transaction facilitation.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.6. Platform Functionality</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The Platform provides several features to assist Investors in accessing information, including:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Search and Filter Functions: Enabling Investors to search and filter Capital Markets Products based on criteria such as investment type, industry, risk profile, expected returns, and more.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Product Information Pages: Offering detailed descriptions, risk assessments, and factual information about Capital Markets Products provided by Distributors.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.7. No Guarantee of Investment Performance</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos does not guarantee or warrant the performance, profitability, or returns of any Capital Markets Products. You acknowledge that investments in financial instruments carry inherent risks, including the potential loss of your entire investment. Kairos shall not be liable for any loss or damage arising from your investment decisions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3.8. Platform Availability</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos makes no representation or warranty that the Platform will be available uninterrupted or error-free. From time to time, the Platform may be unavailable due to scheduled maintenance or updates. Users will be notified in advance of planned maintenance periods, and Kairos will make reasonable efforts to minimize downtime. Kairos shall not be liable for any losses resulting from the unavailability of the Platform.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>4. User Registration, Eligibility and Obligations</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>4.1. Registration Process</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    To access the full functionality of the Platform, you must complete the registration process, which includes providing accurate and complete information about your identity and status as an Accredited Investor or Institutional Investor. You acknowledge and agree to provide valid proof of your investor status, as required under applicable law.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>4.2. Eligibility Requirements</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    By registering on the Platform, you represent and warrant that:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• You confirm that you qualify as an Accredited Investor or Institutional Investor under Singapore's Securities and Futures Act (SFA) or other relevant laws which are applicable to you.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You are at least 18 years of age and legally able to enter binding agreements.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You declare that you possess the legal rights, authority, and capacity to enter this Agreement, whether acting on your own behalf or representing an entity, and you agree to adhere to all terms set forth in this document. If your use of the Services conflicts with any applicable laws, regulations, or rules, you will promptly discontinue using the Services and close your account.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You agree not to use a false identity or an email address owned by another individual when using the Services.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You are responsible for keeping your registration details accurate, up-to-date, and complete, including your current country or place of residence.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You commit to using the Platform in accordance with all relevant laws and regulations, including those governing securities and investment activities.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>4.3. Account Creation and Use</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You are responsible for maintaining the confidentiality of your account credentials, including your username and password. You agree not to share your account credentials with any third party, and you are fully responsible for all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify Kairos immediately.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>4.4. Verification of Identity and Investor Status</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right to verify your identity and Accredited Investor or Institutional Investor status at any time. Failure to provide accurate information or comply with verification requests may result in the suspension or termination of your account.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>4.5. General User Obligations</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    By using the Platform and Services, you agree to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Act in good faith and use the Platform in a responsible manner.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Provide accurate and truthful information when registering and updating your account details, ensuring that all information remains current.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Protect the confidentiality of your account credentials, including your password, and promptly notify Kairos of any unauthorized use of your account.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Refrain from using the Platform for any activity that may harm or interfere with the proper functioning of the Platform, including uploading harmful software, viruses, or attempting to disrupt other users' access.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Avoid tampering with the Platform's security features, including unauthorized attempts to access, modify, or interfere with any part of the Platform's code, systems, or data.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Use the Platform only for legitimate purposes and not for any actions that could tarnish Kairos's reputation or negatively impact the Platform or its users.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Refrain from misrepresenting your identity, investment status, or any other material information while using the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Maintain confidentiality of any sensitive information that you may gain by having access to the Platform and not distribute, republish, or share any information obtained through the Platform without prior written consent from Kairos.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Understand that updates, upgrades, and modifications to the Platform may occur at Kairos's discretion, and you are not automatically entitled to additional support unless specifically provided for in these Terms.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Acknowledge that employees, officers, directors, or affiliates of Kairos may participate in the Services as investors or users. Kairos is not responsible for any activities or statements made by such individuals in their personal capacity, including emails or other communications they may issue.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Kairos may make available APIs for interacting with the Platform. Any use of these APIs is subject to this Agreement and any specific rules related to API usage, which will be posted on the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• By using the Deal Syndication Module, you commit to maintaining the confidentiality of all deal-related information and agree not to contact any companies associated with these deals directly. Breach of this commitment may result in loss of access to the Platform and may involve legal action.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    By using the Platform, you agree that any content you submit, post, or display is owned by you, but you grant Kairos a royalty-free, worldwide, irrevocable license to use, distribute, and display such content for internal business purposes and improving the Platform. You also grant Kairos a worldwide, irrevocable, transferable, assignable, royalty-free license to, both during and after the term of this Agreement, through its agents and contractors, aggregate, analyze, compile, create derivative works from, use, distribute, sub-license, disclose, and sell your content in an anonymized and aggregated form, along with data from other platform users, for the benefit of Kairos and its affiliates.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>5. Investment Information and Risk Acknowledgment</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>5.1. Investment Risks</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    All investments in Capital Markets Products come with a high degree of risk, including the risk of loss of the entire investment. You acknowledge that investment products listed on the Platform may involve risks such as market volatility, liquidity risk, interest rate risk, credit risk, and others. Past performance is not indicative of future results.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>5.2. No Guarantee of Performance</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos does not guarantee the performance, profitability, or returns on any Capital Markets Products. Any projections, estimates, or forecasts provided on the Platform are purely for informational purposes and should not be relied upon for making investment decisions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>5.3. Independent Professional Advice</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You are strongly advised to seek independent financial, legal, and tax advice before making any investment decisions. By using the Platform, you agree that you have conducted your own independent evaluation of the risks and benefits of any investments offered on the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>5.4. No Liability for Investment Decisions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos shall not be held responsible or liable for any investment losses, financial damages, or other losses arising from your investment decisions made based on information provided on the Platform. You acknowledge and agree that all investment decisions are made at your own risk.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>6. Fees And Payments</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>6.1. Platform Fees</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos charges Users, including both Accredited Investors and Institutional Investors, as well as Distributors, a fee for accessing and using certain features of the Platform. These fees may include, but is not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Subscription Fees: Regularly recurring fees charged to Users for ongoing access to Platform features.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Transaction or Listing Fees: Fixed fees charged to Distributors for listing Capital Markets Products on the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Service Fees: Any other fees associated with the use of specialized Platform services, such as data access or premium features.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The applicable fees will be clearly disclosed to you at the time of registration or when you elect to use a fee-based service, and may be changed from time to time. All fees are quoted in Singapore Dollars (SGD) unless otherwise stated.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>6.2. Payment Terms</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    All fees payable to Kairos are due immediately upon receipt of an invoice unless otherwise agreed in writing. You agree to pay all fees in a timely manner and authorize Kairos to charge the payment method provided during registration. Failure to make payments on time may result in account suspension or termination.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>6.3. Payment Methods</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Payments can be made via the methods accepted on the Platform, which may include:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Credit card</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Bank transfer</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Electronic payment services (e.g., PayPal, Stripe)</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>6.3. Taxes</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You are responsible for any taxes, including but not limited to value-added taxes (VAT), goods and services taxes (GST), or any other similar taxes applicable to your use of the Platform. Kairos may collect taxes where required by law but assumes no responsibility for determining whether any taxes apply to you. Kairos disclaims any liability for the User's failure to meet tax obligations in their respective jurisdictions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>6.4. Refund Policy</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    All fees are non-refundable. Kairos may, at its discretion, offer refunds in certain circumstances, such as technical issues preventing access to the Platform. However, any decision to refund fees will be made on a case-by-case basis and in Kairos's sole discretion.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Refunds will only be considered in cases where technical issues directly attributable to Kairos prevent a user from accessing the platform for a prolonged period (minimum 48 hours) or when services promised by Kairos are not delivered. All refund requests must be submitted within 7 days of the issue arising, and Kairos reserves the right to assess such requests on a case-by-case basis.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>6.5. Modification of Fees</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right to change the fees for any services offered through the Platform at any time. Any such changes will be notified to Users before the new fees become applicable. Continued use of the Platform after such notification constitutes your acceptance of the modified fees.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>7. Intellectual Property Rights</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>7.1. Ownership of Intellectual Property</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    All content provided on the Platform, including but not limited to text, data, graphics, logos, trademarks, icons, images, software, and any other materials (“Content”), is the intellectual property of Kairos or its licensors. This includes any custom software or proprietary algorithms used in the Platform's operation. You acknowledge that all intellectual property rights associated with the Platform and its Content are and remain the property of Kairos or the respective third-party owners.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>7.2. Limited License</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Platform and its Content for personal, non-commercial purposes, subject to compliance with these Terms. This license does not grant you any right to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Reproduce, copy, or create derivative works based on the Platform or its Content.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Distribute, perform, or publicly display any part of the Content.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Use any trademarks, service marks, logos, or other proprietary content without the prior written permission of Kairos.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>7.3. Prohibition Against Unauthorized Use</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You may not:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Use any robot, spider, scraper, or other automated means to access the Platform for any purpose without the express written permission of Kairos.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Decompile, reverse-engineer, or attempt to derive the source code of any part of the Platform's software.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Copy, modify, or distribute any Content from the Platform, except as expressly permitted by these Terms or by applicable law.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Remove, obscure, or alter any copyright, trademark, or other proprietary notices displayed on the Platform.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Any breach of this clause may result in the immediate termination of your account and legal action against you to protect Kairos's intellectual property rights.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>7.4. Third-Party Intellectual Property</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The Platform may display content, logos, or trademarks owned by third-party Distributors or partners. You acknowledge that the use of such third-party intellectual property is subject to their respective terms and conditions. Kairos does not grant any rights to Users with respect to third-party intellectual property and disclaims any liability for the misuse of such intellectual property.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>7.5. Use of Third-Party Data and Services</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos may provide access to data, reports, or services sourced from third-party suppliers (“Suppliers”), including but not limited to ESG data services. Users are permitted to access and utilize this content exclusively for internal analysis and decision-making purposes on the Kairos Platform. Redistribution, resale, sharing, or creation of derivative works from any third-party data, reports, or services obtained through the Kairos Platform outside of the Platform is strictly prohibited unless explicitly authorized in writing by Kairos. Users acknowledge that any unauthorized use or distribution may lead to the immediate termination of their access and may expose them to legal action.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>8. Prohibited Conduct</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>8.1. Compliance with Laws</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    As a User of the Platform, you agree to comply with all applicable local, national, and international laws and regulations governing your use of the Platform. This includes, but is not limited to, compliance with:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Singapore's Securities and Futures Act (SFA)</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Singapore's Personal Data Protection Act (PDPA)</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Anti-money laundering (AML) and counter-terrorism financing (CTF) laws</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Any other financial regulations applicable to your jurisdiction</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Failure to comply with these laws may result in the immediate suspension or termination of your account and could expose you to legal consequences.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>8.2. Prohibited Activities</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You agree not to engage in any activities that are unlawful, abusive, or harmful to the Platform or its Users. Prohibited activities include, but are not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Providing false, inaccurate, or misleading information during registration or while using the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Misrepresenting your status as an Accredited Investor or Institutional Investor.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Engaging in fraudulent activities, including the manipulation of investment data or unauthorized use of another User's account.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Attempting to interfere with or disrupt the operation of the Platform or its associated servers, networks, or systems.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Engaging in any activity that may constitute money laundering, terrorist financing, or other illegal activities.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Circumventing any security features or attempting to gain unauthorized access to the Platform or its associated systems.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Using the Platform to disseminate malware, viruses, or other harmful software.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>8.3. Misuse of Content</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You may not use any Content from the Platform for purposes that are harmful to the reputation or business of Kairos or for activities that violate any applicable intellectual property laws. This includes:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Reproducing or distributing Content without permission.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Altering or modifying Content to misrepresent its source or ownership.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>8.4. Monitoring and Enforcement</Typography>
                8.4. Monitoring and Enforcement
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right, but does not assume the obligation, to monitor any activity on the Platform. We may investigate suspected violations of these Terms and take any action deemed appropriate, including:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Suspending or terminating your account.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Reporting activities to law enforcement authorities or regulatory bodies.</ListItem>
                    •	Pursuing legal action to protect the interests of Kairos and its Users.
                </List>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>9. Confidentiality And Non-Disclosure</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>9.1. Confidential Information</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    During your use of the Platform, you may gain access to Confidential Information related to Kairos, Distributors, or other Users. Confidential Information includes, but is not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Trade secrets, financial data, or proprietary algorithms used by Kairos.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Personal or financial information of other Users or Distributors.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Any information not publicly available that is marked as “confidential” or that a reasonable person would understand to be confidential based on the circumstances.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>9.2. Obligations Regarding Confidential Information</Typography>
                9.2. Obligations Regarding Confidential Information
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You agree to protect any Confidential Information that you access through the Platform and to not disclose or use such information for any purposes other than those necessary to use the Platform in accordance with these Terms. Specifically, you agree:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Not to disclose Confidential Information to any third party without the prior written consent of Kairos or the applicable party.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• To take reasonable measures to protect the confidentiality of the information in your possession.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• To immediately notify Kairos if you become aware of any unauthorized access or disclosure of Confidential Information.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    All information accessed through the Kairos Platform, especially within the Deal Syndication Module, including but not limited to, deal details, associated companies, and financial data, is strictly confidential. Users must not disclose, share, or discuss any of this information outside the Platform, nor may they reproduce, distribute, or otherwise circulate it. Unauthorized disclosure will result in immediate termination of access to the Platform and may lead to legal action.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>9.3. Non-Circumvent Obligation</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Users are prohibited from directly contacting or engaging with any company or entity associated with deals presented on the Platform without explicit prior written consent from Kairos, especially within the Deal Syndication Module. Any attempt to establish independent contact or circumvent Kairos's processes will result in immediate access termination and may lead to further legal consequences.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>9.4. Exceptions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Your obligations under this clause do not apply to information that:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Is already publicly available through no fault of your own.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Is required to be disclosed by law, regulation, or court order, provided you notify Kairos in advance of such disclosure where possible.</ListItem>
                </List>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>10. Data Privacy and Protection</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.1. Compliance with the Personal Data Protection Act (PDPA)</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos is committed to complying with the Personal Data Protection Act 2012 (PDPA) of Singapore. We take our responsibilities to protect the personal data of Users seriously. By using the Platform, you agree to the collection, use, and disclosure of your personal data as outlined in these Terms and in our Privacy Policy, which forms an integral part of these Terms.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.2. Collection of Personal Data</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In order to provide you with access to the Platform, we may collect personal data, including but not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Name, address, email address, phone number, and identification details.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Investment history, preferences, and risk profiles.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Payment details, including credit card information, banking information, and transaction histories.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You agree that the personal data you provide to us is accurate and up-to-date, and you will promptly inform us of any changes to such information.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.3. Use of Personal Data</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos uses your personal data for the following non-exhaustive purposes:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• To verify your identity and Accredited Investor or Institutional Investor status.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• To provide you with access to the Platform and its services.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• To process payments and manage your account.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• To communicate with you regarding Platform updates, changes to Terms, or service announcements.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• To ensure compliance with legal and regulatory obligations, including anti-money laundering (AML) and counter-terrorism financing (CTF) regulations.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• For internal business purposes such as auditing, data analysis, and research to improve the Platform and services.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.4. Disclosure of Personal Data</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos may disclose your personal data to third parties, including:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Service providers who assist in operating the Platform, such as payment processors, data storage providers, or legal advisors.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Regulatory authorities, in accordance with applicable laws, regulations, or legal processes.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Distributors, to the extent necessary to facilitate investment opportunities on the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Any other third parties, with your consent or where disclosure is required by law.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Where personal data is transferred outside of Singapore, Kairos will take reasonable steps to ensure that the recipient of the personal data is bound by legally enforceable obligations to provide a standard of protection that is comparable to the protection under Singapore's Personal Data Protection Act (PDPA).
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.5. Security Measures</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos implements appropriate administrative, technical, and physical security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. However, you acknowledge that no method of data transmission or storage is 100% secure, and Kairos cannot guarantee absolute security.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.6. Data Retention</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos will retain your personal data for as long as it is necessary to fulfill the purposes for which it was collected, or as required by law. Upon termination of your account, your personal data may still be retained for a reasonable period of time for record-keeping, regulatory, or legal purposes.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.7. Access to and Correction of Personal Data</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You have the right to access and correct your personal data held by Kairos. If you wish to access or amend any personal data, you may contact us at support@kairoswealth.com. Any corrections or updates will be made as soon as reasonably practicable.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>10.8. Data Breach Notifications</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In the event of any unauthorized access, use, or disclosure of personal data (a “Data Breach”), Kairos will promptly investigate the breach and take necessary actions to mitigate any risks. You will be notified in accordance with the PDPA if your personal data is affected by a Data Breach that could result in significant harm.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>11. Disclaimers Of Liability</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.1. No Financial Advice</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos does not provide financial, legal, or tax advice. The information and tools provided on the Platform are for informational purposes only and should not be construed as personalized investment advice. You acknowledge that all decisions regarding the purchase or sale of any Capital Markets Products are made at your own discretion and risk.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.2. No Guarantee of Accuracy</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos endeavors to provide accurate and up-to-date information on the Platform; however, we do not guarantee the accuracy, completeness, reliability, or timeliness of any information provided by Distributors or third parties. The information available on the Platform may be subject to change, and Kairos shall not be liable for any errors, omissions, or inaccuracies in such information.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.3. No Warranty of Platform Availability</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos makes no representation or warranty that the Platform will be available, uninterrupted, or error-free at all times. From time to time, the Platform may be unavailable due to scheduled maintenance, upgrades, or unforeseen circumstances. Kairos shall not be liable for any loss or damage arising from the unavailability of the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.4. Limitation of Liability for Investments</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos is not responsible for any investment losses or damages incurred as a result of your use of the Platform or your reliance on any information or services provided. You agree that:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Kairos does not endorse or recommend any specific Capital Markets Products.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• All investment decisions are made solely at your own risk.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Kairos shall not be liable for any loss of profits, revenue, or capital arising from investments made based on the information provided on the Platform.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.5. Third-Party Content and Services</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The Platform may contain links to or display content from third-party websites, services, or advertisements. Kairos is not responsible for the accuracy, legality, or reliability of any third-party content or services. Access to and use of third-party content and services are at your own risk, and Kairos shall not be liable for any damages or losses incurred.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.6. Currency Conversion and Exchange Rate Transactions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    While using the Platform, you may receive information or engage with third-party Distributors or financial services that involve currency conversion or foreign exchange (FX) transactions. You acknowledge that any conversion of currency during such transactions may be subject to exchange rate risks. Any gains, losses, or costs incurred as a result of currency conversion, including exchange rate fluctuations or conversion fees, are solely your responsibility.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos is not liable for any diminution in value due to exchange rate fluctuations, foreign exchange controls, or other factors beyond its control, such as government actions or geopolitical events.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.7. Recording of Communications</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You authorize Kairos and its affiliates to record any telephone conversations or electronic communications between you and Kairos or its personnel. These recordings may be retained and used as deemed appropriate by Kairos, including for legal purposes. Such recordings shall be admissible as evidence in legal proceedings and will hold the same weight as a written original document. You agree not to dispute the admissibility, reliability, accuracy, or authenticity of these recordings, and you waive any right to challenge them. The recordings made by Kairos shall serve as conclusive evidence of the communications and will be binding upon you.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.8. Liability for Negligence</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In cases of willful misconduct or gross negligence, Kairos shall be liable according to the applicable statutory provisions.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In cases of ordinary negligence, Kairos shall only be liable for breaches of material contractual obligations (i.e., obligations whose breach endangers the purpose of the agreement and that a user reasonably expects to be fulfilled). In such cases, Kairos's liability is limited to the typical foreseeable damages.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The limitations on liability for negligence do not apply to damages resulting from injury to life, body, or health, or any other statutory obligations that cannot be excluded under applicable law.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.9. No Liability for Technical Failures</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos is not responsible for any technical issues, including malfunctions of computer systems, servers, networks, or internet connections, that may affect the operation of the Platform or Services. Kairos is also not responsible for any loss or damage to content or systems resulting from technical issues or congestion.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.10. No Warranty on Services and Content</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos makes no warranties regarding the completeness, accuracy, availability, timeliness, security, or reliability of the Platform, Services, or any content. The Platform, Services, and content are provided “as-is” and “as available.” You use them at your own risk. Kairos is not responsible for any harm caused to your systems, loss of data, or other damages resulting from your use or inability to use the Platform or Services.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.11. Limitation of Liability</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    To the fullest extent permitted by applicable law, Kairos, its affiliates, directors, officers, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, loss of business, or loss of data, arising out of or in connection with your use of the Platform, even if Kairos has been advised of the possibility of such damages.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In any event, Kairos's total cumulative liability to you for any claim arising out of or relating to the use of the Platform shall not exceed the amount of fees paid by you to Kairos for access to the Platform in the six (6) months preceding the claim.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.12. Force Majeure</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos shall not be liable for any delay or failure to perform its obligations under these Terms if such delay or failure is caused by events beyond its reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, labor disputes, power outages, internet failures, government actions, or pandemics (each, a “Force Majeure Event”).
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>11.13. No Class Actions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    If you are based in a jurisdiction that allows class actions, by agreeing to these Terms, you waive your rights to bring a class action lawsuit against Kairos or its service providers. You agree not to sue as a class plaintiff or participate in any class-action lawsuits against Kairos.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>12. Warranties And Representations</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>12.1. User Warranties</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    By using the Platform, you represent and warrant that:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• You are an Accredited Investor or Institutional Investor, as defined under Singapore's Securities and Futures Act (SFA) or other applicable laws.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You have provided accurate and complete information during registration and will promptly update any changes to such information.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You will use the Platform in compliance with all applicable laws and regulations.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• You are solely responsible for your investment decisions and understand the risks associated with investing in Capital Markets Products.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>12.2. Platform Warranty Disclaimer</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos provides the Platform and all Content on an “as is” and “as available” basis without any warranties of any kind, express or implied, including but not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Warranties of merchantability, fitness for a particular purpose, non-infringement, or availability of the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Warranties that the Platform will be secure, error-free, or free of viruses or other harmful components.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Warranties regarding the accuracy, reliability, or completeness of any Content or information provided on the Platform.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You acknowledge that the use of the Platform is at your own risk and that no representations or warranties are made by Kairos regarding the expected results or outcomes of any investment decisions based on the information provided on the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>12.3. No Endorsement</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos does not endorse or promote any specific Capital Markets Products or Distributors. The listing of any investment opportunities on the Platform does not constitute an endorsement or recommendation by Kairos. Users are encouraged to perform their own due diligence before making any investment decisions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>12.4. Third-Party Service Disclaimer</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos makes no representations or warranties regarding the availability, reliability, or security of third-party services, including internet service providers or cloud storage services that may affect access to the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>12.5. Exclusion of Certain Warranties</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Some jurisdictions may not allow the exclusion of certain warranties. In such cases, the warranties provided by Kairos shall be limited to the minimum extent permitted by applicable law.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>13. Indemnification</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>13.1. User's Obligation to Indemnify</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You agree to defend, indemnify, and hold harmless Kairos, its affiliates, directors, officers, employees, agents, licensors, and service providers from and against any and all claims, liabilities, damages, losses, costs, or expenses (including reasonable attorney's fees) arising out of or in connection with:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Your use of the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Your violation of these Terms, including any breach of your representations and warranties.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Your violation of any rights of a third party, including but not limited to intellectual property rights, privacy rights, or contractual rights.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Your violation of any applicable laws, regulations, or rules.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Any claim that you engaged in fraud, misrepresentation, or unlawful activity while using the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Any claim resulting from your investment decisions made based on the information provided on the Platform.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>13.2. Defense and Cooperation</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you. In such a case, you agree to cooperate fully with Kairos's defense of such claims, including providing any information or documentation required to support the defense.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>13.3. Survival</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Your obligation to indemnify Kairos under this section shall survive the termination of these Terms and your use of the Platform.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>14. Force Majeure</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>14.1. Definition of Force Majeure</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos shall not be held liable for any failure or delay in the performance of its obligations under these Terms due to circumstances beyond its reasonable control (each a “Force Majeure Event”). A Force Majeure Event includes but is not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Acts of God (e.g., natural disasters such as earthquakes, floods, or hurricanes).</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Acts of war, terrorism, hostilities, or civil unrest.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Pandemic or epidemic, including any government-imposed lockdowns or restrictions.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Labor strikes or industrial actions.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• System outages, power failures, or Internet disruptions.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Governmental or regulatory actions that restrict or prevent access to the Platform.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>14.2. Impact of Force Majeure Event</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In the event of a Force Majeure Event, Kairos's obligations under these Terms will be suspended for the duration of the Force Majeure Event. Kairos shall not be liable for any damages, losses, or other consequences resulting from such suspension of services. Users are advised to ensure that all essential data or transactions are backed up independently.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>14.3. Notice</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos will use reasonable efforts to provide you with notice in the event of a Force Majeure Event affecting the availability or functionality of the Platform. However, failure to provide such notice shall not affect the applicability of this section.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>14.4. Termination Due to Force Majeure</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    If a Force Majeure Event continues for an extended period of time (more than 30 consecutive days), either party may terminate these Terms by providing written notice to the other party.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>15. Termination Of Services</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>15.1. Termination by Kairos</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right to suspend or terminate your access to the Platform, at its sole discretion, at any time and for any reason, including but not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• A breach of these Terms by you, including any violation of laws, regulations, or third-party rights.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Your failure to pay any fees or charges owed to Kairos.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Kairos's decision to discontinue the Platform or its services, in whole or in part.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Fraudulent or unlawful activity committed by you or linked to your account.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Providing false or misleading information during the registration process or while using the Platform.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>15.2. Termination by User</Typography>
                15.2. Termination by User
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You may terminate your account and stop using the Platform at any time by providing written notice to Kairos by contacting [email address]. Upon termination, you will remain responsible for any outstanding obligations, including payment of any fees owed to Kairos.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>15.3. Effect of Termination</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Upon termination of your account for any reason:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Your right to access and use the Platform will cease immediately.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Upon termination of this Agreement for any reason, you agree to delete and remove from all computers and storage devices any copies of intellectual property owned by Kairos or other users of the Services that you obtained through the Platform or Services.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• All licenses and rights granted to you under these Terms will be immediately revoked.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Any outstanding fees, charges, or other financial obligations to Kairos must be paid in full.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Kairos may, in its sole discretion, retain or delete any of your data, including personal data and investment-related information, in accordance with its data retention policies and applicable law.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>15.4. No Refunds Upon Termination</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Unless otherwise required by law, Kairos will not provide any refunds for fees paid, and you will not be entitled to a refund if your account is terminated due to a breach of these Terms or for any other reason.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>15.5. Surviving Provisions</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The provisions of these Terms that, by their nature, should survive termination (including, but not limited to, confidentiality obligations, indemnification, limitation of liability, intellectual property provisions, and governing law) shall remain in full force and effect after the termination of these Terms.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>16. Governing Law and Jurisdiction</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>16.1. Governing Law</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms shall be governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law principles. You agree that any disputes arising under or in connection with these Terms shall be resolved in accordance with Singapore law.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>16.2. Jurisdiction</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You agree to submit to the exclusive jurisdiction of the courts of Singapore for the resolution of any disputes arising from or related to these Terms, your use of the Platform, or any transactions made through the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>16.3. Legal Proceedings</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Any legal action or proceeding brought by you against Kairos must be initiated within one (1) year of the date on which the claim arose, failing which such claim will be permanently barred. You hereby waive any longer statute of limitations that may apply under applicable law. This limitation includes, but is not limited to, claims for damages, restitution, or disputes arising from the use of services provided by Kairos.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>17. Dispute Resolution</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>17.1. Negotiation</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In the event of any dispute, controversy, or claim arising out of or relating to these Terms, the parties shall first attempt to resolve the dispute through good faith negotiations. Both parties agree to make reasonable efforts to reach a settlement before pursuing any formal legal action. Neither party shall disclose the existence, content, or outcome of the negotiation without the prior written consent of the other party, except where disclosure is required by law.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>17.2. Mediation</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    If the dispute cannot be resolved through negotiations within 30 days, the parties agree to submit the dispute to mediation in Singapore, to be administered by a mediator agreed upon by both parties. Mediation fees shall be shared equally by both parties. Neither party shall disclose the existence, content, or outcome of the mediation without the prior written consent of the other party, except where disclosure is required by law.
                </Typography>
                17.3. Arbitration
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    If the parties are unable to resolve the dispute through mediation, the dispute shall be submitted to arbitration in accordance with the rules of the Singapore International Arbitration Centre (SIAC). The seat of arbitration shall be Singapore. The arbitration tribunal shall consist of three arbitrators. Each party shall appoint one arbitrator, and the two appointed arbitrators shall appoint the third arbitrator, who shall act as the presiding arbitrator. The arbitration proceedings shall be conducted in English. The parties agree that the arbitral award shall be final and binding on the parties and that the award may be enforced in any court of competent jurisdiction. The parties further agree that the arbitration proceedings and the arbitral award shall remain confidential.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>17.4. Costs of Arbitration</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Each party shall bear its own costs of arbitration, except as otherwise determined by the arbitrator. The arbitrator may, in their discretion, award the prevailing party reasonable legal fees and expenses incurred in the course of the arbitration.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>17.5. No Class Action</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You agree that any disputes with Kairos must be resolved on an individual basis and not as part of any class or representative action. You waive any right to participate in a class action lawsuit against Kairos.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>17.6. Confidentiality Breaches</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Any disputes arising specifically from a breach of confidentiality or non-circumvent obligations, especially in the Deal Syndication Module may be expedited at Kairos's discretion to protect sensitive information. Kairos reserves the right to seek injunctive relief immediately, in addition to any other remedies available.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>18. Miscellaneous Provisions</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>18.1. No Waiver of Rights</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Failure by Kairos to enforce any right or provision of these Terms shall not constitute a waiver of such rights or provisions. Any waiver by Kairos of a particular breach or default shall not constitute a waiver of any other breach or default, whether similar or otherwise. The waiver of any provision of these Terms shall only be effective if made in writing and signed by an authorized representative of Kairos.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>18.2. Entire Agreement</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms, along with the Privacy Policy and any other legal agreements or notices referenced herein, constitute the entire agreement between you and Kairos regarding your use of the Platform. It supersedes all prior agreements, understandings, communications, or representations, whether written or oral, concerning the subject matter of these Terms.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>18.3. No Third-Party Beneficiaries</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms are intended solely for the benefit of you and Kairos, and no third party shall have any rights to enforce or rely on any provision of these Terms. Nothing in these Terms is intended to create any third-party beneficiary rights or benefits.
                </Typography>
                18.4. Headings
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    The headings and titles of the sections in these Terms are for convenience and organization purposes only and do not affect the interpretation or enforceability of any provisions of these Terms.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>18.5. Assignment</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You may not assign, transfer, or delegate your rights or obligations under these Terms without the prior written consent of Kairos. Any attempt to assign or transfer these Terms without such consent shall be null and void. Kairos reserves the right to assign or transfer its rights and obligations under these Terms to an affiliate, subsidiary, or successor in connection with a merger, acquisition, or sale of its assets.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>19. Notices</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>19.1. Method of Communication</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    All notices or communications required or permitted to be given under these Terms shall be in writing and delivered:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• By email to the last known email address you provided during registration or through the Platform;</ListItem>
                    <ListItem sx={{ color: "#777" }}>• By posting a notice on the Platform.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>19.2. Timing of Notices</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Any notice sent by email shall be deemed to have been received by you on the day the email is sent. Any notice posted on the Platform shall be deemed to have been received by you on the day the notice is posted.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>19.3. Changes to Contact Information</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    It is your responsibility to ensure that your contact information, including your email address, is accurate and up-to-date. Kairos is not responsible for any failure to deliver notices due to inaccurate contact information or other reasons outside of its control.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>20. Amendments To Terms</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>20.1. Right to Amend</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right, in its sole discretion, to amend, modify, or update these Terms at any time without prior notice to you. Any such amendments will be posted on the Platform and will be effective immediately upon posting.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>20.2. Notification of Changes</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos may, but is not obligated to, notify you of material changes to these Terms by email or by posting a notice on the Platform. Your continued use of the Platform following the posting of any amendments constitutes your acceptance of such changes. If you do not agree with any amendments to the Terms, you must discontinue your use of the Platform immediately.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>20.3. User Responsibility to Review Terms</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    It is your responsibility to review these Terms periodically to stay informed of any amendments. Kairos encourages you to check the Platform regularly to see if any updates or changes have been made.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>21. Severability</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>21.1. Severability Clause</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    If any provision of these Terms is found by a court of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, the remaining provisions of these Terms shall continue in full force and effect. The invalid, illegal, or unenforceable provision shall be replaced with a valid and enforceable provision that most closely approximates the intent and economic effect of the original provision.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>21.2. Modification by Court</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In the event that a court of competent jurisdiction determines that any provision of these Terms is overly broad or otherwise unenforceable, the court may modify such provision to the minimum extent necessary to make it enforceable while preserving the intent of the original provision.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>22. Governing Language</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms have been written in the English language, and all communications and agreements between you and Kairos shall be conducted in English. In the event of any inconsistency between the English language version of these Terms and any translated version, the English version shall prevail.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>23. Relationship Between the Parties</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms do not create a partnership, joint venture, agency, or employment relationship between you and Kairos. You agree that you are using the Platform as an independent party, and no fiduciary relationship or duty is created by these Terms.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>23.2. No Authority to Bind</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You acknowledge and agree that you have no authority to act on behalf of or bind Kairos in any manner whatsoever unless otherwise explicitly authorized by Kairos in writing.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>24. Acknowledgment</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    By accessing and using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and all related policies, including but not limited to the Privacy Policy and any other policies that may apply to your use of the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>24.1. Confidentiality and Non-Circumvent</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    By accessing the Platform, and especially the Deal Syndication Module (Confidential Deals Section), you confirm that you understand and agree to all the applicable confidentiality and non-circumvent restrictions. Unauthorized sharing or contact with associated companies will be treated as a breach of this Agreement, subject to immediate account termination and possible legal action.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    By accepting these Terms, you confirm your understanding and acceptance of these confidentiality and non-circumvent obligations.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>25. Contact Information</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    If you have any questions about these Terms, or if you need to provide any notices or make any claims, please contact us at:
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairoswealth Pte. Ltd.
                    101 Telok Ayer Street, #03-02
                    Singapore - 068574
                    Email: support@kairoswealth.com
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>26. Final Provisions</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>26.1. Survival of Terms</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    All provisions that by their nature should survive the termination of these Terms shall survive, including but not limited to Confidentiality, Indemnification, Limitation of Liability, Warranties and Representations, and Dispute Resolution.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>26.2. Binding Agreement</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    These Terms constitute a binding agreement between you and Kairos. Your use of the Platform confirms your acceptance of these Terms in full.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>26.3. Effective Date</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Effective Date: [Insert Date]
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 500, marginBottom: 1, marginTop: 3 }}>PRIVACY POLICY SCHEDULE TO TERMS AND CONDITIONS</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Effective Date: [Insert Date]
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairoswealth Pte. Ltd. (“Kairos,” “we,” “us,” or “our”) is committed to safeguarding your personal data in compliance with the Personal Data Protection Act (PDPA) of Singapore and other relevant regulations. This Privacy Policy outlines how we collect, use, disclose, and protect the personal data that you provide when accessing or using our platform (the “Platform”).
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    This Privacy Policy is part of and should be read in conjunction with the Terms and Conditions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>1. Data Collection and Usage</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>1.1. Personal Data We Collect</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos collects personal data from users during the account registration process and throughout their use of the Platform. This data includes but is not limited to:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Identification Data: Name, date of birth, national identification number, passport details.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Contact Information: Email address, physical address, phone number.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Financial Data: Bank account details, income information, transaction</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Investment History: Records of your investments through the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Device Data: IP address, browser type, operating system, and interactions with the Platform (through cookies or tracking technologies).</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>1.2. Purposes for Data Collection</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos collects and processes personal data for the following purposes:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Account Registration: To verify your identity and comply with Know Your Client (KYC) requirements.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• AML Compliance: To meet Anti-Money Laundering (AML) and Counter-Terrorism Financing (CTF) obligations.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Provision of Services: To provide access to our Platform and facilitate your interactions with third-party Distributors offering capital markets products.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Communication: To send you important information about your account, updates, and relevant investment opportunities.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Risk Management: To monitor and manage risks associated with investments made through the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Legal and Regulatory Compliance: To comply with local and international laws, regulatory guidelines, and government authorities.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>2. Sharing and Disclosure of Personal Data</Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>2.1. Sharing and disclosure of personal data</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    We may share your personal data with authorized third-party service providers to perform functions necessary for the provision of our services, including:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Distributors: Third-party financial institutions that offer capital market products through the Platform.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Regulatory Authorities: Entities such as the Monetary Authority of Singapore (MAS) or international regulators when required by law.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Service Providers: Entities that assist with Platform operations, including payment processors, KYC/AML verification providers, and IT infrastructure.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos takes reasonable steps to ensure that third-party service providers implement appropriate safeguards to protect your personal data and comply with relevant data protection regulations. However, Kairos cannot be held responsible for third parties' failure to comply with these obligations.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>2.2. Cross-Border Data Transfers</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Your personal data may be transferred to jurisdictions outside Singapore for processing and storage. Kairos takes reasonable steps to ensure that appropriate safeguards are in place in accordance with the PDPA. However, Kairos cannot be held responsible for the data protection practices of entities in those jurisdictions.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>2.3. Disclosure for Legal and Regulatory Purposes</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    We may disclose personal data to comply with:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Court Orders or Subpoenas issued by a Singapore court or any other relevant jurisdiction.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Regulatory Inquiries: Responding to requests from government agencies or regulatory bodies.</ListItem>
                </List>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>3. Data Protection Measures</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos implements robust security measures to protect personal data from unauthorized access or disclosure, including:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Encryption: All sensitive data is encrypted in transit and at rest.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Access Controls: Only authorized personnel have access to personal data based on a need-to-know basis.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Monitoring: Regular audits and monitoring of access to ensure compliance with internal security protocols.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    While we take all necessary precautions, users acknowledge that no data transmission over the internet or storage system can be fully secure.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>4. Data Retention and Deletion</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos will retain your personal data for as long as it is necessary to fulfill the purposes for which it was collected, or as required by applicable laws. When your data is no longer required for these purposes, it will be securely deleted or anonymized in accordance with PDPA guidelines.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Upon account closure, we may retain certain data to meet record-keeping obligations under Singapore law or applicable international regulations.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>5. Your Rights</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Under the PDPA, you have the following rights regarding your personal data:
                </Typography>
                <List>
                    <ListItem sx={{ color: "#777" }}>• Access: You may request access to the personal data we hold about you.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Correction: You may request corrections to any inaccurate or outdated data.</ListItem>
                    <ListItem sx={{ color: "#777" }}>• Withdrawal of Consent: You may withdraw your consent for the collection, use, or disclosure of your personal data at any time by providing us with written notice. Kairos may require a reasonable time to process your request, and withdrawing consent may impact our ability to continue providing services to you. Upon withdrawal, we may still retain certain data if required by law.</ListItem>
                </List>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    To exercise any of these rights, please contact us at [email address]. We will respond to your request within a reasonable time frame as mandated by law. Please note that a reasonable fee may be charged for access requests, in compliance with PDPA guidelines.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>6. Use of Cookies</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Our Platform uses cookies and similar tracking technologies to improve your user experience. These cookies help us analyze usage patterns and provide personalized content. By using the Platform, you consent to the use of cookies as described in this policy.
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    You can manage or disable cookies through your browser settings, though this may affect the functionality of the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>7. Data Breaches and Notification</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    In the event of a data breach, Kairos will promptly notify affected individuals and the Personal Data Protection Commission (PDPC) if the breach is likely to result in significant harm. Kairos will also take immediate steps to mitigate any damage and restore the security of the Platform.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>8. Changes to This Privacy Policy</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairos reserves the right to modify this Privacy Policy at any time. Any material changes may be communicated via email or through the Platform. Continued use of the Platform following such changes constitutes your agreement to the updated policy.
                </Typography>
                <Typography variant="h4" sx={{ marginBottom: 0.6 }}>9. Contact Us</Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    For any questions or concerns regarding this Privacy Policy or your personal data, please contact:
                </Typography>
                <Typography variant="body1" sx={{ color: "#777", marginBottom: 1 }}>
                    Kairoswealth Pte. Ltd.<br />
                    101 Telok Ayer Street, #03-02<br />
                    Singapore - 068574<br />
                    Email: support@kairoswealth.com
                </Typography>



            </DialogContent>
        </Dialog>
    )
}

export default TermsOfServiceModal
