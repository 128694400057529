import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText
} from "@mui/material"

import { useField, useFormikContext } from "formik"

const CheckboxWrapper = ({ name, label, legend, size = "md", ...props }) => {
    const [field, meta] = useField(name)
    const { setFieldValue } = useFormikContext()

    const onChange = (event) => {
        const value = event.target.checked
        setFieldValue(name, value)
    }

    const checkConfig = {
        ...field,
        ...props,
        onChange
    }

    return (
        <FormControl error={meta.error && meta.touched}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{
                                "&.Mui-checked": {
                                    color: "#7450da"
                                }
                            }}
                            {...checkConfig}
                            checked={field.value}
                        />
                    }
                    label={label}
                />
            </FormGroup>
            <FormHelperText error>{meta.error}</FormHelperText>
        </FormControl>
    )
}
export default CheckboxWrapper
