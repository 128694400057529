import { Box, Typography } from "@mui/material"
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined"

const TokenValidationFailedPage = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 4
            }}
        >
            <Box
                sx={{
                    maxWidth: "60ch",
                    bgcolor: "gray.50",
                    p: 5,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                <LockClockOutlinedIcon
                    color="brand"
                    sx={{ fontSize: 52, mb: 2 }}
                />
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 600, color: "gray.700", mb: 1 }}
                >
                    Invalid or Already Used Link
                </Typography>
                <Typography variant="section2" sx={{ maxWidth: "50ch" }}>
                    It seems like your onboarding link is either invalid or has
                    already been used. If you haven't completed your onboarding,
                    please request a new link and check your email for further
                    instructions.
                </Typography>
                <a
                    style={{
                        color: "#643dd6",
                        marginTop: "10px",
                        fontWeight: 400
                    }}
                    href="mailto:support@kairoswealth.com&amp;subject=Invalid onboard link&amp;"
                >
                    Contact support
                </a>
            </Box>
        </Box>
    )
}
export default TokenValidationFailedPage
