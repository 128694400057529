import React from "react"
import {
    Avatar,
    Grid,
    Typography,
    List,
    ListItem,
    Divider
} from "@mui/material"
import { ReactComponent as Up } from "../../../../theme/images/UpGreen.svg"
import { ReactComponent as Down } from "../../../../theme/images/DownRed.svg"
import { formatNumber } from "../../../../constant/DollorFormats"
import { useNavigate } from "react-router-dom"

const ContactsContent = ({ data, type }) => {
    const navigate = useNavigate()

    const containerHeight = 575
    const generateRandomColor = () => {
        const colors = [
            "#FF7557",
            "#643DD6",
            "#7FE1D8",
            "#3E90D6",
            "#643DD6",
            "#00A500",
            "#D92F22"
        ]
        return colors[Math.floor(Math.random() * colors.length)]
    }

    const getInitials = (name) => {
        // Split the name into parts based on space
        const parts = name.split(" ")

        // Initialize an empty string to collect initials
        let initials = ""

        // Iterate over each part to extract the first letter and capitalize it
        for (let part of parts) {
            if (part.length > 0) {
                // Append the capitalized first letter to initials
                initials += part.charAt(0).toUpperCase()
            }
        }
        return initials // Return the concatenated initials
    }

    const handleClientLinkClick = (id) => {
        console.log("id", id, btoa(id))
        navigate("/myClients/clientprofile/" + btoa(id))
    }

    const handleProspectsLinkClick = (id) => {
        navigate(`/dashboard/EditProspects/${btoa(id)}`)
    }

    return (
        <div
            style={{
                overflowY: "auto",
                maxHeight: `${containerHeight}px`
            }}
        >
            <List dense>
                {type === "Clients"
                    ? data?.length > 0 &&
                      data?.map((row, index) => (
                          <React.Fragment key={"clients" + index}>
                              <ListItem
                                  sx={{
                                      height: "64px",
                                      padding: "0px !important",
                                      color: "#1E1E1E",
                                      display: "flex",
                                      alignItems: "center"
                                  }}
                              >
                                  {/** Table Row */}
                                  <Grid container flexDirection={"row"}>
                                      {/** Display Avatar */}
                                      <Grid item sm={2}>
                                          <Avatar
                                              sx={{
                                                  width: "40px",
                                                  height: "40px",
                                                  backgroundColor:
                                                      generateRandomColor(),
                                                  borderRadius: "50%",
                                                  border: "1px solid #EBEBEB"
                                              }}
                                          >
                                              {getInitials(row.clientName)}
                                          </Avatar>
                                      </Grid>

                                      {/** Display Contact full name */}
                                      <Grid item sm={6}>
                                          <Typography
                                              id={row?.id}
                                              title={row.clientName}
                                              sx={{
                                                  whiteSpace: "nowrap",
                                                  cursor: "pointer",
                                                  textOverflow: "ellipsis",
                                                  pl: "3px",
                                                  pt: "8px",
                                                  overflow: "hidden",
                                                  fontSize: "16px",
                                                  lineHeight: "24px"
                                              }}
                                              onClick={() =>
                                                  handleClientLinkClick(row?.id)
                                              }
                                          >
                                              {row.clientName}
                                          </Typography>
                                      </Grid>

                                      {/** Display rank progression arrow */}
                                      <Grid item sm={2} sx={{ pt: "8px" }}>
                                          {row?.ranking === "Up Arrow" ? (
                                              <Up />
                                          ) : row.ranking !== "Dot" ? (
                                              <Down />
                                          ) : (
                                              <Down />
                                          )}
                                      </Grid>

                                      {/** Display AUM */}
                                      <Grid item sm={2}>
                                          <Typography
                                              sx={{
                                                  whiteSpace: "nowrap",
                                                  minWidth: "20px",
                                                  pt: "8px",
                                                  maxWidth: "25px",
                                                  fontSize: "16px",
                                                  lineHeight: "24px",
                                                  textAlign: "left"
                                              }}
                                          >
                                              ${" "}
                                              {formatNumber(row.investable, 0)}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </ListItem>
                              <Divider sx={{ backgroundColor: "#f4f4f4" }} />
                          </React.Fragment>
                      ))
                    : data?.length > 0 &&
                      data?.map((row, index) => (
                          <React.Fragment key={"prospects" + index}>
                              <ListItem
                                  sx={{
                                      height: "64px",
                                      padding: "0px !important",
                                      color: "#1E1E1E",
                                      display: "flex",
                                      alignItems: "center"
                                  }}
                              >
                                  {/** Table Row */}
                                  <Grid container flexDirection={"row"}>
                                      {/** Display Avatar */}
                                      <Grid item sm={2}>
                                          <Avatar
                                              sx={{
                                                  width: "40px",
                                                  height: "40px",
                                                  backgroundColor:
                                                      generateRandomColor(),
                                                  borderRadius: "50%",
                                                  border: "1px solid #EBEBEB"
                                              }}
                                          >
                                              {getInitials(row.prospectName)}
                                          </Avatar>
                                      </Grid>

                                      {/** Display Contact full name */}
                                      <Grid item sm={6}>
                                          <Typography
                                              title={row.prospectName}
                                              sx={{
                                                  whiteSpace: "nowrap",
                                                  cursor: "pointer",
                                                  textOverflow: "ellipsis",
                                                  pl: "3px",
                                                  pt: "8px",
                                                  overflow: "hidden",
                                                  fontSize: "16px",
                                                  lineHeight: "24px"
                                              }}
                                              onClick={() =>
                                                  handleProspectsLinkClick(
                                                      row?.prospectId
                                                  )
                                              }
                                          >
                                              {row.prospectName}
                                          </Typography>
                                      </Grid>

                                      {/** Display rank progression arrow */}
                                      <Grid item sm={2} sx={{ pt: "8px" }}>
                                          {row?.ranking === "Up Arrow" ? (
                                              <Up />
                                          ) : row.ranking !== "Dot" ? (
                                              <Down />
                                          ) : (
                                              <Down />
                                          )}
                                      </Grid>

                                      {/** Display AUM */}
                                      <Grid item sm={2}>
                                          <Typography
                                              sx={{
                                                  whiteSpace: "nowrap",
                                                  minWidth: "20px",
                                                  pt: "8px",
                                                  maxWidth: "25px",
                                                  fontSize: "16px",
                                                  lineHeight: "24px",
                                                  textAlign: "left"
                                              }}
                                          >
                                              $ {formatNumber(row.netWorth, 0)}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </ListItem>
                              <Divider sx={{ backgroundColor: "#f4f4f4" }} />
                          </React.Fragment>
                      ))}
            </List>

            {type === "Clients" && data?.length === 0 && (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                >
                    No data available
                </Typography>
            )}
            {type !== "Clients" && data?.length === 0 && (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                >
                    No data available
                </Typography>
            )}
        </div>
    )
}
export default ContactsContent
