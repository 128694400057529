import React, { useEffect, useState } from "react"
import { Modal, Box, Typography, Grid } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import GridTable from "../Dashboard/DashboardList/GridTable"
import { useDispatch, useSelector } from "react-redux"
import { setmodalState, setButton } from "../../../redux/slices/navSlice"
import { ReactComponent as Download } from "../../../theme/images/Download.svg"
import { downloadRmPerformance, getRMPerfomanceReport } from "../../../services"
import navButton from "../../Layout/navButtons"
import useMediaQuery from "@mui/material/useMediaQuery"

const RMPerfomance = (modalValue) => {
    const columns = [
        { field: "rmName", headerName: "RM Name", width: 100 },
        { field: "countOfClient", headerName: "Count of HNI", width: 100 },
        { field: "revenue", headerName: "Revenue ($)", width: 100 },
        {
            field: "clientSatisfactionScore",
            headerName: "Client Satisfaction Score (CSAT)",
            width: 200
        },
        {
            field: "assetUnderManagement",
            headerName: "Asset Under Management(AUM) ($)",
            width: 220
        },
        { field: "npsScore", headerName: "NPS Score", width: 100 }
    ]
    const userChecks = JSON.parse(window.localStorage.getItem("user"))
    const isRmChecks =
        userChecks?.userTypeId?.description === "Entity RM" ? true : false

    const assistanceButtons = [
        {
            index: 26,
            text: "FAQs",
            path: "/enquiry"
        },
        {
            index: 27,
            text: isRmChecks ? "Chat" : "Chat with RM",
            path: "/chatqueries"
        },
        {
            index: 28,
            text: "Ask Gaia",
            path: "/chatgpt"
        }
    ]

    const dispatch = useDispatch()
    const text = useSelector((state) => state.nav.text)
    const [rows, setrows] = useState([])
    const isMd = useMediaQuery("(max-width:768px)")

    const handleClose = () => {
        dispatch(setmodalState(false))
        const foundButton = navButton?.find((button) => button.text === text)
        if (foundButton) {
            dispatch(setButton(foundButton))
        } else {
            let assist = assistanceButtons?.find(
                (button) => button.text === text
            )
            dispatch(setButton(assist))
        }
    }
    const handleRmPerformanceDownload = async () => {
        var passingObject = {
            loginUserId: userChecks?.userId
        }

        const response = await downloadRmPerformance(passingObject)

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "RM Performance.xls")
        document.body.appendChild(link)
        link.click()
    }

    useEffect(() => {
        getRMPerfomanceReport(userChecks?.userId).then((res) => {
            if (res?.data?.status) {
                setrows(res?.data?.body)
            }
        }) // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Modal
                open={modalValue}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        width: isMd ? "80%" : "57%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        boxShadow: 24,
                        padding: 2,
                        border: "2px solid white !important"
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item md={4} sm={4} xs={7} lg={3}>
                            <Typography sx={{ marginLeft: 2 }}>
                                RM Performance Report
                            </Typography>
                        </Grid>
                        <Grid item md={1} sm={1} xs={1} lg={1}>
                            <Download
                                onClick={handleRmPerformanceDownload}
                                style={{ cursor: "pointer" }}
                            />
                        </Grid>
                        <Grid
                            item
                            md={7}
                            sm={7}
                            xs={3}
                            lg={8}
                            sx={{ textAlign: "right" }}
                        >
                            <CloseIcon
                                onClick={handleClose}
                                style={{ cursor: "pointer" }}
                                data-testid="close-icon"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GridTable
                                columns={columns}
                                rows={rows}
                                type="example"
                                height="400px"
                                getRowId={(row) => row?.rmId}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

export default RMPerfomance
