import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { List, Typography, MenuItem, Menu } from "@mui/material"
import { getAllNotification, updateNotification } from "../../../services/index"
import { navButtons } from "../../../components/Layout/SideNavBar"
import { useDispatch } from "react-redux"
import { setButton, setSurveyId, setText } from "../../../redux/slices/navSlice"
import throttle from "lodash/throttle"
import Loader from "../../common/Loader/Loader"
import NotificationItem from "./NotificationItem"

function Notification(props) {
    const dispatch = useDispatch()

    const user = JSON.parse(window.localStorage.getItem("user"))
    const userId = user.userId
    const [notifications, SetNotifications] = useState([]) // eslint-disable-next-line
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const notificationListRef = useRef(null)

    useEffect(() => {
        getAllNotification({
            userId: userId,
            pageNo: 0,
            pageSize: 8
        }).then((res) => {
            SetNotifications(res?.data?.body)
            setCount(res?.data?.totalUnreadCount)
            props?.setpushNotificationCount(res?.data?.totalUnreadCount)
            setTotalPages(res?.data?.totalPages)
        }) // eslint-disable-next-line
    }, [])

    const handleNotificationClick = (notification) => {
        if (notification.isRead === false) {
            updateNotification({
                id: notification.id,
                isRead: true,
                userId: userId
            }).then((res) => console.log(res, "update res"))
            // props.onNotificationChange(count)
            SetNotifications((prev) =>
                prev.map((note) =>
                    note.id === notification.id
                        ? { ...note, isRead: true }
                        : { ...note }
                )
            )
            setCount((ps) => ps - 1)
            props?.setpushNotificationCount((ps) => ps - 1)
        }

        if (!notification?.path.includes("/clientprofile")) {
            const button = navButtons.filter(
                (val) => val.path === notification.path
            )[0]
            dispatch(setButton(button))
        }
        if (notification?.path.includes("/prospectprofile")) {
            dispatch(setButton({}))
            dispatch(setText("Prospect Profile"))
        }

        if (notification?.requestId && notification?.path === "/survey") {
            dispatch(
                setSurveyId({
                    surveyId: notification?.requestId,
                    refreshAPI: false
                })
            )
        }
        const notificationPathSplit = notification.path.split("/")[1]
        const selectedPathObj = navButtons.find(
            (val) => val.path === `/${notificationPathSplit}`
        )
        props.onClose()
        dispatch(setButton(selectedPathObj))
        dispatch(setText(selectedPathObj?.text))
        navigate(notification?.path)
    }

    const handleShowMore = async () => {
        await setPage((ps) => ps + 1)
    }

    const handleScroll = throttle(() => {
        const notificationList = notificationListRef.current
        if (
            notificationList.scrollTop + notificationList.clientHeight >=
            notificationList.scrollHeight - 30
        ) {
            setLoader(true)
            // User has scrolled to the bottom
            handleShowMore()
        }
    }, 300)

    useEffect(() => {
        if (page < totalPages) {
            getAllNotification({
                userId: userId,
                pageNo: page,
                pageSize: 3
            }).then((res) => {
                res?.data?.body?.length > 0 &&
                    SetNotifications((ps) => [...ps, ...res.data.body])
                setLoader(false)
            })
        } // eslint-disable-next-line
    }, [page])

    return (
        <React.Fragment>
            <Menu
                anchorEl={props.anchorEl}
                id="account-menu"
                open={props.open}
                onClick={props.onClick}
                onClose={props.onClose}
                sx={{
                    "& .MuiList-root": {
                        padding: "0"
                    },
                    "& .MuiPaper-root": {
                        boxShadow: "none", // Remove the box shadow
                        padding: 0,
                        borderLeft: "2px solid #E7E7E7",
                        position: "absolute",
                        top: "0px !important",
                        minHeight: "100vh !important",
                        width: "420px !important",

                        left: {
                            xs: "0px !important",
                            sm: "70px !important",
                            lg: "70px !important"
                        }
                    },
                    "& .MuiMenuItem-root": {
                        px: "20px",
                        backgroundColor: "#fff"
                    },
                    "& .MuiMenuItem-root.Mui-disabled": {
                        opacity: 100
                    },
                    overflow: "visible"
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
                <MenuItem disabled>
                    <Typography
                        sx={{
                            fontSize: "24px",
                            fontWeight: 500
                        }}
                    >
                        Notifications
                    </Typography>
                </MenuItem>
                <List
                    ref={notificationListRef}
                    onScroll={handleScroll}
                    sx={{
                        overflowY: "scroll",
                        maxHeight: {
                            xs: "86vh",
                            sm: "91vh",
                            lg: "91vh"
                        }
                    }}
                >
                    {notifications.length === 0 && (
                        <Typography
                            sx={{
                                fontSize: "20px",
                                pt: "20vh",
                                fontWeight: 600,
                                textAlign: "center"
                            }}
                        >
                            No notifications
                        </Typography>
                    )}
                    {notifications?.map((notification, key) => (
                        <NotificationItem
                            key={key}
                            notification={notification}
                            handleNotificationClick={handleNotificationClick}
                        />
                    ))}
                    {page >= totalPages && (
                        <MenuItem
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                color: "#693BE1",
                                height: "22px"
                            }}
                        >
                            <p style={{ cursor: "default" }}>
                                No More Notifications{" "}
                            </p>
                        </MenuItem>
                    )}
                </List>
                <MenuItem
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#693BE1"
                    }}
                >
                    {page < totalPages && <Loader isLoading={loader} />}
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default Notification
