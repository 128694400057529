import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"

const filter = createFilterOptions()

const AutoCompleteWrapper = ({
    field,
    form: { setFieldValue, touched, errors },
    options,
    label,
    required,
    ...props
}) => {
    return (
        <Autocomplete
            {...props}
            options={options}
            value={field.value || null}
            onChange={(event, newValue) => {
                console.log(event)
                if (typeof newValue === "string") {
                    setFieldValue(field.name, { label: newValue })
                } else if (newValue && newValue.inputValue) {
                    setFieldValue(field.name, { label: newValue.inputValue })
                } else {
                    setFieldValue(field.name, newValue)
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params

                const isExisting = options.some(
                    (option) => inputValue === option.label
                )
                if (inputValue !== "" && !isExisting) {
                    filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`
                    })
                }
                return filtered
            }}
            getOptionLabel={(option) => {
                if (option.inputValue) return option.inputValue
                return option.label
            }}
            renderOption={({ key, ...props }, option) => (
                <li key={key} {...props}>
                    {option.label}
                </li>
            )}
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    color="brand"
                    error={Boolean(touched[field.name] && errors[field.name])}
                    helperText={touched[field.name] && errors[field.name]}
                />
            )}
        />
    )
}

export default AutoCompleteWrapper
