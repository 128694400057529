export const suggestionsList = [
    {
        chatGptConfigId: "1",
        description:
            "Find out the key areas of revenue & Profit generation for Alphabet",
        stock: "GOOG"
    },
    {
        chatGptConfigId: "2",
        description:
            "Explore how the treasury investments of Alphabet is distributed among various asset classes",
        stock: "GOOG"
    },
    {
        chatGptConfigId: "3",
        description:
            "Check out the analysis of the recent earnings reports for Amazon, including any notable surprises or trends",
        stock: "AMZN"
    },
    {
        chatGptConfigId: "4",
        description: "Find out the total assets and total liabilities of Apple",
        stock: "AAPL"
    },
    {
        chatGptConfigId: "5",
        description:
            "Check out the different tax obligation/provision on Apple",
        stock: "AAPL"
    },
    {
        chatGptConfigId: "6",
        description:
            "Use the bot to analyse the research and development expenses as a percentage of revenue for Meta and also assess Meta’s commitment to innovation",
        stock: "META"
    },
    {
        chatGptConfigId: "7",
        description: "Figure out the revenue by geography for Meta",
        stock: "META"
    },
    {
        chatGptConfigId: "8",
        description:
            "Understand the major areas of capital expenditure for Microsoft",
        stock: "MSFT"
    },
    {
        chatGptConfigId: "9",
        description:
            "Figure out the impact of recent regulatory changes on the business operations of Amazon Communications and its financial outlook",
        stock: "AMZN"
    }
]

export const suggestionsListLifestyle = [
    {
        chatGptConfigId: "11",
        description: "Invest in a timeless designer handbag"
    },
    {
        chatGptConfigId: "22",
        description:
            "Invest in a luxury watch from prestigious brands like rolex,philippe or audemars piguet"
    },
    {
        chatGptConfigId: "33",
        description:
            "Stay stylish and warm with a luxurious coat or jacket from a renowned fashion houses"
    }
]

export const faqList = [
    {
        chatGptConfigId: "111",
        description:
            "What should i consider when selecting a luxury service vendor?"
    },
    {
        chatGptConfigId: "222",
        description:
            "Can luxury service vendors customize their offerings to meet specific needs or preferences?"
    },
    {
        chatGptConfigId: "333",
        description:
            "What type of certifications or credentials should luxury service vendors have?"
    }
]

export const topicsList = [
    {
        chatGptConfigId: "1111",
        description: "Evolution and Trends in Luxury Services"
    },
    {
        chatGptConfigId: "2222",
        description: "Luxury Travel Experiences and Destination Management"
    },
    {
        chatGptConfigId: "3333",
        description: "The Influence of social media on luxury services"
    }
]

export const suggestionsListTerminal = [
    {
        chatGptConfigId: "11111",
        description:
            "Check what are TSLA peers. From those, check which one has the highest market cap. Then, on the ticker that has the highest market cap get the most recent price target estimate from an analyst, and tell me who it was and on what date the estimate was made. Tell me if the analyst is bullish or bearish on this stock.",
        title: "Research Assistant"
    },
    {
        chatGptConfigId: "22222",
        description:
            "Perform a fundamentals financial analysis of AMZN using the most recently available data. What do you find that's interesting?",
        title: "Fundamentals Analysis"
    },
    {
        chatGptConfigId: "33333",
        description:
            "What are the key drivers behind the recent surge in the price of gold, and how sustainable is this trend in the medium term?",
        title: "Market Research"
    },
    {
        chatGptConfigId: "44444",
        description:
            "What is the potential impact of a 25 basis point increase in the Federal Reserve's interest rates on our fixed income portfolio?",
        title: "Financial Analysis"
    },
    {
        chatGptConfigId: "55555",
        description:
            "Given our current portfolio composition (<portfolio composition needs to be provided here>), what is the potential impact of a significant increase in interest rates on our fixed income holdings?",
        title: "Portfolio Risk Management"
    }
]

export const suggestionsListInternal = [
    {
        chatGptConfigId: "111111",
        description:
            "Upload market researches, large reports, and get answer to your questions without having to read these documents",
        title: "Deep search through large documents"
    },
    {
        chatGptConfigId: "222222",
        description:
            "Upload term sheets and prospectus of complex financial products, Gaia will find anything you need from them such as settlement schedule, strike price, knock-out, ...",
        title: "Analyze complex financial documents"
    },
    {
        chatGptConfigId: "333333",
        description:
            "Upload a large document, select it in the folder display, and the summarize button becomes active.",
        title: "Summarize large documents"
    }
]

export const supportedLanguages = ["en", "fr", "jp", "zh", "th", "ar", "hi"]
