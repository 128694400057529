import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DollarConverstion } from "../../../constant/DollorFormats"
import { setTitleCurrency } from "../../../redux/slices/globalCurrencySlice"
export const UseCurrencyHook = ({ val }) => {
    const currency = useSelector((state) => state.globalCurrency.currency)

    const userObject = JSON.parse(localStorage.getItem("user"))
    const [currencyValue, setCurrencyValue] = useState()
    const [currencyCode, setCurrencyCode] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        if (currency) {
            const exchangeRateObject =
                userObject &&
                userObject?.g7Countries?.find((item) => item.id === currency)

            const Value = val * exchangeRateObject?.exchangeRate
            setCurrencyValue(Value)
            dispatch(
                setTitleCurrency({
                    currencyValue: Value,
                    ...exchangeRateObject
                })
            )
            setCurrencyCode(exchangeRateObject)
        }

        // eslint-disable-next-line
    }, [currency, val])

    return <>{DollarConverstion(currencyCode?.currencyCode, currencyValue)}</>
}
