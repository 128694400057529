import { Formik, Form } from "formik"

import * as Yup from "yup"
import FormWrapper from "../../components/FormWrapper"
import FormField from "../../components/FormField"
import { Box } from "@mui/material"
import { SelectWrapper, TextFieldWrapper } from "components/common/Ui/Form"
import { useDispatch, useSelector } from "react-redux"
import FormAction from "../../components/FormAction"
import { goToNextEntityDetails } from "redux/slices/selfOnboardSlice"
import { useSelfOnboardDropdown } from "../../hooks/useOnboardDropdown"
import { useCallback } from "react"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import {
    fetchCityByState,
    fetchStateByCountry,
    updateSelfOnboardProspect
} from "services/prospectsServices"
import { toast } from "react-toastify"
import { setCities, setStates } from "redux/slices/selfOnboardSlice"
import { REGEX } from "constant/globalConstants"
import { sortInAscendingOrder } from "constant/helpersUtility"

const title = "Entity details"
const subtitle =
    "In this step, we collect important information about your entity. This helps us verify your organization and ensure compliance with relevant regulations."

const DAY = 1
const getMaxYear = () => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - DAY)
    return currentDate
}

const validationSchema = Yup.object({
    first_name: Yup.string().trim().required("Company name required"),
    last_name: Yup.string()
        .trim()
        .required("Entity type required")
        .max(80, "Cannot exceed 80 characters"),
    company_registration_id: Yup.string().required(
        "Company registration ID required"
    ),
    country_id: Yup.string().required("Country of registration required"),
    address_1: Yup.string().trim().required("Company address required"),
    tax_id: Yup.string().required("Tax ID required"),
    address_2: Yup.string().trim().required("Company trade address required"),
    state_id: Yup.string().required("State is required "),
    city_id: Yup.object().required("City required").typeError("City required"),
    mobile_country_code: Yup.string().required("Required"),
    mobile: Yup.string()
        .required("Mobile number required")
        .matches(REGEX.mobileNumber, "Valid mobile number required"),
    postal_code: Yup.string().matches(
        REGEX.postalCode,
        "Valid postal code required. Should be 1-8 digits"
    ),
    dob: Yup.date()
        .max(getMaxYear(), "Incorporation date should be in past")
        .typeError("Valid incorporation date required")
        .required("Incorporation date required")
})

export default function EntityDetails() {
    const { get } = useSelfOnboardApi()
    const { isLoading, post } = useSelfOnboardApi()
    useSelfOnboardDropdown()
    const dispatch = useDispatch()
    const {
        investorType,
        prospect_id,
        email,
        token,
        entityDetails,
        dropDowns: { countries, cities, states }
    } = useSelector((state) => state.selfOnboard)

    // eslint-disable-next-line
    const countryOptions = useCallback(
        countries.map(({ label, id }) => ({
            label,
            value: id
        })),
        [countries]
    )

    // eslint-disable-next-line
    const phoneCodeOptions = useCallback(
        countries.map(({ label, id, code }) => ({
            label: `${label} (${code})`,
            value: id
        })),
        [countries]
    )

    const handleNext = async (data) => {
        const { city_id, ...rest } = data
        let prospect_type = investorType.toLowerCase()
        prospect_type = prospect_type[0].toUpperCase() + prospect_type.slice(1)

        if (city_id.value) {
            rest["city_id"] = city_id.value
        } else {
            rest["city_text"] = city_id.label
        }

        const payload = {
            ...rest,
            prospect_type,
            prospect_id,
            email,
            token
        }

        const resp = await post({
            apiCaller: updateSelfOnboardProspect,
            payload
        })

        if (resp.status !== 200) {
            const defaultMsg =
                "Something went wrong. Try contacting support teams"
            const errorMsg = resp.data?.detail[0]?.msg
            toast.error(errorMsg || defaultMsg)
            return
        }

        dispatch(goToNextEntityDetails(data))
    }

    const handleCountryChange = async (countryId) => {
        const [data, status] = await get({
            apiCaller: fetchStateByCountry,
            params: { country_id: countryId, token }
        })

        if (status !== 200) {
            toast.error("Failed to load state")
            return
        }

        const options = sortInAscendingOrder(
            data.values.map((state) => ({
                label: state.at(1),
                value: state.at(0)
            }))
        )

        dispatch(setStates(options))
    }

    const handleStateChange = async (stateId) => {
        const [data, status] = await get({
            apiCaller: fetchCityByState,
            params: { state_id: stateId, token }
        })

        if (status !== 200) {
            toast.error("Failed to load cities")
            return
        }

        const options = sortInAscendingOrder(
            data.values.map((city) => ({
                label: city.at(1),
                value: city.at(0)
            }))
        )

        dispatch(setCities(options))
    }

    const schemaList = [
        {
            name: "first_name",
            label: "Entity name",
            required: true
        },
        {
            name: "last_name",
            label: "Entity type",
            required: true
        },
        {
            name: "company_registration_id",
            label: "Company registration ID",
            required: true
        },
        {
            name: "dob",
            label: "Incorporation date",
            type: "date",
            required: true
        },
        {
            name: "tax_id",
            label: "Tax ID",
            required: true
        },
        {
            name: "mobile",
            type: "phone",
            label: "Mobile number",
            codeName: "mobile_country_code",
            codeLabel: "Country code",
            codeOptions: phoneCodeOptions || []
        },
        {
            name: "address_1",
            label: "Company registered address",
            required: true
        },
        {
            name: "address_2",
            label: "Company trade address",
            required: true
        },
        {
            name: "country_id",
            label: "Country of Registration",
            options: countryOptions || [],
            type: "select",
            required: true,
            handleChange: handleCountryChange
        },
        {
            name: "state_id",
            label: "State",
            options: states,
            type: "select",
            required: true,
            handleChange: handleStateChange
        },

        {
            name: "city_id",
            label: "City",
            options: cities,
            type: "creatable",
            required: true
        },
        {
            name: "postal_code",
            label: "Postal code",
            required: true
        }
    ]

    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={entityDetails}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    {schemaList.map((schema) => {
                        return schema.type === "phone" ? (
                            <Box
                                key={schema.name}
                                sx={{ mb: 4, display: "flex" }}
                            >
                                <SelectWrapper
                                    size="normal"
                                    name={schema.codeName}
                                    label={schema.codeLabel}
                                    options={schema.codeOptions}
                                    fullWidth={false}
                                    required
                                    sx={{ width: "200px" }}
                                />
                                <TextFieldWrapper
                                    size="normal"
                                    name={schema.name}
                                    label={schema.label}
                                    required
                                />
                            </Box>
                        ) : (
                            <Box mb={4} key={schema.name}>
                                <FormField
                                    size="normal"
                                    name={schema.name}
                                    label={schema.label}
                                    type={schema.type}
                                    handleChange={schema.handleChange}
                                    options={schema.options}
                                    required={schema.required}
                                />
                            </Box>
                        )
                    })}
                    <FormAction isLoading={isLoading} />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
