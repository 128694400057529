import { Formik, Form, Field } from "formik"
import { useCallback } from "react"
import FormField from "../../components/FormField"
import RadioGroupWrapper from "../../components/RadioGroupWrapper"
import RadioSection from "../../components/RadioGroupSection"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import { Box } from "@mui/material"
import { sortInAscendingOrder } from "constant/helpersUtility"

export const THIRD_PARTY_TYPE = [
    { label: "UBO", value: "ubo" },
    { label: "SHAREHOLDER", value: "shareholder" },
    { label: "PRINCIPAL", value: "principal" },
    { label: "SIGNATORY", value: "signatory" },
    { label: "TRUSTEE", value: "trustee" },
    { label: "SETTLOR", value: "settlor" },
    { label: "PROTECTOR", value: "protector" },
    { label: "MANAGEMENT", value: "management" },
    { label: "BOARD", value: "board" }
]

const stateOwned = [
    {
        value: "Yes",
        label: "Yes",
        subtext: "Party is a State Owned Entity or Politically Exposed Person."
    },
    {
        value: "No",
        label: "No",
        subtext:
            "Party is not a State Owned Entity or Politically Exposed Person."
    }
]

const validationSchema = Yup.object({
    name: Yup.string().trim().required("Party name required"),
    type: Yup.string().trim().required("Party type required"),
    email: Yup.string().email().required("Email required"),
    address: Yup.string().required("Address required"),
    nationality: Yup.string().required("Nationality required"),
    country_id: Yup.string().required("Country required"),
    is_pep: Yup.string()
        .oneOf(["No"], "Party must not be politically exposed")
        .required("Party must not be politically exposed")
})

const AddForm = ({ handleSubmit, defaultValues, children }) => {
    const {
        dropDowns: { countries }
    } = useSelector((state) => state.selfOnboard)

    // eslint-disable-next-line
    const countryOptions = useCallback(
        countries.map(({ label, id }) => ({
            label,
            value: id
        })),
        [countries]
    )

    const schemaList = [
        {
            name: "type",
            label: "Party type",
            required: true,
            options: sortInAscendingOrder(THIRD_PARTY_TYPE),
            type: "select"
        },
        {
            name: "name",
            label: "Party name",
            required: true
        },
        {
            name: "address",
            label: "Address",
            required: true
        },
        {
            name: "email",
            label: "Email",
            required: true
        },
        {
            name: "country_id",
            label: "Country",
            required: true,
            type: "select",
            options: countryOptions
        },
        {
            name: "nationality",
            label: "Nationality",
            required: true
        }
    ]

    return (
        <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <Form>
                {schemaList.map((schema) => (
                    <Box key={schema.name} mb={4}>
                        <FormField
                            size="normal"
                            name={schema.name}
                            label={schema.label}
                            type={schema.type}
                            options={schema.options}
                            required={schema.required}
                        />
                    </Box>
                ))}
                <RadioSection title="Is Party a State Owned Entity or Politically Exposed Person?">
                    <Field
                        name="is_pep"
                        component={RadioGroupWrapper}
                        options={stateOwned}
                    />
                </RadioSection>
                {children}
            </Form>
        </Formik>
    )
}
export default AddForm
