import {
    Typography,
    List,
    Stack,
    ListItemText,
    IconButton,
    ListItem,
    Tooltip
} from "@mui/material"
import { AttachmentOutlined, DeleteOutlined } from "@mui/icons-material"
import { memo } from "react"

const KycDocumentList = ({ handleDeleteClick, data }) => {
    return (
        <List
            sx={{
                border: "1px solid",
                borderColor: "gray.300",
                borderRadius: "12px"
            }}
        >
            {data?.length > 0 ? (
                <>
                    {data.map(({ url, name, field, id }) => (
                        <DocumentItem
                            attachmentType={field}
                            attachmentName={name}
                            id={id}
                            handleClick={() =>
                                handleDeleteClick({ url, field, id })
                            }
                        />
                    ))}
                </>
            ) : (
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "center",
                        py: 2,
                        fontSize: 14,
                        fontWeight: 500
                    }}
                >
                    No attachments available
                </Typography>
            )}
        </List>
    )
}

const DocumentItem = ({ handleClick, attachmentType, attachmentName, id }) => {
    return (
        <ListItem
            sx={{
                borderBottom: "1px solid",
                borderColor: "gray.300",
                px: 4,
                py: 1,
                "&:last-of-type": {
                    borderBottom: 0
                }
            }}
        >
            <ListItemText>
                <Stack
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        alignItems: "center"
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        <AttachmentOutlined sx={{ color: "gray.400" }} />
                        <Typography variant="subtitle2" ml={1} mr={1}>
                            {attachmentName}{id && <span style={{marginLeft: "4px"}}>({id})</span>}
                        </Typography>
                    </Stack>
                    <Typography
                        variant="section2"
                        sx={{ fontWeight: 400, text: "15px", ml: 4 }}
                    >
                        {attachmentType}
                    </Typography>
                    <Stack direction="row" ml="auto" gap={0}>
                        <Tooltip title="Delete document">
                            <IconButton onClick={handleClick}>
                                <DeleteOutlined sx={{ color: "red.600" }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            </ListItemText>
        </ListItem>
    )
}
export default memo(KycDocumentList)
