import { Formik, Form } from "formik"
import { CheckboxWrapper } from "components/common/Ui/Form"

import * as Yup from "yup"
import FormWrapper from "../components/FormWrapper"
import { Typography, Box, Link } from "@mui/material"
import FormAction from "../components/FormAction"
import { useSelector } from "react-redux"
import { completeSelfRegistration } from "services/prospectsServices"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import TermsOfServiceModal from "./TermsOfServiceModal"

// ** constants
const FAILED_MSG =
    "We encountered an issue completing your onboarding. Please try again."
const SUCCESS_MSG = "Welcome! You've completed the onboarding process."
const title = "Review & Submit"
const subtitle =
    "Please confirm that all the information provided is accurate and complete. If any details need to be updated, feel free to go back and make the necessary changes before submission."
const terms =
    "I hereby confirm that the information I have provided is true and accurate. I acknowledge that all information on the Kairos platform, including within the Deal Syndication Module (Confidential Deals Section), is strictly confidential. I agree not to share, discuss, or use this information outside the platform, and I will not, under any circumstances, contact any associated companies directly without prior written consent from Kairos."

const validationSchema = Yup.object({})

export default function IndividualKyc() {
    const { token } = useSelector((state) => state.selfOnboard)
    const { post, isLoading } = useSelfOnboardApi()
    const navigate = useNavigate()
    const [showTermsOfService, setShowTermsOfService] = useState(false)

    const handleOnboardCompletion = async () => {
        const resp = await post({
            apiCaller: completeSelfRegistration,
            payload: { token }
        })

        if (resp.status === 200) {
            toast.success(SUCCESS_MSG, {
                toastId: resp.status
            })

            localStorage.removeItem("self-onboard-data")
            navigate("/onboard-success")
        } else {
            toast.error(FAILED_MSG, { toastId: resp.status })
        }
    }

    return (
        <>
            <FormWrapper title={title} subtitle={subtitle}>
                <Formik
                    initialValues={{}}
                    validationSchema={validationSchema}
                    onSubmit={handleOnboardCompletion}
                >
                    <Form>
                        <Box
                            sx={{
                                px: 4,
                                py: 2,
                                mb: 2,
                                borderRadius: "12px",
                                border: "1px solid",
                                borderColor: "gray.300"
                            }}
                        >
                            <CheckboxWrapper
                                id="declaration"
                                required
                                name="declaration"
                                label="Declaration of correctness and confidentiality"
                            />
                            <Typography
                                variant="body2"
                                sx={{ color: "gray.500" }}
                            >
                                {terms}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                px: 4,
                                py: 2,
                                mb: 2,
                                borderRadius: "12px",
                                border: "1px solid",
                                borderColor: "gray.300"
                            }}
                        >
                            <CheckboxWrapper
                                id="termsOfService"
                                name="termsOfService"
                                required
                                label="Terms of Service and Confidentiality Agreement"
                            />
                            <Typography
                                variant="body2"
                                sx={{ color: "gray.500" }}
                            >
                                By checking this box, you agree to our{" "}
                                <Link
                                    color="brand.main"
                                    sx={{ fontWeight: 500 }}
                                    component="button"
                                    variant="body2"
                                    type="button"
                                    onClick={() => setShowTermsOfService(true)}
                                >
                                    Terms of Service and Confidentiality Agreement
                                </Link>
                            </Typography>
                        </Box>
                        <FormAction isLoading={isLoading} />
                    </Form>
                </Formik>
            </FormWrapper>
            <TermsOfServiceModal
                showModal={showTermsOfService}
                closeModal={() => setShowTermsOfService(false)}
            />
        </>
    )
}
