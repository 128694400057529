import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
//import * as api from "../../services/index";

export const getUserManagementDetails = createAsyncThunk(
    "usermanagement/getUserManDetails",
    async () => {
        return null
    }
)

const userManagementSlice = createSlice({
    name: "usermanagement",
    initialState: {
        loading: false,
        error: "",
        userManagementDetails: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserManagementDetails.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getUserManagementDetails.fulfilled, (state, action) => {
            state.loading = false
            state.userManagementDetails = action.payload
        })
        builder.addCase(getUserManagementDetails.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload?.message
        })
    }
    // extraReducers: {

    //   [getUserManagementDetails.pending]: (state, action) => {
    //     state.loading = true;
    //   },
    //   [getUserManagementDetails.fulfilled]: (state, action) => {
    //     state.loading = false;
    //     state.userManagementDetails = action.payload;
    //   },
    //   [getUserManagementDetails.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload?.message;
    //   },
    // },
})

export default userManagementSlice.reducer
